import { SyntheticEvent } from 'react'
import classnames from 'classnames'
import { InputMoney } from '@pig-common/components/InputMoney'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import { InfoCircleOutlined } from '@ant-design/icons'

export type WithdrawalFormProps = {
  labelText: string
  inputPlaceholder: string
  inputErrorMessage: string
  informationText: string
  buttonText: string
  isButtonDisabled: boolean
  isInputError: boolean
  onInputChange: (value: any) => void
  onInputBlur: (value: any) => void
  onButtonClick: () => void
  fullWidth?: boolean
}

const WithdrawalForm = ({
  labelText,
  inputPlaceholder,
  inputErrorMessage,
  informationText,
  buttonText,
  isButtonDisabled,
  isInputError,
  fullWidth,
  onInputChange,
  onInputBlur,
  onButtonClick,
}: WithdrawalFormProps): JSX.Element => {
  const handleSubmitForm = (e: SyntheticEvent) => {
    e.preventDefault()
  }

  return (
    <form
      onSubmit={handleSubmitForm}
      className="ps-withdrawal-form row justify-content-center"
    >
      <div
        className={classnames('col-12', {
          'col-md-6': !fullWidth,
          'col-md-12': fullWidth,
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor="withdrawalFormControlInput"
          className="ps-withdrawal-form__label"
        >
          {labelText}
        </label>
        <InputMoney
          title=""
          id="withdrawalFormControlInput"
          placeholder={inputPlaceholder}
          errorMessage={inputErrorMessage}
          maxAmount={100000}
          maxFloatingNumbers={0}
          isError={isInputError}
          onInputChange={onInputChange}
          isAllowDisplayOverMaxAmount
          onInputBlur={onInputBlur}
        />
      </div>
      <div className="w-100" />
      <div
        data-testid="withdrawal-form__information"
        className={classnames('col-12', {
          'col-md-6': !fullWidth,
          'col-md-12': fullWidth,
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <p className="ps-withdrawal-form__information" tabIndex={0}>
          <InfoCircleOutlined className="ps-withdrawal-form__caution-icon" />
          <span>{informationText}</span>
        </p>
      </div>
      <div className="w-100" />
      <div className="col-12">
        <Button
          id="withdrawal-form__btn"
          className="ps-withdrawal-form__btn"
          type={BUTTON_TYPE.CTA_1}
          size={BUTTON_SIZE.LG}
          disabled={isButtonDisabled}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    </form>
  )
}

export default WithdrawalForm
