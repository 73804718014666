import html2Canvas, { Options } from 'html2canvas'
import download from 'downloadjs'

export const captureAndDownload = (
  node: HTMLElement,
  options: Partial<Options> = {},
  fileName = 'screenshot.png',
) => {
  return html2Canvas(node, options).then((canvas) => {
    download(canvas.toDataURL(), fileName)
    return fileName
  })
}
