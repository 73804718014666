import { AxiosRequestConfig } from 'axios'
import { phpApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { BaseResponse } from '@pig-common/services/service.type'

export enum RECOVERY_USER_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  BLOCK = 'BLOCK',
  IN_ACTIVE = 'IN_ACTIVE',
}

export type RecoveryUserServiceRequest = {
  phoneNumber?: string // NOTE : please read this from cookies
  cid: string
  recoveryPhoneNumber: string
}

export type RecoveryUserServiceData = {
  status: RECOVERY_USER_STATUS
  message: string
  otp_token: string
  phone_number: string
  phone_number_status: string
  otp_ref_code: string
  otp_number: string
  otp_expire_date: Date
  otp_block_minutes: null
}

export type RecoveryUserServiceResponse = BaseResponse & {
  status: string
  data: RecoveryUserServiceData
}

export const RecoveryUserService = (
  request: RecoveryUserServiceRequest,
): Promise<RecoveryUserServiceResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/user/phone/verify-forget-pin',
    method: 'POST',
    data: {
      phone_number: request.phoneNumber,
    },
  }
  return fetchWithJSON<RecoveryUserServiceResponse>(
    phpApiInstance,
    requestConfig,
  )
}

export enum RESET_PIN_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  IN_ACTIVE = 'IN_ACTIVE',
  BLOCK = 'BLOCK',
}

export type ResetPinServiceRequest = {
  phoneNumber: string
  otpToken?: string
  otpKey?: string
  pin: string
}

export type ResetPinServiceData = {
  status: RESET_PIN_STATUS
  message: string
  cfid: string
  uid: string
  game_token: string
  CustomerName: string
  customer_code: string
}

export type ResetPinServiceResponse = BaseResponse & {
  status: string
  data: ResetPinServiceData
}

export const ResetPinService = (
  request: ResetPinServiceRequest,
): Promise<ResetPinServiceResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/user/reset-pin',
    method: 'PUT',
    data: {
      phone: request.phoneNumber,
      otp_token: request.otpToken,
      otp_key: request.otpKey,
      user_new_pin: request.pin,
    },
  }
  return fetchWithJSON<ResetPinServiceResponse>(phpApiInstance, requestConfig)
}
