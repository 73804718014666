import {
  SyntheticEvent,
  createElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import cx from 'classnames'
import {
  Button,
  Image,
  Modal,
  ProfileAvatar,
  notification,
} from '@pig-frontend/uikit'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { Dropdown, MenuProps } from 'antd'
import { EditOutlined, MoreOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { WEB_URL } from '@pig-common/constants/env'
import {
  GuildRoleType,
  IGuildDataResponse,
  IGuildInfo,
  IGuildMemberType,
  IOtherGuild,
} from '@pig-common/types/Guild.type'
import { Loading } from '@pig-common/components/Loading'
import useLeaveGuild from '@pig-common/hooks/useLeaveGuild'
import useGuild from '@pig-common/hooks/useGuild'
import { RequestListModal } from '@pig-common/components/guild'
import useExpelGuild from '@pig-common/hooks/useExpelGuild'
import useAssignRoleGuild from '@pig-common/hooks/useAssignRoleGuild'
import { guildRoleName } from '@pig-common/constants/guild'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import {
  GuildRoleOwnerImage,
  GuildRoleAdminImage,
  GuildRoleMemberImage,
} from '@pig-common/constants/images'
import useGuildVIPMember from '@pig-common/hooks/useGuildVIPMember'
import styles from './index.module.scss'

const dropdownItems = (member: IGuildMemberType): MenuProps['items'] => [
  {
    key: 'assign-role',
    label: member?.role === 'admin' ? 'ยกเลิกตำแหน่ง' : 'แต่งตั้งผู้ดูแล',
  },
  {
    key: 'expel',
    label: 'เชิญออก',
  },
]

const guildRoleImageMapper: Record<GuildRoleType, any> = {
  owner: GuildRoleOwnerImage,
  member: GuildRoleMemberImage,
  admin: GuildRoleAdminImage,
}

interface IMyGuildProps {
  guild?: IGuildInfo
  guildData?: IGuildDataResponse
  handleRequestGuild: (e: SyntheticEvent, guild: IOtherGuild) => void
  guildIDSlug?: string
}

export default function MyGuild({
  guild,
  guildData,
  handleRequestGuild,
  guildIDSlug,
}: IMyGuildProps) {
  const router = useRouter()
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const [isMountedDom, setMountedDom] = useState<boolean>(false)
  const { mutate: leaveGuild, isLoading: isLeavingGuild } = useLeaveGuild()
  const { isInitialLoading: isGetGuildLoading } = useGuild({
    enabled: false,
  })
  const { mutate: getVIPMember, data: memberVIP } = useGuildVIPMember()
  const { mutate: expelMember } = useExpelGuild()
  const { mutate: assignRole } = useAssignRoleGuild()
  const otherGuildFromSlug: IOtherGuild = useMemo(() => {
    return find(guildData?.otherGuild, ['guildId', guildIDSlug]) as IOtherGuild
  }, [guildData, guildIDSlug])

  useEffect(() => {
    setMountedDom(true)
  }, [])

  useEffect(() => {
    const members = guildData?.myGuild?.members
    const customerCodes: string[] = []
    if (!isEmpty(members)) {
      members?.map((member) => {
        customerCodes.push(member?.customerCode || '')
      })
      getVIPMember({ memberCustomerCodes: customerCodes })
    }
  }, [guildData?.myGuild?.members])

  const goToEditPage = () => {
    router.push(pageLinkCommon.guild.edit)
  }

  const handleCopyText = () => {
    notification.success({
      message: 'คัดลอกสำเร็จ',
    })
    navigator.clipboard.writeText(
      `${WEB_URL?.replace(/[//]$/g, '')}${pageLinkCommon.guild.index}/${
        guild?.guildId
      }`,
    )
  }

  const handleLeaveGuild = () => {
    Modal.confirm({
      title: (
        <div className={styles['leave-guild-modal']}>
          <span>
            {guild?.role === 'owner'
              ? 'สมาชิกทุกคนในกิลด์จะถูกเชิญออก คุณต้องการยุบกิลด์ใช่หรือไม่'
              : 'ต้องการออกจากกิลด์ใช่หรือไม่'}
          </span>
          <br />
          <span className={styles.danger}>
            *หากมีการเข้าร่วมกิจกรรมทัวร์นาเมนต์ใดๆ อยู่ จะถือว่าสละสิทธิ
            และสูญเสียเทิร์นสะสมทั้งหมดทันที
          </span>
        </div>
      ),
      okText: 'ตกลง',
      cancelText: 'ยกเลิก',
      onOk: () => leaveGuild({}),
    })
  }

  const handleClickDropdownMenu = (menu: any, member: IGuildMemberType) => {
    if (menu.key === 'assign-role') {
      Modal.confirm({
        title:
          member?.role === 'admin'
            ? `ยกเลิกตำแหน่งผู้ดูแล ${member?.nickname}`
            : `แต่งตั้ง ${member?.nickname} เป็นผู้ดูแล`,
        okText: 'ตกลง',
        cancelText: 'ยกเลิก',
        onOk: () => {
          assignRole({
            guildUid: guild?.guildUid || '',
            member: member?.customerCode || '',
            type: member?.role === 'admin' ? 'unassign' : 'assign',
          })
        },
      })
    } else {
      Modal.confirm({
        title: `ต้องการเชิญ ${member?.nickname} ออกจากกิลด์หรือไม่`,
        onOk: () => {
          expelMember({
            guildUid: guild?.guildUid || '',
            member: member?.customerCode || '',
          })
        },
        okText: 'ตกลง',
        cancelText: 'ยกเลิิก',
      })
    }
  }

  return (
    <div className={styles.container}>
      {(isLeavingGuild || isGetGuildLoading) && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div>
        <div className={styles.form}>
          <Image
            src={guild?.iconUrl || ''}
            alt="avatar guild"
            width={66}
            height={66}
          />
          <div className={styles.detail}>
            <div className={styles['title-row']}>
              <span className={styles.title}>{guild?.guildId}</span>
              <Button
                type="primary"
                size="small"
                className={styles['copy-btn']}
                onClick={handleCopyText}
              >
                คัดลอก
              </Button>
            </div>
            <div className={styles['description-row']}>
              <span>{guild?.description}</span>
              {guild?.role === 'owner' && !guildIDSlug && (
                <EditOutlined
                  role="button"
                  className={styles['edit-icon']}
                  onClick={goToEditPage}
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles['btn-container']}>
          {guildIDSlug ? (
            <>
              <span>จำนวนคน {guild?.numberOfMember}</span>
              {isMountedDom && isAuthenticated && !guildData?.myGuild && (
                <Button
                  className={cx(styles.small, styles.default)}
                  onClick={(e) => handleRequestGuild(e, otherGuildFromSlug)}
                >
                  {otherGuildFromSlug?.status === 'Applied'
                    ? 'ยกเลิก'
                    : 'สมัคร'}
                </Button>
              )}
            </>
          ) : (
            <>
              {guild?.role === 'owner' || guild?.role === 'admin' ? (
                <RequestListModal />
              ) : (
                <div />
              )}
              <Button
                className={cx(styles.small, styles.default)}
                onClick={handleLeaveGuild}
              >
                {guild?.role === 'owner' ? 'ยุบกิลด์' : 'ออกจากกิลด์'}
              </Button>
            </>
          )}
        </div>

        {!guildIDSlug && <span>จำนวนคน {guild?.numberOfMember}</span>}

        <div className={styles.list}>
          {guildData?.myGuild?.members?.map((member, i) => (
            <div className={styles.card} key={member?.uid}>
              <div className={styles['profile-avatar-container']}>
                <div className={styles.role}>
                  {createElement(
                    guildRoleImageMapper?.[member?.role || 'member'],
                    { width: '100%' },
                  )}
                </div>
                <ProfileAvatar
                  name={member?.avatar}
                  width={40}
                  height={40}
                  className={styles.avatar}
                  vip={{
                    level: memberVIP?.data?.[i]?.level,
                    tier: memberVIP?.data?.[i]?.tier,
                  }}
                />
              </div>
              <div className={styles.detail}>
                <span className={styles.name}>{member?.nickname}</span>
                <span className={styles.description}>
                  {guildRoleName[member?.role ?? 'member']}
                </span>
              </div>
              {guild?.role === 'owner' && member?.role !== 'owner' && (
                <Dropdown
                  arrow={{ pointAtCenter: true }}
                  trigger={['click']}
                  menu={{
                    items: dropdownItems(member),
                    onClick: (menu) => handleClickDropdownMenu(menu, member),
                  }}
                >
                  <MoreOutlined className={styles['more-icon']} />
                </Dropdown>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
