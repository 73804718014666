import * as React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { Badge } from '@pig-common/components/Badge'
import { screen } from '@pig-common/utils/responsive'

type IPillProps = {
  className?: string
  label?: string | JSX.Element
  content?: string
  onCopy?: () => void
}

const PillWrapper = styled.div`
  background: ${({ theme }) => theme['color-yellow-100']};
  border-radius: 100px;
  font-size: 20px;
  padding: 14px 20px;
  max-width: 390px;
  margin: auto;
  line-height: 1;
  @media ${screen.md} {
    font-size: 1rem;
    padding: 16px 20px;
  }
`

const PillRow = styled(Row)`
  flex-flow: unset;
`

const Label = styled.div``

const Content = styled.div`
  text-align: right;
  font-weight: 200;
  color: ${({ theme }) => theme['color-greyscale-500']};
`

const Pill: React.FunctionComponent<IPillProps> = ({
  className,
  content,
  label,
  onCopy,
}) => {
  const [_content, setContent] = React.useState('')

  React.useEffect(() => {
    // Force re-render content with fallback value due to unknow hydration error
    setContent(content || '')
  }, [content])

  return (
    <PillWrapper className={className}>
      <PillRow gutter={[8, 0]}>
        <Col flex="100px">
          <Label>{label}</Label>
        </Col>
        <Col flex="auto">
          <Content>{_content}</Content>
        </Col>
        <Col flex="70px">
          <Badge onClick={onCopy}>คัดลอก</Badge>
        </Col>
      </PillRow>
    </PillWrapper>
  )
}

export default Pill
