/* eslint-disable no-nested-ternary */
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'

import { TextLink } from '@pig-common/components/Button'
import { CreateAvatar } from '@pig-common/components/CreateAvatar'
import {
  useCookies,
  COOKIES_KEY,
  UtmCookiesType,
} from '@pig-common/utils/cookies'
import { useRegister } from '@pig-common/hooks/useRegister'
import { REFER_TYPE, REGISTER_BY } from '@pig-common/models/shared-types'
import { useLogin } from '@pig-common/hooks/useLogin'
import { LOGIN_STATUS } from '@pig-common/services/login'
import useStorage from '@pig-common/hooks/useStorage'
import { useLoading } from '@pig-common/hooks/useLoading'

export type RegisterWithLineProps = {
  data: any
  onError?: (error: any) => void
}

const RegisterWithLine = ({
  data,
  onError = (error?: any) => {},
}: RegisterWithLineProps) => {
  const router = useRouter()
  const { removeItem, getItem } = useStorage()
  const { callRegister } = useRegister()
  const { callLineLogin } = useLogin()
  const { isLoading, loading, stopLoading } = useLoading()
  const [, setAuth] = useRecoilState(authStateCommon.authState)
  const referrerObj = JSON.parse(getItem('referrer', 'session') || '{}')
  const isReferLink = referrerObj?.isValid && referrerObj?.psCode?.length > 0
  const [cookies, setCookies] = useCookies<string | Partial<UtmCookiesType>>([
    COOKIES_KEY.REGISTER,
    COOKIES_KEY.OTP,
    COOKIES_KEY.CFID,
    COOKIES_KEY.REFERRER,
    COOKIES_KEY.UTM,
  ])

  const onSubmit = async ({ nickName, avatar, referrer }: any) => {
    loading()
    let utmData
    if (cookies.utm) {
      const {
        firstVisitUrl,
        utmId,
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        utmTerm,
      } = cookies.utm as UtmCookiesType
      utmData = {
        firstVisitUrl,
        utm_id: utmId,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_term: utmTerm,
      }
    }
    const registerResponse = await callRegister({
      avatar,
      referrer,
      referrerType: !referrer
        ? undefined
        : isReferLink
        ? REFER_TYPE.LINK
        : REFER_TYPE.CODE,
      displayName: nickName,
      registerBy: REGISTER_BY.LINE,
      lineIdToken: `${router.query.id_token}`,
      utm: {
        ...utmData,
        url: utmData?.firstVisitUrl,
      },
    })
    if (registerResponse.status === 'SUCCESS') {
      const loginResponse = await callLineLogin({
        idToken: `${router.query.id_token}`,
      })
      if (loginResponse.Status === LOGIN_STATUS.SUCCESS) {
        setCookies(COOKIES_KEY.CFID, loginResponse.cfid)
        setCookies(COOKIES_KEY.UID, loginResponse.uid || '0000000000')
        setCookies(COOKIES_KEY.CUSTOMER_CODE, loginResponse.CustomerCode)
        setAuth({
          cfid: loginResponse.cfid,
          customerCode: loginResponse.CustomerCode,
          gameToken: loginResponse.GameToken,
          name: loginResponse.CustomerName,
          isFirstLogin: true,
          isLoginPhoneSuccess: true,
          isRegisterLineSuccess: true,
          userUID: loginResponse.UserUid || '',
        })
        removeItem('referrer', 'session')
        router.push('/lobby')
      }
    }
    stopLoading()
  }

  const onClickTextLink = () => {
    setCookies(COOKIES_KEY.LINE_DATA, JSON.stringify(data))
    router.push(
      { pathname: '/login', query: { line_id_token: router.query.id_token } },
      '/login',
    )
  }

  return (
    <div className="ps-register-with-line">
      <h3 className="ps-register-with-line__title">สมัครสมาชิก</h3>
      <CreateAvatar onSubmit={onSubmit} isLoading={isLoading} />
      <TextLink
        className="ps-register-with-line__already-member"
        onClick={onClickTextLink}
      >
        เคยสมัครด้วยหมายเลขโทรศัพท์แล้ว ?
      </TextLink>
    </div>
  )
}

RegisterWithLine.displayName = 'RegisterWithLine'
export default RegisterWithLine
