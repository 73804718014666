import { AxiosRequestConfig } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { CashTransactionHistory } from '@pig-common/hooks/useWalletHistory'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { PromotionDetailResponse } from '@pig-common/services/promotions/promotionDetail'

interface IBonusDetail {
  detail: CashTransactionHistory['detail']
}

const BonusDetail = ({ detail }: IBonusDetail) => {
  const [, promotionId] = detail.split(/-(?=[a-f0-9]{8}-)/i)
  const isPromotionIdValid = promotionId !== undefined
  const {
    isLoading,
    refetch,
    error,
    data: promotion,
  } = useQuery<PromotionDetailResponse>(
    ['promotionDetail', promotionId],
    async () => {
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/promotions/${promotionId}`,
        method: 'GET',
      }
      const result = await fetchWithJSON<PromotionDetailResponse>(
        pigspinApiInstance,
        requestConfig,
      )
      return result
    },
    {
      enabled: isPromotionIdValid,
    },
  )

  if (!isPromotionIdValid) {
    return <div>กำหนดเอง</div>
  }
  if (isLoading) {
    return <div>loading ...</div>
  }
  if (error) {
    return (
      <div>
        try again
        <span role="button" aria-hidden onClick={() => refetch()}>
          click here
        </span>
      </div>
    )
  }
  return <div>{promotion?.data.name}</div>
}

export default BonusDetail
