import { authStateCommon } from '@pig-common/recoils'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import findIndex from 'lodash/findIndex'
import { IGuildDataResponse } from '@pig-common/types/Guild.type'
import { CommonResponse } from '@pig-common/types/Api.type'
import useGuild from '@pig-common/hooks/useGuild'
import { notification } from '@pig-frontend/uikit'

export default function useReviewMemberRequestGuild() {
  const queryClient = useQueryClient()
  const { customerCode } = useRecoilValue(authStateCommon.authState)
  const { refetch: getGuild } = useGuild({ enabled: false })

  return useMutation({
    mutationFn: (params: guildApi.IReviewMemberRequestGuildParams) =>
      guildApi.reviewMemberRequest({
        ...params,
        customerCode,
      }),
    onSuccess: (data, params) => {
      // Remove requested from list
      if (data?.status === 'SUCCESS') {
        const guildData = queryClient.getQueryData([
          guildApi.key.get,
        ]) as CommonResponse<IGuildDataResponse>
        const memberRequestIndex = findIndex(
          guildData?.data?.myGuild?.requestApply?.list,
          ['uid', params?.requestUid],
        )
        delete guildData?.data?.myGuild?.requestApply?.list?.[
          memberRequestIndex
        ]
        guildData.data.myGuild.requestApply.total =
          (guildData?.data?.myGuild?.requestApply?.total || 0) - 1
        queryClient.setQueryData([guildApi.key.get], () => ({
          ...guildData,
        }))

        notification.success({
          message: data?.data?.message,
        })
        getGuild()
        return true
      } else {
        notification.error({
          message: data?.data?.message,
        })
        getGuild()
        return false
      }
    },
  })
}
