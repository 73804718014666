import { useRecoilValue } from 'recoil'
import { useMutation } from '@tanstack/react-query'
import { authStateCommon } from '@pig-common/recoils'
import { vipApi } from '@pig-common/api'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal } from '@pig-frontend/uikit'
import useVIPStatus from '@pig-common/hooks/useVipStatus'
import useVIPSummary from '@pig-common/hooks/useVipSummary'

interface IVIPRedeemRewardParams {
  rewardUID: string
}

export default function useVIPRedeemReward() {
  const { refetch: getVIPStatus } = useVIPStatus({ enabled: false })
  const { refetch: getVIPSummary } = useVIPSummary({
    enabled: false,
  })
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useMutation({
    mutationFn: ({ rewardUID }: IVIPRedeemRewardParams) =>
      vipApi.redeemReward({ customerCode, rewardUID }),
    onSuccess: (data) => {
      if (data?.status === 'ERROR') {
        Modal.error({
          icon: <InfoCircleOutlined />,
          title: <strong>{data?.data?.message}</strong>,
          content: data?.data?.detail,
          centered: true,
          okText: 'ตกลง',
        })
      } else {
        getVIPStatus()
        getVIPSummary()
      }
    },
  })
}
