import React, { useEffect, useState } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import styled from 'styled-components'
import moment from 'moment'
import { Radio, RadioChangeEvent } from 'antd'
import {
  BonusConfig,
  PromotionType,
  useProfileBonus,
} from '@pig-common/hooks/useProfileBonus'
import Timer, {
  TimeData,
  TIMER_TYPE,
} from '@pig-common/components/DailyQuests/Timer'
import AlertModal, {
  ALERT_MODAL_TYPE,
} from '@pig-common/components/Modal/AlertModal/AlertModal'
import ModalBonusToggle from '@pig-common/components/ProfileSetting/modalBonusToggle'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { findCashbackDateRangeOf } from '@pig-common/utils/bonus'
import { forceDateToISO } from '@pig-common/utils/datetime'
import { useResponsiveState } from '@pig-common/hooks/useResponsive'

const CashbackWrapper = styled.div``

const HighlightPrimaryText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme['color-violet-400']};
`

const cashbackOptionsColor = ({ disabled }: any) =>
  disabled ? '#ADC8B6' : '#408558'

const CashbackOptions = styled(Radio.Group)`
  margin: auto;
  color: ${cashbackOptionsColor};
  border-radius: 24px;
  overflow: hidden;
  border: 2px solid ${cashbackOptionsColor};
  padding: 2px;
  .ant-radio-button-wrapper {
    width: 169px;
    max-width: 50%;
    text-align: center;
    border-radius: 24px;
    font-size: 24px;
    color: ${cashbackOptionsColor};
    border: none;
    &::before {
      background-color: #ffffff !important;
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: ${cashbackOptionsColor};
    color: white !important;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):focus-within {
    box-shadow: 0 0 0 3px rgba(64, 133, 88, 0.12);
  }
`

const BonusToggle = ({
  profileBonusState,
  isBonusToggle,
  isEnable,
  getTimerBonusToggle,
  reactivateToggler,
  fetchProfileBonus,
  onChange,
}: {
  profileBonusState?: BonusConfig
  isBonusToggle?: boolean
  isEnable: boolean
  getTimerBonusToggle?: TimeData
  reactivateToggler: () => void
  fetchProfileBonus: () => void
  onChange: (param?: any) => void
}): JSX.Element => {
  const { setProfileBonus } = useProfileBonus()
  const [isActive, setActive] = useState<boolean | undefined>(
    isBonusToggle || undefined,
  )
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [bonusType, setBonusType] = useState<PromotionType>('DEPOSIT')
  const [isModalBonusToggleActive, setModalBonusToggleActive] =
    useState<boolean>(false)
  const { isMD } = useResponsiveState()

  const triggerSwitch = () => {
    setModalBonusToggleActive(!isModalBonusToggleActive)
  }
  const bonusToggling = async () => {
    try {
      const res = await setProfileBonus({
        newBonusState: !isActive,
        promotionType: profileBonusState?.promotion_type || 'DEPOSIT',
      })
      setModalBonusToggleActive(false)
      if (res && isEnable) {
        setActive(!isActive)
        onChange()
      }
    } catch (error) {
      setModalOpen(true)
    }
  }
  useEffect(() => {
    setActive(isBonusToggle)
  }, [isBonusToggle])
  useEffect(() => {
    setBonusType(profileBonusState?.promotion_type || 'DEPOSIT')
  }, [profileBonusState])
  const options = [
    { label: 'เติมเงิน', value: 'DEPOSIT' },
    { label: 'คืนยอดเสีย ', value: 'CASHBACK' },
  ]
  const onOptionChange = ({ target: { value } }: RadioChangeEvent) => {
    const selectingCashback = value === 'CASHBACK'
    const [startDate, endDate] = findCashbackDateRangeOf(
      new Date(),
      selectingCashback ? 'next' : 'latest',
    )
    const withFormatDate = (date: Date) =>
      moment(date).format('DD/MM/YY (HH:mm)')
    const content = selectingCashback ? (
      <div>
        <div>โบนัสจากการคืนยอดเสีย</div>
        <div>จะเริ่มต้นนับในรอบถัดไปวันที่</div>
        <HighlightPrimaryText>{`${withFormatDate(startDate)} - ${withFormatDate(
          endDate,
        )}`}</HighlightPrimaryText>
        <div>ท่านต้องการที่จะทำรายการต่อหรือไม่?</div>
      </div>
    ) : (
      <div>
        <div>เนื่องจากมีการเปลี่ยนแปลงการตั้งค่าโบนัส</div>
        <div>คุณจะไม่ได้รับเงินจากการคืนยอดเสียของ</div>
        <HighlightPrimaryText>{`${withFormatDate(startDate)} - ${withFormatDate(
          endDate,
        )}`}</HighlightPrimaryText>
        <div>ท่านต้องการที่จะทำรายการต่อหรือไม่?</div>
      </div>
    )

    NiceModal.show(AlertConfirmModal, {
      content,
      open: true,
      isShowIcon: false,
      onClose: () => {},
      onConfirm: async () => {
        if (!profileBonusState) return
        await setProfileBonus({
          newBonusState: isActive || false,
          promotionType: value,
        })
        fetchProfileBonus()
      },
    })
  }

  const CashbackDate = React.useMemo(() => {
    if (profileBonusState?.promotion_type === 'CASHBACK') {
      const [latestMonday, latestSunday] = findCashbackDateRangeOf(
        new Date(),
        'latest',
      )
      const [nextMonday] = findCashbackDateRangeOf(new Date(), 'next')
      const isCashbackEnable = moment(
        forceDateToISO(
          profileBonusState.latest_type_toggle_at || new Date().toISOString(),
        ),
      ).isBefore(moment(latestMonday))
      return (
        <>
          {isCashbackEnable
            ? `สิ้นสุดรอบวันที่ ${moment(latestSunday).format(
                'DD/MM/YY',
              )} (23:59)`
            : `รอบถัดไปวันที่ ${moment(nextMonday).format('DD/MM/YY')} (00:00)`}
        </>
      )
    }
    return null
  }, [profileBonusState?.promotion_type])

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div
            className={`col-5 col-md-2 d-flex align-items-center ps-profile__bonus-toggle-label ${
              isActive && 'ps-profile__bonus-toggle-label--active'
            }`}
          >
            <div className="ps-profile__bonus-toggle-flag" />
            รับโบนัส
          </div>
          <div className="col-7 col-md-1 d-flex align-items-center justify-content-end text-end">
            <div className="form-check form-switch col-12 d-flex justify-content-end">
              {isActive !== undefined && (
                <input
                  className="ps-profile__bonus-toggle-switch form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={isActive}
                  onClick={(e) => {
                    e.preventDefault()
                    triggerSwitch()
                  }}
                  onChange={() => {
                    triggerSwitch()
                  }}
                  disabled={!isEnable}
                />
              )}
            </div>
          </div>
          <CashbackWrapper className="offset-md-1 col-12 col-md-8">
            <div className="row">
              <div
                className="col-12 col-md-7 d-flex align-items-center"
                style={{ textAlign: 'center' }}
              >
                <CashbackOptions
                  disabled={!isActive}
                  value={bonusType}
                  options={options}
                  onChange={onOptionChange}
                  optionType="button"
                />
              </div>
              <div className="col-12 col-md-5 d-flex align-items-center">
                <div
                  className="ps-profile__bonus-toggle-timer"
                  style={{
                    margin: isMD ? 'initial' : 'auto',
                    textAlign: isMD ? 'left' : 'center',
                  }}
                >
                  {!isEnable && getTimerBonusToggle && (
                    <Timer
                      time={getTimerBonusToggle}
                      variants={TIMER_TYPE.COMPACT}
                      onTimeOut={reactivateToggler}
                    />
                  )}
                  {CashbackDate}
                </div>
              </div>
            </div>
          </CashbackWrapper>
        </div>
        <div className="row">
          <div className="col-6 col-md-2 d-flex align-items-center ps-profile__bonus-toggle-label ps-profile__bonus-toggle-label--active">
            <div className="ps-profile__bonus-toggle-flag" />
            แนะนำเพื่อน
          </div>
          <div className="col-6 col-md-1 d-flex align-items-center justify-content-end">
            <div className="form-check form-switch col-12 d-flex justify-content-end">
              <input
                className="ps-profile__bonus-toggle-switch form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-2 d-flex align-items-center ps-profile__bonus-toggle-label ps-profile__bonus-toggle-label--active">
            <div className="ps-profile__bonus-toggle-flag" />
            โปรโมชั่นอื่นๆ
          </div>
          <div className="col-6 col-md-1 d-flex align-items-center justify-content-end">
            <div className="form-check form-switch col-12 d-flex justify-content-end">
              <input
                className="ps-profile__bonus-toggle-switch form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <ModalBonusToggle
        active={isModalBonusToggleActive}
        onAccept={bonusToggling}
        onClose={triggerSwitch}
      />
      <AlertModal
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        type={ALERT_MODAL_TYPE.CONTACT_US}
      />
    </div>
  )
}

export default BonusToggle
