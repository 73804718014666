import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'

export enum FREE_IN_GAME_CLAIM_STATUS {
  CLAIMABLE = 'claimable',
  DISABLED = 'disabled',
}

export enum FREE_IN_GAME_TYPE {
  FREE_CREDIT = 'freecredit',
  FREE_SPIN = 'freespin',
}

export type FreeInGame = {
  index: number
  reward_uid: string
  title: string
  sub_title: string
  description?: string
  game_code?: string
  tickets: string
  type: FREE_IN_GAME_TYPE
  status: FREE_IN_GAME_CLAIM_STATUS
  is_out_of_stock: boolean
}

export type RedemptionHistoryDataProps = {
  transaction_uid: string
  reward_type: FREE_IN_GAME_TYPE
  transaction_code: string
  transaction_date: string
  title: string
  sub_title: string
  reward_unit: string
  bet_amount?: string
  game_code?: string
}

export type HistoryInGame = {
  month: string
  year: number
  data: RedemptionHistoryDataProps[]
}

export type FreeInGameData = {
  free_credit: FreeInGame[] | []
  free_spin: FreeInGame[] | []
  history: HistoryInGame[] | []
}

export type GetFreeInGameRequest = {
  userCode: string
}

type GetFreeInGameResponse = BaseResponse & {
  status: string
  data: FreeInGameData
}

const getFreeInGame = async (
  request: GetFreeInGameRequest,
): Promise<FreeInGameData[] | []> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${request.userCode}/shop/rewards`,
      method: 'GET',
    }

    const result = await fetchWithJSON<GetFreeInGameResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    const { data } = result

    return data || []
  } catch (error) {
    return []
  }
}

export default getFreeInGame
