/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px 10px;
  position: relative;
`

const Header = styled.div`
  margin: 0 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme['color-greyscale-400']};
`

const Underline = styled.span`
  text-decoration: underline;
`

const UplineDownline = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme['color-violet-300']};
  div {
    flex: 1;
    padding: 5px 3px;
    &:not(:first-child) {
      border-left: 1px solid ${({ theme }) => theme['color-violet-300']};
    }
  }
`

const Formula = styled.div`
  padding: 0.2rem 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme['color-violet-300']};
  font-weight: bold;
  font-size: 0.8rem;
  text-align: center;
`

const Detail = styled.div`
  height: 65vh;
  padding: 1rem 0.5rem;
  overflow-y: auto;
  h5 {
    line-height: 20px;
    font-size: 20px;
  }
  h6 {
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    line-height: 15px;
    font-size: 0.8rem;
  }
  hr {
    background-color: ${({ theme }) => theme['color-violet-200']};
    opacity: 1;
  }
  p {
    margin: 0.5rem 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    strong {
      font-weight: bold;
    }
  }
`

const AffiliateTAC = () => {
  return (
    <Wrapper>
      <Header>
        <h5>ข้อตกลงและเงื่อนไข</h5>
      </Header>
      <Detail>
        <div style={{ height: 1000 }}>
          <h5>ระบบแนะนำเพื่อน</h5>
          <p>
            คือคุณลักษณะบนเว็บไซต์ที่อนุญาตให้ผู้เล่นสามารถแนะนำผู้อื่น
            ให้มาสมัครเป็นสมาชิกเว็บไซต์ได้ โดยผู้เล่นที่แนะนำ
            <strong> (“ผู้แนะนำ”) </strong>
            จะได้รับผลประโยชน์ต่างๆ ตามที่ระบุไว้
            เมื่อผู้ถูกแนะนำทำตามเงื่อนไขที่ กำหนด
          </p>
          <UplineDownline>
            <div>
              <h5>ผู้แนะนำ</h5>
              <p>
                คือผู้ใช้งานที่ทำการชักชวน ผู้ใช้งานท่านอื่น ให้มาเป็นสมาชิก
                เว็บไซต์โดยผ่านทางลิงก์ผู้แนะนำ หรือการส่งรหัสผู้แนะนำให้
                ผู้ใช้งานที่ทำการสมัครสมาชิก กรอกในขั้นตอนการสมัคร
              </p>
            </div>
            <div>
              <h5>ผู้ถูกแนะนำ</h5>
              <p>
                คือผู้ใช้งานที่ทำการสมัครสมาชิก เว็บไซต์โดยผ่านทางลิงก์ผู้แนะนำ
                หรือมีการกรอกรหัสผู้แนะนำ ในขั้นตอนการสมัคร
              </p>
            </div>
          </UplineDownline>
          <hr />

          <h5>รายได้</h5>
          <p>คือรายได้ทั้งหมดที่ผู้แนะนำได้รับ โดยแบ่งออกเป็น 3 ช่องทาง</p>

          <h6>1.รายได้จากการยืนยันตัวตน</h6>
          <p>
            ผู้แนะนำจะได้รับรายได้จากการยืนยันตัวตน
            ทันทีที่ผู้ถูกแนะนำยืนยันตัวตนสำเร็จ โดยจะได้รับโบนัส 50 บาท
            เข้ากระเป๋าโบนัส ต้องทำเทิร์น 40 เท่า ถอนได้สูงสุด 50 บาท
          </p>
          <h6>2.รายได้จากเพื่อนทำเทิร์น (หลังจากการยืนยันตัวตน)</h6>
          <p>
            ผู้แนะนำจะได้รับโบนัส 50 บาท
            <ul style={{ paddingLeft: 20, marginBottom: 0 }}>
              <li>ต้องทำเทิร์น 40 เท่า</li>
              <li>ถอนได้สูงสุด 50 บาท</li>
              <li>เมื่อผู้ถูกแนะนำทำเทิร์นด้วยเงินสดของ ครบ 500 บาท</li>
            </ul>
            <br />
            <u>ตัวอย่างการคำนวณ</u>
            <br />
            นาย A แนะนำนาย B มาสมัคร เมื่อนาย B ทำเทิร์นด้วยเงินสดถึง 500 บาท
            นาย A จะได้รายได้เข้ากระเป๋าโบนัส 50 บาท ต้องทำเทิร์น 40 เท่า
            ถอนได้สูงสุด 50 บาท
          </p>
          <h6>3.รายได้จากยอดเสีย</h6>
          <p>
            ผู้แนะนำจะได้รับรายได้จากยอดเสียของผู้ถูกแนะนำทั้งสิ้น เป็นระยะเวลา
            150 วัน นับจากวันที่สมัครสำหรับสมาชิกใหม่หรือนับ
            ตั้งแต่วันเริ่มโปรโมชั่นสำหรับสมาชิกปัจจุบัน โดยมีวิธีการคิดดังนี้
          </p>
          <Formula>
            5% x (ยอดเงินคงเหลือจากเดือนก่อนหน้า +
            <br />
            ยอดเติมทั้งหมดในเดือน - ยอดถอนทั้งหมดในเดือน -
            <br />
            ยอดเงินคงเหลือตอนสิ้นเดือน)
          </Formula>
          <p>
            <Underline>ตัวอย่างการคำนวณ</Underline>
            <br />
            ถ้านาย B เติมเงินทั้งเดือน 5,000 บาท และมีการถอนไปทั้งเดือน 1,000
            บาท ไม่มียอดเงินคงเหลือตอนสิ้นเดือน นาย A จะได้รับรายได้จาก นาย B
            เป็นเงินทั้งสิ้น = 5% x (5,000 - 1,000) = 200 บาท
          </p>
          <p>
            รายได้จากยอดเสียจะถูกโอนเข้ากระเป๋ารายได้ ภายในวันที่ 1
            ของเดือนถัดไป
            <br />
            <Underline>ตัวอย่างการจ่ายรายได้</Underline>
            <br />
            นาย A แนะนำนาย B มาวันที่ 15 มกราคม 2565 นาย A
            จะได้รับรายได้ยอดเสียจากนาย B ทั้งหมด 4 รอบ ดังนี้
            <br />
            รอบที่ 1: 15 ม.ค. - 31 ม.ค. (16 วัน) โดยได้รับยอดเสียวันที่ 1 ก.พ.
            <br />
            รอบที่ 2: 1 ก.พ. - 28 ก.พ. (28 วัน) โดยได้รับยอดเสียวันที่ 1 มี.ค.
            <br />
            รอบที่ 3: 1 มี.ค. - 31 มี.ค. (31 วัน) โดยได้รับยอดเสียวันที่ 1 เม.ย.
            <br />
            รอบที่ 4: 1 เม.ย. - 15 เม.ย. (15 วัน) โดยได้รับยอดเสียวันที่ 1 พ.ค.
          </p>
          <hr />
          <h5>กระเป๋ารายได้</h5>
          <p>
            คือกระเป๋าพิเศษที่แยกออกมาจาก กระเป๋าเงินสด และกระเป๋าโบนัส
            โดยกระเป๋ารายได้จะเก็บรายได้ของผู้แนะนำที่ได้รับจากช่องทางรายได้
            ทั้งสามทางข้างต้น เงินในกระเป๋ารายได้ไม่สามารถนำไปเล่นเกมได้
            ผู้แนะนำสามารถถอนเงินจากกระเป๋ารายได้เข้าบัญชีธนาคารได้ ตามต้องการ
          </p>
          <hr />
          <p>
            ผู้ให้บริการขอสงวนสิทธิ์ในการ แก้ไข ยกเลิก หรือเรียกคืนข้อตกลงและ
            รายได้ โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
          </p>
        </div>
      </Detail>
    </Wrapper>
  )
}

export default AffiliateTAC
