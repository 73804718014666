import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import useGuild from '@pig-common/hooks/useGuild'
import { notification } from '@pig-frontend/uikit'

export default function useLeaveGuild() {
  const { customerCode } = useRecoilValue(authStateCommon.authState)
  const { refetch: getGuild } = useGuild({ enabled: false })

  return useMutation({
    mutationFn: (params: guildApi.ILeaveGuildParams) =>
      guildApi.remove({
        customerCode: params?.customerCode || customerCode || '',
      }),
    onSuccess: (data) => {
      // Refresh guild data after leaving current guild success
      if (data?.status === 'SUCCESS') {
        notification.success({
          message: 'ดำเนินการสำเร็จ',
        })
      } else {
        notification.error({
          message: data?.data?.message || data?.data?.detail,
        })
      }
      getGuild()
    },
  })
}
