import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import { Images } from '@pig-common/components/Images'
import { withdrawSuccessImage } from '@pig-common/constants/images'
import styled from 'styled-components'
import { StaticImageData } from 'next/image'

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
`

type imgKeyName = 'success'

const withdrawImageMapper: Record<imgKeyName, StaticImageData> = {
  success: withdrawSuccessImage,
}

export type WithdrawalContentProps = {
  imageSrc: imgKeyName
  imageAlt: string
  content: string
  buttonText: string
  onButtonClick: () => void
}

const WithdrawalContent = ({
  imageSrc,
  imageAlt,
  content,
  buttonText,
  onButtonClick,
}: WithdrawalContentProps): JSX.Element => {
  return (
    <div className="ps-withdrawal-content">
      <StyledImageContainer>
        <Images
          src={withdrawImageMapper[imageSrc]}
          width={160}
          height={122}
          alt={imageAlt}
          quality={75}
        />
      </StyledImageContainer>
      <div className="row justify-content-center">
        <div className="col-8">
          <p
            dangerouslySetInnerHTML={{ __html: content }}
            className="ps-withdrawal-content__message"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12">
          <Button
            type={BUTTON_TYPE.PLAIN_TEXT}
            onClick={onButtonClick}
            className="ps-withdrawal-content__btn"
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default WithdrawalContent
