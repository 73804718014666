import { useState, useRef } from 'react'
import { MAXIMUM_DISPLAYABLE_BALANCE } from '@pig-common/models/runtime-constant'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import {
  InformationBox,
  INFO_BOX_VARIANT,
} from '@pig-common/components/InformationBox'
import {
  InputMoney,
  InputMoneyHandles,
} from '@pig-common/components/InputMoney'
import AddMoneyButton from './AddMoneyButton'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const ADD_MONEY_LIST = [50, 100, 300, 600]
const MAX_DEPOSIT_AMOUNT = MAXIMUM_DISPLAYABLE_BALANCE

type DepositQRFirstStepProps = {
  isLoading?: boolean
  onClickConfirmButton?: (amount: number) => void
}

const DepositQRFirstStep = ({
  onClickConfirmButton = () => {},
  isLoading,
}: DepositQRFirstStepProps) => {
  const [moneyAmount, setMoneyAmount] = useState<number | undefined>()
  const [isInvalidAmount, setIsInvalidAmount] = useState(false)
  const moneyInputRef = useRef<InputMoneyHandles>(null)

  // NOTE : i18n
  const moneyInputTitle = 'ระบุจำนวนเงิน'
  const errorMessage = 'คุณสามารถเติมเงินได้ตั้งแต่ 1 บาทขึ้นไป'
  const moneyPlaceholder = '500'
  const moneyInputInfoMessage = 'เติมเงินขั้นต่ำ 100 บาทขึ้นไป'
  const generateQRButtonText = 'ยืนยันจำนวนเงิน'

  const checkInvalidAmount = (amount?: number) => {
    if (amount === undefined) return false
    if (amount > MAX_DEPOSIT_AMOUNT) return true
    if (amount >= 1 && !Number.isInteger(amount)) return false
    if (amount <= 0 || !Number.isInteger(amount)) return true
    return false
  }

  const inputChangeHandler = (val: number | undefined) => {
    setMoneyAmount(val)
    setIsInvalidAmount(checkInvalidAmount(val))
  }

  const plusMoney = (amount: number) => {
    let moneyToAdd = amount
    if (amount + (moneyAmount || 0) > MAX_DEPOSIT_AMOUNT) {
      moneyToAdd = MAX_DEPOSIT_AMOUNT - (moneyAmount || 0)
    }
    moneyInputRef.current?.increaseAmount(moneyToAdd)
    setMoneyAmount((prevAmount) => {
      setIsInvalidAmount(checkInvalidAmount((prevAmount || 0) + moneyToAdd))
      return (prevAmount || 0) + moneyToAdd
    })
  }

  return (
    <>
      <InputMoney
        id="money-input"
        ref={moneyInputRef}
        title={moneyInputTitle}
        placeholder={moneyPlaceholder}
        errorMessage={errorMessage}
        maxAmount={MAX_DEPOSIT_AMOUNT}
        maxFloatingNumbers={0}
        onInputChange={inputChangeHandler}
        onInputBlur={inputChangeHandler}
        isError={isInvalidAmount}
        isAllowDisplayOverMaxAmount={false}
        disabled={isLoading}
      />
      <div id="money-button-group" className="ps-deposit-qr__add-money-group">
        {ADD_MONEY_LIST.map((amount, index) => {
          return (
            <AddMoneyButton
              id={`add-money-item-${amount}__btn`}
              key={`add-money-item-${amount}`}
              className="ps-deposit-qr__add-money-item"
              amount={amount}
              onClick={() => plusMoney(amount)}
              disabled={isLoading}
            />
          )
        })}
      </div>
      <InformationBox
        id="top-up-information-box"
        width="100%"
        content={moneyInputInfoMessage}
        prependContent={
          <ExclamationCircleOutlined style={{ marginLeft: 10 }} />
        }
        variant={INFO_BOX_VARIANT.INFO}
      />
      <Button
        id="confirm-button"
        className="ps-deposit-action-button mx-auto"
        type={BUTTON_TYPE.CTA_1}
        size={BUTTON_SIZE.LG}
        onClick={() => onClickConfirmButton(moneyAmount || 0)}
        disabled={!moneyAmount || moneyAmount < 1 || isLoading}
      >
        {generateQRButtonText}
      </Button>
    </>
  )
}

export default DepositQRFirstStep
