import { useQuery } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IGuildAvatarImage } from '@pig-common/types/Guild.type'

interface IListIconGuildType<SelectReturnType = IGuildAvatarImage[]> {
  select?: (data: CommonResponse<IGuildAvatarImage[]>) => SelectReturnType
  enabled?: boolean
  refetchInterval?: number
}

export default function useListIconGuild<
  SelectReturnType = IGuildAvatarImage[],
>({ select, enabled }: IListIconGuildType<SelectReturnType>) {
  return useQuery({
    queryKey: [guildApi.key.listIcon],
    queryFn: () => guildApi.listIcon(),
    enabled,
    select: (data) => (select ? select(data) : data) || [],
  })
}
