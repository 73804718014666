import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button'
import Image from 'next/image'

type TutorialProps = {
  openTutorial?: () => void
}

const Tutorial = ({ openTutorial }: TutorialProps) => {
  return (
    <div className="ps-bigwin-card-tutorial ps-bigwin-card-tutorial--tutorial">
      <div className="ps-bigwin-card-tutorial__top">
        <div className="ps-bigwin-card-tutorial__avatar">
          <Image
            alt="Tutorial Icon"
            src={'/images/dailyquests/tutorials/tutorial.png'}
            className="ps-bigwin-card-tutorial__avatar-img"
            fill
          />
        </div>
        <div className="ps-bigwin-card-tutorial__title">
          ได้รับ Tickets ฟรี จากภารกิจแชมป์เปี้ยน
        </div>
      </div>
      <div className="ps-bigwin-card-tutorial__bottom">
        <div className="ps-bigwin-card-tutorial__tickets" />
        <Button
          className="ps-bigwin-card-tutorial__button"
          id="bigwin-tutorial-button"
          type={BUTTON_TYPE.CTA_1}
          size={BUTTON_SIZE.SM}
          onClick={openTutorial}
        >
          <label>เริ่มเลย</label>
        </Button>
      </div>
    </div>
  )
}

export default Tutorial
