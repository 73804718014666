import moment from 'moment'
import { IProfileData } from '@pig-common/types/Profile.type'

export const forceDateToISO = (dateStr: string): Date => {
  let result = dateStr.toLocaleUpperCase()
  if (result.slice(-1) !== 'Z') {
    result += 'Z'
  }
  return new Date(result)
}

export const diffExpiredDate = (date: string, inDays: number): number => {
  const formatDate = moment(forceDateToISO(date.replace(/z/g, ''))).add(
    inDays,
    'days',
  )
  if (!formatDate.isValid()) return 1
  return formatDate.diff(moment(), 'seconds')
}

export const getEditPhoneInSeconds = (profile?: IProfileData): number => {
  if (profile && !profile.lastPhoneNumberUpdated) return 0
  if (profile) {
    return diffExpiredDate(profile.lastPhoneNumberUpdated, 7)
  }
  return -1
}

export const getEditDisplayNameInSeconds = (profile?: IProfileData): number => {
  if (profile && !profile.lastDisplayNameUpdated) return 0
  if (profile) {
    return diffExpiredDate(profile.lastDisplayNameUpdated, 30)
  }
  return -1
}

export const getEditBankInSeconds = (profile?: IProfileData) => {
  if (profile && !profile.lastBankAccountUpdated) return 0
  if (profile) {
    return diffExpiredDate(profile.lastBankAccountUpdated, 1)
  }
  return -1
}

export const enableEditPhone = (profile?: IProfileData) => {
  return getEditPhoneInSeconds(profile) === 0
}

export const enableEditDisplayName = (profile?: IProfileData) => {
  return getEditDisplayNameInSeconds(profile) === 0
}

export const enableEditBank = (profile?: IProfileData) => {
  return getEditBankInSeconds(profile) === 0
}
