import { ReactNode } from 'react'
import { errorNoticeImage } from '@pig-common/constants/images'
import styled from 'styled-components'
import Image from 'next/image'

export type ErrorNoticeProps = {
  title?: string
  subTitle: string | ReactNode
  size?: number
}
const ErrorImage = styled.div<{ size: number }>`
  margin: auto;
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  * {
    object-fit: contain;
  }
`
const ErrorNotice = ({ title, subTitle, size = 124 }: ErrorNoticeProps) => (
  <div className="ps-error-notice-layout">
    <ErrorImage size={size}>
      <Image fill src={errorNoticeImage} alt={'error'} />
    </ErrorImage>
    {title && <strong className="ps-error-notice-title">{title}</strong>}
    <span className="ps-error-notice-subtitle">{subTitle}</span>
  </div>
)

export default ErrorNotice
