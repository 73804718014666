import { ALERT_TYPE } from '@pig-common/components/Alert'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import CommonAlertModal from '../Modal/CommonAlertModal/CommonAlertModal'
import { ClockCircleOutlined } from '@ant-design/icons'

type ModalProps = {
  active: boolean
  onAccept: () => void
  onClose: (param: any) => void
}

const ModalBonusToggle = ({ active, onAccept, onClose }: ModalProps) => {
  const footer = (
    <div className="d-flex justify-content-end mt-3">
      <Button
        type={BUTTON_TYPE.PLAIN_TEXT}
        size={BUTTON_SIZE.LG}
        onClick={() => onClose(false)}
        className="p-1 lh-1 ps-text-greyscale-4"
      >
        ยกเลิก
      </Button>
      <Button
        type={BUTTON_TYPE.PLAIN_TEXT}
        size={BUTTON_SIZE.LG}
        onClick={() => onAccept()}
        className="p-1 lh-1 ps-text-primary-1"
      >
        ยืิินยัน
      </Button>
    </div>
  )

  return active ? (
    <CommonAlertModal
      id="alert-bonus-toggle"
      open={active}
      type={ALERT_TYPE.NONE}
      footer={footer}
      onClose={() => {
        return false
      }}
    >
      <div style={{ fontSize: '24px' }}>
        เมื่อเปลี่ยนแปลงการตั้งค่าโบนัสแล้ว
        <br />
        ท่านสามารถตั้งค่าได้อีกครั้ง
        <span className="fw-bold ps-text-primary--force text-nowrap">
          {' '}
          หลัง 24 ชั่วโมง &nbsp;
          <ClockCircleOutlined style={{ fontSize: '16px' }} />
        </span>
      </div>
    </CommonAlertModal>
  ) : (
    <> </>
  )
}

export default ModalBonusToggle
