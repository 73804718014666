import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from 'react'
import { useRecoilState } from 'recoil'
import { motion, useAnimation, Variants } from 'framer-motion'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'

import {
  StepperChildrenProps,
  StepperChildrenHandles,
} from '@pig-common/components/Stepper'
import {
  InputDigit,
  InputDigitHandles,
} from '@pig-common/components/InputDigit'
import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'
import { TOTAL_PIN_CONFIG } from '@pig-common/models/runtime-constant'
import { backButtonStateCommon } from '@pig-common/recoils'

const motionVariant: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.45 } },
}

export type ConfirmProps = StepperChildrenProps

const SetPinV2 = forwardRef<StepperChildrenHandles, ConfirmProps>(
  ({ next }, ref) => {
    const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
    const [userPin, setUserPin] = useState<string>('')
    const [disabled, setDisabled] = useState(false)
    const [, setBackButton] = useRecoilState(
      backButtonStateCommon.backButtonState,
    )
    const digitInputRef = useRef<InputDigitHandles>(null)
    const [isConfirmPin, setIsConfirmPin] = useState(false)

    const controls = useAnimation()

    const getChildrenData = () => {
      const pin = userPin

      return {
        pin,
      }
    }

    useImperativeHandle(ref, () => ({
      getChildrenData,
    }))

    const setUpPinHanlder = async (pin: string) => {
      digitInputRef.current?.clearInput()
      setUserPin(pin)
      await controls.start('initial')
      setIsConfirmPin(true)
      await controls.start('animate')
      setBackButton({
        onBack: async () => {
          setIsConfirmPin(false)
          await controls.start('initial')
          await controls.start('animate')
          setUserPin('')
        },
      })
    }

    useEffect(() => {
      if (!isConfirmPin) {
        setBackButton({
          onBack: -1,
        })
      }
    }, [isConfirmPin])

    const confirmPinHandler = async (confirmPin: string) => {
      if (userPin !== confirmPin) {
        digitInputRef.current?.errorWithOutAnimation(
          'รหัส PIN ไม่ตรงกัน กรุณาลองใหม่อีกครั้ง',
        )
        await digitInputRef.current?.error()
        await controls.start('initial')
        setIsConfirmPin(false)
        await controls.start('animate')
        digitInputRef.current?.errorWithOutAnimation(
          'รหัส PIN ไม่ตรงกัน กรุณาลองใหม่อีกครั้ง',
        )
      } else {
        setDisabled(true)
        await refreshSystemStatus()
        next()
      }
      setUserPin(confirmPin)
    }

    const getTitle = () => {
      return isConfirmPin ? (
        <>
          <h5>ยืนยันรหัส PIN</h5>
          <p
            className="lh-1"
            style={{ marginTop: '8px', marginBottom: '44px', fontSize: '20px' }}
          >
            กรุณากรอกรหัส PIN ให้ตรงกัน
          </p>
        </>
      ) : (
        <>
          <h5>ตั้งรหัส PIN</h5>
          <p
            className="lh-1"
            style={{ marginTop: '8px', marginBottom: '24px', fontSize: '20px' }}
          >
            {autoLineBreakerWithBr(
              `กรอกตัวเลข ${TOTAL_PIN_CONFIG} ตัว หลีกเลี่ยง เลขเรียง เลขตอง และเลขซ้ำ`,
            )}
          </p>
        </>
      )
    }

    const title = useMemo(getTitle, [isConfirmPin])

    React.useEffect(() => {
      return () => {
        setBackButton({ onBack: -1 })
      }
    }, [])

    return (
      <motion.div
        className="text-center"
        style={{ marginTop: '40px' }}
        variants={motionVariant}
        animate={controls}
      >
        {title}
        <InputDigit
          ref={digitInputRef}
          onComplete={isConfirmPin ? confirmPinHandler : setUpPinHanlder}
          disabled={disabled}
          total={TOTAL_PIN_CONFIG}
        />
      </motion.div>
    )
  },
)

SetPinV2.displayName = 'SetPinV2'
export default SetPinV2
