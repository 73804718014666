import React, { useRef, useState, useEffect } from 'react'

import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'

import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import {
  CheckPhoneResponse,
  CHECK_PHONE_RESULT,
  useCheckPhone,
} from '@pig-common/hooks/useCheckPhone'
import {
  useVerifyOtp,
  VerifyOtpResult,
  VERIFY_OTP_STATUS,
} from '@pig-common/hooks/useVerifyOtp'

import {
  RecoveryUserService,
  RecoveryUserServiceResponse,
  RECOVERY_USER_STATUS,
} from '@pig-common/services/resetPin/resetPin'

import {
  AlertContactModal,
  AlertModal,
  ALERT_MODAL_TYPE,
} from '@pig-common/components/Modal'
import {
  InputDigit,
  InputDigitHandles,
} from '@pig-common/components/InputDigit'
import { TextLink } from '@pig-common/components/Button/TextLink/TextLink'
import { backButtonStateCommon } from '@pig-common/recoils'

const RESEND_DALAY_SECOND = 30

export type OTP_DATA = {
  phoneNumber: string
  otpCode: string
  otpToken: string
  otpKey: string
}

export type OtpProps = {
  otpData: OTP_DATA
  onSuccess: (result: OTP_DATA) => void
  isRecovery?: boolean
  shouldShowSubTitleText?: boolean
  cid?: string
}

const Otp = ({
  otpData,
  onSuccess,
  isRecovery = false,
  shouldShowSubTitleText = false,
  cid = '',
}: OtpProps) => {
  const inputDigitRef = useRef<InputDigitHandles>(null)
  const [disabled, setDisabled] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [, setBackButton] = useRecoilState(
    backButtonStateCommon.backButtonState,
  )
  const [shouldShowContactModal, setShouldShowContactModal] = useState(false)
  const [currentOtpData, setCurrentOtpData] = useState(otpData)
  const [resendCountdown, setResendCoundown] =
    useState<number>(RESEND_DALAY_SECOND)
  const [alertModalType, setAlertModalType] = useState<ALERT_MODAL_TYPE>(
    ALERT_MODAL_TYPE.TOO_MANY_OTP_REQUEST,
  )
  const { pathname, back } = useRouter()

  const { data: systemStatus, refetch: refreshSystemStatus } = useSystemStatus({
    enabled: false,
  })
  const { callCheckPhone } = useCheckPhone()
  const { callVerifyOtp } = useVerifyOtp()

  const { phoneNumber, otpToken, otpCode } = currentOtpData

  const showContactModal = () => {
    setShouldShowContactModal(true)
    setOpenModal(true)
  }
  const showAlertModal = (type: ALERT_MODAL_TYPE) => {
    setShouldShowContactModal(false)
    setAlertModalType(type)
    setOpenModal(true)
  }

  // countdown resend timer
  useEffect(() => {
    const timer = setInterval(() => {
      if (resendCountdown > 0) {
        setResendCoundown(resendCountdown - 1)
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [resendCountdown])

  useEffect(() => {
    setBackButton({
      onBack: () => {
        back()
      },
    })
    return () => {
      setBackButton({ onBack: -1 })
    }
  }, [])

  const checkOtpStatus = async (response: VerifyOtpResult, otpKey: string) => {
    switch (response.status) {
      case VERIFY_OTP_STATUS.SUCCESS:
        onSuccess({ ...currentOtpData, otpKey })
        setDisabled(false)
        break
      case VERIFY_OTP_STATUS.ERROR:
        inputDigitRef.current?.error('รหัส OTP ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง')
        setDisabled(false)
        break
      case VERIFY_OTP_STATUS.EXPIRE:
        await inputDigitRef.current?.error('expire')
        break
      case VERIFY_OTP_STATUS.BLOCK:
        inputDigitRef.current?.error()
        showAlertModal(ALERT_MODAL_TYPE.TOO_MANY_OTP_ATTEMPT)
        setDisabled(false)
        break
      default:
        inputDigitRef.current?.error()
    }
  }

  const checkPhoneNumberStatus = (response: CheckPhoneResponse) => {
    switch (response.data.phone_number_status) {
      case CHECK_PHONE_RESULT.REGISTER:
        setCurrentOtpData({
          phoneNumber: currentOtpData.phoneNumber,
          otpCode: response.data.otp_ref_code,
          otpToken: response.data.otp_token,
          otpKey: '',
        })
        showAlertModal(ALERT_MODAL_TYPE.OTP_SENT)
        setTimeout(() => {
          setOpenModal(false)
        }, 3000)
        break
      case CHECK_PHONE_RESULT.ERROR:
        showAlertModal(ALERT_MODAL_TYPE.TOO_MANY_OTP_REQUEST_OR_ATTEMPT)
        break
      case CHECK_PHONE_RESULT.BLOCKED:
        showAlertModal(ALERT_MODAL_TYPE.PIN_LOCKED)
        break
      case CHECK_PHONE_RESULT.DISABLED:
      case undefined:
        showContactModal()
        break
      default:
        setAlertModalType(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
        setOpenModal(true)
    }
  }

  const checkRecoveryStatus = (response: RecoveryUserServiceResponse) => {
    switch (response.status) {
      case RECOVERY_USER_STATUS.SUCCESS:
        setCurrentOtpData({
          phoneNumber: currentOtpData.phoneNumber,
          otpCode: response.data.otp_ref_code,
          otpToken: response.data.otp_token,
          otpKey: '',
        })
        showAlertModal(ALERT_MODAL_TYPE.OTP_SENT)
        setTimeout(() => {
          setOpenModal(false)
        }, 3000)
        break
      case RECOVERY_USER_STATUS.ERROR:
        break
      case RECOVERY_USER_STATUS.BLOCK:
        showAlertModal(ALERT_MODAL_TYPE.TOO_MANY_OTP_REQUEST)
        break
      default:
        setAlertModalType(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
        setOpenModal(true)
    }
  }

  const otpInputHandler = async (otpKey: string) => {
    setDisabled(true)
    refreshSystemStatus()
    try {
      const response = await callVerifyOtp({
        phoneNumber,
        otpToken,
        otpKey,
      })
      if (response) checkOtpStatus(response, otpKey)
    } catch (error) {
      inputDigitRef.current?.error()
      setAlertModalType(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
      setOpenModal(true)
      setDisabled(false)
    }
  }

  const resendOtpHandler = async () => {
    setDisabled(true)
    try {
      await refreshSystemStatus()
      if (isRecovery) {
        const response = await RecoveryUserService({
          phoneNumber: currentOtpData.phoneNumber,
          cid,
          recoveryPhoneNumber: currentOtpData.phoneNumber,
        })
        if (response) checkRecoveryStatus(response)
      } else if (
        systemStatus?.register &&
        pathname.indexOf('register') !== -1
      ) {
        showAlertModal(ALERT_MODAL_TYPE.CLOSE_REGISTER)
      } else {
        const response = await callCheckPhone({
          phone: currentOtpData.phoneNumber,
          otpToken: currentOtpData.otpToken,
        })
        if (response) checkPhoneNumberStatus(response)
      }
    } catch (error) {
      setAlertModalType(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
      setOpenModal(true)
    } finally {
      inputDigitRef.current?.clearError()
      setResendCoundown(RESEND_DALAY_SECOND)
      setDisabled(false)
    }
  }

  const displayResendDelay = () => {
    const timerPrefix = resendCountdown >= 10 ? '00:00:' : '00:00:0'
    return (
      <div style={{ marginTop: '24px' }} data-testid="otp__resend-delay">
        ขอรหัส OTP ได้ใหม่ในอีก
        <span style={{ fontWeight: 800 }}>
          {` ${timerPrefix}${resendCountdown}`}
        </span>
      </div>
    )
  }

  const displayResendButton = () => {
    return (
      <div style={{ display: 'inline-block', marginTop: '24px' }}>
        <TextLink id="otp__resend-button" onClick={resendOtpHandler}>
          ขอรหัส OTP ใหม่
        </TextLink>
      </div>
    )
  }

  return (
    <>
      <div className="text-center">
        <h5 data-testid="otp__title">ยืนยันรหัส OTP</h5>
        {shouldShowSubTitleText && (
          <p className="lh-1" style={{ marginTop: '8px', fontSize: '20px' }}>
            ส่งรหัส OTP เรียบร้อยแล้ว
            <br />
            โปรดตรวจสอบ <strong>SMS</strong> ของคุณเพื่อยืนยันตัวตน
          </p>
        )}
        <InputDigit
          id="otp-code"
          label={`รหัสอ้างอิง: ${otpCode}`}
          ref={inputDigitRef}
          disabled={disabled}
          onComplete={otpInputHandler}
        />
        {resendCountdown > 0 ? displayResendDelay() : displayResendButton()}
      </div>
      {shouldShowContactModal ? (
        <AlertContactModal
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
        />
      ) : (
        <AlertModal
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
          type={alertModalType}
        />
      )}
    </>
  )
}

export default Otp
