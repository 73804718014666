import { AxiosRequestConfig } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { useAxios } from './useAxios'

export type Tournament = {
  tournament_name: string
  start_date: string
  payout_amount: number
}

export type OverAll = {
  total_turnover: number
  max_payout_ratio: number
  max_payout_amount: number
  casino_max_payout_amount: number
  casino_win_stack: number
}

export type OverviewData = {
  tournament: Tournament[]
  overall: OverAll
}

export type OverviewHook = {
  overviewData: OverviewData
  callOverview: () => void
}

const emptyOverview = {
  tournament: [],
  overall: {
    total_turnover: 0,
    max_payout_ratio: 0,
    max_payout_amount: 0,
    casino_max_payout_amount: 0,
    casino_win_stack: 0,
  },
}

export const useOverview = (): OverviewHook => {
  const { pigspinApiInstance } = useAxios()
  const { data: overviewData, refetch: callOverview } = useQuery<
    OverviewData,
    any
  >(
    ['OverviewData'],
    async () => {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/loyalty/user-stats/overall',
        method: 'GET',
      }
      const response = await fetchWithJSON<any>(
        pigspinApiInstance,
        requestConfig,
      )
      return response.data
    },
    {
      enabled: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
  return {
    overviewData: overviewData || emptyOverview,
    callOverview,
  }
}
