import NiceModal from '@ebay/nice-modal-react'
import SetKyc from '@pig-common/pages-common/profile/set-kyc'

function SetKycPage() {
  return (
    <NiceModal.Provider>
      <SetKyc />
    </NiceModal.Provider>
  )
}

export { SetKycPage }
