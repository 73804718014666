import { Steps } from 'antd'
import range from 'lodash/range'

export type StepperV2Props = {
  length: number
  currentStep: number
}

const { Step } = Steps

const StepperV2 = ({ length = 0, currentStep = 0 }: StepperV2Props) => {
  const getSafeCurrentStep = () => {
    if (currentStep <= 0) {
      return 0
    }
    if (currentStep > length) {
      return length - 1
    }
    return currentStep
  }

  const computedCurrentStep = getSafeCurrentStep()

  const renderChidrenSteps = () => {
    return range(length).map((n) => {
      let stepClassName = 'ps-stepper-v2__step-item'
      if (n < computedCurrentStep) {
        stepClassName += '--finish'
      }
      if (n === computedCurrentStep) {
        stepClassName += '--process'
      }
      return <Step key={`stepper-item-${n}`} className={stepClassName} />
    })
  }

  return (
    <Steps className="ps-stepper-v2" size="default" responsive={false}>
      {renderChidrenSteps()}
    </Steps>
  )
}

export default StepperV2
