import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import { GameIcon } from '@pig-common/components/GameIcon'
import { gameAssetUrl } from '@pig-common/utils/utils'
import { gameStateCommon } from '@pig-common/recoils'
import { GameItem } from '@pig-common/services/games'
import isInNewGameDuration from '@pig-common/utils/getNewGameDuration'
import ModalSuccession from '@pig-common/components/DailyQuests/modalSuccession'
import { AlertConfirmationModal } from '@pig-common/components/Modal'
import { FreeInGame } from '@pig-common/services/pigShop/getFreeInGame'
import { LoaderFilter } from '@pig-common/components/Loading/Loading'
import useRedeemReward from '@pig-common/hooks/useRedeemReward'

export type RedemptionCardProps = {
  id?: string
  gameCode?: string
  tickets?: string
  title: string
  item: FreeInGame
  subTitle: string
  description?: string
  isDisabled: boolean
  buttonText: string
  refetch?: () => void
  handleLoading: (trigger: boolean) => void
}

const RedemptionCard = ({
  id,
  gameCode,
  tickets,
  title,
  item,
  subTitle,
  description,
  isDisabled,
  buttonText,
  refetch,
  handleLoading,
}: RedemptionCardProps) => {
  const [visible, setVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const gamesState: GameItem[] = useRecoilValue(gameStateCommon.gameState)
  const game = gamesState.find(
    (gameItem: GameItem) => gameItem.game_code === gameCode,
  )
  const {
    mutate: redeemReward,
    isSuccess: isRedeemSuccess,
    data: redeemRewardResponse,
    reset: resetRedeemState,
  } = useRedeemReward()

  const redeemFn = () => {
    setLoading(true)
    handleLoading(true)
    redeemReward({ rewardUID: item.reward_uid })
    setVisible(false)
  }

  useEffect(() => {
    setLoading(false)
    handleLoading(false)
    refetch && refetch()
  }, [isRedeemSuccess])

  const RedemptionIcon = ({ gameData }: { gameData?: GameItem }) => {
    return (
      <div style={{ width: '44px', height: '44px' }}>
        {game && gameCode ? (
          <GameIcon
            src={gameAssetUrl(game, 'icon')}
            width={44}
            height={44}
            quality={75}
            alt={`${id}-image`}
            isCampaign={false}
            isNew={isInNewGameDuration(game?.created_at)}
            refType={game?.ref_type}
          />
        ) : (
          <span>
            <img src="/images/pig-shop/coin.svg" alt={id} />
          </span>
        )}
      </div>
    )
  }

  const redeemContent = (
    <div className="ps-quest-modal">
      <div data-testid="dailyquests-modal__congratulations-label">
        {redeemRewardResponse?.data?.message}
      </div>
      <div
        data-testid="dailyquests-modal__claim-tickets-label"
        className="ps-quest-modal__content"
      >
        {redeemRewardResponse?.data?.detail}
      </div>
    </div>
  )

  return (
    <div id={id} data-testid={id} className="ps-redemption-card">
      <LoaderFilter switcher={isLoading} />
      <ModalSuccession
        active={isRedeemSuccess}
        errorCode={`${redeemRewardResponse?.code}`}
        closeModalHandler={resetRedeemState}
        claimQuestTicketContent={redeemContent}
        messageError={redeemRewardResponse?.data?.message}
      />
      <AlertConfirmationModal
        open={visible}
        isShowIcon={false}
        header={`ยืนยันการ${title}`}
        content={`คุณต้องการใช้ ${tickets} Tickets ${title} ${subTitle} หรือไม่?`}
        onClose={() => setVisible(false)}
        onConfirm={redeemFn}
      />
      <div className="ps-redemption-card__content-wrapper">
        <RedemptionIcon gameData={game} />
        <div className="ps-redemption-card__content">
          <div className="ps-redemption-card__title">{title}</div>
          <div
            className="ps-redemption-card__sub-title"
            style={{ fontWeight: gameCode ? 700 : 400 }}
          >
            {subTitle}
            {description && <>{description}</>}
          </div>
        </div>
      </div>
      <div className="ps-redemption-card__button-wrapper">
        <Button
          id={`${id}-button`}
          type={BUTTON_TYPE.CTA_1}
          disabled={isDisabled || isLoading || visible}
          onClick={() => setVisible(true)}
          className="ps-redemption-card__button"
        >
          <div
            id={`${id}-button-icon`}
            className="ps-redemption-card__button-icon"
          >
            <img
              src={'/images/dailyquests/white-ticket.png'}
              alt="bigwin-ticket"
              className="ps-redemption-card__icon"
            />
          </div>
          <div id={`${id}-button-text`}>{buttonText}</div>
        </Button>
      </div>
    </div>
  )
}

export default React.memo(RedemptionCard)
