import styled from 'styled-components'
import { Table, TableProps } from 'antd'

import { screen } from '@pig-common/utils/responsive'
import NoResult from './NoResult'

export type IAffiliateTableProps<T> = TableProps<T>

const BaseTd = styled.td`
  text-align: center !important;
  &::before {
    display: none;
  }
`

const BaseTr = styled.tr`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme['color-greyscale-50']};
  }
`

const HeaderCell = styled(BaseTd)`
  background-color: ${({ theme }) => theme['color-yellow-200']} !important;
  font-size: 1rem;
  padding: 2px 0;
  @media ${screen.md} {
    padding: 8px 0;
    font-size: 1rem;
  }
`

const BodyCell = styled(BaseTd)`
  color: ${({ theme }) => theme['color-greyscale-300']};
  font-weight: 200;
  padding: 8px 0 !important;
  font-size: 0.85rem;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  @media ${screen.md} {
    font-size: 1rem;
  }
`

const AffiliateTable = ({
  dataSource,
  ...props
}: IAffiliateTableProps<any>) => {
  return (
    <>
      <Table
        dataSource={dataSource}
        components={{
          header: {
            cell: (cellProps: any) => {
              return <HeaderCell>{cellProps.children}</HeaderCell>
            },
          },
          body: {
            wrapper: (wrapperProps: any) => {
              if (dataSource && dataSource?.length < 1) {
                return null
              }
              return wrapperProps.children
            },
            cell: (cellProps: any) => {
              return <BodyCell>{cellProps.children}</BodyCell>
            },
            row: (rowProps: any) => {
              return <BaseTr>{rowProps.children}</BaseTr>
            },
          },
        }}
        {...props}
      />
      {dataSource && dataSource?.length < 1 && <NoResult isAffiliate />}
    </>
  )
}

export default AffiliateTable
