import { useEffect, useState } from 'react'
import { useModal } from '@ebay/nice-modal-react'
import { PigSpinLoading } from '@pig-common/components/Loading'

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const loadingModal = useModal(PigSpinLoading)

  const loading = () => {
    setIsLoading(true)
    loadingModal.show()
  }
  const stopLoading = () => {
    setIsLoading(false)
    loadingModal.remove()
  }

  useEffect(() => {
    return () => {
      loadingModal.remove()
    }
  }, [])

  return {
    isLoading,
    loading,
    stopLoading,
  }
}
