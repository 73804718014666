import { SpinHistory } from '@pig-common/hooks/useSpin'
import Table from '@pig-common/components/Table/Table'

const HistorySection = ({ data }: { data: SpinHistory[] }) => {
  return (
    <Table
      scrollBar
      data={data}
      rowStyle="white"
      columns={[
        {
          title: 'วันเวลา',
          dataIndex: 'createdAt',
          key: 'createdAt',
          styleType: 'datetime',
        },
        {
          title: 'เงินรางวัล',
          dataIndex: 'amount',
          key: 'amount',
          styleType: 'amount',
        },
      ]}
    />
  )
}

export default HistorySection
