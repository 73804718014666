import { useRef, useState } from 'react'
import { useRecoilState } from 'recoil'

import { TOTAL_PIN_CONFIG } from '@pig-common/models/runtime-constant'
import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'

import {
  InputDigit,
  InputDigitHandles,
} from '@pig-common/components/InputDigit'
import { backButtonStateCommon } from '@pig-common/recoils'

type SetupNewPinProps = {
  isLoading: boolean
  shouldDisabled: boolean
  title: string
  firstPinTitle: string
  firstPinInfo: string
  repeatedPinTitle: string
  repeatedPinInfo: string
  pinMismatch: string
  onComplete: (newUserPin: string) => Promise<void>
}

const SetupNewPin = ({
  isLoading,
  shouldDisabled,
  onComplete,
  title,
  firstPinTitle,
  firstPinInfo,
  repeatedPinTitle,
  repeatedPinInfo,
  pinMismatch,
}: SetupNewPinProps) => {
  const [isConfirmPinStep, setIsConfirmPinStep] = useState(false)
  const [userPin, setUserPin] = useState<string>('')
  const [, setBackButton] = useRecoilState(
    backButtonStateCommon.backButtonState,
  )

  const digitInputRef = useRef<InputDigitHandles>(null)

  const setPinHandler = (pin: string) => {
    setUserPin(pin)
    digitInputRef.current?.clearInput()
    setIsConfirmPinStep(true)
    setBackButton({
      onBack: async () => {
        setIsConfirmPinStep(false)
        setUserPin('')
      },
    })
  }

  const setConfirmPinHandler = async (userRepeatedPin: string) => {
    if (userPin === userRepeatedPin) {
      await onComplete(userRepeatedPin)
      digitInputRef.current?.clearInput()
    } else {
      await digitInputRef.current?.error(pinMismatch)
    }
    setIsConfirmPinStep(false)
  }

  const setupNewPinLabel = isConfirmPinStep
    ? {
        title: repeatedPinTitle,
        info: autoLineBreakerWithBr(repeatedPinInfo),
      }
    : {
        title: firstPinTitle,
        info: autoLineBreakerWithBr(firstPinInfo),
      }

  return (
    <div className="ps-setup-new-pin row justify-content-center">
      <h3
        data-testid="forget-pin-step3__sub-title"
        className="ps-setup-new-pin__title mx-auto"
      >
        {title}
      </h3>
      <h5
        data-testid="forget-pin-step3__description"
        className="ps-setup-new-pin__sub-title mx-auto"
      >
        {setupNewPinLabel.title}
      </h5>
      <span className="ps-setup-new-pin__description mx-auto">
        {setupNewPinLabel.info}
      </span>
      <div className="text-center">
        <InputDigit
          id="forget-pin-step3__new-pin"
          total={TOTAL_PIN_CONFIG}
          ref={digitInputRef}
          onComplete={isConfirmPinStep ? setConfirmPinHandler : setPinHandler}
          disabled={isLoading || shouldDisabled}
        />
      </div>
    </div>
  )
}

export default SetupNewPin
