import { intervalToDuration } from 'date-fns'
import { LanguageType } from './common'

const localize = {
  'en-US': {
    YEAR: 'years',
    MONTH: 'months',
    DAY: 'days',
    HOUR: 'hours',
    MINUTE: 'minutes',
    SECOND: 'seconds',
  },
  th: {
    YEAR: 'ปี',
    MONTH: 'เดือน',
    DAY: 'วัน',
    HOUR: 'ชั่วโมง',
    MINUTE: 'นาที',
    SECOND: 'วินาที',
  },
}

const getDurationDate = (
  expireIn: Date,
  locale: LanguageType = 'th',
  baseDate = new Date(),
) => {
  const start = baseDate
  const { years, months, days, hours, minutes, seconds } = intervalToDuration({
    start,
    end: expireIn,
  })
  const { YEAR, MONTH, DAY, HOUR, MINUTE } = localize[locale] || localize.th
  if (start < expireIn) {
    if (years) {
      return `${years} ${YEAR}`
    }
    if (months) {
      return `${months} ${MONTH}`
    }
    if (days) {
      return `${days} ${DAY}`
    }
    if (hours) {
      return `${hours} ${HOUR} ${minutes} ${MINUTE}`
    }
    if (minutes || seconds) {
      return `${minutes} ${MINUTE}`
    }
  }

  return `0 ${MINUTE}`
}

export default getDurationDate
