import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { useRecoilState } from 'recoil'
import NiceModal from '@ebay/nice-modal-react'

import { registerStateCommon } from '@pig-common/recoils'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import {
  StepperChildrenProps,
  StepperChildrenHandles,
} from '@pig-common/components/Stepper'
import { InputDigitHandles } from '@pig-common/components/InputDigit'
import { InputPhone } from '../InputPhone'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import {
  CHECK_PHONE_RESULT,
  useCheckPhone,
} from '@pig-common/hooks/useCheckPhone'
import {
  AlertModalV2,
  AlertContactModalV2,
  ALERT_MODAL_TYPE,
} from '@pig-common/components/Modal'

export type PhoneFormProps = StepperChildrenProps

const PhoneFormStep = forwardRef<StepperChildrenHandles, PhoneFormProps>(
  ({ next, getStepData, previousStep }, ref) => {
    const [disabled, setDisabled] = useState(true)
    const digitInputRef = useRef<InputDigitHandles>(null)
    const [, setRegister] = useRecoilState(registerStateCommon.registerState)
    const stepConfirmPinData = getStepData(4)
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
    const { callCheckPhone } = useCheckPhone()

    useEffect(() => {
      // NOTE : To confirm created Ref.
      if (stepConfirmPinData?.isError === true && previousStep === 4) {
        setTimeout(() => {
          digitInputRef.current?.errorWithOutAnimation(
            'รหัส PIN ไม่ตรงกัน กรุณาลองใหม่อีกครั้ง',
          )
        })
      }
    }, [])

    const getChildrenData = () => {
      return { phoneNumber }
    }

    useImperativeHandle(ref, () => ({
      getChildrenData,
    }))

    const onPhoneNumberChange = (val: string, isInvalid: boolean) => {
      setDisabled(isInvalid)
      setPhoneNumber(val)
    }

    const getValidPhoneNumber = () => {
      let checkPhoneNumber = phoneNumber
      if (phoneNumber.length < 10) {
        checkPhoneNumber = `0${phoneNumber}`
      }
      return checkPhoneNumber
    }

    const showAlertModal = (type: ALERT_MODAL_TYPE) => {
      NiceModal.show(AlertModalV2, { type })
    }

    const checkPhoneHandler = async () => {
      setDisabled(true)
      refreshSystemStatus()

      const checkPhoneNumber = getValidPhoneNumber()
      const response = await callCheckPhone({ phone: checkPhoneNumber })
      await refreshSystemStatus()
      if (response.service_code === 'PIG-2002') {
        if (response?.data) {
          setRegister({
            phoneNumber: getValidPhoneNumber(),
            otpToken: response.data.otp_token,
            otpCode: response.data.otp_ref_code,
          })
        }
        if (
          response?.data?.phone_number_status === CHECK_PHONE_RESULT.REGISTER
        ) {
          setDisabled(false)
          next()
          return
        }
        if (
          response?.data.phone_number_status === CHECK_PHONE_RESULT.DISABLED
        ) {
          NiceModal.show(AlertContactModalV2, {})
          return
        }
        const isExistingPhone =
          response?.data.phone_number_status === CHECK_PHONE_RESULT.ACTIVE
        if (isExistingPhone) {
          NiceModal.show(AlertModalV2, {
            type: ALERT_MODAL_TYPE.DUPLICATED_ACCOUNT,
          })
          setPhoneNumber(checkPhoneNumber)
        }
      } else if (response.service_code === 'PIG-4004') {
        showAlertModal(ALERT_MODAL_TYPE.TOO_MANY_OTP_REQUEST_OR_ATTEMPT)
      } else if (response?.service_code === 'PIG-2003') {
        showAlertModal(ALERT_MODAL_TYPE.DUPLICATED_ACCOUNT)
      } else {
        showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
      }

      setDisabled(false)
    }

    return (
      <div className="mt-4" style={{ maxWidth: 400, margin: 'auto' }}>
        <InputPhone
          id="login-checkphone-input"
          className="ps-login__phone-input"
          title="หมายเลขโทรศัพท์"
          placeholder="กรอกหมายเลขโทรศัพท์"
          errorMessage="กรุณากรอกเบอร์มือถือให้ถูกต้อง เช่น 0100000000"
          onInputChange={onPhoneNumberChange}
          isError={phoneNumber.length > 0 && phoneNumber.trim().length === 0}
        />
        <Button
          buttonForm
          id="checkphone-next-button"
          type={BUTTON_TYPE.CTA_1}
          className="ps-login__button-submit m-auto mt-4"
          disabled={disabled}
          onClick={checkPhoneHandler}
          nativeType="submit"
        >
          ดำเนินการต่อ
        </Button>
      </div>
    )
  },
)

PhoneFormStep.displayName = 'PhoneFormStep'
export default PhoneFormStep
