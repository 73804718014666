import React from 'react'
import router from 'next/router'
import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'
import { Images } from '../Images'
import RedemptionHistoryCard from './RedemptionCard/RedemptionHistoryCard'
import HistoryGroup, { HISTORY_TYPE } from './HistoryGroup'

export type RedemptionHistoryProps = {
  id: string
  redemptionHistoryData: any
}

const redemptionHistoryCardSpawner = (data: any) => (
  <RedemptionHistoryCard historyData={data} />
)

const RedemptionHistory = ({
  id,
  redemptionHistoryData,
}: RedemptionHistoryProps) => {
  const isEmpty = redemptionHistoryData.length <= 0

  return (
    <div id={id} key={id} data-testid={id}>
      <div className="row">
        {!isEmpty ? (
          <HistoryGroup
            key={id}
            historyData={redemptionHistoryData}
            spawner={redemptionHistoryCardSpawner}
            type={HISTORY_TYPE.INGAME}
          />
        ) : (
          <div data-testid="" className="ps-pig-shop__empty-list">
            <Images
              src="/images/pig-shop/empty.png"
              alt="main-quest-error"
              width={178}
              height={120}
            />
            <div className="ps-pig-shop__empty-text">
              {autoLineBreakerWithBr('คุณยังไม่ได้แลกของรางวัล\nมาทำ ')}
              <span
                onClick={() => router.push('/daily-quests')}
                onKeyDown={() => router.push('/daily-quests')}
              >
                {autoLineBreakerWithBr('ภารกิจ')}
              </span>
              {autoLineBreakerWithBr(
                ' เพื่อเก็บ ticket\nเพื่อของรางวัลกันเถอะ',
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default RedemptionHistory
