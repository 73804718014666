import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { achievementApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IAchievementProps } from '@pig-common/types/Achievement.type'

interface IGetAchievementProps<
  SelectReturnType extends IAchievementProps = IAchievementProps,
> {
  select?: (data: CommonResponse<IAchievementProps>) => SelectReturnType
  enabled?: boolean
  refetchInterval?: number
}

export default function useAchievement({
  select,
  enabled,
}: IGetAchievementProps<IAchievementProps>) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useQuery({
    queryKey: [achievementApi.key.get],
    queryFn: () =>
      achievementApi.get({
        customerCode: customerCode || '',
      }),
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select ? select(data) : data?.data) || {},
  })
}
