import { useQuery } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IGuildBannerImage } from '@pig-common/types/Guild.type'

interface IListBannerGuildType<SelectReturnType = IGuildBannerImage[]> {
  select?: (data: CommonResponse<IGuildBannerImage[]>) => SelectReturnType
  enabled?: boolean
  refetchInterval?: number
}

export default function useListBannerGuild<
  SelectReturnType = IGuildBannerImage[],
>({ select, enabled }: IListBannerGuildType<SelectReturnType>) {
  return useQuery({
    queryKey: [guildApi.key.listBanner],
    queryFn: () => guildApi.listBanner(),
    enabled,
    select: (data) => (select ? select(data) : data) || [],
  })
}
