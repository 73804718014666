import { ReactNode } from 'react'
import { TRANSACTION_COLLAPSE_VARIANT } from './TransactionCollapse.type'
import BigNumber from 'bignumber.js'
import { coinIcon } from '@pig-common/constants/images'
import { ClockCircleFilled } from '@ant-design/icons'
import { Image } from '@pig-frontend/uikit'

export type BonusInformationHeaderLocaleText = {
  expireInTitle?: string
  bonusRemainTitle?: string
  currency?: string
}

export type BonusInformationHeaderProps = {
  index?: number
  status?: string
  variant?: TRANSACTION_COLLAPSE_VARIANT
  bonusName: string
  expireInTitle: string
  bonusRemainTitle: string
  expireIn: string
  bonusRemain: number
  currency: string
}

const iconMapper: Record<string, ReactNode> = {
  active: <ClockCircleFilled />,
}

const BonusInformationHeader = ({
  index = 0,
  status,
  variant = TRANSACTION_COLLAPSE_VARIANT.PRIMARY,
  bonusName,
  expireInTitle,
  expireIn,
  bonusRemainTitle,
  bonusRemain,
  currency = '฿',
}: BonusInformationHeaderProps) => {
  return (
    <div
      className={`ps-bonus-transaction-header ps-bonus-transaction-header--${status}`}
      data-testid="bonus-transaction-header__section"
    >
      {/* {label && <div data-testid="cash-transaction-header__label" className="ps-cash-transaction-header__label">{label}</div>} */}
      <div className="d-flex align-items-center flex-wrap ps-bonus-transaction-header__wrapper">
        <div
          className={`ps-bonus-transaction-header__icon ps-bonus-transaction-header__icon--${status}`}
          data-testid="cash-transaction-header__icon"
        >
          {iconMapper[status || 'active']}
        </div>
        <h5
          data-testid="bonus-transaction-header__title"
          className="ps-bonus-transaction-header__title"
        >
          {bonusName}
        </h5>
        {/* {isBonus && (
          <div className="ps-cash-transaction-header__bonus-flag">รับโบนัส</div>
        )} */}
      </div>
      <span
        data-testid="bonus-transaction-header__amount"
        className="ps-bonus-transaction-header__amount"
      >
        <span>{BigNumber(bonusRemain).toFormat(2)}</span>
        <Image
          src={coinIcon}
          alt="coin"
          className="ps-bonus-transaction-header__coin"
        />
      </span>
      <time
        data-testid="bonus-transaction-header__date-time"
        className="ps-bonus-transaction-header__date-time"
      >
        <span className="ps-bonus-transaction-header__id">{index + 1}</span>
        <span>
          {expireInTitle}: {expireIn}
        </span>
        {/* {dateTime} */}
      </time>
    </div>
  )
}

export default BonusInformationHeader
