import cx from 'classnames'
import { DropDownProps, Dropdown } from 'antd'
import find from 'lodash/find'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Image } from '@pig-frontend/uikit'
import { MenuProps } from 'antd/lib/menu'
import styles from './index.module.scss'

interface IImageSelectorProps extends DropDownProps {
  items?: MenuProps['items']
  className?: string
  selectedImageUrl?: string
  setSelectedImageUrl: (image: string) => void
}

export default function ImageSelector({
  items,
  className,
  selectedImageUrl,
  setSelectedImageUrl,
}: IImageSelectorProps) {
  const handleSelectImage = (selectedItem: any) => {
    const item = find(items, ['key', selectedItem?.key])
    if (item) setSelectedImageUrl(item?.key as string)
  }

  return (
    <Dropdown
      placement="bottomLeft"
      arrow
      menu={{ items, onClick: handleSelectImage }}
      overlayClassName={styles['dropdown-overlay']}
      getPopupContainer={(node) => node}
    >
      <div
        className={cx(
          styles.container,
          { [styles['selected-image']]: selectedImageUrl },
          className,
        )}
      >
        {!selectedImageUrl ? (
          <PlusCircleOutlined />
        ) : (
          <Image
            src={selectedImageUrl}
            alt="avatar guild pigbet"
            width={66}
            height={66}
          />
        )}
      </div>
    </Dropdown>
  )
}
