import NiceModal from '@ebay/nice-modal-react'
import Profile from '@pig-common/pages-common/profile'

function ProfilePage() {
  return (
    <NiceModal.Provider>
      <Profile />
    </NiceModal.Provider>
  )
}

export { ProfilePage }
