import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import useGuild from '@pig-common/hooks/useGuild'
import { notification } from '@pig-frontend/uikit'

export default function useAssignRoleGuild() {
  const { customerCode } = useRecoilValue(authStateCommon.authState)
  const { refetch: getGuild } = useGuild({
    enabled: false,
  })

  return useMutation({
    mutationFn: (params: guildApi.IAssignRoleGuildParams) =>
      guildApi.assignRole({
        ...params,
        customerCode: params?.customerCode || customerCode || '',
      }),
    onSuccess: (data) => {
      getGuild()
      if (data?.serviceCode === 'PIG-7019') {
        notification.success({
          message: data?.data?.message,
        })
      } else {
        notification.error({
          message: data?.data?.message,
        })
      }
    },
  })
}
