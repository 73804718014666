import { ReactNode } from 'react'
import styled from 'styled-components'

const WrapperTemporaryCloseOverlay = styled.div`
  position: relative;
`

export const GrayOverlay = styled.div`
  filter: grayscale(1);
  opacity: 0.25;
`

export type TemporaryCloseLabelStyle = {
  size?: 'sm' | 'md' | 'lg'
  textShadow?: string
  transform?: string
}
const WrapperTemporaryCloseLabel = styled.div<TemporaryCloseLabelStyle>`
  position: absolute;
  text-align: center;
  right: 0;
  left: 0;
  top: 50%;
  transform: ${({ transform }) => transform || 'translateY(-50%)'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme['color-greyscale-500']};
  font-size: ${({ size }) => {
    if (size === 'sm') return '0.8rem'
    if (size === 'md') return '1rem'
    if (size === 'lg') return '1.5rem'
    return '1rem'
  }};
  text-shadow: ${({ textShadow }) =>
    textShadow ||
    '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff'};
`

export type ITemporaryCloseOverlayProps = {
  label?: TemporaryCloseLabelStyle
  text?: string
  children?: ReactNode
}

const TemporaryCloseOverlay = ({
  label,
  text,
  children,
}: ITemporaryCloseOverlayProps) => {
  return (
    <WrapperTemporaryCloseOverlay>
      <GrayOverlay>{children}</GrayOverlay>
      <WrapperTemporaryCloseLabel {...label}>
        {text || 'ปิดปรับปรุง'}
      </WrapperTemporaryCloseLabel>
    </WrapperTemporaryCloseOverlay>
  )
}

export default TemporaryCloseOverlay
