import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { achievementApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { notification } from '@pig-frontend/uikit'
import { CommonErrorResponse } from '@pig-common/types/Api.type'

export default function useAchievementSetHighlight() {
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useMutation({
    mutationFn: (params: achievementApi.ISetAchievementSetHighlightParams) =>
      achievementApi.setHighlight({ customerCode, ...params }),
    onSuccess: (data) => {
      if (data?.status === 'ERROR') {
        notification.error({
          message: (data?.data as CommonErrorResponse)?.message,
        })
      }
    },
  })
}
