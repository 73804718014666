import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { Carousel } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import { useRouter } from 'next/router'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import {
  KycForm,
  KycFormHandler,
  KycSuccessModal,
} from '@pig-common/components/KYC'
import { useLoading } from '@pig-common/hooks/useLoading'
import { useKyc, KycFormType, COMMON_STATUS } from '@pig-common/hooks/useKyc'
import { BankForm } from '@pig-common/components/BankForm'
import {
  BankFormCallBack,
  BankFormHandler,
} from '@pig-common/components/BankForm/BankForm'
import { AlertContactModalV2 } from '@pig-common/components/Modal'
import { pageLinkCommon } from '@pig-common/constants/page-name'

export const SetKycPage = () => {
  const router = useRouter()
  const slider = React.useRef<CarouselRef>(null)
  const { loading, stopLoading } = useLoading()
  const { callCheckKyc, callSetupKyc } = useKyc()
  const { callProfile } = useLegacyProfile()
  const kycFormRef = React.useRef<KycFormHandler>(null)
  const bankFormRef = React.useRef<BankFormHandler>(null)
  const [kycFormState, setKycFormState] = React.useState<KycFormType>({
    cid: '',
    laserNumber: '',
    firstName: '',
    lastName: '',
    birthday: '',
  })

  const openKycSuccess = async () => {
    NiceModal.show(KycSuccessModal, {})
    await callProfile()
    router.replace(pageLinkCommon.profile.home)
  }

  const checkKycProfile = async (kycForm: KycFormType, skipBank = false) => {
    loading()
    const { cid, laserNumber, lastName, firstName, birthday } = kycForm
    const response = await callCheckKyc({
      cid,
      laserNumber,
      firstName,
      lastName,
      birthday,
    })
    if (response.Status === COMMON_STATUS.SUCCESS) {
      stopLoading()
      setKycFormState(kycForm)
      if (skipBank) {
        const kycResponse = callSetupKyc({
          cid,
          laserNumber,
          firstName,
          lastName,
          birthday,
          bank_account_name: `${firstName} ${lastName}`,
        })
        const [kycResult] = await Promise.all([kycResponse])
        stopLoading()
        if (kycResult.status === 'SUCCESS') {
          openKycSuccess()
          return
        }
        NiceModal.show(AlertContactModalV2, {
          header: 'ดำเนินการไม่สำเร็จ',
          content: 'ยืนยันตัวตนไม่สำเร็จ กรุณาลองอีกครั้ง',
        })
      } else {
        stopLoading()
        slider.current?.next()
        return
      }
    }
    stopLoading()
    kycFormRef.current?.handleVerifyKYCResponse(response)
  }

  const onBankFormFinish = async (form: BankFormCallBack) => {
    loading()
    if (form.result?.Status === 'Success') {
      const kycResponse = callSetupKyc({
        ...kycFormState,
        bank_code: form.selectedBank,
        bank_account_name: form.bankAccountName,
        bank_account_number: form.bankAccountNumber,
      })
      const [kycResult] = await Promise.all([kycResponse])
      stopLoading()
      if (kycResult.status === 'SUCCESS') {
        openKycSuccess()
        return
      }
      NiceModal.show(AlertContactModalV2, {
        header: 'ดำเนินการไม่สำเร็จ',
        content: 'ยืนยันตัวตนไม่สำเร็จ กรุณาลองอีกครั้ง',
      })
    }
    stopLoading()
  }

  React.useEffect(() => {
    bankFormRef.current?.setBankAccount({
      bankAccountName: `${kycFormState.firstName} ${kycFormState.lastName}`,
    })
    bankFormRef.current?.disableBankAccount(true)
  }, [kycFormState, bankFormRef.current])

  return (
    <Carousel
      draggable={false}
      ref={slider}
      dots={false}
      infinite={false}
      swipe={false}
    >
      <div className="ps-kyc">
        <div style={{ padding: '1rem' }}>
          <h3 className="text-center ps-kyc__title" style={{ fontSize: 42 }}>
            ยืนยันตัวตน
          </h3>
          <div
            className="text-center ps-kyc__sub-title"
            style={{ fontSize: 24 }}
          >
            เพื่อรับฟรีเครดิต
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4">
              <KycForm ref={kycFormRef} onSubmit={checkKycProfile} />
            </div>
          </div>
        </div>
      </div>
      <div className="ps-kyc">
        <div style={{ padding: '1rem' }}>
          <h3 className="text-center ps-kyc__title">บัญชีธนาคาร</h3>
          <div className="text-center ps-kyc__sub-title"> </div>
          <div className="row justify-content-center">
            <div
              className="col-12 col-md-6 col-lg-4"
              style={{ margin: 'auto', maxWidth: 500 }}
            >
              {kycFormState.cid && (
                <BankForm ref={bankFormRef} onFinish={onBankFormFinish} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  )
}

export default SetKycPage
