import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'

export enum REDEEM_PHYSICAL_REWARD_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type RedeemPhysicalRewardData = {
  message: string
  detail: string
}

export type RedeemPhysicalRewardRequest = {
  customerCode: string
  rewardUid: string
}

export type RedeemPhysicalRewardResponse = BaseResponse & {
  status: REDEEM_PHYSICAL_REWARD_STATUS
  data: RedeemPhysicalRewardData
}

const redeemPhysicalReward = async (
  request: RedeemPhysicalRewardRequest,
): Promise<RedeemPhysicalRewardResponse | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${request.customerCode}/shop/redeem/${request.rewardUid}`,
      method: 'POST',
    }

    const result = await fetchWithJSON<RedeemPhysicalRewardResponse>(
      pigspinApiInstance,
      requestConfig,
    )

    return result
  } catch (error) {
    return null
  }
}

export default redeemPhysicalReward
