import React from 'react'
import Image from 'next/image'
import { IMAGE_FOLDER_PATH } from '@pig-common/models/buildtime-constant'
import { OverviewData } from '@pig-common/hooks/useOverview'
import { numberWithCommas } from '@pig-common/utils/number'
import {
  OverviewContainer,
  OverviewTextContent,
  OverviewText,
  OverviewBody,
  TotalTurnover,
  Content,
  SubContent,
  Divider,
  Space,
} from './styled'

type IOverview = {
  overviewData: OverviewData
}

const Overview = ({ overviewData }: IOverview) => {
  return (
    <OverviewContainer>
      <OverviewBody>
        <TotalTurnover>
          {numberWithCommas(overviewData?.overall?.total_turnover)}
        </TotalTurnover>
        <OverviewText>เทิร์นโอเวอร์ทั้งหมด</OverviewText>
        <Content>
          <SubContent>
            <Image
              src={`${IMAGE_FOLDER_PATH}/assets/page-profile-details-overview-pig.webp`}
              width={40}
              height={17}
              alt="Overview Spin Image"
              style={{ marginBottom: '30px' }}
            />
            <OverviewTextContent>
              {numberWithCommas(overviewData?.overall?.max_payout_amount)}
            </OverviewTextContent>
            <OverviewText>จำนวนเงินสูงสุด</OverviewText>
            <Space />
            <OverviewTextContent>
              {numberWithCommas(overviewData?.overall?.max_payout_ratio)}
            </OverviewTextContent>
            <OverviewText>จำนวนเท่าสูงสุด</OverviewText>
          </SubContent>
          <Divider />
          <SubContent>
            <Image
              src={`${IMAGE_FOLDER_PATH}/assets/page-profile-details-overview-bet.webp`}
              width={40}
              height={17}
              alt="Overview Bet Image"
              style={{ marginBottom: '30px' }}
            />
            <OverviewTextContent>
              {numberWithCommas(
                overviewData?.overall?.casino_max_payout_amount,
              )}
            </OverviewTextContent>
            <OverviewText>จำนวนเงินสูงสุด</OverviewText>
            <Space />
            <OverviewTextContent>
              {numberWithCommas(overviewData?.overall?.casino_win_stack)}
            </OverviewTextContent>
            <OverviewText>ชนะติดต่อกันสูงสุด</OverviewText>
          </SubContent>
        </Content>
      </OverviewBody>
    </OverviewContainer>
  )
}

export default Overview
