import { AxiosRequestConfig } from 'axios'
import { useRecoilValue } from 'recoil'

import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { BaseApiResponse } from '@pig-common/services/service.type'

import { useAxios } from './useAxios'
import { authStateCommon } from '../recoils'
import { useQuery } from '@tanstack/react-query'

export type CoinWalletType = {
  results: CoinWalletResultType[]
  total: number
}

export enum COIN_WALLET_RESULT_STATUS {
  ACTIVE = 'active',
  CONVERTED = 'converted',
  /** @description When the wallet is cancelled by that user. (ex. The user withdraws the money) */
  CANCELLED = 'canceled',
  /** @description When the wallet is revoked by administer. */
  REVOKED = 'revoked',
  USED = 'used',
  EXPIRED = 'expired',
}

export type CoinWalletResultStatus =
  | 'active'
  | 'converted'
  | 'canceled'
  | 'revoked'
  | 'used'
  | 'expired'

export type CoinWalletResultType = {
  name: string
  initial_balance: number
  turn_over_ratio: number
  expire_at: string
  current_balance: number
  current_turnover: number
  converted_to_cash_amount: number
  uid: string
  is_active?: boolean
  is_converted_to_cash?: boolean
  status: CoinWalletResultStatus
  cash_wallet_transaction?: {
    uid: string
    transaction_amount: number
  }
  created_at?: string
}

export type CoinWalletResponse = BaseApiResponse<CoinWalletType>

export type CoinWalletHook = {
  coinWalletData: CoinWalletType
  callCoinWallet: () => void
}

export const useCoinWallet = (): CoinWalletHook => {
  const { pigspinApiInstance } = useAxios()
  const auth = useRecoilValue(authStateCommon.authState)

  const fetchCoinWallet = async (): Promise<CoinWalletResponse> => {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/wallet/${auth.userUID}/coin-wallet`,
      method: 'GET',
    }
    const coinWalletResponse = await fetchWithJSON<CoinWalletResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    return coinWalletResponse
  }
  const {
    data: coinWalletData = {
      results: [],
      total: 0,
    },
    refetch: callCoinWallet,
  } = useQuery(
    ['callCoinWallet'],
    async () => {
      const result = await fetchCoinWallet()
      return result?.data
    },
    {
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  )
  return {
    coinWalletData,
    callCoinWallet,
  }
}
