export enum UserType {
  PHONE_USER = 'verify-phone-only',
  PHONE_BANK_USER = 'verify-phone-bank',
  LINE_USER = 'verify-line',
  LINE_PHONE_USER = 'verify-line-phone',
  LINE_PHONE_BANK_USER = 'verify-line-phone-bank',
  VERIFY_ALL_USER = 'verify-all',
}

const checkUserType = (
  line_user_connect: boolean,
  phoneNumber: string,
  bankNumber: string,
) => {
  if (!line_user_connect && phoneNumber !== null && bankNumber === null) {
    return UserType.PHONE_USER
  }
  if (!line_user_connect && phoneNumber !== null && bankNumber !== null) {
    return UserType.PHONE_BANK_USER
  }
  if (line_user_connect && phoneNumber === null && bankNumber === null) {
    return UserType.LINE_USER
  }
  if (line_user_connect && phoneNumber !== null && bankNumber === null) {
    return UserType.LINE_PHONE_USER
  }
  if (line_user_connect && phoneNumber !== null && bankNumber !== null) {
    return UserType.LINE_PHONE_BANK_USER
  }
  return UserType.VERIFY_ALL_USER
}

export default checkUserType
