import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { readFlagApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import useGetReadFlag from './useGetReadFlag'

export default function useCreateReadFlag() {
  const { userUID } = useRecoilValue(authStateCommon.authState)
  const { refetch: getDailyQuestReadFlag } = useGetReadFlag({
    enabled: false,
    params: { fieldName: 'DAILY_QUESTS' },
  })

  return useMutation({
    mutationFn: ({
      flagStatus,
      fieldName,
    }: readFlagApi.ICreateReadFlagParams) =>
      readFlagApi.create({ userUID, flagStatus, fieldName }),
    onSuccess: (data) => {
      if (data?.status === 'SUCCESS') {
        getDailyQuestReadFlag()
      }
    },
  })
}
