import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import NiceModal from '@ebay/nice-modal-react'
import { useRouter } from 'next/router'
import { useRecoilValue } from 'recoil'
import { bankList } from '@pig-common/utils/utils'
import Head from 'next/head'
import styled from 'styled-components'
import Image from 'next/image'
import { REGISTER_TYPE } from '@pig-common/services/profile'
import { IBankAccountData } from '@pig-common/types/Profile.type'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { dateFormatConverter } from '@pig-common/utils/datetime'
import ChangePinModal from '@pig-common/components/Modal/ChangePinModal/ChangePinModal'
import { profileStateCommon } from '@pig-common/recoils'
import { authStateCommon } from '@pig-common/recoils'
import { BonusToggler } from '@pig-common/components/BonusToggler'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { GenericModal } from '@pig-common/components/Modal'
import Timer, {
  maptoTimeData,
  TIMER_TYPE,
} from '@pig-common/components/DailyQuests/Timer'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { AlertContactModalV2 } from '@pig-common/components/Modal'
import { LineButton } from '@pig-common/components/Button'
import { EditOutlined } from '@ant-design/icons'
import {
  getEditBankInSeconds,
  getEditDisplayNameInSeconds,
  getEditPhoneInSeconds,
} from '@pig-common/utils/profile'
import { ReactComponent as LineLogo } from '@pig-common/public/images/Line-Logo.svg'
import { notification, profileAvatarMapper } from '@pig-frontend/uikit'
import { useCookies } from 'react-cookie'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import UnlinkLineModal from '@pig-common/components/Modal/UnlinkLineModal/UnlinkLineModal'

const Divider = () => (
  <div className="ps-profile__container">
    <div className="row">
      <div className="col-12">
        <hr className="ps-profile__divider" />
      </div>
    </div>
  </div>
)

const StyledTimer = styled(Timer)`
  .${TIMER_TYPE.COMPACT_DATE} {
    color: #7f7f82;
    font-weight: 400;
    font-size: 20px;
  }
`

export default function Profile() {
  const router = useRouter()
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
  const { callProfile } = useLegacyProfile()
  const [isPinModalOpen, setIsPinModalOpen] = useState<boolean>(false)
  const [isUnlinkLineModalOpen, setIsUnlinkLineModalOpen] =
    useState<boolean>(false)
  const auth = useRecoilValue(authStateCommon.authState)
  const profile = useRecoilValue(profileStateCommon.profileState)
  const [cookies] = useCookies([COOKIES_KEY.UID])
  const phoneNumber = cookies?.uid

  useEffect(() => {
    callProfile()
  }, [])

  if (!profile) {
    return null
  }
  const { bankAccountList, registerType } = profile

  const first_name = profile?.firstName?.trim()
  const last_name = profile?.lastName?.trim()
  const identity_number = profile?.identityNumber?.trim()
  const back_identity_number = profile?.backIdentityNumber?.trim()
  const date_of_birth = profile?.dateOfBirth?.trim()
  const hasBank =
    profile?.bankAccountList && profile?.bankAccountList.length > 0
  const shouldShowPersonalInfo = !(
    isEmpty(first_name) &&
    isEmpty(last_name) &&
    isEmpty(identity_number) &&
    isEmpty(back_identity_number) &&
    isEmpty(date_of_birth)
  )
  const editNameInSeconds = getEditDisplayNameInSeconds(profile)
  const editBankInSeconds = getEditBankInSeconds(profile)
  const editPhoneInSeconds = getEditPhoneInSeconds(profile)

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    notification.success({ message: 'คัดลอกสำเร็จ', duration: 6 })
  }

  const PersonalInfoHeader = () => (
    <div className="ps-profile__container">
      <div className="ps-profile-header">
        <div className="ps-profile-header__profile">
          <Image
            src={profileAvatarMapper[profile?.avatar ?? 'pixel'].img}
            placeholder="blur"
            alt="user avatar"
            width={52}
            height={52}
          />
        </div>
        <div className="ps-profile-header__text my-auto">
          <div className="ps-profile__subtitle ps-profile__myprofile">
            <div>ยินดีต้อนรับ</div>
            {editNameInSeconds > 0 && (
              <StyledTimer
                time={maptoTimeData(editNameInSeconds)}
                variants={TIMER_TYPE.COMPACT_DATE}
                onTimeOut={() => {}}
                style={{ margin: 0 }}
              />
            )}
          </div>
          <div className="ps-profile-header__name ps-profile__myprofile">
            <span>{profile?.displayName}</span>
            <EditOutlined
              style={{ fontSize: '21px' }}
              onClick={() => {
                router.push('/profile/set-profile', '/profile/set-profile', {
                  scroll: true,
                })
              }}
              aria-hidden
            />
          </div>
          <div className="d-flex align-items-center">
            <div className="ps-profile-header__copy">
              รหัสผู้ใช้ : {auth.customerCode}
            </div>
            <div
              className="ps-profile-header__tag-btn"
              onClick={() => copyToClipboard(auth.customerCode as string)}
              aria-hidden
            >
              คัดลอก
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const PersonalInfoSection = () => (
    <div className="ps-profile__container">
      <div
        data-testid="profile__personal-info-title"
        className="ps-profile__title"
      >
        ข้อมูลส่วนตัว
      </div>
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div
              data-testid="profile__personal-info-first-name--label"
              className="ps-profile__subtitle"
            >
              ชื่อ
            </div>
          </div>
          <div className="row">
            <div
              data-testid="profile__personal-info-first-name--value"
              className="ps-profile__value"
            >
              {first_name || '-'}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div
              data-testid="profile__personal-info-last-name--label"
              className="ps-profile__subtitle"
            >
              นามสกุล
            </div>
          </div>
          <div className="row">
            <div
              data-testid="profile__personal-info-last-name--value"
              className="ps-profile__value"
            >
              {last_name || '-'}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div
              data-testid="profile__personal-info-id-card--label"
              className="ps-profile__subtitle"
            >
              เลขหน้าบัตร
            </div>
          </div>
          <div className="row">
            <div
              data-testid="profile__personal-info-id-card--value"
              className="ps-profile__value"
            >
              {registerType === REGISTER_TYPE.ALIEN
                ? '-'
                : identity_number || '-'}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div
              data-testid="profile__personal-info-back-id-card--label"
              className="ps-profile__subtitle"
            >
              เลขหลังบัตร
            </div>
          </div>
          <div className="row">
            <div
              data-testid="profile__personal-info-back-id-card--value"
              className="ps-profile__value"
            >
              {registerType === REGISTER_TYPE.ALIEN
                ? '-'
                : back_identity_number || '-'}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div
              data-testid="profile__personal-info-dob--label"
              className="ps-profile__subtitle"
            >
              วัน เดือน ปีเกิด (ค.ศ.)
            </div>
          </div>
          <div className="row">
            <div
              data-testid="profile__personal-info-dob--value"
              className="ps-profile__value"
            >
              {(registerType === REGISTER_TYPE.ALIEN && !date_of_birth) ||
              registerType === REGISTER_TYPE.NO_DOB
                ? '-'
                : dateFormatConverter(date_of_birth || '') || '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const ContactInfoSection = () => (
    <div className="ps-profile__container">
      <div
        data-testid="profile__contact-info-title"
        className="ps-profile__title"
      >
        <span>หมายเลขโทรศัพท์</span>
        {editPhoneInSeconds > 0 ? (
          <StyledTimer
            time={maptoTimeData(editPhoneInSeconds)}
            variants={TIMER_TYPE.COMPACT_DATE}
            onTimeOut={() => {}}
            style={{ margin: 0 }}
          />
        ) : (
          <EditOutlined
            style={{ fontSize: '21px' }}
            onClick={() => {
              router.push('/profile/set-phone', '/profile/set-phone', {
                scroll: true,
              })
            }}
            aria-hidden
          />
        )}
      </div>
      {profile?.phoneNumber && (
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div
                data-testid="profile__contact-info-phone--label"
                className="ps-profile__subtitle"
              >
                หมายเลขโทรศัพท์
              </div>
            </div>
            <div className="row">
              <div
                data-testid="profile__contact-info-phone--value"
                className="ps-profile__value"
              >
                {profile?.phoneNumber}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const BankAccountInfoSection = () => (
    <div className="ps-profile__container">
      <div
        className="ps-profile__title"
        data-testid="profile__bank-account-info-title"
      >
        <span>ข้อมูลธนาคาร</span>
        {editBankInSeconds > 0 ? (
          <StyledTimer
            time={maptoTimeData(editBankInSeconds)}
            variants={TIMER_TYPE.COMPACT_DATE}
            onTimeOut={() => {}}
            style={{ margin: 0 }}
          />
        ) : (
          <EditOutlined
            style={{ fontSize: '21px' }}
            onClick={() => {
              if (phoneNumber && !hasBank) {
                router.push('/profile/set-bank', '/profile/set-bank', {
                  scroll: true,
                })
              } else if (!phoneNumber && !hasBank) {
                router.push(
                  '/profile/set-phone-bank',
                  '/profile/set-phone-bank',
                  { scroll: true },
                )
              } else {
                NiceModal.show(AlertContactModalV2, {
                  header: 'ต้องการเปลี่ยนแปลงข้อมูล',
                  content: 'กรุณาติดต่อเจ้าหน้าที่เพื่อรับความช่วยเหลือ',
                })
              }
            }}
            aria-hidden
          />
        )}
      </div>
      {bankAccountList?.map((account: IBankAccountData) => {
        return (
          <div className="row" key={`bank-account-${account.bankCode}`}>
            <div className="col-6">
              <div className="row">
                <div
                  className="ps-profile__subtitle"
                  data-testid="profile__bank-account-info-name--label"
                >
                  ธนาคาร
                </div>
              </div>
              <div className="row">
                <div
                  className="ps-profile__value"
                  data-testid="profile__bank-account-info-name--value"
                >
                  {bankList[account?.bankCode || '']}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div
                  data-testid="profile__bank-account-info-number--label"
                  className="ps-profile__subtitle"
                >
                  หมายเลขบัญชี
                </div>
              </div>
              <div className="row">
                <div
                  data-testid="profile__bank-account-info-number--value"
                  className="ps-profile__value"
                >
                  {account.bankAccountNumber}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )

  const PinInfoSection = () => (
    <div className="ps-profile__container">
      <div className="ps-profile__section">
        <div
          className="ps-profile__title"
          data-testid="profile__pin-info-title"
        >
          รหัส PIN
        </div>
        <EditOutlined
          style={{ fontSize: '21px' }}
          data-testid="profile__pin-info-edit--button"
          onClick={() => {
            refreshSystemStatus()
            setIsPinModalOpen(true)
          }}
          aria-hidden
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className="ps-profile__subtitle"
            data-testid="profile__pin-info-code--label"
          >
            รหัส PIN 6 หลัก
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="ps-profile__value"
          data-testid="profile__pin-info-code--value"
        >
          ******
        </div>
      </div>
    </div>
  )

  const LineInfoSection = () => (
    <div className="ps-profile__container">
      <div className="ps-profile__title mb-2">
        <span>LINE</span>
        {profile?.phoneNumber && profile?.lineUserId && (
          <EditOutlined
            style={{ fontSize: '21px' }}
            data-testid="profile__pin-info-edit--button"
            onClick={() => {
              NiceModal.show(AlertConfirmModal, {
                centered: true,
                header: 'ยกเลิกการเชื่อมต่อบัญชี LINE',
                content: 'ยกเลิกการเชื่อมต่อบัญชี PIGBET กับบัญชี LINE ?',
                buttonCloseText: 'ไม่ต้องการ',
                onConfirm: () => {
                  refreshSystemStatus()
                  setIsUnlinkLineModalOpen(true)
                },
              })
            }}
            aria-hidden
          />
        )}
        {profile?.phoneNumber && !profile?.lineUserId && (
          <EditOutlined
            style={{ fontSize: '21px' }}
            onClick={() => {
              NiceModal.show(GenericModal, {
                centered: true,
                children: (
                  <div
                    className="bg-white p-2"
                    style={{
                      borderRadius: '10px',
                      minHeight: 100,
                      display: 'flex',
                    }}
                  >
                    <LineButton
                      redirectPath="/line/link"
                      wrapperStyle={{ margin: 'auto' }}
                    />
                  </div>
                ),
              })
            }}
            aria-hidden
          />
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-flex">
            <LineLogo width={19.87} height={19.87} />
            <div className="ps-profile__subtitle ms-1">
              {profile?.lineUserId ? 'เชื่อมต่อแล้ว' : 'ไม่มีการเชื่อมต่อ'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Head>
        <title>ข้อมูลส่วนตัว | PIGBET</title>
      </Head>
      <section className="container ps-profile ps-profile">
        {PersonalInfoHeader()}
        {shouldShowPersonalInfo && (
          <>
            <Divider />
            {PersonalInfoSection()}
          </>
        )}
        <Divider />
        {ContactInfoSection()}
        <Divider />
        {BankAccountInfoSection()}
        <Divider />
        {LineInfoSection()}
        <Divider />
        <BonusToggler />
        {profile?.phoneNumber && (
          <>
            <Divider />
            {PinInfoSection()}
          </>
        )}
        <ChangePinModal
          phoneNumber={phoneNumber}
          open={isPinModalOpen}
          closableBackDrop={false}
          onClose={() => {
            setIsPinModalOpen(false)
          }}
        />
        <UnlinkLineModal
          phoneNumber={phoneNumber}
          open={isUnlinkLineModalOpen}
          closableBackDrop={false}
          onClose={() => {
            setIsUnlinkLineModalOpen(false)
          }}
        />
      </section>
    </>
  )
}
