import dayjs from 'dayjs'
import { InboxAll, InboxItem } from '@pig-common/recoils/modules/inbox'

export const mapSystemStatusInbox = (allInbox: InboxAll, cookies: any) => {
  const systemInboxs = allInbox.systemStatus.inboxs.map((systemStatus) => {
    return {
      ...systemStatus,
      isRead:
        dayjs((cookies as any)[systemStatus.uid])
          .toDate()
          .getTime() === dayjs(systemStatus.date).toDate().getTime(),
    }
  })
  return systemInboxs.filter(
    (item) => item?.productType === undefined || item?.productType === 'PIGBET',
  )
}

export const getUnreadSystemStatusAmount = (allSystemStatus: InboxItem[]) => {
  return allSystemStatus.filter((systemStatus) => !systemStatus.isRead).length
}
