import React from 'react'

import { Input } from '@pig-common/components/Input'
import { numberWithoutCommas } from '@pig-common/utils/number'

export type InputMoneyProps = {
  id?: string
  className?: string
  title: string
  placeholder?: string
  errorMessage?: string
  maxAmount: number
  maxFloatingNumbers: number
  isError?: boolean
  isAllowDisplayOverMaxAmount?: boolean
  disabled?: boolean
  onInputChange: (amount?: number) => void
  onInputFocus?: () => void
  onInputBlur?: (amount?: number) => void
}

export type InputMoneyHandles = {
  clearValue: () => void
  increaseAmount: (amount: number) => void
}

const InputMoney = React.forwardRef<InputMoneyHandles, InputMoneyProps>(
  (
    {
      id = 'input-money',
      className,
      title,
      placeholder,
      errorMessage,
      maxAmount = Number.MAX_SAFE_INTEGER,
      isAllowDisplayOverMaxAmount = false,
      maxFloatingNumbers = 0,
      isError = false,
      disabled,
      onInputChange,
      onInputFocus = () => {},
      onInputBlur = () => {},
    }: InputMoneyProps,
    ref,
  ) => {
    const [displayAmount, setDisplayAmount] = React.useState('')

    const formatNumber = (rawNumber = '') => {
      return rawNumber
        .replace(/(\D|\.)/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const getStrippedValue = (amount = displayAmount) => {
      let finalAmount = amount
      if (amount.includes('.')) {
        const [finiteNumber, decimal] = amount.split('.', 2)
        if (decimal.length > maxFloatingNumbers) {
          const numberPiece = decimal.slice(0, maxFloatingNumbers)
          finalAmount = numberPiece
            ? `${finiteNumber}.${decimal.slice(0, maxFloatingNumbers)}`
            : finiteNumber
        }
      }
      return finalAmount
    }

    const getValidNumber = (currentAmount: string) => {
      let formattedValue = ''
      if (currentAmount?.indexOf('.') >= 0) {
        const decimalPos = currentAmount.indexOf('.')

        let leftSide = currentAmount.slice(0, decimalPos)
        let rightSide = currentAmount.slice(decimalPos)

        leftSide = formatNumber(leftSide)
        rightSide = formatNumber(rightSide)

        formattedValue = `${leftSide}.${rightSide}`
      } else {
        formattedValue = formatNumber(currentAmount)
      }
      return formattedValue
    }

    const onFocusHandler = () => {
      if (displayAmount) {
        setDisplayAmount((previousAmount) => {
          if (numberWithoutCommas(previousAmount) > Number.MAX_SAFE_INTEGER) {
            return numberWithoutCommas(maxAmount).toString()
          }
          return numberWithoutCommas(previousAmount).toString()
        })
      }
      onInputFocus()
    }

    const onBlurHandler = () => {
      const currentAmount = getStrippedValue()
      let validNumber = getValidNumber(currentAmount)

      if (validNumber.lastIndexOf('.') === validNumber.length - 1) {
        validNumber = validNumber.replace('.', '')
      }
      setDisplayAmount(validNumber)

      let returnAmount: number | undefined = numberWithoutCommas(validNumber)
      if (returnAmount > Number.MAX_SAFE_INTEGER) returnAmount = maxAmount
      if (currentAmount === '') returnAmount = undefined
      onInputBlur(returnAmount)
    }

    const inputChangeHandler = (rawAmount = '') => {
      if (rawAmount === '') {
        setDisplayAmount('')
        onInputChange(undefined)
        return
      }

      const afterMath = numberWithoutCommas(rawAmount)
      setDisplayAmount(rawAmount)

      onInputChange(afterMath)
    }

    const moneyValidator = (rawAmount = '') => {
      if (
        !isAllowDisplayOverMaxAmount &&
        numberWithoutCommas(rawAmount) > maxAmount
      )
        return false

      if (rawAmount.split('.').length > 2) return false
      if (rawAmount[0] === '0' && rawAmount[1] !== '.' && rawAmount.length > 1)
        return false
      if (Number.isNaN(Number(rawAmount.replace(/,/g, '')))) return false

      return rawAmount === '' || /\d\.?\d*$/g.test(rawAmount)
    }

    const onPasteReplacer = (pasteAmount: string) => {
      const currentAmount = getStrippedValue(pasteAmount.trim())
      const validNumber = getValidNumber(currentAmount)
      if (!moneyValidator(validNumber)) return ''
      return validNumber
    }

    const clearValue = () => setDisplayAmount('')
    const increaseAmount = (amount: number) => {
      let returnAmount = numberWithoutCommas(displayAmount)
      if (returnAmount > Number.MAX_SAFE_INTEGER) returnAmount = maxAmount
      returnAmount += amount

      const validDisplayNumber = returnAmount.toString()
      const currentAmount = getStrippedValue(validDisplayNumber)
      const validFinalNumber = getValidNumber(currentAmount)

      setDisplayAmount(validFinalNumber)
    }

    React.useImperativeHandle(ref, () => ({
      clearValue,
      increaseAmount,
    }))

    return (
      <Input
        id={id}
        className={className}
        disabled={disabled}
        title={title}
        type="text"
        inputMode="decimal"
        placeholder={placeholder}
        errorMsg={errorMessage}
        value={displayAmount}
        isError={isError}
        onChange={inputChangeHandler}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        validator={moneyValidator}
        replacerOnPaste={onPasteReplacer}
        autoRollback
      />
    )
  },
)

InputMoney.displayName = 'InputMoney'

export default InputMoney
