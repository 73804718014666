export const isValidCID = (cid: string) => {
  if (cid.length !== 13) {
    return false
  }

  let check = 0
  for (let i = 0; i < 12; i += 1) {
    check += parseInt(cid[i]) * (13 - i)
  }
  check %= 11
  check = 11 - check
  if (check >= 10) {
    check -= 10
  }

  if (check === parseInt(cid[12])) {
    return true
  }
  return false
}
