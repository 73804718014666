import styled from 'styled-components'
import { screen } from '@pig-common/utils/responsive'

export type PageTitleProps = {
  size?: 'small' | 'medium' | 'large'
}

export const PageTitle = styled.h2<PageTitleProps>`
  margin: 16px auto 16px auto;
  text-align: center;
  width: fit-content;
  ${({ size }) => {
    if (size === 'small') return 'font-size: 34px;'
    if (!size || size === 'medium') return 'font-size: 41.47px;'
    if (size === 'large') return 'font-size: 43px;'
    return ''
  }}

  @media ${screen.md} {
    margin: 24px auto;
    ${({ size }) => {
      if (size === 'small') return 'font-size: 46px;'
      if (!size || size === 'medium') return 'font-size: 64px;'
      if (size === 'large') return 'font-size: 70px;'
      return ''
    }}
  }
`

export default PageTitle
