import { SyntheticEvent, useEffect, useState } from 'react'
import cx from 'classnames'
import { Button, notification } from '@pig-frontend/uikit'
import { GuildSearch, MyGuild } from '@pig-common/components/guild'
import { useRouter } from 'next/router'
import { Section, Image } from '@pig-frontend/uikit'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import useGuild from '@pig-common/hooks/useGuild'
import { IGuildInfo, IOtherGuild } from '@pig-common/types/Guild.type'
import useGuildDetail from '@pig-common/hooks/useGuildDetail'
import useRequestGuild from '@pig-common/hooks/useRequestGuild'
import { useQueryClient } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import findIndex from 'lodash/findIndex'
import orderBy from 'lodash/orderBy'
import { guildBannerDefaultImage } from '@pig-common/constants/images'
import styles from './index.module.scss'
import dynamic from 'next/dynamic'

interface IGuildProps {
  slug?: string
}

const Loading = dynamic(
  () => import('@pig-common/components/Loading/Loading'),
  {
    ssr: false,
  },
)
export default function Guild({ slug }: IGuildProps) {
  const router = useRouter()
  const queryClient = useQueryClient()
  const [filteredGuilds, setFilteredGuilds] = useState<IOtherGuild[]>([])
  const guildIDSlug = slug
  const { data: guildData, isInitialLoading: isGuildDataInitialing } = useGuild(
    {},
  )
  const {
    data: guildSlug,
    isFetching: isGuildSlugLoading,
    remove: resetGuildSlugState,
  } = useGuildDetail({
    params: { guildId: guildIDSlug },
    enabled: !!guildIDSlug,
  })
  const isLoading = isGuildDataInitialing || isGuildSlugLoading
  const guild = guildData?.myGuild?.info || (guildSlug as IGuildInfo)
  const guilds: IOtherGuild[] = guildData?.otherGuild || []
  const {
    data: requestGuildData,
    mutate: requestGuild,
    isSuccess: isRequestGuildSuccess,
  } = useRequestGuild()

  useEffect(() => {
    // Clear state when guild id slug is removed
    if (!guildIDSlug) {
      resetGuildSlugState()
    }
  }, [guildIDSlug])

  useEffect(() => {
    /** Should filter from API */
    // Filter guilds from qs 'q'
    if (!guildData?.myGuild && router.query?.q) {
      const _filteredGuilds = guilds.filter((guild) => {
        return guild?.guildName
          ?.toLowerCase()
          ?.includes((router.query?.q as string)?.toLowerCase())
      })
      setFilteredGuilds(orderBy(_filteredGuilds, ['status']) as IOtherGuild[])
    } else {
      setFilteredGuilds(
        orderBy(guilds, ['status']).filter((guild) => !guild?.isFull),
      )
    }
  }, [router.query?.q, guildData])

  const goToCreateGuild = () => {
    router.push(pageLinkCommon.guild.create)
  }

  const goToGuild = (selectedGuild: IOtherGuild) => {
    router.push(`${pageLinkCommon.guild.index}/${selectedGuild?.guildId}`)
  }

  const handleRequestGuild = (e: SyntheticEvent, guild: IOtherGuild) => {
    e.stopPropagation()
    if (guild?.guildUid) {
      requestGuild({
        type: guild?.status === 'Applied' ? 'cancel' : 'apply',
        guildUid: guild?.guildUid,
      })
      // Change status to Applied
      const requestedGuild = filteredGuilds
      const guildIndex = findIndex(filteredGuilds, [
        'guildUid',
        guild?.guildUid,
      ])
      if (guild?.status === 'Applied') {
        // refetch in this case. but already declared fetching in onSuccess of requestGuild hook
        return null
      } else {
        requestedGuild[guildIndex].status = 'Applied'
        requestedGuild.unshift(requestedGuild.splice(guildIndex, 1)[0])
        queryClient.setQueryData([guildApi.key.get], (oldData: any) => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              otherGuild: requestedGuild,
            },
          }
        })
      }
    }
    return null
  }

  const GuildSectionContent = () => {
    return (
      <div
        className={styles.container}
        style={{ display: isLoading ? 'none' : 'block' }}
      >
        <Section sectionClassName={styles.body} sectionId={'guild'}>
          {!guildIDSlug && !guild ? (
            <div className={styles['list-container']}>
              <div className={styles['create-and-input']}>
                <GuildSearch />
                <Button
                  type="primary"
                  className={styles['create-guild-btn']}
                  onClick={goToCreateGuild}
                >
                  สร้างกิลด์
                </Button>
              </div>
              {filteredGuilds.map((guild) => (
                <div
                  role="button"
                  aria-hidden
                  onClick={() => goToGuild(guild)}
                  className={styles.card}
                  key={guild.guildUid}
                >
                  <Image
                    src={guild?.iconUrl || ''}
                    width={52}
                    height={52}
                    alt="Avatar guild"
                    className={styles.logo}
                  />
                  <span className={styles.name}>{guild?.guildName}</span>
                  <span>{guild?.guildId}</span>
                  <span>{guild?.numberOfMember}</span>
                  <Button
                    className={cx(styles['register-btn'], {
                      [styles.applied]: guild?.status === 'Applied',
                    })}
                    onClick={(e) => handleRequestGuild(e, guild)}
                  >
                    {guild?.status === 'Applied' ? 'ยกเลิก' : 'สมัคร'}
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <MyGuild
              guild={guild}
              guildData={guildData}
              handleRequestGuild={handleRequestGuild}
              guildIDSlug={guildIDSlug}
            />
          )}
        </Section>
      </div>
    )
  }

  useEffect(() => {
    if (requestGuildData?.status === 'ERROR') {
      notification.info({
        message: requestGuildData?.data?.message,
      })
    } else if (isRequestGuildSuccess) {
      notification.success({
        message: requestGuildData?.data?.message,
      })
    }
  }, [isRequestGuildSuccess, requestGuildData])

  return (
    <div className={styles.container}>
      <Section
        className={styles['header-body']}
        sectionClassName={styles.header}
        style={{
          backgroundImage: !isLoading
            ? `url(${guild?.bannerUrl || guildBannerDefaultImage?.src})`
            : 'none',
        }}
        sectionId="guild-banner"
      >
        <h1 className={styles.title}>
          {guild?.guildName ? guild?.guildName : 'กิลด์'}
        </h1>
        {!guildIDSlug && (
          <Button
            className={cx(styles['tournament-btn'], styles.disabled)}
            onClick={goToCreateGuild}
            disabled
          >
            กิลด์์ทัวร์นาเมนต์
          </Button>
        )}
      </Section>
      {isLoading && <Loading />}
      <GuildSectionContent />
    </div>
  )
}
