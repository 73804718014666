// To bring back bonus card please install  "react-slick": "^0.29.0",
import { CSSProperties, useState } from 'react'
// import Slider from 'react-slick'
import { WalletCard, Card, WALLET_CARD_TYPE } from '@pig-common/components/Card'
import useWindowSize from '@pig-common/utils/useWindowSize'
import { checkMQ } from '@pig-common/utils/utils'
// import { LeftOutlined, RightOutlined } from '@ant-design/icons'

export type WalletSectionProps = {
  sliderItemStyle?: CSSProperties
  wallet?: number
  onChange?: (arg: number) => void
  bonusAmount: string
  walletAmount: string
  phoneNumber?: string
  time: string
  loading?: boolean
  refreshUserBalance?: () => void
}

// interface InnerSlider {
//   onTrackLeave: () => void
// }

// interface AddonSlider {
//   innerSlider: InnerSlider
// }

// @ts-ignore
// interface SlickElement extends HTMLElement, Slider, AddonSlider {}

const WalletSection = ({
  refreshUserBalance,
  //   sliderItemStyle = { width: 500 },
  wallet = 0,
  //   onChange = () => {},
  //   bonusAmount,
  walletAmount,
  phoneNumber,
  time,
  loading = false,
}: WalletSectionProps) => {
  const { width } = useWindowSize()
  //   const sliderEl = useRef<SlickElement | Slider>(null)
  const [current] = useState(wallet)
  //   const settings = {
  //     className: 'slider variable-width',
  //     dots: true,
  //     infinite: false,
  //     centerMode: false,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     variableWidth: true,
  //     focusOnSelect: true,
  //     afterChange: (current: number) => {
  //       setCurrent(current)
  //       if (onChange) {
  //         onChange(current)
  //       }
  //     },
  //   }

  //   const walletToggle = (wallet: number) => {
  //     setCurrent(wallet)
  //     // sliderEl?.current?.slickGoTo(wallet)
  //     if (onChange) {
  //       onChange(wallet)
  //     }
  //   }

  //   useEffect(() => {
  //     walletToggle(wallet)
  //   }, [wallet])

  //   const customPaging = () => <div className="ps-wallet-section-dot" />

  //   const appendDots = (dots: any[]) => {
  //     const children = dots.map((Item, i) => {
  //       return cloneElement(Item, {
  //         key: `dot-${i}`, // eslint-disable-line
  //         onClick: () => sliderEl?.current?.slickGoTo(i),
  //       })
  //     })
  //     return (
  //       <div>
  //         <ul className="ps-wallet-section-track-dots">{children}</ul>
  //       </div>
  //     )
  //   }
  return (
    <section className="ps-wallet-section">
      {checkMQ(width, ['ss', 'xs', 'sm', 'md']) ? (
        <div className="container" style={{ padding: 0 }}>
          {/* <div className="ps-wallet-section__wrapper"> */}
          <div className="d-flex justify-content-center position-relative">
            {/* <Slider
              {...settings}
              ref={sliderEl}
              appendDots={appendDots}
              customPaging={customPaging}
              className="ps-wallet-section__slider ps-wallet-section__slider--no-bonus" // remove me if bonus card is exist
              centerMode // remove me if bonus card is exist
            >
              <div
                style={
                  checkMQ(width, ['ss', 'xs', 'sm'])
                    ? { width: 288 }
                    : sliderItemStyle
                }
              > */}
            <Card style={{ marginLeft: '16px' }}>
              <WalletCard
                id="wallet-card"
                loading={loading}
                type={WALLET_CARD_TYPE.DEFAULT}
                amount={walletAmount}
                phoneNumber={phoneNumber}
                time={time}
                refreshUserBalance={refreshUserBalance}
              />
            </Card>
          </div>
          {/* NOTE: temporary remove bonus card */}
          {/* <div
                style={
                  checkMQ(width, ['ss', 'xs', 'sm'])
                    ? { width: 288 }
                    : sliderItemStyle
                }
              >
                <Card style={{ marginLeft: '16px' }}>
                  <WalletCard
                    id="bonus-card"
                    loading={loading}
                    type={WALLET_CARD_TYPE.BONUS}
                    amount={bonusAmount}
                    phoneNumber={phoneNumber}
                    time={time}
                    refreshUserBalance={refreshUserBalance}
                  />
                </Card>
              </div> */}
          {/* </Slider> */}
          {/* </div> */}
        </div>
      ) : (
        <div className="container" style={{ padding: 0 }}>
          <div className="d-flex justify-content-center position-relative">
            <Card
              style={{ zIndex: 1, opacity: current === 1 ? 0.2 : 1 }}
              //   onClick={() => walletToggle(0)}
            >
              <WalletCard
                refreshUserBalance={refreshUserBalance}
                id="wallet-card"
                loading={loading}
                type={WALLET_CARD_TYPE.DEFAULT}
                amount={walletAmount}
                phoneNumber={phoneNumber}
                time={time}
              />
            </Card>
            {/* NOTE: Uncomment me if wanna bring back bonus card */}
            {/* <Card
              style={{
                zIndex: 1,
                marginLeft: '16px',
                opacity: current === 0 ? 0.2 : 1,
              }}
              onClick={() => walletToggle(1)}
            >
              <WalletCard
                id="bonus-card"
                loading={loading}
                type={WALLET_CARD_TYPE.BONUS}
                amount={bonusAmount}
                phoneNumber={phoneNumber}
                time={time}
                refreshUserBalance={refreshUserBalance}
              />
            </Card> */}
            {/* <div className="ps-wallet-section__wallet-desktop">
              <div
                className="ps-wallet-section__arrow"
                onClick={() => walletToggle(0)}
                aria-hidden
              >
                <LeftOutlined style={{ opacity: current === 0 ? 0.2 : 1 }} />
              </div>
              <div
                className="ps-wallet-section__arrow"
                onClick={() => walletToggle(1)}
                aria-hidden
              >
                <RightOutlined style={{ opacity: current === 1 ? 0.2 : 1 }} />
              </div>
            </div> */}
          </div>
          {/* <div className="ps-wallet-section__dots-container">
            <div className="ps-wallet-section__dots-layout">
              <div
                className={`ps-wallet-section-dot ${
                  current === 0 && 'ps-wallet-section__active'
                }`}
                onClick={() => walletToggle(0)}
                aria-hidden
              />
              <div
                className={`ps-wallet-section-dot ${
                  current === 1 && 'ps-wallet-section__active'
                }`}
                onClick={() => walletToggle(1)}
                aria-hidden
              />
            </div>
          </div> */}
        </div>
      )}
    </section>
  )
}

export default WalletSection
