import React, { useImperativeHandle, forwardRef } from 'react'
import getUnixTime from 'date-fns/getUnixTime'
import { captureAndDownload } from '@pig-common/utils/snapshot'

export type CaptureAreaProps = {
  fileNamePrefix?: string
  className?: string
  width?: number
  height?: number
  children: React.ReactNode
}

export type CaptureAreaHandles = {
  save: () => Promise<void>
}

const CaptureArea = forwardRef<CaptureAreaHandles, CaptureAreaProps>(
  ({ fileNamePrefix = 'capture', className, width, height, children }, ref) => {
    const snapshotRef = React.useRef<HTMLDivElement>(null)
    const fileName = `${fileNamePrefix}-${getUnixTime(new Date())}`

    const save = async () => {
      captureAndDownload(
        snapshotRef.current as HTMLDivElement,
        {
          useCORS: true,
          width: snapshotRef.current?.offsetWidth,
          height: snapshotRef.current?.offsetHeight,
          scale: 2,
        },
        fileName,
      )
    }

    useImperativeHandle(ref, () => ({
      save,
    }))

    return (
      <div ref={snapshotRef} className={className}>
        {children}
      </div>
    )
  },
)

CaptureArea.displayName = 'CaptureArea'
export default CaptureArea
