import { Image, ProfileAvatar } from '@pig-frontend/uikit'
import { Tabs } from 'antd'
import { UserProfile } from '@pig-common/components/User'
import {
  Achivement,
  Tournament,
  Overview,
} from '@pig-common/components/ProfileDetails'
import useProfile from '@pig-common/hooks/useProfile'
import useGuild from '@pig-common/hooks/useGuild'
import { useOverview } from '@pig-common/hooks/useOverview'
import styles from './index.module.scss'

const { TabPane } = Tabs

export default function ProfileDetailsPage() {
  const { data: guildData } = useGuild({})
  const { data: profile } = useProfile({})
  const { overviewData } = useOverview()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.avatar}>
          <ProfileAvatar name={profile?.avatar} width={56} height={56} />
          {guildData?.myGuild?.info?.iconUrl && (
            <Image
              src={guildData?.myGuild?.info?.iconUrl || ''}
              width={28}
              height={28}
              alt="guild"
              className={styles.guild}
            />
          )}
        </div>
        <UserProfile />
      </div>
      <Tabs
        animated
        centered
        defaultActiveKey="1"
        type="card"
        className="ps-profile-tabs"
      >
        <TabPane tab="ความสำเร็จ" key="achivement" className="container">
          <Achivement />
        </TabPane>
        <TabPane tab="ทัวร์นาเมนต์" key="tournament" className="container">
          <Tournament overviewData={overviewData} />
        </TabPane>
        <TabPane tab="ภาพรวม" key="overview">
          <Overview overviewData={overviewData} />
        </TabPane>
      </Tabs>
    </div>
  )
}
