import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

export type ClaimBigWinRequest = {
  bigwinUid?: string
}

export type ClaimBigWinData = {
  ticket?: number
  message?: string
}

type ClaimBigWinResponse = BaseResponse & {
  status: string
  data: ClaimBigWinData
}

const claimBigWin = async (
  request: ClaimBigWinRequest,
): Promise<ClaimBigWinResponse | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/casino/claim-bigwin/${request.bigwinUid}`,
      method: 'POST',
    }

    const result = await fetchWithJSON<ClaimBigWinResponse>(
      pigspinApiInstance,
      requestConfig,
    )

    return result
  } catch (error) {
    return null
  }
}

export default claimBigWin
