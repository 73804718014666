import Maintenance, {
  MaintenanceProps,
} from '@pig-common/pages-common/maintenance'

function MaintenancePage(props: MaintenanceProps) {
  return <Maintenance {...props} />
}

export { MaintenancePage }
export type { MaintenanceProps }
