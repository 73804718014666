import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'

import { registerStateCommon } from '@pig-common/recoils'
import { AlertModal, ALERT_MODAL_TYPE } from '@pig-common/components/Modal'
import {
  RegisterWithLine,
  RegisterWithPhone,
} from '@pig-common/components/Register'
import { GetServerSideProps } from 'next'
import {
  requestVerfiyCfid,
  VERIFY_CFID_STATUS,
} from '@pig-common/services/verifyCfid'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import { pageLinkCommon } from '@pig-common/constants/page-name'

const DEFAULT_ALERT_TYPE = ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS

export type RegisterPageV2Props = {
  data: { lineData: any }
  registerType: 'phone' | 'line'
}

export default function RegisterPageV2({
  data: dataProps,
  registerType,
}: RegisterPageV2Props) {
  const [register, setRegister] = useRecoilState(
    registerStateCommon.registerState,
  )
  const { phoneNumber, otpCode, otpToken } = register
  const [isReady, setIsReady] = React.useState(false)
  const [openAlertModal, setOpenAlertModal] = React.useState(false)
  const [alertModalType, setAlertModalType] =
    React.useState<ALERT_MODAL_TYPE>(DEFAULT_ALERT_TYPE)
  const router = useRouter()

  const prepareData = async () => {
    if (registerType === 'phone') {
      // NOTE : if user come directly to /register, redirect back to /login
      if (!(phoneNumber && otpCode && otpToken)) {
        await router.replace('/login')
      }
    }
    setIsReady(true)
  }

  React.useEffect(() => {
    prepareData()
    return () => {
      setRegister(registerStateCommon.defaultRegisterState)
      setIsReady(false)
    }
  }, [])

  const showAlertModal = (type?: ALERT_MODAL_TYPE) => {
    setAlertModalType(type || DEFAULT_ALERT_TYPE)
    setOpenAlertModal(true)
  }

  return isReady ? (
    <section className="container mx-auto space-top-1 space-bottom-1 w-100 overflow-hidden">
      <AnimatePresence>
        {registerType === 'phone' ? (
          <RegisterWithPhone onError={showAlertModal} />
        ) : (
          <RegisterWithLine
            data={dataProps?.lineData}
            onError={showAlertModal}
          />
        )}
      </AnimatePresence>
      <AlertModal
        open={openAlertModal}
        type={alertModalType}
        onClose={() => setOpenAlertModal(false)}
      />
    </section>
  ) : null
}

export const getRegisterServerSideProps: GetServerSideProps = async ({
  req,
  res,
  query,
}) => {
  const { type = 'phone' } = query
  let registerType = type

  // TODO : Might not use it
  const getLineData = () => {
    try {
      return JSON.parse(req.cookies?.line_data || '')
    } catch (e) {
      return null
    }
  }

  // TODO : Might not use it
  const lineData = getLineData()
  if (lineData) {
    res.setHeader('set-cookie', [`${COOKIES_KEY.LINE_DATA}=deleted; Max-Age=0`])
    registerType = 'line'
  }

  const props = {
    data: { lineData },
    registerType,
  }

  // NOTE : Considering should register or stay inside app
  // NOTE : call api to check cfid...
  const cfid = req.cookies?.cfid as { name?: string; value?: string }
  const uid = req.cookies?.uid as { name?: string; value?: string }
  const response = await requestVerfiyCfid({ cfid, uid })
  if (response.Status === VERIFY_CFID_STATUS.SUCCESS) {
    return {
      redirect: {
        destination: pageLinkCommon.lobby,
        permanent: false,
      },
      props,
    }
  }

  return {
    props,
  }
}
