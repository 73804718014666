import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'

export default function useEditGuild() {
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useMutation({
    mutationFn: (params: guildApi.IEditGuildParams) =>
      guildApi.edit({ customerCode, ...params }),
  })
}
