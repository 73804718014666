import { ParsedUrlQuery } from 'querystring'
import { GetServerSidePropsContext } from 'next'
import jwtDecode from 'jwt-decode'
import absoluteUrl from 'next-absolute-url'
import { LoginResponse } from '@pig-common/services/login'
import getLineAccessToken from '@pig-common/services/line/getLineAccessToken'
import {
  LINE_CHANNEL_ID,
  LINE_CLIENT_SECRET,
} from '@pig-common/models/buildtime-constant'
import {
  LoginResponseResult,
  LOGIN_STATUS,
  requestLineLogin,
} from '@pig-common/services/login/login'
import { mapToLoginResponse } from '@pig-common/mappers/mapToLoginResponse'

export type LineIdTokenData = {
  iss: string
  sub: string
  aud: string
  exp: number
  iat: number
  nonce: string
  amr: string[]
  name: string
  picture: string
}

export type LineResponse = {
  connectedLine: boolean
  lineName: string
  lineImg: string
  lineIdToken: string
}

export type LoginWithLineResponse = LoginResponse & LineResponse

const LOGIN_ERROR_RESPONSE: LoginWithLineResponse = {
  connectedLine: false,
  Status: LOGIN_STATUS.ERROR,
  cfid: '',
  uid: '',
  Phone: '',
  lineName: '',
  lineImg: '',
  lineIdToken: '',
  CustomerCode: '',
}

export const LoginWithLine = async (
  context: GetServerSidePropsContext<ParsedUrlQuery>,
): Promise<LoginWithLineResponse> => {
  try {
    const { query, req, resolvedUrl } = context
    const { code } = query
    const { origin } = absoluteUrl(req)
    const requestUrl = `${origin}${resolvedUrl?.split('?')[0]}`
    const lineResponse = await getLineAccessToken({
      code: `${code}`,
      redirect_uri: requestUrl,
      client_id: LINE_CHANNEL_ID,
      client_secret: LINE_CLIENT_SECRET,
    }).catch((err) => {
      return { id_token: '' }
    })
    if (lineResponse && lineResponse.id_token) {
      const lineData: LineIdTokenData = jwtDecode(lineResponse.id_token)
      const response: LoginResponseResult = await requestLineLogin({
        id_token: lineResponse.id_token,
      }).catch((err) => err.response.data)
      const loginResult = mapToLoginResponse(response)
      return {
        ...loginResult,
        connectedLine: true,
        lineName: lineData.name,
        lineImg: lineData.picture,
        lineIdToken: lineResponse.id_token || '',
      }
    }
    return LOGIN_ERROR_RESPONSE
  } catch {
    return LOGIN_ERROR_RESPONSE
  }
}

export const LinkLine = async (
  context: GetServerSidePropsContext<ParsedUrlQuery>,
): Promise<LineResponse> => {
  try {
    const { query, req, resolvedUrl } = context
    const { code } = query
    const { origin } = absoluteUrl(req)
    const requestUrl = `${origin}${resolvedUrl?.split('?')[0]}`
    const lineResponse = await getLineAccessToken({
      code: `${code}`,
      redirect_uri: requestUrl,
      client_id: LINE_CHANNEL_ID,
      client_secret: LINE_CLIENT_SECRET,
    }).catch(() => {
      return { id_token: '' }
    })
    if (lineResponse && lineResponse.id_token) {
      const lineData: LineIdTokenData = jwtDecode(lineResponse.id_token)
      return {
        connectedLine: true,
        lineName: lineData.name,
        lineImg: lineData.picture,
        lineIdToken: lineResponse.id_token,
      }
    }
    return LOGIN_ERROR_RESPONSE
  } catch {
    return LOGIN_ERROR_RESPONSE
  }
}
