import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import {
  ImageSelector,
  HeaderImageSelector,
} from '@pig-common/components/guild'
import { Button, Form, Skeleton } from 'antd'
import find from 'lodash/find'
import {
  Input,
  Image,
  Section,
  Modal,
  notification,
  usePrevious,
} from '@pig-frontend/uikit'
import {
  IGuildAvatarImage,
  IGuildBannerImage,
} from '@pig-common/types/Guild.type'
import useCreateGuild from '@pig-common/hooks/useCreateGuild'
import { guildApi } from '@pig-common/api'
import useGetTicket from '@pig-common/hooks/useGetTotalTicket'
import { useRouter } from 'next/router'
import useGuild from '@pig-common/hooks/useGuild'
import useEditGuild from '@pig-common/hooks/useEditGuild'
import useGuildConfig from '@pig-common/hooks/useGuildConfig'
import useListBannerGuild from '@pig-common/hooks/useListBannerGuild'
import { guildBannerDefaultImage } from '@pig-common/constants/images'
import useListIconGuild from '@pig-common/hooks/useListIconGuild'
import styles from './index.module.scss'

export interface ICreateGuildProps {
  isEdit?: boolean
}

export default function CreateGuild({ isEdit }: ICreateGuildProps) {
  const router = useRouter()
  const [form] = Form.useForm()
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(true)
  const [descriptionTextCount, setDescriptionCount] = useState<number>(0)
  const [selectedAvatarImageUrl, setAvatarImageUrl] = useState<string>()
  const [selectedHeaderImageUrl, setHeaderImageUrl] = useState<string>()
  const previousSelectedAvatarImageUrl = usePrevious(selectedAvatarImageUrl)
  const previousSelectedHeaderImageUrl = usePrevious(selectedHeaderImageUrl)
  const [avatarItems, setAvatarItems] = useState<any[]>([])
  const [headerItems, setHeaderItems] = useState<any[]>([])
  const [formValues, setFormValues] = useState<guildApi.ICreateGuildParams>()
  const { data: guildConfig } = useGuildConfig({})
  const { data: avatarData } = useListIconGuild({})
  const { data: headerData } = useListBannerGuild({})
  const getGuildQuery = useGuild({ enabled: false })
  const { data: guildData, isFetchedAfterMount: isFetchedGuildAfterMount } =
    useGuild({ enabled: isEdit })
  const myGuild = guildData?.myGuild
  const {
    mutate: createGuild,
    isSuccess: isCreateGuildSuccess,
    isLoading: isCreatingGuild,
    data: createGuildError, // NOTE: be is return invalid status code; should be error: createGuildError if API is return correctly
  } = useCreateGuild()
  const {
    mutate: editGuild,
    isSuccess: isEditGuildSuccess,
    isLoading: isEditingGuild,
    data: editGuildError,
  } = useEditGuild()
  const {
    data: totalTicket,
    isSuccess: isGetTotalTicketSuccess,
    refetch: getTotalTicket,
    remove: resetTotalTicketState,
  } = useGetTicket({ enabled: false })

  const dropdownDataResolver = ({ data, size }: any) => {
    const items = data.map((item: any) => {
      return {
        key: item.url,
        label: (
          <Image
            src={item.url}
            alt={item.code}
            width={size.width}
            height={size.height}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        ),
      }
    })
    return items
  }

  useEffect(() => {
    // Initial values for edit guild
    if (isEdit && myGuild) {
      form.setFieldsValue({
        guildName: myGuild?.info?.guildName,
      })
      form.setFieldsValue({
        description: myGuild?.info?.description,
      })
      setDescriptionCount(myGuild?.info?.description?.length ?? 0)
      setAvatarImageUrl(myGuild?.info?.iconUrl)
      setHeaderImageUrl(myGuild?.info?.bannerUrl)
      // Submit form; Preparing values for submit
      const selectedHeaderItem = find(headerData, [
        'url',
        myGuild?.info?.bannerUrl,
      ]) as IGuildBannerImage | undefined
      const selectedAvatarItem = find(avatarData, [
        'url',
        myGuild?.info?.iconUrl,
      ]) as IGuildAvatarImage | undefined
      setFormValues({
        bannerUid: selectedHeaderItem?.uid || '',
        description: myGuild?.info?.description || '',
        guildName: myGuild?.info?.guildName || '',
        iconUid: selectedAvatarItem?.uid || '',
      })
    }
  }, [isEdit, myGuild])

  useEffect(() => {
    if (avatarData) {
      const items = dropdownDataResolver({
        data: avatarData,
        size: { width: 66, height: 66 },
      })
      setAvatarItems(items)
    }
  }, [avatarData])

  useEffect(() => {
    if (headerData) {
      const items = dropdownDataResolver({
        data: headerData,
        size: { width: 220, height: 110 },
      })
      setHeaderItems(items)
      // Set default of header image
      setHeaderImageUrl(myGuild?.info?.bannerUrl || guildBannerDefaultImage.src)
    }
  }, [headerData])

  const handleSubmit = (values: Record<string, string | number>) => {
    // Submit form; Preparing values for submit
    const selectedHeaderItem = find(headerData, [
      'url',
      selectedHeaderImageUrl,
    ]) as IGuildBannerImage | undefined
    const selectedAvatarItem = find(avatarData, [
      'url',
      selectedAvatarImageUrl,
    ]) as IGuildAvatarImage | undefined
    const allValues: any = {
      ...values,
      bannerUid: selectedHeaderItem?.uid,
      iconUid: selectedAvatarItem?.uid,
    }
    // Custom validate values
    const allValueNotEmpty = Object.keys(allValues).some((k) => {
      return !allValues[k]
    })
    if (allValueNotEmpty) {
      Modal.info({
        title: 'กรุณาเลือกกรอกข้อมูลให้ครบ',
        okText: 'ตกลง',
      })
      return null
    }
    setFormValues(allValues)

    // Create Form
    if (!isEdit) {
      getTotalTicket()
    } else {
      handleEditGuild(allValues)
    }
    return null
  }

  useEffect(() => {
    // Check fields change then enable submit button for non-form fields
    // For fields in form, check in onChange in Form component
    if (previousSelectedAvatarImageUrl && previousSelectedHeaderImageUrl) {
      setSubmitDisabled(false)
    }
  }, [selectedAvatarImageUrl, selectedHeaderImageUrl])

  useEffect(() => {
    // Show confirm using ticket modal
    if (isGetTotalTicketSuccess) {
      Modal.confirm({
        title: (
          <span className={styles['create-guild-modal']}>
            ใช้{' '}
            <span className={styles.highlight}>
              {BigNumber(guildConfig?.ticketCreate || '0').toFormat(0)}
            </span>{' '}
            Tickets เพื่อสร้างกิลด์ <br />
            คุณมี {BigNumber(totalTicket?.tickets || '0').toFormat(0)} Tickets
          </span>
        ),
        okText: 'ตกลง',
        onOk: () => {
          handleCreateGuild()
          resetTotalTicketState()
        },
        cancelText: 'ยกเลิก',
        onCancel: () => {
          resetTotalTicketState()
        },
      })
    }

    return () => {}
  }, [isGetTotalTicketSuccess, totalTicket, guildConfig])

  useEffect(() => {
    if (createGuildError?.status === 'ERROR') {
      Modal.info({
        title: createGuildError?.data?.message,
      })
    }
  }, [createGuildError])

  useEffect(() => {
    // Redirect after creating guild
    if (
      isCreateGuildSuccess &&
      createGuildError?.status !== 'ERROR' &&
      getGuildQuery?.isSuccess &&
      !getGuildQuery?.isFetching
    ) {
      notification.success({
        message: 'สร้างกิลด์สำเร็จ',
      })
      router.back()
    }
  }, [isCreateGuildSuccess, createGuildError, getGuildQuery])

  const handleCreateGuild = () => {
    if (totalTicket?.tickets) {
      if (totalTicket?.tickets >= Number(guildConfig?.ticketCreate || 0)) {
        createGuild(formValues as guildApi.ICreateGuildParams)
        return null
      }
    }
    Modal.info({
      title: 'จำนวน ticket ไม่เพียงพอ',
    })
    resetTotalTicketState()
    return null
  }

  const handleEditGuild = (allValues: guildApi.ICreateGuildParams) => {
    // Don't use allValue from state because state of formValue is not update on time (side-effect)
    Modal.confirm({
      title: 'ยืนยันการแก้ไข',
      okText: 'ตกลง',
      onOk: () => {
        editGuild({
          ...allValues,
          guildUid: myGuild?.info?.guildUid || '',
        })
      },
      cancelText: 'ยกเลิก',
    })
  }

  useEffect(() => {
    // On edit error and success
    if (editGuildError?.status === 'ERROR') {
      Modal.info({
        title: editGuildError?.data?.message,
        okText: 'ตกลง',
      })
    } else if (isEditGuildSuccess) {
      notification.success({
        message: 'แก้ไขสำเร็จ',
      })
      router.back()
    }
  }, [isEditGuildSuccess, editGuildError])

  return (
    <div className={styles.container}>
      <Section
        sectionClassName={styles['header-wrapper']}
        style={{
          backgroundImage: `url(${selectedHeaderImageUrl})`,
        }}
        className={styles.header}
        sectionId={'guild-create'}
      >
        <h1 className={styles.title}>
          {isEdit ? myGuild?.info?.guildName : 'สร้างกิลด์'}
        </h1>
        <HeaderImageSelector
          placement="bottomCenter"
          items={headerItems}
          setSelectedImageUrl={setHeaderImageUrl}
        />
      </Section>

      <Section sectionClassName={styles['form-container']} sectionId={''}>
        <Form
          className={styles.form}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          form={form}
          onChange={() => setSubmitDisabled(false)}
        >
          <div className={styles['row-name']}>
            <ImageSelector
              items={avatarItems}
              selectedImageUrl={selectedAvatarImageUrl}
              setSelectedImageUrl={setAvatarImageUrl}
            />
            <Form.Item
              name="guildName"
              className={styles.name}
              label="ชื่อกิลด์"
              rules={[{ required: true, message: 'กรุณากรอกชื่อกิลด์' }]}
            >
              <Input placeholder="กรอกชื่อกิลด์" />
            </Form.Item>
          </div>
          <Form.Item
            name="description"
            label="รายละเอียดกิิลด์"
            rules={[
              { required: true, message: 'กรุณากรอกรายละเอียดกิลด์' },
              {
                pattern: /^[\S\s]{0,250}$/,
                message: 'รายละเอียดกิลด์ต้องไม่เกิน 250 ตัวอักษร',
              },
            ]}
            className={styles['textarea-container']}
          >
            {isFetchedGuildAfterMount ? (
              <>
                <Input.TextArea
                  defaultValue={guildData?.myGuild?.info?.description}
                  maxLength={250}
                  onChange={(e) => {
                    form.setFieldValue('description', e?.target?.value)
                    setDescriptionCount(e?.target?.value?.length ?? 0)
                  }}
                  placeholder="กรอกรายละเอียดกิิลด์"
                  rows={7}
                  className={styles.textarea}
                />
                <span className={styles['text-countdown']}>
                  {descriptionTextCount}/250
                </span>
              </>
            ) : (
              <Skeleton.Input block className={styles.textarea} />
            )}
          </Form.Item>

          <div className={styles.submit}>
            {!isEdit && (
              <span>
                ใช้ {guildConfig?.ticketCreate} Tickets เพื่อสร้างกิลด์
              </span>
            )}
            {isEdit ? (
              <Button
                htmlType="submit"
                type="primary"
                className={styles.btn}
                loading={isEditingGuild}
                disabled={isSubmitDisabled}
              >
                บันทึก
              </Button>
            ) : (
              <Button
                htmlType="submit"
                type="primary"
                className={styles.btn}
                loading={isCreatingGuild || getGuildQuery?.isFetching}
                disabled={isSubmitDisabled}
              >
                สร้างกิลด์
              </Button>
            )}
          </div>
        </Form>
      </Section>
    </div>
  )
}
