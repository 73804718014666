import React from 'react'
import styled from 'styled-components'

export type NotiBadgeProps = {
  amount?: number
}

export const StyledNotiBadge = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  z-index: 12;
  transform: translateX(50%) translateY(-25%);
  border-radius: 12px;
  background-color: #c8373e;
  border: 2px solid ${({ theme }) => theme['color-greyscale-100']};
  color: ${({ theme }) => theme['color-greyscale-100']};
  font-size: 12px;
  padding: 0 5px;
  width: fit-content;
  line-height: 12px;
`

export const NotiBadge: React.FC<NotiBadgeProps> = ({ amount }) => {
  if (amount) {
    return <StyledNotiBadge>{amount}</StyledNotiBadge>
  }
  return <div className="ps-notification-badge" />
}

export default NotiBadge
