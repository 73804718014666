import { useMutation } from '@tanstack/react-query'
import { losingStreakApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { notification } from '@pig-frontend/uikit'

const messageMapper = {
  'PIG-4015': 'ไม่พบบัญชีธนาคาร',
  'PIG-3035': 'ไม่พบค่าคอมมิสชั่นของคุณ',
  'PIG-3036': 'ค่าคอมมิสชั่นได้ถูกรับไปแล้ว',
  'PIG-3031': 'ไม่สามารถรับค่าคอมมิสชั่นได้ เนื่องจากเกินเวลารับที่กำหนด',
}

export default function useClaimLosingStreak() {
  const auth = useRecoilValue(authStateCommon.authState)

  return useMutation({
    mutationFn: (losingStreakUid: string) =>
      losingStreakApi.claim({
        customerCode: auth?.customerCode,
        losingStreakUid: losingStreakUid,
      }),
    onSuccess: (data) => {
      if (data?.serviceCode !== 'PIG-3030') {
        notification.error({
          message: messageMapper?.[data?.serviceCode] || 'มีบางอย่างผิดพลาด',
        })
      }
    },
  })
}
