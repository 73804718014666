import React, { useEffect, useState } from 'react'
import { faqStateCommon } from '@pig-common/recoils'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import Router from 'next/router'
import styled from 'styled-components'
import { numberWithCommas } from '@pig-common/utils/number'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import {
  RedemptionHistory,
  ShopRedemption,
} from '@pig-common/components/ShopRedemption'
import { FAQ_TAB } from '@pig-common/recoils/modules/faq'
import { FreeInGameData, getFreeInGame } from '@pig-common/services/pigShop'
import ShopHeader from '@pig-common/components/ShopRedemption/Header'
import { useLoyaltyProgram } from '@pig-common/hooks/useLoyaltyProgram'
import AnimatedBanner, {
  BANNER_ON_PAGE,
} from '@pig-common/components/AnimatedBanner/AnimatedBanner'
import { useAuth } from '@pig-common/hooks/useAuth'
import Tabs from '@pig-common/components/Common/Tabs'

const StyledTabs = styled(Tabs)`
  align-items: flex-start;
`
export type PigShopProps = {
  freeInGameData?: FreeInGameData
}

export enum PRIMARY_TAB {
  REDEEM_FREE_IN_GAME = 0,
  REDEEM_FREE_PHYSICAL = 1,
}

export enum SECONDARY_IN_GAME_TAB {
  IN_GAME_REWARD = 'redeem',
  REWARD_HISTORY = 'history',
}

export enum SECONDARY_PHYSICAL_TAB {
  PHYSICAL_REWARD = 0,
  REWARD_HISTORY = 1,
}

export default function PigShop({ freeInGameData }: PigShopProps) {
  const auth = useAuth()
  const { customerCode = '' } = useRecoilValue(authStateCommon.authState)
  const { callTotalTickets } = useLoyaltyProgram()
  const [totalTicket, setTotalTicket] = useState<number>(0)
  const [freeCredit, setFreeCredit] = useState(
    freeInGameData?.free_credit || [],
  )
  const [getRedemptionHistory, setRedemptionHistory] = useState(
    freeInGameData?.history || [],
  )
  const [currentSecondaryInGameTab, setCurrentSecondaryInGameTab] = useState(
    SECONDARY_IN_GAME_TAB.IN_GAME_REWARD,
  )
  const setFaq = useSetRecoilState(faqStateCommon.faqState)

  const fetchFreeInGameList = async () => {
    const freeInGameResponse: any = await getFreeInGame({
      userCode: customerCode,
    })
    //const games = await getGames();
    if (freeInGameResponse) {
      setFreeCredit(freeInGameResponse.free_credit)
      //setGamesState(games || []);
      setRedemptionHistory(freeInGameResponse.history)
    }
  }

  const fetchTotalTickets = async () => {
    const totalTicketResponse = await callTotalTickets()
    if (totalTicketResponse) {
      setTotalTicket(totalTicketResponse?.tickets)
    }
  }

  const fetchFreeInGame = async () => {
    await fetchFreeInGameList()
    await fetchTotalTickets()
  }

  const faqHandler = () => {
    setFaq({ selectedTab: FAQ_TAB.QUEST })
    Router.push('/faq')
  }

  useEffect(() => {
    fetchFreeInGame()
  }, [auth])

  return (
    <div className="ps-pig-shop container mb-4">
      <AnimatedBanner
        content={
          <ShopHeader total_tickets={numberWithCommas(totalTicket, 0)} />
        }
        page={BANNER_ON_PAGE.SHOP}
      />
      <StyledTabs
        centered
        activeKey={currentSecondaryInGameTab}
        items={[
          { key: SECONDARY_IN_GAME_TAB.IN_GAME_REWARD, label: 'ของรางวัล' },
          {
            key: SECONDARY_IN_GAME_TAB.REWARD_HISTORY,
            label: 'ของรางวัลที่ฉันรับแล้ว',
          },
        ]}
        onChange={(activeKey) =>
          setCurrentSecondaryInGameTab(activeKey as SECONDARY_IN_GAME_TAB)
        }
      />
      {[SECONDARY_IN_GAME_TAB.IN_GAME_REWARD].includes(
        currentSecondaryInGameTab,
      ) ? (
        <ShopRedemption
          id="in-game__redemption"
          key="in-game-redemption"
          userCode={customerCode}
          freeCreditData={freeCredit}
          refetch={fetchFreeInGame}
        />
      ) : (
        <RedemptionHistory
          id="in-game__history-redemption"
          key="in-game__history-redemption"
          redemptionHistoryData={getRedemptionHistory}
        />
      )}
      <hr className="ps-pig-shop__horizon-line" />

      <Button
        id="pig-shop__faq-button"
        className="ps-pig-shop__faq-button"
        type={BUTTON_TYPE.OUTLINE_CTA_1}
        onClick={faqHandler}
      >
        คำถามที่พบบ่อย
      </Button>
    </div>
  )
}
