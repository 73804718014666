import React, { useRef } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { useRouter } from 'next/router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { profileStateCommon, chatChannelStateCommon } from '@pig-common/recoils'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { AlertContactModalV2 } from '@pig-common/components/Modal'
import { AvatarDetail, CreateAvatar } from '@pig-common/components/CreateAvatar'
import { AvatarHandles } from '@pig-common/components/CreateAvatar/CreateAvatar'
import { notification } from '@pig-frontend/uikit'
import { pageLinkCommon } from '@pig-common/constants/page-name'

const SetProfilePage = () => {
  const router = useRouter()
  const { callProfile, callUpdateProfile } = useLegacyProfile()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const profile = useRecoilValue(profileStateCommon.profileState)
  const avatarRef = useRef<AvatarHandles>(null)
  const [chatChannel, setRecoilChatChannel] = useRecoilState(
    chatChannelStateCommon.chatChannelState,
  )
  const onSubmit = async (data: AvatarDetail) => {
    setIsLoading(true)
    const { nickName, avatar } = data
    const response = await callUpdateProfile({
      avatar,
      display_name: profile?.displayName !== nickName ? nickName : undefined,
    })
    if (response.status === 'SUCCESS') {
      setRecoilChatChannel({
        ...chatChannel,
        forceReconnection: true,
      })
      await callProfile()
      router.replace(pageLinkCommon.profile.home)
      notification.success({ message: 'ดำเนินการสำเร็จ', duration: 6 })
      return
    }
    let errorMessage
    const registerData = response.data
    if (response.data.is_display_name_has_locked)
      errorMessage = 'กรุณารอ 30 วันหลังจากการเปลี่ยนชื่อล่าสุด'
    if (response.data.is_duplicate_display_name)
      errorMessage = 'ชื่อนี้มีผู้อื่นใช้แล้ว ลองใช้ชื่ออื่น'
    if (Array.isArray(registerData)) {
      errorMessage = registerData.reduce((acc, message) => message.message, '')
    }
    NiceModal.show(AlertContactModalV2, {
      header: 'ดำเนินการไม่สำเร็จ',
      content: errorMessage,
    })
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (profile) {
      avatarRef.current?.setName(profile?.displayName || '')
      avatarRef.current?.setAvatar(profile?.avatar || 'pixel')
    }
  }, [profile])

  return (
    <div style={{ padding: '1.4rem', margin: 'auto' }}>
      <h3 className="text-center">แก้ไขโปรไฟล์</h3>
      <CreateAvatar
        ref={avatarRef}
        onSubmit={onSubmit}
        isLoading={isLoading}
        hideReferral
        hideTAC
        initialAvatar={profile?.avatar}
        initialNickName={profile?.displayName}
      />
    </div>
  )
}

export default SetProfilePage
