import { useEffect, useState } from 'react'
import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FAQ } from '@pig-common/components/FAQ'
import { faq } from '@pig-common/constants/faq'
import { AlertModal, ALERT_MODAL_TYPE } from '@pig-common/components/Modal'
import ContactUs from '@pig-casino/components/contact-us'
import { faqStateCommon } from '@pig-common/recoils'
import { Tabs } from 'antd'
import { pageLinkCommon } from '@pig-common/constants/page-name'

const { FAQ_TAB } = faqStateCommon

const AffiliateLink = () => (
  <Link href={pageLinkCommon.affiliate}>แนะนำเพื่อน</Link>
)

export default function Faq() {
  const [openContactModal, setContactModal] = useState(false)
  const [activeKey, setActiveKey] = useState<string>('')
  const router = useRouter()

  useEffect(() => {
    setActiveKey((router.query?.tab as string) || FAQ_TAB.GENERAL)
  }, [router.query])

  const ContactAdmin = ({ children }: any) => (
    <span
      className="ps-faq__contact-admin"
      onClick={() => {
        setContactModal(true)
      }}
      onKeyDown={() => {
        setContactModal(true)
      }}
    >
      {children}
    </span>
  )

  return (
    <>
      <Head>
        <title>บาคาร่าเว็บไหนดี คำถามที่พบบ่อย | PIGBET บาคาร่าเว็บตรง</title>
        <meta
          name="title"
          content="บาคาร่าเว็บไหนดี คำถามที่พบบ่อย | PIGBET บาคาร่าเว็บตรง"
        />
        <meta
          property="og:title"
          content="บาคาร่าเว็บไหนดี คำถามที่พบบ่อย | PIGBET บาคาร่าเว็บตรง"
        />
        <meta
          property="twitter:title"
          content="บาคาร่าเว็บไหนดี คำถามที่พบบ่อย | PIGBET บาคาร่าเว็บตรง"
        />

        <meta
          name="description"
          content="บาคาร่าเว็บไหนดี คาสิโนออนไลน์เว็บตรง ได้เงินจริง คำถามที่พบบ่อย ที่นี่มีคำตอบ"
        />
        <meta
          property="og:description"
          content="บาคาร่าเว็บไหนดี คาสิโนออนไลน์เว็บตรง ได้เงินจริง คำถามที่พบบ่อย ที่นี่มีคำตอบ"
        />
        <meta
          property="twitter:description"
          content="บาคาร่าเว็บไหนดี คาสิโนออนไลน์เว็บตรง ได้เงินจริง คำถามที่พบบ่อย ที่นี่มีคำตอบ"
        />
      </Head>
      <section className="ps-faq container">
        <AlertModal
          open={openContactModal}
          onClose={() => {
            setContactModal(false)
          }}
          type={ALERT_MODAL_TYPE.CONTACT_US}
        />
        <div className="ps-faq__title">
          <h1>ถาม-ตอบ</h1>
        </div>
        <Tabs
          className="mb-3 ps-tabs"
          activeKey={activeKey}
          onChange={setActiveKey}
          items={[
            {
              key: FAQ_TAB.GENERAL,
              label: 'คำถามทั่วไป',
              children: (
                <FAQ
                  key={FAQ_TAB.GENERAL}
                  questions={faq.questions}
                  components={{
                    ContactUs,
                    ContactAdmin,
                  }}
                />
              ),
            },
            {
              key: FAQ_TAB.INVITE_FRIEND,
              label: 'ชวนเพื่อน',
              children: (
                <FAQ
                  key={FAQ_TAB.INVITE_FRIEND}
                  questions={faq.inviteFriend}
                  components={{
                    ContactUs,
                    ContactAdmin,
                    AffiliateLink: AffiliateLink,
                  }}
                />
              ),
            },
          ]}
        />
      </section>
    </>
  )
}
