import React, { useEffect, useState, ChangeEvent, useRef } from 'react'
import classNames from 'classnames'
import { Spin } from 'antd'
import { ProgressBar } from '../ProgressBar/index'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button'
import { GameIcon } from '../GameIcon'
import { uploadBigWinFile } from '@pig-common/services/bigWin'
import { notification } from '@pig-frontend/uikit'
import { QUEST_LIST_TYPE } from '@pig-common/hooks/useLoyaltyProgram'
import { BigwinQuestItem } from '@pig-common/hooks/useQuest'
import { LoaderFilter } from '../Loading/Loading'
import TemporaryCloseOverlay from '../Common/TemporaryCloseOverlay'

const SHOW_ERROR_BG_IN_MIN = 3

export type QuestCardProps = {
  componentPrefix?: string
  className?: string
  index?: number
  type: QUEST_LIST_TYPE
  quest: BigwinQuestItem
  showProgressBar?: boolean
  isUploadButton?: boolean
  buttonText?: string | React.ReactNode
  isTutorial?: boolean
  inactive?: boolean
  onClick?: (claimQuestProps: BigwinQuestItem) => void
  fetchBigWin?: () => void
}

enum STATUS_UPLOADING_FILE {
  VALID = 'valid',
  INVALID = 'invalid',
  ERROR = 'error',
}

const QuestCard = ({
  componentPrefix = 'dailyquests-card',
  className = '',
  index,
  type,
  quest,
  showProgressBar,
  isUploadButton,
  buttonText,
  isTutorial,
  inactive,
  onClick,
  fetchBigWin,
}: QuestCardProps) => {
  const [getQuest] = useState(quest)
  const [questProgress, setQuestProgress] = useState<number>(
    quest?.imageUrl ? 1 : 0,
  )
  //const questMaxProgress = quest?.max_progress ? quest.max_progress : 0
  const questMaxProgress = 0
  const inputUploadFile = useRef<HTMLInputElement>(null)
  let progressLabel = ''
  const progressLabelColor = 'white'
  const progressColor = '#408558'
  const [customButtonText, setCustomButtonText] = useState(buttonText)
  const [isLoading, setIsLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const classPrefix = `ps-${componentPrefix}`

  progressLabel = 'ยังไม่สำเร็จ'

  const toggleClassName = (elementName?: string): string => {
    let customClassName = classPrefix
    const postfix = elementName ? `__${elementName}` : ''
    if (isError) {
      customClassName = `${classPrefix}${postfix} ${classPrefix}${postfix}--error`
    } else if (quest?.imageUrl) {
      customClassName = `${classPrefix}${postfix} ${classPrefix}${postfix}--done`
    } else if (elementName) {
      customClassName = `${classPrefix}${postfix}`
    } else {
      customClassName = `${classPrefix}`
    }
    return customClassName && classNames(customClassName)
  }

  const handleErrorBg = () => {
    setIsError(true)
    setTimeout(() => {
      setIsError(false)
    }, SHOW_ERROR_BG_IN_MIN * 1000)
  }

  const generateQuestId = (companentName: string) =>
    `${type}-${companentName}-${isTutorial ? 'tutorial' : quest.uid || index}`

  const checkValid = (file: File) => {
    const { size, type: fileType } = file
    const maxSize = 20 // MB
    const fileSizeMB = parseFloat((size / 1024 / 1024).toFixed(2)) // MB
    const validFileType = ['image/jpeg', 'image/jpg', 'image/png']
    if (fileSizeMB > maxSize) {
      return STATUS_UPLOADING_FILE.INVALID
    }
    if (!validFileType.includes(fileType)) {
      return STATUS_UPLOADING_FILE.INVALID
    }
    return STATUS_UPLOADING_FILE.VALID
  }

  const claimGameQuestReward = () => {
    if (quest && quest.uid) {
      setButtonLoading(true)
      onClick && onClick(quest)
    }
  }

  const uploadError = (status: STATUS_UPLOADING_FILE) => {
    handleErrorBg()
    let notiMsg = ''
    if (status === STATUS_UPLOADING_FILE.INVALID) {
      notiMsg =
        'ขนาดของรูปต้องไม่เกิน 20 MB\nและเฉพาะไฟล์ jpg, png และ heif/heic เท่านั้น'
    } else {
      notiMsg = 'อัพโหลดรูปภาพไม่สำเร็จ'
    }
    notification.error({
      message: notiMsg,
      duration: 2,
      placement: 'top',
    })
  }

  const uploadFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!(e.target.files && e.target.files[0])) {
      uploadError(STATUS_UPLOADING_FILE.INVALID)
      return
    }
    const file = e.target.files[0]
    const statusUploading = checkValid(file)
    try {
      setIsLoading(true)
      const formData = new FormData()
      let response
      switch (statusUploading) {
        case STATUS_UPLOADING_FILE.VALID:
          formData.append('file', file)
          response = await uploadBigWinFile({
            uid: quest.uid || '',
            formData,
          })
          if (response && response.status === 'ERROR')
            uploadError(STATUS_UPLOADING_FILE.ERROR)
          if (fetchBigWin) fetchBigWin()
          break
        case STATUS_UPLOADING_FILE.INVALID:
          uploadError(STATUS_UPLOADING_FILE.INVALID)
          if (inputUploadFile.current) {
            inputUploadFile.current.value = ''
          }
          break
        default:
          break
      }
    } catch {
      setIsLoading(false)
      uploadError(STATUS_UPLOADING_FILE.ERROR)
    } finally {
      setIsLoading(false)
    }
  }

  const setupQuestCard = () => {
    const cQuestProgress = 0

    switch (type) {
      case QUEST_LIST_TYPE.BIGWIN_QUEST:
        if (quest?.imageUrl === '') {
          setCustomButtonText(buttonText)
        } else if (quest?.imageUrl) {
          setCustomButtonText(`รับ ${quest?.tickets} Tickets`)
        } else {
          setCustomButtonText(buttonText)
        }
        break
      case QUEST_LIST_TYPE.WEEKLY_QUEST:
        setCustomButtonText('ทำภารกิจ!')
        break
      case QUEST_LIST_TYPE.TUTORIAL_QUEST:
        setCustomButtonText(buttonText)
        break

      default:
        break
    }
    setQuestProgress(cQuestProgress)
  }

  useEffect(() => {
    setupQuestCard()
  }, [])

  useEffect(() => {
    setupQuestCard()
  }, [quest])

  const handleIcon = () => {
    if (isError) {
      return '/images/dailyquests/error-ticket.png'
    } else if (quest?.imageUrl) {
      return '/images/dailyquests/claim-ticket.png'
    } else {
      return '/images/dailyquests/primary-ticket.png'
    }
  }

  const questCardContent = () => {
    return (
      <div
        className={`${className} ${toggleClassName()}`}
        data-testid={generateQuestId('card')}
        data-transaction-progress={`${questProgress}/${questMaxProgress}`}
        data-transaction-uid={getQuest?.uid}
      >
        <LoaderFilter switcher={isLoading} />
        <div className={toggleClassName('top')}>
          <div
            className={toggleClassName('logo')}
            data-testid={generateQuestId('icon')}
          >
            <div className="ps-dailyquests-card__bigwin">
              <GameIcon
                src={`/images/dailyquests/champion/champion-${
                  quest?.gameRefType && quest.gameRefType.toLowerCase()
                }-icon.png`}
                quality={75}
                width={40}
                height={40}
                alt="gameLogo"
                priority
                campaign="nope"
                isCampaign={false}
              />
            </div>
          </div>
          <div
            className={toggleClassName('title')}
            data-testid={generateQuestId('title')}
            dangerouslySetInnerHTML={{
              __html: `คุณได้รับเงินรางวัล ${quest?.totalPayout} บาท<br/>จากค่ายเกม ${quest?.gameRefType}`,
            }}
          />
          <div
            className={toggleClassName('subtitle')}
            data-testid={generateQuestId('subtitle')}
          >
            {`ชนะ ${quest?.roundStack} ครั้ง (เดิมพันรวม ${quest?.totalBet} บาท)`}
          </div>
          {showProgressBar && (
            <div className={toggleClassName('progress-bar')}>
              <ProgressBar
                id={`dailyquests-${type}-progress`}
                label={progressLabel}
                labelColor={progressLabelColor}
                currentProgress={questProgress}
                maxProgress={0}
                progressColor={progressColor}
              />
            </div>
          )}
        </div>
        <div className={toggleClassName('bottom')}>
          <div
            className={toggleClassName('tickets')}
            data-testid={generateQuestId('tickets')}
          >
            <img
              alt="quest-ticket"
              src={handleIcon()}
              className="ps-dailyquests-card__icon"
            />
            <span className="d-none d-sm-inline">รางวัล</span>
            <span style={{ padding: '0px 2px' }}>{quest?.tickets}</span>
            <span>tickets</span>
          </div>
          {quest?.imageUrl ? (
            <>
              {buttonLoading ? (
                <Spin />
              ) : (
                <Button
                  id={
                    type === QUEST_LIST_TYPE.GAME_QUEST
                      ? 'claim-daily-quest-button'
                      : `${type}__card-button`
                  }
                  //testId={generateQuestId('bottom')}
                  className={toggleClassName('button')}
                  type={BUTTON_TYPE.CTA_1}
                  size={BUTTON_SIZE.SM}
                  onClick={claimGameQuestReward}
                >
                  {customButtonText}
                </Button>
              )}
            </>
          ) : (
            <Button
              //testId={generateQuestId('upload-button')}
              className={toggleClassName('button')}
              id={`${type}__upload-button`}
              type={BUTTON_TYPE.CTA_1}
              size={BUTTON_SIZE.SM}
            >
              <label
                data-testid={`file-upload__label-${getQuest.uid}`}
                htmlFor={`file-upload-${getQuest.uid}`}
              >
                {customButtonText}
              </label>
              <input
                ref={inputUploadFile}
                data-testid={`file-upload__input-${getQuest.uid}`}
                id={`file-upload-${getQuest.uid}`}
                type="file"
                name="file-upload"
                className={`${classPrefix}__file-upload`}
                accept=".jpeg, .jpg, .png"
                onChange={uploadFileHandler}
              />
            </Button>
          )}
        </div>
      </div>
    )
  }

  const isInactive = inactive && type !== QUEST_LIST_TYPE.BIGWIN_QUEST

  return isInactive ? (
    <TemporaryCloseOverlay label={{ textShadow: 'none', size: 'sm' }}>
      {questCardContent()}
    </TemporaryCloseOverlay>
  ) : (
    <>{questCardContent()}</>
  )
}

export default QuestCard
