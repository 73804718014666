import { useEffect } from 'react'
import { Form } from 'antd'
import { Input } from '@pig-frontend/uikit'
import { SearchOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'
import debounce from 'lodash/debounce'
import styles from './index.module.scss'

export default function GuildSearch() {
  const router = useRouter()
  const [form] = Form.useForm()

  const handleSubmit = (values: { q?: string }) => {
    router.push({ query: { q: values?.q } }, undefined, { shallow: true })
  }

  const searchDebounce = debounce(handleSubmit, 400)
  const handleChange = (value: string) => {
    searchDebounce({ q: value })
  }

  useEffect(() => {
    form.setFieldValue('q', router.query?.q)
  }, [router.query])

  return (
    <Form
      form={form}
      name="guild_search"
      onFinish={handleSubmit}
      className={styles.form}
    >
      <Form.Item name="q">
        <Input
          prefix={<SearchOutlined />}
          placeholder="ค้นหากิลด์"
          onChange={(e) => handleChange(e.target.value)}
        />
      </Form.Item>
    </Form>
  )
}
