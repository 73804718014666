import { useRef, useEffect } from 'react'
import parse from 'html-react-parser'
import { StaticImageData } from 'next/image'
import { Image } from '@pig-frontend/uikit'
import { useRouter } from 'next/router'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import styles from './index.module.scss'

export type MaintenanceProps = {
  logoImage: StaticImageData | string
  maintenanceImage: StaticImageData | string
}

const Maintenance = ({ logoImage, maintenanceImage }: MaintenanceProps) => {
  const router = useRouter()
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { data: systemStatus, isSuccess } = useSystemStatus({})
  const currentPopState: any = useRef()

  useEffect(() => {
    // Redirect after reloaded page and not in under construction anymore
    if (isSuccess && !systemStatus?.underConstruction) {
      if (isAuthenticated) {
        router.replace(pageLinkCommon.lobby)
      } else {
        router.replace(pageLinkCommon.index)
      }
    }
  }, [systemStatus, isSuccess, isAuthenticated])

  useEffect(() => {
    // Lock maintenance page by prevent navigating page back to other pages
    if (typeof window !== 'undefined') {
      currentPopState.current = window.onpopstate
      window.history.pushState(null, '', window.location.href)
      window.onpopstate = function () {
        window.history.go(1)
      }
    }
    return () => {
      window.onpopstate = currentPopState.current
    }
  }, [])

  return (
    <div className={styles.container}>
      <Image
        className={styles.logo}
        src={logoImage}
        placeholder="blur"
        alt="logo"
      />
      <div className={styles['maintenance-logo']}>
        <Image
          src={maintenanceImage}
          width={247}
          height={233}
          alt="maintenance"
        />
      </div>
      <h5 className={styles.title}>กำลังปิดปรับปรุงเว็บไซต์</h5>
      <div className={styles.content}>
        {parse(systemStatus?.underConstructionText || '')}
      </div>
    </div>
  )
}

export default Maintenance
