import { Collapse } from 'antd'
import JsxParser from 'react-jsx-parser'
import { UpOutlined, DownOutlined } from '@ant-design/icons'

const { Panel } = Collapse

const FAQ = ({ components, questions = [] }) => (
  <Collapse
    className="ps-faq-component"
    bordered={false}
    expandIcon={({ isActive }) => {
      if (isActive) {
        return <UpOutlined />
      } else {
        return <DownOutlined />
      }
    }}
    expandIconPosition="right"
    accordion
  >
    {questions.map(({ question, answer }) => (
      <Panel
        className="ps-faq-component__panel-title"
        header={question}
        key={`${question}`}
      >
        <div className="ps-faq-component__panel-content">
          {/* @ts-ignore */}
          <JsxParser components={components} jsx={answer} />
        </div>
      </Panel>
    ))}
  </Collapse>
)

export default FAQ
