import { Divider } from 'antd'
import NiceModal from '@ebay/nice-modal-react'
import styled from 'styled-components'
import { screen } from '@pig-common/utils/responsive'
import { TextLink } from '../Button'
import { Withdrawal } from '../Withdrawal'
import { AlertModalV2, ALERT_MODAL_TYPE } from '../Modal'
import { WITHDRAW_TYPE } from '@pig-common/hooks/useWithdraw'
import { WithdrawalProps } from '../Withdrawal/Withdrawal'
import { RightOutlined as AntdRightOutlined } from '@ant-design/icons'

type IAffiliateWithdrawProps = WithdrawalProps

const WithdrawWrapper = styled.div`
  background-color: white;
  position: relative;
  padding: 1rem;
  border-radius: 8px;
`

const StyledWithdraw = styled(Withdrawal)`
  @media ${screen.md} {
    max-width: 360px;
    margin: auto;
  }
`

const AffiliateWithdrawTitle = styled.h3`
  text-align: center;
  font-size: 28.8px;
  font-weight: 500;
`

const StyledDivider = styled(Divider)`
  margin: 22px 0;
`

const StyledTextLink = styled(TextLink)`
  height: 24px;
`

const RightOutlined = styled(AntdRightOutlined)`
  display: flex;
  font-size: 16px;
`

const AffiliateWithdraw = ({
  onWithdraw,
  onError,
}: IAffiliateWithdrawProps) => {
  return (
    <WithdrawWrapper>
      <AffiliateWithdrawTitle>ถอนเงิน</AffiliateWithdrawTitle>
      <StyledWithdraw
        fullWidthForm
        withdrawType={WITHDRAW_TYPE.AFFILIATE}
        onWithdraw={onWithdraw}
        onError={onError}
      />
      <StyledDivider className="ps-menu-divider" />
      <StyledTextLink
        id="menu-footer-link-btn"
        className="ps-menu-footer-link mx-auto"
        appendContent={<RightOutlined />}
        onClick={() => {
          NiceModal.show(AlertModalV2, {
            type: ALERT_MODAL_TYPE.CONTACT_US,
          })
        }}
      >
        แจ้งปัญหา
      </StyledTextLink>
    </WithdrawWrapper>
  )
}

export default AffiliateWithdraw
