import { AxiosRequestConfig } from 'axios'
import { useRecoilState } from 'recoil'
import NiceModal from '@ebay/nice-modal-react'

import {
  BankCodeName,
  REFER_TYPE,
  REGISTER_BY,
  REGISTER_TYPE,
} from '@pig-common/models/shared-types'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

import { useAxios } from './useAxios'
import { registerStateCommon } from '../recoils'
import { AlertContactModalV2 } from '@pig-common/components/Modal'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'

export type UTM = {
  url?: string
  utm_source?: string
  utm_medium?: string
  utm_id?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export type RegisterRequest = {
  phoneNumber?: string
  firstName?: string
  lastName?: string
  identityNumber?: string
  backIdentityNumber?: string
  dateOfBirth?: string
  bankCode?: BankCodeName
  bankAccountNumber?: string
  bankAccountName?: string
  userPin?: string
  registerType?: REGISTER_TYPE
  referrer?: string
  referrerType?: REFER_TYPE
  utm?: UTM
  registerBy: REGISTER_BY
  trueWalletId?: string
  lineIdToken?: string
  displayName?: string
  avatar?: string
}

export type RegisterPayload = {
  phone_number?: string
  first_name?: string
  last_name?: string
  identity_number?: string
  back_identity_number?: string
  /**
   * @description must be YYYY-MM-DD in Anno Domini (AD) format
   * @example ```1990-06-29```
   * */
  date_of_birth?: string
  bank_code?: BankCodeName | ''
  bank_account_number?: string
  bank_account_name?: string
  user_pin?: string
  register_type?: REGISTER_TYPE
  referrer?: string
  referrer_type?: REFER_TYPE
  utm?: UTM
  register_by: REGISTER_BY
  true_wallet_id?: string
  line_id_token?: string
  display_name: string
  avatar: string
}

export type RegisterResponseData = {
  player_username?: string
  user_code?: string
  game_token?: string
} & { exception_detail?: string } & { field: string; message: string }[]

export type RegisterResponse = BaseApiResponse<RegisterResponseData>

export type RegisterHook = {
  callRegister: (request: RegisterRequest) => Promise<RegisterResponse>
}

export const useRegister = (): RegisterHook => {
  const { pigspinApiInstance } = useAxios()
  const [register, setRegister] = useRecoilState(
    registerStateCommon.registerState,
  )

  const callRegister = async (
    request: RegisterRequest,
  ): Promise<RegisterResponse> => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/register',
      method: 'POST',
    }
    const { utm } = request
    let registerPayload: RegisterPayload = {
      phone_number: request.phoneNumber || undefined,
      first_name: request.firstName || '',
      last_name: request.lastName || '',
      identity_number: request.identityNumber || '',
      back_identity_number: request.backIdentityNumber || '',
      date_of_birth: request.dateOfBirth,
      bank_code: request.bankCode || '',
      bank_account_number: request.bankAccountNumber || '',
      bank_account_name: request.bankAccountName || '',
      user_pin: request.userPin || '',
      register_type: request.registerType,
      referrer: request.referrer ? request.referrer : undefined,
      register_by: request.registerBy,
      referrer_type:
        request.referrer && request.referrerType
          ? request.referrerType
          : undefined,
      display_name: request.displayName || '',
      avatar: request.avatar || '',
      line_id_token: request.lineIdToken || undefined,
    }
    if (utm?.utm_source && utm?.utm_medium) {
      registerPayload = {
        ...registerPayload,
        utm: {
          url: utm?.url || '',
          utm_source: utm?.utm_source || '',
          utm_medium: utm?.utm_medium || '',
          utm_id: utm?.utm_id || '',
          utm_campaign: utm?.utm_campaign || '',
          utm_term: utm?.utm_term || '',
          utm_content: utm?.utm_content || '',
        },
      }
    }

    const response = await fetchWithJSON<RegisterResponse>(
      pigspinApiInstance,
      requestConfig,
      registerPayload,
    )
    if (response?.service_code === 'PIG-4015') {
      setRegister({
        ...register,
        isReferrerValid: false,
      })
    }
    const registerData = response?.data as RegisterResponseData
    if (response?.service_code === 'PIG-5025') {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        header: 'มีบางอย่างผิดพลาด',
        content: registerData.exception_detail,
        hideOnClose: true,
        buttonConfirmText: 'ตกลง',
        onClose: () => {},
        onConfirm: () => {},
      })
      return response
    }

    let errorMessage = ''
    if (Array.isArray(registerData)) {
      errorMessage = registerData.reduce((acc, message) => message.message, '')
    }
    if (registerData.exception_detail) {
      errorMessage = registerData.exception_detail
    }
    if (errorMessage) {
      NiceModal.show(AlertContactModalV2, {
        header: 'มีบางอย่างผิดพลาด',
        content: errorMessage,
      })
    }
    return response
  }

  return { callRegister }
}
