import NiceModal from '@ebay/nice-modal-react'
import SetBank, {
  SetBankPageProps,
} from '@pig-common/pages-common/profile/set-bank'

function SetBankPage(props: SetBankPageProps) {
  return (
    <NiceModal.Provider>
      <SetBank {...props} />
    </NiceModal.Provider>
  )
}

export { SetBankPage }
export type { SetBankPageProps }
