import { useRef } from 'react'
import { CarouselRef } from 'antd/es/carousel'

export const useCarousel = () => {
  const sliderEl = useRef<CarouselRef>(null)

  const next = () => {
    if (sliderEl?.current) {
      sliderEl?.current.next()
    }
  }

  const previous = () => {
    if (sliderEl?.current) {
      sliderEl?.current.prev()
    }
  }

  const onTouchStart = () => {
    if (sliderEl?.current) {
      const { autoPlay } = sliderEl.current
      autoPlay('blur')
    }
  }

  const onTouchEnd = () => {
    if (sliderEl?.current) {
      const { autoPlay } = sliderEl.current
      autoPlay('blur')
    }
  }

  return {
    sliderEl,
    next,
    previous,
    onTouchEnd,
    onTouchStart,
  }
}
