import { AxiosRequestConfig } from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import NiceModal from '@ebay/nice-modal-react'

import dayjs from 'dayjs'
import { useState } from 'react'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import { useAxios } from './useAxios'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { forceDateToISO } from '@pig-common/utils/datetime'
import { AlertContactModalV2 } from '@pig-common/components/Modal/AlertContactModal/AlertContactModal'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { VipItem, useVip } from '@pig-common/hooks/useVip'

export type SpinTypeData = 'freespin' | 'ticket' | 'none'

export type ErrorMessage = {
  detail?: string
  message?: string
}
export type SpinHistoryData = {
  uid?: string
  user_uuid?: string
  customer_code?: string
  created_at?: Date
  updated_at?: Date
  daily_spin_amount?: number
  daily_spin_type?: SpinTypeData
}
export type SpinRanking = {
  index: number
  createdAt: string
  byTicket: boolean
  amount: number
  customerCode: string
  displayName: string
  vip?: VipItem
}

export type SpinType = 'FREE' | 'TICKET' | 'NONE'
export type SpinHistory = {
  amount: number
  userId: string
  psCode: string
  createdAt: Date
  type: SpinType
}

const mapType: { [key in SpinTypeData]: SpinType } = {
  freespin: 'FREE',
  ticket: 'TICKET',
  none: 'NONE',
}
const mapToSpin = (item: SpinHistoryData): SpinHistory => {
  return {
    amount: item.daily_spin_amount || 0,
    userId: item.user_uuid || '',
    psCode: item.customer_code || '',
    createdAt: dayjs(forceDateToISO(`${item.created_at}`)).toDate(),
    type: mapType[item.daily_spin_type || 'none'] || 'NONE',
  }
}
const mapRanking = (ranking: any): SpinRanking => {
  return {
    index: ranking.no_index,
    createdAt: ranking.created_at,
    byTicket: ranking.daily_spin_type === 'freespin',
    amount: ranking.daily_spin_amount,
    customerCode: ranking.customer_code,
    displayName: ranking.display_name,
  }
}
const extractRanking = (ranking: any, vipData: VipItem[]): SpinRanking[] => {
  if (!Array.isArray(ranking)) return []
  const result: SpinRanking[] = ranking.map((rank) => {
    const mappedRanking = mapRanking(rank)
    const matchedVip =
      vipData.find((vip) => vip.customerCode === rank.customer_code) ||
      undefined
    return {
      ...mappedRanking,
      vip: matchedVip,
    }
  })
  return result
}

export const useSpin = () => {
  const { pigspinApiInstance } = useAxios()
  const [, setCookies] = useCookies<any>([COOKIES_KEY.DAILY_SPIN])
  const [summaryRankingDay, setSummaryDay] = useState<number>(0)
  const [summaryRankingMonth, setSummaryMonth] = useState<number>(0)
  const { callPlayerVipInfo } = useVip()

  const flagDailyFreeSpin = () => {
    setCookies(COOKIES_KEY.DAILY_SPIN, new Date().getTime(), {
      expires: dayjs().endOf('day').toDate(),
    })
  }
  const {
    data: quotaFreespin,
    refetch: checkQuotaFreespin,
    isFetching: isCheckingFreespin,
  } = useQuery<number, any>(
    ['quotaFreespin'],
    async () => {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/loyalty/spin/free',
        method: 'GET',
      }
      const result = await fetchWithJSON<
        BaseApiResponse<{ user_daily_free_spin_left: number }>
      >(pigspinApiInstance, requestConfig)
      return result.data.user_daily_free_spin_left || 0
    },
    {
      initialData: 0,
      enabled: false,
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: spinHistory,
    isFetching: isSpinHistoryFetching,
    refetch: fetchSpinHistory,
  } = useQuery<SpinHistory[], any>(
    ['spinHistory'],
    async () => {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/loyalty/spin/history',
        method: 'GET',
      }
      const result = await fetchWithJSON<
        BaseApiResponse<SpinHistoryData[] & { data: SpinHistoryData[] }>
      >(pigspinApiInstance, requestConfig)
      // TODO : remove when api fixed
      if (result.data.data) {
        return result.data.data.map(mapToSpin)
      }
      return result.data.map(mapToSpin)
    },
    {
      initialData: [],
      refetchOnWindowFocus: false,
      retry: 0,
    },
  )

  const {
    data: freeSpinResult,
    mutate: freeSpin,
    isLoading: isFreeSpinLoading,
  } = useMutation({
    mutationFn: async () => {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/loyalty/spin/free',
        method: 'POST',
      }
      const result = await fetchWithJSON<BaseApiResponse<SpinHistoryData>>(
        pigspinApiInstance,
        requestConfig,
      )
      if (result.status === 'SUCCESS') {
        return result.data.daily_spin_amount
      }
      throw new Error(result.service_code)
    },
    onError: () => {
      NiceModal.show(AlertContactModalV2, {
        header: 'มีบางอย่างผิดพลาด',
      })
    },
    onSuccess: () => {
      flagDailyFreeSpin()
    },
  })

  const {
    data: topRanking,
    isFetching: isFetchingRanking,
    refetch: fetchingRanking,
  } = useQuery<SpinRanking[], any>(
    ['spinRanking'],
    async () => {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/loyalty/spin/history-ranking',
        method: 'POST',
      }
      const result = await fetchWithJSON<any>(
        pigspinApiInstance,
        requestConfig,
        {
          pagination: {
            page: 1,
            size: 10,
          },
          date_type: 'day',
          order_by: 'desc',
        },
      )
      setSummaryDay(result?.data?.daily_spin_summary_today || 0)
      setSummaryMonth(result?.data?.daily_spin_summary_month || 0)
      const customers =
        result?.data?.top_ranking.map(
          (ranking: any) => ranking.customer_code,
        ) || []
      const vipData = await callPlayerVipInfo(customers)
      const rankingList: SpinRanking[] = extractRanking(
        result.data.top_ranking,
        vipData,
      )
      return rankingList
    },
    {
      enabled: true,
      staleTime: 10 * (60 * 1000),
      cacheTime: 1 * (60 * 1000),
    },
  )

  const { data: jackpotAmount, isFetching: isFetchingJackpot } = useQuery<
    number,
    any
  >(
    ['spinJackpotAmount'],
    async () => {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/loyalty/spin/announcement',
        method: 'GET',
      }
      const result = await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
      return result?.data?.jackpot || 0
    },
    {
      enabled: true,
      staleTime: 10 * (60 * 10000),
      cacheTime: 1 * (60 * 10000),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: spinByTicketResult,
    mutate: spinByTicket,
    isLoading: isSpinByTicketLoading,
    reset,
  } = useMutation({
    mutationFn: async () => {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/loyalty/spin/ticket',
        method: 'POST',
      }
      const result = await fetchWithJSON<
        BaseApiResponse<SpinHistoryData & ErrorMessage>
      >(pigspinApiInstance, requestConfig)
      if (result.status === 'SUCCESS') {
        return result.data.daily_spin_amount
      }
      if (result.service_code === 'PIG-8011') {
        NiceModal.show(AlertConfirmModal, {
          open: true,
          hideOnClose: true,
          header: result.data.message,
          content: result.data.detail,
          onConfirm: () => {
            reset()
          },
        })
        return undefined
      }
      throw new Error(result.service_code)
    },
    onError: () => {
      NiceModal.show(AlertContactModalV2, {
        header: 'มีบางอย่างผิดพลาด',
      })
      reset()
    },
  })

  return {
    isFreeSpin: quotaFreespin && quotaFreespin > 0,
    quotaFreespin,
    isCheckingFreespin,
    isFetchingJackpot,
    spinHistory: spinHistory || [],
    isSpinHistoryFetching,
    isFreeSpinLoading,
    isSpinByTicketLoading,
    spinByTicketResult: spinByTicketResult || -1,
    freeSpinResult: freeSpinResult || -1,
    topRanking: topRanking || [],
    jackpotAmount,
    isFetchingRanking,
    summaryRankingDay,
    summaryRankingMonth,
    checkQuotaFreespin,
    freeSpin,
    spinByTicket,
    fetchSpinHistory,
    fetchingRanking,
  }
}
