import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { registerStateCommon } from '@pig-common/recoils'
import {
  useCookies,
  RegisterCookieType,
  COOKIES_KEY,
  OtpCookieType,
} from '@pig-common/utils/cookies'

import {
  StepperChildrenProps,
  StepperChildrenHandles,
} from '@pig-common/components/Stepper'
import { Otp, OTP_DATA } from '@pig-common/components/Otp'

export type VerifyOtpProps = StepperChildrenProps

const VerifyOtp = forwardRef<StepperChildrenHandles, VerifyOtpProps>(
  ({ next }, ref) => {
    const register = useRecoilValue(registerStateCommon.registerState)
    const { phoneNumber, otpCode, otpToken } = register

    const [otpData, setOtpData] = useState<OTP_DATA>({
      phoneNumber: phoneNumber || '',
      otpCode: otpCode || '',
      otpToken: otpToken || '',
      otpKey: '',
    })

    const [cookies, setCookies, removeCookies] = useCookies<
      OtpCookieType | Partial<RegisterCookieType>
    >([COOKIES_KEY.REGISTER, COOKIES_KEY.OTP])

    const getChildrenData = () => {
      return otpData
    }

    useImperativeHandle(ref, () => ({
      getChildrenData,
    }))

    return (
      <div style={{ marginTop: '40px' }}>
        <Otp
          shouldShowSubTitleText
          otpData={{ ...otpData }}
          onSuccess={(result) => {
            const otpCookies = cookies[COOKIES_KEY.OTP]
            if (
              otpCookies &&
              phoneNumber !==
                (cookies[COOKIES_KEY.OTP] as OtpCookieType)?.phoneNumber
            ) {
              removeCookies(COOKIES_KEY.REGISTER)
            }
            setCookies(COOKIES_KEY.OTP, result)
            setOtpData(result)
            next()
          }}
        />
      </div>
    )
  },
)

VerifyOtp.displayName = 'VerifyOtp'
export default VerifyOtp
