import React from 'react'
import Router from 'next/router'

const DailyQuestHeader = ({
  total_tickets,
}: {
  total_tickets: string
}): JSX.Element => {
  return (
    <div className="ps-quests-banner__info">
      <div className="ps-quests-banner__title">ภารกิจ</div>
      <div className="ps-quests-banner__redemption">
        <div className="ps-quests-banner__tickets">
          <img
            alt="quest-ticket"
            src="/images/dailyquests/white-ticket.png"
            className="ps-quests-banner__icon"
          />
          <span>{total_tickets}</span>
          Tickets
        </div>
        <button
          data-testid="dailyquests-bubble-redeem-button"
          className="ps-quests-banner__button ps-button ps-button--cta-1 ps-button--sm d-block"
          onClick={() => Router.push('/pig-shop')}
        >
          <span>แลกรางวัล</span>
        </button>
        <div className="ps-dailyquests-animated-highlight" />
        <div className="ps-dailyquests-animated-highlight ps-dailyquests-animated-highlight--1" />
      </div>
    </div>
  )
}

export default DailyQuestHeader
