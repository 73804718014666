import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import { LoginWithLine, LoginWithLineResponse } from '@pig-common/server/line'
import { LOGIN_STATUS } from '@pig-common/services/login'
import { authStateCommon } from '@pig-common/recoils'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { AlertContactModalV2 } from '@pig-common/components/Modal'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { useLoading } from '@pig-common/hooks/useLoading'
import { notification } from '@pig-frontend/uikit'

export type RegisterLineProps = {
  loginResponse: LoginWithLineResponse
  existingPhone: string
  existingPin: string
}

export default function LineVerify(props: RegisterLineProps) {
  const { loginResponse, existingPhone, existingPin } = props
  const router = useRouter()
  const [, setCookies, removeCookies] = useCookies<string>([])
  const [, setAuth] = useRecoilState(authStateCommon.authState)
  const { callUpdateProfileWithoutAuth } = useLegacyProfile()
  const { loading, stopLoading } = useLoading()
  const hasExistingPhone = existingPhone.length > 0
  const hasExistingPin = existingPin.length > 0

  const setAuthorization = (response: LoginWithLineResponse) => {
    setCookies(COOKIES_KEY.CFID, response.cfid)
    setCookies(COOKIES_KEY.UID, response.Phone || '0000000000')
    setCookies(COOKIES_KEY.CUSTOMER_CODE, response.CustomerCode || '')
    setAuth({
      cfid: response.cfid,
      customerCode: response.CustomerCode,
      gameToken: response.GameToken,
      name: response.CustomerName,
      isLoginPhoneSuccess: true,
      userUID: response.UserUid || '',
    })
  }

  const login = async () => {
    // LOGIN LINE
    if (!hasExistingPhone && !hasExistingPin) {
      setAuthorization(loginResponse)
      notification.success({ message: 'ยินดีต้อนรับ', duration: 3 })
      router.push('/lobby')
      return
    }

    // LINE NOT FOUND
    if (loginResponse.Status === LOGIN_STATUS.LINE_ID_NOT_FOUND) {
      await router.push('/login', '/login', { scroll: true })
      removeCookies(COOKIES_KEY.EXISTING_PHONE)
      removeCookies(COOKIES_KEY.EXISTING_PIN)
      NiceModal.show(AlertConfirmModal, {
        hideOnClose: true,
        header: 'สมัครสมาชิกไม่สำเร็จ',
        content: 'ไม่พบบััญชีผู้ใช้ที่สมัครด้วย LINE นี้',
        buttonConfirmText: 'ตกลง',
      })
      return
    }

    const pureLineAccount =
      loginResponse.lineIdToken.length > 0 && loginResponse.Phone === ''

    // MAP WITH EXISTING LINE ACCOUNT SUCCESS
    if (pureLineAccount && hasExistingPhone && hasExistingPin) {
      const updateProfileResponse = await callUpdateProfileWithoutAuth({
        bearer_token: loginResponse.cfid,
        phone_number: existingPhone,
        user_code: loginResponse.UserUid,
        user_pin: existingPin,
      })
      removeCookies(COOKIES_KEY.EXISTING_PHONE)
      removeCookies(COOKIES_KEY.EXISTING_PIN)
      if (updateProfileResponse.status === 'SUCCESS') {
        setAuthorization({
          ...loginResponse,
          Phone: existingPhone,
        })
        notification.success({ message: 'ดำเนินการสำเร็จ', duration: 6 })
        router.push('/profile', '/profile', { scroll: true })
      } else {
        NiceModal.show(AlertContactModalV2, {
          header: 'อัพเดทข้อมูลไม่สำเร็จ',
        })
      }
      return
    }

    // MAP WITH EXISTING LINE ACCOUNT FAIL
    await router.push('/login', '/login', { scroll: true })
    removeCookies(COOKIES_KEY.EXISTING_PHONE)
    removeCookies(COOKIES_KEY.EXISTING_PIN)
    NiceModal.show(AlertConfirmModal, {
      hideOnClose: true,
      header: 'สมัครสมาชิกไม่สำเร็จ',
      content: 'เนื่องจากบััญชี LINE นี้ถูกใช้ลงทะเบียนแล้ว',
      buttonConfirmText: 'ตกลง',
    })
  }

  React.useEffect(() => {
    login()
    loading()
    return () => {
      stopLoading()
    }
  }, [])

  return (
    <div className="text-center" style={{ height: '50vh', display: 'flex' }} />
  )
}

export const getLineVerifyServerSideProps = async (context: any) => {
  const setCookies = [`${'line_state'}=deleted; Max-Age=0;`]
  const loginResponse = await LoginWithLine(context)
  const baseProps = {
    existingPhone: context.req.cookies.existing_phone || '',
    existingPin: context.req.cookies.existing_pin || '',
  }

  if (
    loginResponse.Status === LOGIN_STATUS.LINE_ID_NOT_FOUND &&
    !context.req.cookies.existing_phone &&
    !context.req.cookies.existing_pin
  ) {
    setCookies.push(
      `${COOKIES_KEY.LINE_DATA}=${JSON.stringify({
        id_token: loginResponse.cfid,
      })}`,
    )
    return {
      props: {
        ...baseProps,
        loginResponse,
      },
      redirect: {
        destination: `/register?type=line&id_token=${loginResponse.lineIdToken}`,
        permanent: false,
      },
    }
  }

  // Set auth on client side and go to lobby
  return {
    props: {
      ...baseProps,
      loginResponse,
    },
  }
}
