import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IGuildDataResponse, IGuildInfo } from '@pig-common/types/Guild.type'
import { camelizeKeys } from '@pig-frontend/utils'

interface IGetGuildType<SelectReturnType = IGuildInfo> {
  params: guildApi.IGetGuildDetailParams
  select?: (
    data: CommonResponse<Partial<IGuildDataResponse['myGuild']>>,
  ) => SelectReturnType
  enabled?: boolean
  refetchInterval?: number
}

export default function useGuildDetail<SelectReturnType>({
  params,
  select,
  enabled,
}: IGetGuildType<SelectReturnType>) {
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useQuery({
    queryKey: [guildApi.key.getDetail],
    queryFn: () =>
      guildApi.getDetail({
        guildId: params?.guildId,
        customerCode: params?.customerCode || customerCode || '',
      }),
    enabled,
    select: (data) =>
      select
        ? select(data)
        : data?.data?.info
        ? camelizeKeys(data?.data?.info)
        : {},
  })
}
