import React, { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import moment from 'moment'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { walletStateCommon } from '@pig-common/recoils'
import Head from 'next/head'
import { profileStateCommon } from '@pig-common/recoils'
import NiceModal from '@ebay/nice-modal-react'
import { useCookies, COOKIES_KEY } from '@pig-common/utils/cookies'
import { WalletSection } from '@pig-common/components/WalletSection'
import {
  WalletMenu,
  WalletMenuKeyType,
  WalletMenuEnum,
  WalletMenuMode,
} from '@pig-common/components/WalletMenu'
import { AlertModal, ALERT_MODAL_TYPE } from '@pig-common/components/Modal'
import { EmptyMenu, EmptyMenuProps } from '@pig-common/components/EmptyMenu'
import { TransactionHistorySection } from '@pig-common/components/TransactionHistorySection'
import { Withdrawal } from '@pig-common/components/Withdrawal'
import { buildCashTransactionGroupList } from '@pig-common/components/TransactionHistorySection/TransactionHistorySectionBuilder'
import { DepositSection } from '@pig-common/components/DepositSection'
import { DepositQRSection } from '@pig-common/components/DepositQRSection'
import { DepositInfo, DEPOSIT_TYPE } from '@pig-common/components/DepositInfo'
import {
  InformationBox,
  INFO_BOX_VARIANT,
} from '@pig-common/components/InformationBox'
import useWindowSize from '@pig-common/utils/useWindowSize'
import { BALANCE_STATUS, useBalance } from '@pig-common/hooks/useBalance'
import {
  useDepositBank,
  DepositBankInfo,
  FETCH_DEPOSIT_BANK_LIST_STATUS,
} from '@pig-common/hooks/useDepositBank'
import { useCoinWallet } from '@pig-common/hooks/useCoinWallet'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { useWalletHistory } from '@pig-common/hooks/useWalletHistory'
import { formatPhoneNumber } from '@pig-common/utils/formatPhoneNumber'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { ExclamationCircleOutlined } from '@ant-design/icons'

type TargetStatesType = {
  current: number
  tabKey: WalletMenuKeyType
  mode: WalletMenuMode
}

// const showMenu: WalletMenuMode[] = ['cash', 'bonus']
const getTargetStatesFromQuery = (query: ParsedUrlQuery): TargetStatesType => {
  const defaultParams: TargetStatesType = {
    current: query.selectBonusWallet === 'yes' ? 1 : 0,
    tabKey: 'deposit',
    mode: 'cash',
  }

  if (!Object.getOwnPropertyDescriptor(query, 'target')) {
    return defaultParams
  }

  const { target } = query as { target: string }
  const targetValue = target.toLowerCase()

  switch (targetValue) {
    case 'withdraw':
    case 'history':
      return {
        ...defaultParams,
        tabKey: targetValue,
      }
    case 'bonus':
      return {
        current: 1,
        tabKey: 'bonus',
        mode: 'bonus',
      }
    default:
      return defaultParams
  }
}

export default function Wallet() {
  const { mq } = useWindowSize()
  const [cookies, ,] = useCookies<string>([COOKIES_KEY.UID, COOKIES_KEY.CFID])
  const router = useRouter()
  const balance = useBalance()
  const { query } = router
  const [isReady, setIsReady] = useState(false)
  const [current, setCurrent] = useState(0)
  const [isEmpty, setIsEmpty] = useState(false)
  const [tabKey, setTabKey] = useState<WalletMenuKeyType>('cash')
  const mode: WalletMenuMode = 'cash'
  const [qrList, setQRList] = useState<DepositBankInfo[]>([])
  const [bankList, setBankList] = useState<DepositBankInfo[]>([])
  const [trueList, setTrueList] = useState<DepositBankInfo[]>([])
  const { callProfile } = useLegacyProfile()
  const [showContactModal, setShowContactModal] = useState(false)
  const [isFetchingDepositBankList, setIsFetchingDepositBankList] =
    useState(true)
  const { callDepositBank } = useDepositBank()
  const { coinWalletData, callCoinWallet } = useCoinWallet()
  const {
    walletHistory,
    isWalletAllFetched,
    isFetching,
    callWalletHistory,
    setFetchAllWalletHistory,
    setFetchAllBonusHistory,
  } = useWalletHistory()
  const [emptyMenuProps, setEmptyMenuProps] = useState<EmptyMenuProps>({
    title: 'กำหนดเอง',
    subTitle: 'โบนัสคงเหลือ',
    buttonText: 'ยอดวางเดิมพันที่ต้องทำ',
  })
  //   const bonusInfomationLocalText: LocaleText = useMemo(
  //     () => ({
  //       expireInTitle: 'หมดอายุภายใน',
  //       bonusRemainTitle: 'โบนัสคงเหลือ',
  //       turnOverText: 'ยอดวางเดิมพันที่ต้องทำ',
  //       turnOverDetailText: 'เพื่อเปลี่ยนโบนัสเป็นเงินสด',
  //       fromTotalText: 'จากทั้งหมด',
  //       remainText: 'เหลืออีก',
  //       betText: 'ยอดเดิมพัน',
  //       currency: '฿',
  //     }),
  //     [],
  //   )
  const [isLoading, setIsLoading] = React.useState(true)
  const [time, setTime] = useState<string>('00.00')
  const [phoneNumber, setPhonNumber] = useState('')

  const setWallet = useSetRecoilState(walletStateCommon.walletSelectorState)
  const profile = useRecoilValue(profileStateCommon.profileState)
  const { walletBalance, walletCoin } = useRecoilValue(
    walletStateCommon.walletSelectorState,
  )

  useEffect(() => {
    setPhonNumber(formatPhoneNumber(cookies.uid || ''))
  }, [cookies.uid])

  useEffect(() => {
    callProfile()
  }, [callProfile])

  const refreshUserBalance = async (uid = '', cfid = '') => {
    if (!cookies.uid || !cookies.cfid) {
      setIsLoading(false)
      return
    }
    try {
      setIsLoading(true)
      setIsEmpty(false)
      const balanceResponse = await balance.callGetBalance()
      const currenTime = moment().format('HH.mm')
      setTime(currenTime)
      if (balanceResponse?.Status === BALANCE_STATUS.SUCCESS) {
        const validateWallet = {
          balance: balanceResponse?.Balance,
          coin: balanceResponse?.Coin,
        }
        setWallet(validateWallet)
      }
    } catch (_error) {
      setWallet(undefined)
    } finally {
      setIsLoading(false)
    }
  }

  const walletToggle = (arg: number) => {
    setCurrent(arg)
    // setMode(showMenu[arg])
  }

  const onClickFooter = (key: WalletMenuKeyType) => {
    switch (key) {
      case WalletMenuEnum.DEPOSIT:
      case WalletMenuEnum.CASH:
        setShowContactModal(true)
        break
      case WalletMenuEnum.WITHDRAW:
        setShowContactModal(true)
        break
      case WalletMenuEnum.BONUS:
        router.push('/promotions')
        break
      default:
        break
    }
  }

  const goToTab = (key: WalletMenuKeyType, currentTab: 0 | 1 = 0) => {
    setCurrent(currentTab)
    setTabKey(key)
  }

  const fetchBankList = async () => {
    setIsFetchingDepositBankList(true)
    const depositBankList = await callDepositBank()
    if (depositBankList.Status === FETCH_DEPOSIT_BANK_LIST_STATUS.SUCCESS) {
      setQRList(depositBankList.QRList || [])
      setBankList(depositBankList.BankList || [])
      setTrueList(depositBankList.TrueList || [])
    } else {
      setBankList([])
      setTrueList([])
    }
    setIsFetchingDepositBankList(false)
  }
  const onMenuChange = async (key: WalletMenuKeyType) => {
    setTabKey(key)

    switch (key) {
      case WalletMenuEnum.DEPOSIT:
        await fetchBankList()
        break
      case WalletMenuEnum.WITHDRAW:
        // Do something...
        break
      case WalletMenuEnum.HISTORY:
        callWalletHistory()
        break
      case WalletMenuEnum.EDIT:
        // Do something...
        break
      case WalletMenuEnum.BONUS:
        callCoinWallet()
        break
      default:
        break
    }
  }

  const handleWithdraw = async () => {
    await refreshUserBalance(cookies.uid, cookies.cfid)
  }

  const setTargetStates = async () => {
    if (!isReady) {
      return
    }

    const targetStates = getTargetStatesFromQuery(query)
    const {
      current: targetCurrent,
      tabKey: targetTabKey,
      //   mode: targetMode,
    } = targetStates

    setCurrent(targetCurrent)
    // setMode(targetMode)

    await onMenuChange(targetTabKey)
  }

  const handleFetchMore = () => {
    if (mode === 'cash') {
      setFetchAllWalletHistory(true)
    } else if (mode === 'bonus') {
      setFetchAllBonusHistory(true)
    }
  }

  const bankValidationModal = () => {
    const hasBank = profile && (profile?.bankAccountList || [])?.length > 0
    const hasPhone = profile && (profile?.phoneNumber || '')?.length > 0
    if (profile && !hasBank && !hasPhone) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        header: 'เติมเงิน/ถอนเงิน',
        content: 'กรุณายืนยันตัวตนและตั้งค่าบัญชีธนาคาร',
        buttonCloseText: 'ยกเลิก',
        buttonConfirmText: 'ตกลง',
        onClose: () => {
          router.back()
        },
        onConfirm: () => {
          router.push('/profile/set-phone-bank', '/profile/set-phone-bank', {
            scroll: true,
          })
        },
      })
      return
    }
    if (profile && !hasBank) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        header: 'เติมเงิน/ถอนเงิน',
        content: 'กรุณาตั้งค่าบัญชีธนาคาร',
        buttonCloseText: 'ยกเลิก',
        buttonConfirmText: 'ตกลง',
        onClose: () => {
          router.back()
        },
        onConfirm: () => {
          router.push('/profile/set-bank', '/profile/set-bank', {
            scroll: true,
          })
        },
      })
    }
  }

  useEffect(() => {
    const hasBank =
      profile?.bankAccountList && profile?.bankAccountList.length > 0
    const hasPhone = profile?.phoneNumber && profile?.phoneNumber.length > 0
    if (profile && !hasBank && !hasPhone) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        header: 'เติมเงิน/ถอนเงิน',
        content: 'กรุณายืนยันตัวตนและตั้งค่าบัญชีธนาคาร',
        buttonCloseText: 'ยกเลิก',
        buttonConfirmText: 'ตกลง',
        onClose: () => {
          router.back()
        },
        onConfirm: () => {
          router.push('/profile/set-phone-bank', '/profile/set-phone-bank', {
            scroll: true,
          })
        },
      })
      return
    }
    if (profile && !hasBank) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        header: 'เติมเงิน/ถอนเงิน',
        content: 'กรุณาตั้งค่าบัญชีธนาคาร',
        buttonCloseText: 'ยกเลิก',
        buttonConfirmText: 'ตกลง',
        onClose: () => {
          router.back()
        },
        onConfirm: () => {
          router.push('/profile/set-bank', '/profile/set-bank', {
            scroll: true,
          })
        },
      })
    }
  }, [profile])

  useEffect(() => {
    fetchBankList().then(() => {
      setIsReady(true)
    })
  }, [])
  useEffect(() => {
    bankValidationModal()
  }, [profile])
  useEffect(() => {
    setTargetStates()
  }, [isReady, query])
  useEffect(() => {
    const emptyMenuPropsTemp: EmptyMenuProps = {
      title: mode === 'cash' ? '' : 'คุณไม่มีรายการโบนัสขณะนี้',
      subTitle:
        mode === 'cash'
          ? 'เติมเงินขั้นต่ำ 100 บาท'
          : 'เติมเงินเพื่อรับโบนัสผ่านโปรโมชั่นมากมายของเรา',
      buttonText: 'เติมเงินเลย!',
    }
    setEmptyMenuProps(emptyMenuPropsTemp)
    // NOTE : refresh balance when change route
    refreshUserBalance()
  }, [mode, tabKey])

  useEffect(() => {
    setTargetStates()
  }, [isReady, query])

  const walletHistoryList = useMemo(
    () => buildCashTransactionGroupList(walletHistory),
    [walletHistory],
  )
  const coinWalletList = useMemo(
    () => buildCashTransactionGroupList(coinWalletData.results),
    [coinWalletData],
  )
  useEffect(() => {
    switch (tabKey) {
      case WalletMenuEnum.HISTORY:
        setIsEmpty(!(Array.isArray(walletHistory) && walletHistory.length > 0))
        break
      case WalletMenuEnum.BONUS:
        setIsEmpty(coinWalletData.total === 0)
        break
      default:
        setIsEmpty(false)
        break
    }
  }, [walletHistoryList, coinWalletList, tabKey])

  return (
    <>
      <Head>
        <title>กระเป๋าเงิน | PIGBET</title>
      </Head>
      <AlertModal
        open={showContactModal}
        onClose={() => setShowContactModal(false)}
        type={ALERT_MODAL_TYPE.CONTACT_US}
      />
      <WalletSection
        refreshUserBalance={refreshUserBalance}
        onChange={walletToggle}
        walletAmount={walletBalance as string}
        bonusAmount={walletCoin as string}
        phoneNumber={phoneNumber}
        time={time}
        loading={isLoading}
        wallet={current}
      />
      <WalletMenu
        title={{
          deposit: 'เติมเงิน',
          withdraw: 'ถอนเงิน',
          history: 'ประวัติเติม / ถอน',
          bonus: 'ข้อมูลโบนัส',
          bonusHistory: 'ประวัติโบนัส',
          reportAdmin: 'แจ้งปัญหาการเติม/ถอน',
          seeOtherPromotion: 'โปรโมชั่นอื่นๆของ PIGBET',
        }}
        isFetching={isFetching}
        setFetchMore={handleFetchMore}
        allFetched={mode === 'cash' && isWalletAllFetched}
        value={tabKey}
        mode={mode}
        isEmpty={isEmpty}
        isLoading={isLoading}
        deposit={
          <DepositSection
            title="เลือกช่องทางการเติมเงิน"
            cardDetail={['คิวอาร์โค้ด', 'โอนเงิน', 'ทรูมันนี่ วอลเล็ท']}
            isLoading={isFetchingDepositBankList}
            qrList={qrList}
            bankList={bankList}
            trueList={trueList}
            // eslint-disable-next-line max-len
            // Don't forget to pass props bankList or trueList into qrSection, transferSection, trueMoneySection
            qrSection={<DepositQRSection />}
            transferSection={
              <>
                <div data-testid="deposit-label" className="ps-deposit-label">
                  เลือกบัญชีปลายทาง
                </div>
                {bankList.map((data, i) => (
                  <DepositInfo
                    id={`bank-deposit-list__${i}`}
                    bank={data.BankName}
                    accountName={data.BankAccountName}
                    bankNumber={data.BankNo}
                    logoName={data.BankLogo}
                    key={`bankList-${data?.ID}`}
                  />
                ))}
                <InformationBox
                  content={
                    <>
                      {/* {!data.SCBPremium && renderDescInfoBox()} */}
                      <div className="ps-deposit-banklist__condition-text">
                        {' '}
                        เติมเงินขั้นต่ำ 100 บาทขึ้นไป
                      </div>
                    </>
                  }
                  id="info-box-arrow"
                  prependContent={<ExclamationCircleOutlined />}
                  title={`โอนจากหมายเลข ${profile?.bankAccountList?.[0]?.bankAccountNumber} เท่านั้น`}
                  variant={INFO_BOX_VARIANT.INFO}
                />
              </>
            }
            trueMoneySection={
              <>
                <div className="ps-deposit-label">เลือกบัญชีปลายทาง</div>
                {trueList.map((data, i) => (
                  <DepositInfo
                    id={`true-money-deposit-list__${i}`}
                    type={DEPOSIT_TYPE.TRUE}
                    bank={trueList[0]?.BankName}
                    bankNumber={trueList[0]?.BankNo}
                    logoName={trueList[0]?.BankLogo}
                    key={`trueList-${data.ID}`}
                  />
                ))}
                <InformationBox
                  content={
                    <>
                      {['ss', 'xs', 'sm'].includes(mq)}
                      <div className="ps-deposit-banklist__condition-text">
                        {' '}
                        เติมเงินขั้นต่ำ 100 บาทขึ้นไป
                      </div>
                    </>
                  }
                  id="info-box-arrow"
                  prependContent={<ExclamationCircleOutlined />}
                  title={`โอนจากหมายเลข ${profile?.phoneNumber} เท่านั้น`}
                  variant={INFO_BOX_VARIANT.INFO}
                />
              </>
            }
            qrErrorTitle={'ขณะนี้ระบบเติมเงินด้วยคิวอาร์โค้ดมีปัญหา'}
            qrErrorDetail={
              'เรากำลังแก้ไขปัญหานี้อย่างเร่งด่วนขออภัยในความไม่สะดวก'
            }
            transferErrorTitle={'ขณะนี้ระบบโอนเงินด้วยเลขที่บัญชีมีปัญหา'}
            transferErrorDetail={
              'เรากำลังแก้ไขปัญหานี้อย่างเร่งด่วนขออภัยในความไม่สะดวก'
            }
            trueMoneyErrorTitle={'ขณะนี้ระบบทรูมันนี่ วอลเล็ทมีปัญหา'}
            trueMoneyErrorDetail={
              'เรากำลังแก้ไขปัญหานี้อย่างเร่งด่วนขออภัยในความไม่สะดวก'
            }
          />
        }
        withdraw={<Withdrawal onWithdraw={handleWithdraw} />}
        history={
          <TransactionHistorySection
            mode="cash"
            historyList={walletHistoryList}
          />
        }
        bonus={
          <TransactionHistorySection
            mode="bonusInformation"
            historyList={coinWalletList}
          />
        }
        empty={
          <div style={{ marginBottom: 30 }}>
            <EmptyMenu
              {...emptyMenuProps}
              onClickButton={() => goToTab('deposit')}
            />
          </div>
        }
        onChange={onMenuChange}
        onClickFooter={onClickFooter}
      />
    </>
  )
}
