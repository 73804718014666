/**
 * Bussiness logic for TransactionCollapse component goes here
 */
import { LanguageType } from '@pig-common/utils/common'
import getDurationDate from '@pig-common/utils/getDurationDate'
import { forceDateToISO } from '@pig-common/utils/datetime'
import {
  CoinWalletResultStatus,
  COIN_WALLET_RESULT_STATUS,
} from '@pig-common/hooks/useCoinWallet'
import {
  BonusInformation,
  TRANSACTION_COLLAPSE_VARIANT,
} from './TransactionCollapse.type'
import CashTransactionHeader from './CashTransactionHeader'
import BonusInformationHeader, {
  BonusInformationHeaderLocaleText,
} from './BonusInformationHeader'
import BonusInformationContent, {
  BonusInformationContentLocaleText,
} from './BonusInformationContent'
import {
  CashTransactionHistory,
  WALLET_HISTORY_TYPE,
  WALLET_HISTORY_STATUS,
} from '@pig-common/hooks/useWalletHistory'

export const mapCashTransactionDisplayVariant = (
  list: CashTransactionHistory[],
): CashTransactionHistory[] => {
  // NOTE : The default one is green (case transfer back or success)
  // And : Deposit Hold on/Pending status cannot show in cash history transaction
  return list
    .filter(
      (transaction) =>
        !(
          transaction.type === WALLET_HISTORY_TYPE.DEPOSIT &&
          transaction.status === WALLET_HISTORY_STATUS.PENDING
        ),
    )
    .map((transaction) => {
      let variant: TRANSACTION_COLLAPSE_VARIANT =
        TRANSACTION_COLLAPSE_VARIANT.SUCCESS
      if (transaction.status === WALLET_HISTORY_STATUS.REJECTED) {
        variant = TRANSACTION_COLLAPSE_VARIANT.DANGER
      } else if (
        transaction.status === WALLET_HISTORY_STATUS.PENDING ||
        transaction.status === WALLET_HISTORY_STATUS.WAITING_TRANSFER
      ) {
        variant = TRANSACTION_COLLAPSE_VARIANT.WARNING
      } else if (
        transaction.type === WALLET_HISTORY_TYPE.WITHDRAW &&
        transaction.status === WALLET_HISTORY_STATUS.COMPLETED
      ) {
        variant = TRANSACTION_COLLAPSE_VARIANT.DEFAULT
      }
      if (transaction?.detail === 'payout-shop') {
        transaction.title = 'เติมเงิน'
      }
      if (transaction?.detail === 'payout-rain') {
        transaction.title = 'รับเงินโปรย'
      }
      if (transaction?.detail === 'payout-tip') {
        transaction.title = 'เติมเงิน'
      }
      if (transaction?.detail === 'payout-daily-free-spin') {
        transaction.detail = 'รางวัลจาก "สปินรายวัน"'
      }
      if (transaction?.detail === 'payout-pighunt') {
        transaction.detail = 'รางวัลจาก "กิจกรรมล่าหมู"'
      }
      return { ...transaction, displayVariant: variant }
    })
}
export const getBonusHistoryStatus = (
  transaction: BonusInformation,
): CoinWalletResultStatus => {
  // Has to remove this condition after the Backend has been improved in the future...
  if (
    transaction.status === COIN_WALLET_RESULT_STATUS.ACTIVE &&
    new Date() > forceDateToISO(transaction.expire_at)
  ) {
    return COIN_WALLET_RESULT_STATUS.EXPIRED
  }
  return transaction.status
}
export const checkDisabledBonusHistory = (status: CoinWalletResultStatus) => {
  switch (status) {
    case COIN_WALLET_RESULT_STATUS.ACTIVE:
    case COIN_WALLET_RESULT_STATUS.CONVERTED:
      return false
    default:
      return true
  }
}

// CASH HEADER
export const buildCashTransactionHeader = (
  transaction: CashTransactionHistory,
) => {
  const { title, icon, status, amount, dateTime, isBonus = false } = transaction
  return CashTransactionHeader({
    title,
    icon,
    amount,
    dateTime,
    status,
    isBonus,
  })
}

// INFO
// INFO HEADER
export const buildBonusInformationHeader = (
  transaction: BonusInformation,
  localeText: BonusInformationHeaderLocaleText = {},
  language: LanguageType = 'th',
  index: number,
) => {
  const {
    bonusRemainTitle = 'โบนัสคงเหลือ',
    expireInTitle = 'กำหนดอายุภายใน',
    currency = '฿',
  } = localeText
  const expireIn = getDurationDate(
    forceDateToISO(transaction.expire_at),
    language,
  )
  return BonusInformationHeader({
    index,
    status: transaction.status,
    variant: transaction.displayVariant,
    bonusName: transaction.name,
    expireIn,
    expireInTitle,
    bonusRemainTitle,
    bonusRemain: transaction.current_balance,
    currency,
  })
}
// INFO CONTENT
export const buildBonusInformationContent = (
  transaction: BonusInformation,
  localeText: BonusInformationContentLocaleText = {},
) => {
  const {
    turnOverText = 'ยอดวางเดิมพันที่ต้องทำ',
    turnOverDetailText = 'เพื่อเปลี่ยนโบนัสเป็นเงินสด',
    fromTotalText = 'จากทั้งหมด',
    remainText = 'เหลืออีก',
    betText = 'สำเร็จแล้ว',
    currency = '฿',
  } = localeText
  const total = transaction.initial_balance * transaction.turn_over_ratio
  const remain = total - transaction.current_turnover
  const percentBet = Math.floor((transaction.current_turnover / total) * 100)
  return BonusInformationContent({
    turnOverText,
    turnOverDetailText,
    fromTotalText,
    total,
    remainText,
    remain,
    betText,
    percentBet,
    currency,
  })
}

// BONUS HEADER
export const buildHistoryTransactionHeader = (
  transaction: CashTransactionHistory,
) => {
  const { title, icon, status, amount, dateTime, isBonus = false } = transaction
  return CashTransactionHeader({
    title,
    icon,
    amount,
    dateTime,
    status,
    isBonus,
    isBonusHistory: true,
  })
}
