import React from 'react'
import styled from 'styled-components'
import { Image } from '@pig-frontend/uikit'
import {
  noResultImage,
  affiliateNoResultImage,
} from '@pig-common/constants/images'

type INoResultProps = {
  isAffiliate?: boolean
}

const NoResultWrapper = styled.div`
  text-align: center;
  padding-bottom: 1.5rem;

  img {
    width: 190px;
    height: fit-content;
  }
`

const NoResultText = styled.div`
  font-weight: 900;
  font-size: 1.1rem;
  line-height: 1;

  img {
    width: 190px;
    height: fit-content;
  }
`

const NoResult: React.FunctionComponent<INoResultProps> = ({ isAffiliate }) => {
  return (
    <NoResultWrapper>
      {isAffiliate ? (
        <Image
          src={affiliateNoResultImage}
          alt="no result"
          width={190}
          height={190}
        />
      ) : (
        <Image src={noResultImage} alt="no result" width={190} height={190} />
      )}
      <NoResultText>
        {isAffiliate ? 'ไม่พบรายการที่แสดง' : 'ยังไม่มีรายการ'}
      </NoResultText>
    </NoResultWrapper>
  )
}

export default NoResult
