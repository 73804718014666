import { useEffect, useRef, useState } from 'react'
import Script from 'next/script'
import { useRecoilValue } from 'recoil'
import { decodeAuthToken, JwtTokenData } from '@pig-common/utils/jwt'
import { authStateCommon, profileStateCommon } from '@pig-common/recoils'
import { initFreshChat } from './config'

export const FreshChat = () => {
  const widget = useRef<typeof window.fcWidget | null>(null)
  const auth = useRecoilValue(authStateCommon.authState)
  const profile = useRecoilValue(profileStateCommon.profileState)
  const [isFChatScriptReady, setFChatScriptReady] = useState<boolean>(false)

  useEffect(() => {
    if (profile && auth.customerCode && isFChatScriptReady) {
      const phone = decodeAuthToken(auth.cfid || '').phone_number
      widget.current = initFreshChat({ ...profile, phone })
    }
  }, [profile, auth, isFChatScriptReady])

  useEffect(() => {
    return () => {
      // Destroy freshchat
      if (widget.current) {
        widget.current?.destroy()
        widget.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (profile && auth.cfid && isFChatScriptReady) {
      const jwtData: JwtTokenData = decodeAuthToken(auth.cfid)
      const phone =
        !jwtData.phone_number || jwtData.phone_number === 'None'
          ? ''
          : jwtData.phone_number
      const req = {
        userID: auth.customerCode || '',
        firstName: profile?.firstName || '',
        lastName: profile?.lastName || '',
        phone,
      }
      widget.current = initFreshChat(req)
    }
  }, [profile, isFChatScriptReady])

  if (!auth.cfid) return null
  return (
    <Script
      src="https://wchat.freshchat.com/js/widget.js"
      strategy="lazyOnload"
      onReady={() => setFChatScriptReady(true)}
    />
  )
}
