import { AxiosRequestConfig } from 'axios'
import { useRecoilValue } from 'recoil'
import NiceModal from '@ebay/nice-modal-react'
import { authStateCommon } from '../recoils'
import { useAxios } from './useAxios'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { TimerType } from './useLoyaltyProgram'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'

export type PromotionType = 'DEPOSIT' | 'CASHBACK'
export type BonusConfig = {
  promotion_name: string
  promotion_type: PromotionType
  status: string
  updated_at: string
  time_left: TimerType
  latest_type_toggle_at?: string
}

export type ProfileBonusType = {
  config: BonusConfig[]
}
export enum FETCH_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
export enum BONUS_STATE {
  ON = 'ON',
  OFF = 'OFF',
}
export type SetProfileBonusRequest = {
  newBonusState: boolean
  promotionType: PromotionType
}
export type ProfileBonusResponse = BaseApiResponse<ProfileBonusType>
export type ProfileBonusHook = {
  callProfileBonus: () => Promise<ProfileBonusResponse>
  setProfileBonus: (request: SetProfileBonusRequest) => Promise<any>
}

export const useProfileBonus = (): ProfileBonusHook => {
  const { pigspinApiInstance } = useAxios()
  const auth = useRecoilValue(authStateCommon.authState)

  const callProfileBonus = async (): Promise<ProfileBonusResponse> => {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/user/config/promotion?user_code=${auth.userUID}`,
      method: 'GET',
    }
    const profileBonusResponse = await fetchWithJSON<ProfileBonusResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    return profileBonusResponse
  }

  const setProfileBonus = async (
    request: SetProfileBonusRequest,
  ): Promise<any> => {
    const isBonusOn = request.newBonusState ? 'ON' : 'OFF'
    const data = {
      user_code: auth.userUID,
      promotion_name: 'DEPOSIT',
      promotion_status: isBonusOn,
      promotion_type: request.promotionType,
    }
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/user/config/promotion?user_code=${auth.userUID}`,
      method: 'POST',
      data,
    }
    const profileBonusResponse = await fetchWithJSON<ProfileBonusResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    if (profileBonusResponse.status !== FETCH_STATUS.SUCCESS) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        hideOnClose: true,
        header: 'แก้ไขการรับโบนัสไม่สำเร็จ',
        content: profileBonusResponse.service_message,
        onClose: () => {},
        onConfirm: () => {},
      })
    }
    return profileBonusResponse
  }
  return {
    callProfileBonus,
    setProfileBonus,
  }
}
