import { FRESHCHAT_TOKEN } from '@pig-common/constants/env'

declare global {
  interface Window {
    fcWidget: {
      init: (props: IFCWidgetPayload) => void
      destroy: () => void
      isOpen: () => boolean
      isInitialized: () => boolean
      isLoaded: () => boolean
      on: () => void
      off: () => void
      open: () => void
      close: () => void
      show: () => void
      hide: () => void
      setTags: () => void
      setFaqTags: () => void
      setExternalId: () => void
      setConfig: () => void
      conversation: unknown
      user: object
      track: unknown
      trackPage: unknown
      authenticate: unknown
    }
  }
}

const APP_LOGO =
  'https://s3.ap-southeast-1.amazonaws.com/assets.pigspin.dev/web-logo/logo-icon.png'

interface IInitFreshChatProps {
  userID?: string
  firstName?: string
  lastName?: string
  phone?: string
}

interface IFCWidgetPayload {
  token: string
  host: string
  externalId?: string
  firstName?: string
  lastName?: string
  phone?: string
  config: {
    agent: {
      hideName: boolean
      hidePic: boolean
      hideBio: boolean
    }
    headerProperty: {
      appName: string
      appLogo: string
      backgroundColor: string
      foregroundColor: string
      hideChatButton: boolean
    }
    content: {
      headers: {
        channel_response: {
          online: {
            minutes: {
              one: string
              more: string
            }
            hours: {
              one: string
              more: string
            }
          }
        }
      }
    }
  }
}

const payload: IFCWidgetPayload = {
  token: FRESHCHAT_TOKEN || '',
  host: 'https://wchat.freshchat.com',
  config: {
    agent: {
      hideName: true,
      hidePic: true,
      hideBio: true,
    },
    headerProperty: {
      appName: 'PIGBET',
      appLogo: APP_LOGO,
      backgroundColor: '#6200EE',
      foregroundColor: '#FFF',
      hideChatButton: true,
    },
    content: {
      headers: {
        channel_response: {
          online: {
            minutes: {
              one: 'กำลังตอบกลับใน {!time!} นาที',
              more: 'โดยทั่วไปแล้วจะตอบกลับใน {!time!} นาที',
            },
            hours: {
              one: 'กำลังตอบกลับภายในหนึ่งชั่วโมง',
              more: 'โดยทั่วไปแล้วจะตอบกลับใน {!time!} ชั่วโมง',
            },
          },
        },
      },
    },
  },
}

export const initFreshChat = ({
  userID,
  firstName,
  lastName,
  phone,
}: IInitFreshChatProps): typeof window.fcWidget | null => {
  if (typeof window === 'undefined') {
    return null
  }

  if (userID) {
    payload.externalId = userID
  }
  if (firstName) {
    payload.firstName = firstName
  }
  if (lastName) {
    payload.lastName = lastName
  }
  if (phone) {
    payload.phone = phone
  }

  window?.fcWidget?.init(payload)

  return window?.fcWidget
}
