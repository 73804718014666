import React from 'react'
import { useRouter } from 'next/router'
import { useRecoilState, useRecoilValue } from 'recoil'
import NiceModal from '@ebay/nice-modal-react'
import { HTMLMotionProps, motion, AnimatePresence } from 'framer-motion'

import { authStateCommon, registerStateCommon } from '@pig-common/recoils'
import { VerifyOtp, SetPinV2, PhoneForm } from '@pig-common/components/Register'
import {
  StepperChildrenType,
  IStepperData,
  StepperController,
} from '@pig-common/components/Stepper'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { AlertContactModalV2 } from '@pig-common/components/Modal'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import { notification } from '@pig-frontend/uikit'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { useLogin } from '@pig-common/hooks/useLogin'
import dayjs from 'dayjs'

const motionProps: HTMLMotionProps<'div'> = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  variants: {
    initial: { opacity: 0, x: 500 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -500, position: 'absolute' },
  },
  transition: { bounce: 0 },
}

enum REGISTER_STEP {
  INPUT_NUMBER,
  VERIFY_OTP,
  SETUP_PIN,
}
export default function SetPhonePage() {
  const [isLoading, setIsLoading] = React.useState(false)
  const router = useRouter()
  const [, setCookies] = useCookies()
  const register = useRecoilValue(registerStateCommon.registerState)
  const { callLogin } = useLogin()
  const [, setAuth] = useRecoilState(authStateCommon.authState)
  const { callProfile, callUpdateProfile } = useLegacyProfile()
  const [step, setStep] = React.useState(REGISTER_STEP.INPUT_NUMBER)

  const children: StepperChildrenType[] = [PhoneForm, VerifyOtp, SetPinV2]
  const stepTitleList = [
    { title: 'ผูกหมายเลขโทรศัพท์', subtitle: 'register:step1.subtitle' },
    { title: 'register:step1.title', subtitle: 'register:step1.subtitle' },
    { title: 'register:step2.title', subtitle: 'register:step2.subtitle' },
    { title: 'register:step2.title', subtitle: 'register:step2.subtitle' },
  ]
  const data: IStepperData[] = []
  const onNextStep = async (
    currentStep: number,
    _nextStep: number,
    currentData?: IStepperData,
  ) => {
    setIsLoading(true)
    if (currentData) {
      switch (currentStep) {
        case REGISTER_STEP.INPUT_NUMBER:
          setStep(REGISTER_STEP.VERIFY_OTP)
          break
        case REGISTER_STEP.VERIFY_OTP:
          setStep(REGISTER_STEP.SETUP_PIN)
          break
        case REGISTER_STEP.SETUP_PIN:
          if (currentData.pin) {
            const response = await callUpdateProfile({
              user_pin: currentData.pin,
              phone_number: register.phoneNumber,
            })
            if (response.status === 'SUCCESS') {
              const loginResponse = await callLogin({
                pin: currentData.pin,
                phoneNumber: register.phoneNumber || '',
              })
              if (loginResponse.cfid) {
                const expires = dayjs().add(3, 'months').toDate()
                setCookies(COOKIES_KEY.CFID, loginResponse?.cfid, { expires })
                setCookies(COOKIES_KEY.UID, loginResponse?.uid || '0000000000')
                setCookies(
                  COOKIES_KEY.CUSTOMER_CODE,
                  loginResponse?.CustomerCode || '',
                )
                setAuth({
                  cfid: loginResponse?.cfid,
                  customerCode: loginResponse?.CustomerCode,
                  gameToken: loginResponse?.GameToken,
                  name: loginResponse?.CustomerName,
                  isLoginPhoneSuccess: true,
                  userUID: loginResponse?.UserUid || '',
                })
              }
              await callProfile()
              router.replace(pageLinkCommon.profile.home)
              notification.success({ message: 'ดำเนินการสำเร็จ', duration: 6 })
              setCookies(COOKIES_KEY.UID, register.phoneNumber)
              return true
            }
          }
          NiceModal.show(AlertContactModalV2, {
            header: 'ดำเนินการไม่สำเร็จ',
          })
          break
        default:
          break
      }
    }

    setIsLoading(false)
    return false
  }
  return (
    <div
      className="w-100 overflow-hidden"
      style={{ padding: '1.4rem', margin: 'auto' }}
    >
      <AnimatePresence initial>
        <motion.div key="motion-stepper" {...motionProps}>
          <StepperController
            title={step === 2 ? 'ตั้งค่าความปลอดภัย' : 'ผูกหมายเลขโทรศัพท์'}
            isLoading={isLoading}
            showProgressBar={false}
            isShowBackButton={false}
            stepperDetails={stepTitleList}
            currentStep={step}
            data={data}
            onNextStep={onNextStep}
          >
            {children}
          </StepperController>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
