import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import useGuild from './useGuild'

export default function useRequestGuild() {
  const { customerCode } = useRecoilValue(authStateCommon.authState)
  const { refetch: getGuild } = useGuild({ enabled: false })

  return useMutation({
    mutationFn: (params: guildApi.IApplyGuildParams) =>
      guildApi.request({
        customerCode: params?.customerCode || customerCode || '',
        guildUid: params?.guildUid,
        type: params?.type,
      }),
    onSuccess: (response, request) => {
      if (response.status === 'ERROR' || request?.type !== 'apply') {
        getGuild()
      }
    },
  })
}
