import { AxiosRequestConfig } from 'axios'

import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { useAxios } from './useAxios'

export type RenewPinRequest = {
  newPin: string
  phone: string
  otpToken: string
  otpKey: string
}

export enum RENEW_PIN_STATUS {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export type RenewPin = {
  customer_name: string
  game_token: string
}

export type RenewPinResponse = BaseApiResponse<RenewPin>

export type RenewPinHook = {
  callRenewPin: (request: RenewPinRequest) => Promise<RenewPinResponse>
}

export const useRenewPin = (): RenewPinHook => {
  const { pigspinApiInstance } = useAxios()

  const callRenewPin = (
    request: RenewPinRequest,
  ): Promise<RenewPinResponse> => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/reset-pin',
      method: 'PUT',
      data: {
        phone: request.phone,
        otp_token: request.otpToken,
        otp_key: request.otpKey,
        user_new_pin: request.newPin,
      },
    }
    return fetchWithJSON<RenewPinResponse>(pigspinApiInstance, requestConfig)
  }

  return {
    callRenewPin,
  }
}
