import { ReactNode } from 'react'
import {
  CheckCircleFilled,
  ClockCircleFilled,
  ExclamationCircleFilled,
  SwapOutlined,
} from '@ant-design/icons'
import { TransactionIcon } from './TransactionCollapse.type'
import { coinIcon } from '@pig-common/constants/images'
import { Image } from '@pig-frontend/uikit'

export type TransactionHeaderProps = {
  icon?: TransactionIcon
  title: string
  amount: string
  dateTime: string
  status: string
  isBonus: boolean
  isBonusHistory?: boolean
}

const iconMapper: Record<TransactionIcon, ReactNode> = {
  'caution-filled': <ExclamationCircleFilled />,
  'check-filled': <CheckCircleFilled />,
  processing: <ClockCircleFilled />,
}

const TransactionHeader = ({
  icon,
  title,
  amount,
  dateTime,
  status,
  isBonus,
  isBonusHistory = false,
}: TransactionHeaderProps) => {
  return (
    <div
      className={`ps-cash-transaction-header ps-cash-transaction-header--${status}`}
      data-testid="cash-transaction-header__section"
    >
      {/* {label && <div data-testid="cash-transaction-header__label" className="ps-cash-transaction-header__label">{label}</div>} */}
      <div className="d-flex align-items-center flex-wrap">
        <div
          className={`ps-cash-transaction-header__icon ps-cash-transaction-header__icon--${status}`}
          data-testid="cash-transaction-header__icon"
        >
          {iconMapper[icon || 'caution-filled']}
        </div>
        <h5
          data-testid="cash-transaction-header__title"
          className="ps-cash-transaction-header__title"
        >
          {title}
        </h5>
        {isBonus && (
          <div className="ps-cash-transaction-header__bonus-flag">รับโบนัส</div>
        )}
      </div>
      <span
        data-testid="cash-transaction-header__amount"
        className="ps-cash-transaction-header__amount"
      >
        {amount?.replace('+', '')?.trim()}
        {isBonusHistory ? (
          <Image
            src={coinIcon}
            alt="coin"
            className="ps-cash-transaction-header__coin"
          />
        ) : (
          ' ฿'
        )}
      </span>
      <time
        data-testid="cash-transaction-header__date-time"
        className="ps-cash-transaction-header__date-time"
      >
        {isBonusHistory && status?.toLowerCase() === 'converted' && (
          <span className="ps-cash-transaction-header__swap-icon">
            <SwapOutlined />
          </span>
        )}
        <span>{dateTime}</span>
      </time>
    </div>
  )
}

export default TransactionHeader
