import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import {
  BigWinChampionDetail as BigWinChampionDetailType,
  BigWinChampionRequestPayLoad,
} from './getBigWinChampionList.type'

export type BigWinRequest = {
  customerCode: string
}

export enum BIG_WIN_STATE {
  NONE_FILE = 'none_file',
  CLAIMABLE = 'claimable',
}

export type BigWinChampionDetail = BigWinChampionDetailType

type BigWinChampionResponse = BaseResponse & {
  status: string
  data: BigWinChampionDetail[]
}
export type BigWinChampionProps = BaseResponse & {
  status: string
  data: BigWinChampionDetail[]
}

const getBigWinChampionList = async ({
  page,
  limit,
}: BigWinChampionRequestPayLoad): Promise<BigWinChampionDetail[] | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/casino/bigwin-stack?page=${page}&limit=${limit}`,
      method: 'GET',
    }
    const requestPayload: BigWinChampionRequestPayLoad = {
      page,
      limit,
    }
    const result = await fetchWithJSON<BigWinChampionResponse>(
      pigspinApiInstance,
      requestConfig,
      requestPayload,
    )
    const bigWinData = result?.data as BigWinChampionDetail[]

    return bigWinData
  } catch (error) {
    return null
  }
}

export default getBigWinChampionList
