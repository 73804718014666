import cx from 'classnames'
import { VIPTag } from '@pig-frontend/uikit'
import { IVIPLevelDetail } from '@pig-common/types/Vip.type'
import styles from './index.module.scss'

const maxBenefits = 4

interface IBenefitsProps {
  benefits?: IVIPLevelDetail[]
  isClaimed?: boolean
}

export default function VIPBenefitListing({
  benefits = [],
  isClaimed,
}: IBenefitsProps) {
  let maxBenefitCount = 0 // Limit merged VIP detail in each level and splice it by detail

  return (
    <div className={cx({ [styles.claimed]: isClaimed })}>
      {benefits?.slice(0, maxBenefits)?.map((data, i) => {
        if (maxBenefitCount < 4) {
          return (
            <div className={styles.benefit} key={data?.level}>
              <div className={styles['badge-row']}>
                <VIPTag tier={data?.tier} level={data?.level} />
              </div>
              <div className={styles['text-row']}>
                {data?.detail?.map((detail) => {
                  maxBenefitCount += 1
                  if (detail && maxBenefitCount <= 4) {
                    return (
                      <li key={detail}>
                        <span>{detail}</span>
                      </li>
                    )
                  }
                  return null
                })}
              </div>
            </div>
          )
        }
        return null
      })}
    </div>
  )
}
