import { AxiosRequestConfig } from 'axios'
import { useRecoilValue } from 'recoil'
import { BIG_WIN_STATE, BigWinData } from '@pig-common/services/bigWin'
import { GameItem } from '@pig-common/services/games'
import { BaseResponse } from '@pig-common/services/service.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { authStateCommon } from '@pig-common/recoils'
import { GameBrandState } from '@pig-common/models/shared-types'

export type TotalTicketsData = {
  tickets: number
}
type TotalTicketsResponse = BaseResponse & {
  status: string
  data: TotalTicketsData
}
export type TimerType = {
  day: number
  hour: number
  minute: number
  second: number
}

export type DailyQuestRequest = {
  userCode: string
}

export enum RESPONSE_STATE {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
export enum QUEST_STATE {
  UNCLAIM = 'unclaim',
  CLAIMED = 'claimed',
}
export enum QUEST_LIST_TYPE {
  GAME_QUEST = 'game-quests',
  WEEKLY_QUEST = 'weekly-quests',
  SPECIAL_QUEST = 'special-quests',
  BIGWIN_QUEST = 'bigwin-quests',
  TUTORIAL_QUEST = 'tutorial-quests',
}
export type MainQuest = {
  tickets: number
  max_progress: number
  progress: number
  state: QUEST_STATE
}

// DAILY QUEST
export type QuestDataType = {
  transaction_uid?: string | null
  bigwintransaction_uid?: string
  title: string
  sub_title?: string
  tickets: number
  state: GAME_QUEST_STATE | BIG_WIN_STATE
  progress: number
  max_progress: number
  game_id?: string
  game_code?: string
  game_icon_url?: string
  game_name?: string
  index?: number
  bet_amount?: string
  game?: GameItem | null
  ref_type?: string
}
export type DailyQuestData = {
  on_date: string
  total_tickets: number
  time_left: TimerType
  main_quest: MainQuest
  game_quest: Partial<QuestDataType>[]
  special_quest: Partial<QuestDataType>[]
  bigwin_quest: Partial<QuestDataType>[]
}
export type DailyQuestsProps = {
  dailyQuestData?: DailyQuestData
  userCode?: string
  isLogin?: boolean
  bigWinData?: BigWinData
  brandsData?: GameBrandState
}
export enum GAME_QUEST_STATE {
  DEFAULT = 'default',
  DONE = 'done',
  UNCLAIM = 'unclaim',
  CLAIMED = 'claimed',
}
type DailyQuestResponse = BaseResponse & {
  status: string
  data: DailyQuestData
}

// WEEKLY QUEST
type WeeklyQuestResponse = BaseResponse & {
  quest: Partial<QuestDataType>
  time_left: TimerType
  status: 'SUCCESS' | 'ERROR'
}
export type WeeklyQuestData = BaseResponse & {
  quest: Partial<QuestDataType>
  time_left: TimerType
  status: 'SUCCESS' | 'ERROR'
}
export type LoyaltyProgramHook = {
  callTotalTickets: () => Promise<TotalTicketsData | null>
  callDailyQuest: () => Promise<DailyQuestData | null>
  callWeeklyQuest: () => Promise<WeeklyQuestData | null>
  claimMainQuest: () => Promise<ClaimQuestResponse | null>
  claimDailyQuest: (questID: string) => Promise<ClaimQuestResponse | null>
  claimWeeklyQuest: (questID: string) => Promise<ClaimQuestResponse | null>
}

// GAME QUEST - CLAIM
export type ClaimGameQuestData = {
  message: string
}
export type ClaimQuestRequest = {
  userCode: string
  transaction_uid: string
  tickets?: number
}
type ClaimQuestResponse = BaseResponse & {
  status: string
  data: ClaimGameQuestData
}

export const useLoyaltyProgram = (): LoyaltyProgramHook => {
  const auth = useRecoilValue(authStateCommon.authState)

  const callTotalTickets = async (): Promise<TotalTicketsData | null> => {
    try {
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/loyalty/${auth.customerCode}/totalticket`,
        method: 'GET',
      }
      const response = await fetchWithJSON<TotalTicketsResponse>(
        pigspinApiInstance,
        requestConfig,
      )
      const totalTicketsData = response?.data as TotalTicketsData
      return totalTicketsData
    } catch (error) {
      return null
    }
  }
  const callDailyQuest = async (): Promise<DailyQuestData | null> => {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${auth.customerCode}/quest/list`,
      method: 'GET',
    }
    const response = await fetchWithJSON<DailyQuestResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    if (response.status === 'ERROR') {
      return null
    }
    return response.data
  }
  const callWeeklyQuest = async (): Promise<WeeklyQuestData | null> => {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${auth.customerCode}/weekly_quest/list`,
      method: 'GET',
    }
    const response = await fetchWithJSON<WeeklyQuestResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    if (response.status === RESPONSE_STATE.ERROR) {
      return null
    }
    return response.data
  }

  const claimMainQuest = async (): Promise<ClaimQuestResponse | null> => {
    try {
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/loyalty/${auth.customerCode}/quest/claim_main`,
        method: 'POST',
      }
      const result = await fetchWithJSON<ClaimQuestResponse>(
        pigspinApiInstance,
        requestConfig,
      )
      return result
    } catch (error) {
      return null
    }
  }
  const claimDailyQuest = async (
    transaction_uid: string,
  ): Promise<ClaimQuestResponse | null> => {
    try {
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/loyalty/${auth.customerCode}/quest/claim/${transaction_uid}`,
        method: 'POST',
      }
      const result = await fetchWithJSON<ClaimQuestResponse>(
        pigspinApiInstance,
        requestConfig,
      )
      return result
    } catch (error) {
      return null
    }
  }
  const claimWeeklyQuest = async (
    transaction_uid: string,
  ): Promise<ClaimQuestResponse | null> => {
    try {
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/loyalty/weekly_quest/claim/${transaction_uid}`,
        method: 'POST',
      }
      const result = await fetchWithJSON<ClaimQuestResponse>(
        pigspinApiInstance,
        requestConfig,
      )
      return result
    } catch (error) {
      return null
    }
  }
  return {
    callTotalTickets,
    callDailyQuest,
    callWeeklyQuest,
    claimMainQuest,
    claimDailyQuest,
    claimWeeklyQuest,
  }
}
