import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { QuestDataType } from '@pig-common/hooks/useLoyaltyProgram'

export type BigWinRequest = {
  customerCode: string
}

export enum BIG_WIN_STATE {
  NONE_FILE = 'none_file',
  CLAIMABLE = 'claimable',
}

export type BigWinDetail = {
  bigwintransaction_uid: string
  title: string
  sub_title: string
  bet_amount: string
  tickets: string
  state: BIG_WIN_STATE
}

export type RequestPayLoad = {
  page: number
  size: number
}

export type BigWinData = RequestPayLoad & {
  is_uploaded: boolean
  data: Partial<QuestDataType>[] | null
}

type BigWinResponse = BaseResponse & {
  status: string
  data: BigWinData
}

const getBigWinList = async (
  request: BigWinRequest,
  requestPayload: RequestPayLoad,
): Promise<BigWinData | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${request.customerCode}/bigwin`,
      method: 'POST',
    }

    const result = await fetchWithJSON<BigWinResponse>(
      pigspinApiInstance,
      requestConfig,
      requestPayload,
    )
    const bigWinData = result?.data as BigWinData

    return bigWinData
  } catch (error) {
    return null
  }
}

export default getBigWinList
