import { Collapse as AntdCollapse } from 'antd'

import {
  IRearrangeArrayByDateResult,
  mapKeyToOrder,
  mapOrderToKey,
} from '@pig-common/utils/groupRelativeDate'
import {
  BonusInformation,
  TransactionCollapse,
} from '@pig-common/components/TransactionCollapse'
import { CashTransactionHistory } from '@pig-common/hooks/useWalletHistory'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import {
  BonusInformationGroup,
  TransactionMode,
} from '@pig-common/components/TransactionCollapse/TransactionCollapse.type'

const dateLangMapper: Record<string, string> = {
  future: 'อนาคต',
  tomorrow: 'พรุ่งนี้',
  today: 'วันนี้',
  thisWeek: 'อาทิตย์นี้',
  lastWeek: 'อาทิตย์ที่แล้ว',
  thisMonth: 'เดือนนี้',
  lastMonth: 'เดือนที่แล้ว',
  previousMonth: 'ก่อนหน้าทั้งหมด',
}
// TODO : Implement for bonus history mode soon...SEL-1922

export type CashTransactionHistoryGroup =
  IRearrangeArrayByDateResult<CashTransactionHistory>
export type BonusTransactionHistoryGroup =
  IRearrangeArrayByDateResult<BonusInformation>
export type BonusTransactionInformationGroup =
  IRearrangeArrayByDateResult<BonusInformation>

type TransactionHistorySectionProps = {
  id?: string
  mode: TransactionMode
  historyList:
    | CashTransactionHistoryGroup
    | BonusTransactionHistoryGroup
    | BonusInformationGroup
  accordion?: boolean
}

const { Panel } = AntdCollapse

const TransactionHistorySection = ({
  id = 'transaction-history',
  mode,
  historyList,
  accordion = true,
}: TransactionHistorySectionProps) => {
  const relativeDatekeys = Object.keys(historyList) as [
    keyof Omit<typeof historyList, 'future' | 'tomorrow'>,
  ]
  const orderArr = relativeDatekeys.map((key) => mapKeyToOrder[key])
  const orderedRelativeDatekeys = orderArr
    .sort()
    .map((order) => mapOrderToKey[order])
  const historyValues = orderedRelativeDatekeys.map(
    (key: any) => (historyList as any)[key],
  )

  return (
    <section
      id={id}
      data-testid={`${id}__section`}
      className="ps-transaction-history"
    >
      <AntdCollapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? (
            <DownOutlined className="ps-transaction-history__icon" />
          ) : (
            <UpOutlined className="ps-transaction-history__icon" />
          )
        }
        expandIconPosition="right"
        defaultActiveKey="transaction-group-0"
        ghost
      >
        {historyValues.map((transactionItem, index) => (
          <Panel
            id={`transaction-group-${index}`}
            key={`transaction-group-${
              transactionItem?.id || transactionItem?.uid || index
            }`}
            className="ps-transaction-history__panel-item"
            header={dateLangMapper[orderedRelativeDatekeys[index]]}
            showArrow
          >
            <TransactionCollapse
              id="deposit-withdraw-transaction"
              mode={mode}
              accordion={accordion}
              dataList={transactionItem}
            />
          </Panel>
        ))}
      </AntdCollapse>
    </section>
  )
}

export default TransactionHistorySection
