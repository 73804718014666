import React, {
  ChangeEvent,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import classNames from 'classnames'
import { useRecoilState } from 'recoil'
import { onInputFocusStateCommon } from '@pig-common/recoils'
import Image from 'next/image'
import { ExclamationCircleOutlined } from '@ant-design/icons'

export type InputProps = {
  mode?: 'text' | 'bank' | 'phone'
  type?: string
  inputMode?:
    | 'search'
    | 'text'
    | 'numeric'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'decimal'
  title?: string | JSX.Element
  name?: string
  value?: string | number
  rows?: number
  width?: string | number
  height?: string | number
  length?: number
  placeholder?: string
  autoRollback?: boolean
  autoResizeTextArea?: boolean
  errorMsg?: string
  disabled?: boolean
  bankNo?: string
  bankIcon?: any
  phoneText?: string
  phoneIcon?: any
  isError?: boolean
  showErrorIcon?: boolean
  skipCheckValue?: boolean
  className?: string
  id?: string
  onClick?: () => void
  onFocus?: () => void
  onChange?: (value: any, name?: string) => void
  onBlur?: () => void
  validator?: (value: any) => boolean
  replacerOnPaste?: (value: string) => string
}

const formatBankNo = (bankNo: string) => {
  const bankNoWithoutDash = bankNo.replace('-', '')
  const bankLength = bankNoWithoutDash.length
  switch (bankLength) {
    case 4:
      return bankNoWithoutDash
    case 6:
      return `${bankNoWithoutDash.slice(0, 3)}-${bankNoWithoutDash.slice(3, 6)}`
    default:
      return bankNo // unknown format
  }
}

const Input = ({
  mode = 'text',
  inputMode,
  type = 'text',
  title,
  name,
  placeholder,
  value,
  rows = 1,
  autoRollback = false,
  autoResizeTextArea = false,
  errorMsg,
  width = '100%',
  height = 44,
  length,
  disabled = false,
  bankNo,
  bankIcon,
  phoneText,
  phoneIcon,
  showErrorIcon = false,
  isError = false,
  className = '',
  skipCheckValue = false,
  id,
  replacerOnPaste,
  onClick,
  onFocus,
  onChange,
  onBlur,
  validator = () => true,
}: InputProps) => {
  const [inputValue, setInputValue] = useState<string | number>(value || '')
  const [isValid, setIsValid] = useState<boolean>(!isError)
  const [styleLayoutObj, setStyleLayoutObj] = useState({})
  const [styleInputObj, setStyleInputObj] = useState({})
  const [, setOnFocusInput] = useRecoilState(
    onInputFocusStateCommon.onInputFocusState,
  )
  const inputClassName = classNames('ps-input-layout', className)
  const checkValue = useCallback(
    (val: any) => {
      if (skipCheckValue) return true
      const isExceed = length != null && `${val}`.length > length
      return typeof validator === 'function'
        ? validator(val) && !isExceed
        : !isExceed
    },
    [validator, length],
  )
  const bankNoFormatted = useMemo(() => formatBankNo(bankNo || ''), [bankNo])
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    setIsValid(!isError)
  }, [isError])

  useEffect(() => {
    if (width != null) {
      const style: any = {}
      style.width = width
      setStyleLayoutObj(style)
    }
    if (height != null) {
      const style: any = {}
      style.height = height
      setStyleInputObj(style)
    }
  }, [width, height])

  useEffect(() => {
    const isValidTemp = checkValue(value)
    if (value != null) {
      setInputValue(value)
      setIsValid(isValidTemp && !isError)
    }
  }, [value])

  const validateAndSetValue = (
    text: string,
    isInputValid = checkValue(text),
    inputName?: string,
  ) => {
    setInputValue(text)
    setIsValid(isInputValid && !isError)
    if (onChange) onChange(text, inputName || '')
  }

  const onChangeInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    event.preventDefault()
    const { value: val, name: inputName } = event.target
    const isValidTemp = checkValue(val)

    if (val.length < inputValue.toString().length) {
      validateAndSetValue(val, isValidTemp, inputName)
    } else if (
      (autoRollback && !isValidTemp) ||
      (length != null && val.length > length)
    ) {
      setInputValue(inputValue)
    } else {
      validateAndSetValue(val, isValidTemp, inputName)
    }
  }

  const onInputFocus = () => {
    setOnFocusInput(true)
    if (onFocus !== undefined) {
      onFocus()
    }
  }
  const onInputBlur = () => {
    setOnFocusInput(false)
    if (onBlur !== undefined) {
      onBlur()
    }
  }

  const onPasteInput = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()

    let text = event.clipboardData.getData('text')
    if (replacerOnPaste) {
      text = replacerOnPaste(text)
    }
    const formattedText = text.substring(0, length)
    validateAndSetValue(formattedText)
  }

  const onTextAreaChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault()
    if (autoResizeTextArea && textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = `${
        textAreaRef.current.scrollHeight + 8
      }px`
    }
    onChangeInput(event)
  }

  return (
    <div className={inputClassName} style={styleLayoutObj}>
      {title ? (
        <div
          id={`${id}-title`}
          data-testid={`${id}-title`}
          className="ps-input-title"
        >
          {title}
        </div>
      ) : (
        ''
      )}
      {mode === 'bank' && (
        <>
          <div className="ps-input-bank-icon">{bankIcon}</div>
          <input
            style={styleInputObj}
            disabled
            className={`ps-input ps-input--bank-${
              bankNoFormatted.length > 4 ? '6-digit' : '4-digit'
            }`}
            value={bankNoFormatted}
          />
        </>
      )}
      {mode === 'phone' && (
        <>
          <Image
            className="ps-input-phone-icon"
            src={phoneIcon}
            alt="phone-prefix-flag-icon"
          />
          <input
            style={styleInputObj}
            readOnly
            disabled={disabled}
            className="ps-input ps-input--phone-extension"
            value={phoneText}
          />
        </>
      )}
      {type !== 'textarea' ? (
        <input
          id={id}
          data-testid={id}
          type={type}
          name={name || id}
          inputMode={inputMode}
          style={styleInputObj}
          placeholder={placeholder}
          onClick={onClick}
          onChange={onChangeInput}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onPaste={onPasteInput}
          value={inputValue}
          disabled={disabled}
          className={`ps-input ps-input--text ${
            !isValid ? 'ps-input--error' : ''
          } ${showErrorIcon ? 'ps-input--show-error-icon' : ''}`}
        />
      ) : (
        <textarea
          id={id}
          data-testid={id}
          name={name || id}
          ref={textAreaRef}
          rows={rows}
          value={inputValue}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onTextAreaChangeHandler}
          onClick={onClick}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          className={`ps-input ps-input--textarea ps-input--text ${
            !isValid ? 'ps-input--error' : ''
          } ${showErrorIcon ? 'ps-input--show-error-icon' : ''}`}
        />
      )}

      {!isValid ? (
        <>
          {mode !== 'bank' && showErrorIcon && (
            <i className="ps-icon-caution ps-input-icon-caution-filled ps-input-icon-caution-filled--error" />
          )}
          <div className="ps-input-error-layout">
            <ExclamationCircleOutlined className="ps-input-icon-caution-outline--error" />
            <span data-testid={`${id}-error-msg`} className="ps-input-message">
              {errorMsg}
            </span>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default Input
