/**
 * Special Thanks : https://gist.github.com/bbachi/3b193b011b3fda158fce5a5a9a1da89e#file-countdowntimer-tsx
 */
import React from 'react'

const REFRESH_INTERVAL = 1000

type CountDownTimerProps = {
  hours?: number
  minutes?: number
  seconds?: number
  onTimeIsUp?: () => void
}

const CountDownTimer = ({
  hours = 0,
  minutes = 0,
  seconds = 1,
  onTimeIsUp = () => {},
}: CountDownTimerProps) => {
  const [time, setTime] = React.useState({ hours, minutes, seconds })

  const reset = () =>
    setTime({ hours: time.hours, minutes: time.minutes, seconds: time.seconds })
  const isTimeOut = () => {
    if (time.hours === 0 && time.minutes === 0 && time.seconds === 0)
      return true
    if (time.hours + time.minutes + time.seconds <= 0) return true
    return false
  }

  const tick = () => {
    if (isTimeOut()) {
      reset()
      onTimeIsUp()
    } else if (time.hours >= 0 && time.minutes === 0 && time.seconds === 0) {
      const h = time.hours - 1 <= 0 ? 0 : time.hours - 1
      setTime({ hours: h, minutes: 59, seconds: 59 })
    } else if (time.seconds === 0) {
      const m = time.minutes - 1 <= 0 ? 0 : time.minutes - 1
      setTime({ hours: time.hours, minutes: m, seconds: 59 })
    } else {
      const s = time.seconds - 1 <= 0 ? 0 : time.seconds - 1
      setTime({ hours: time.hours, minutes: time.minutes, seconds: s })
    }
  }

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), REFRESH_INTERVAL)
    return () => clearInterval(timerId)
  })

  return (
    <>
      {`${time.hours.toString().padStart(2, '0')}:${time.minutes
        .toString()
        .padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}
    </>
  )
}

export default CountDownTimer
