import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const useIP = () => {
  const { data: userIP, refetch: getIP } = useQuery<string, any>(
    ['user-ip'],
    async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      return `${res.data.IPv4}`
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  )
  return {
    userIP,
    getIP,
  }
}

export default useIP
