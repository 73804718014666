import isEmpty from 'lodash/isEmpty'
import router from 'next/router'
import moment from 'moment'
import { GameIcon } from '@pig-common/components/GameIcon'
import { BigWinChampionDetail } from '@pig-common/services/bigWin/getBigWinChampionList'
import { getAvatar } from '@pig-common/utils/getAvatar'
import { RightOutlined } from '@ant-design/icons'

const ChampionCard = ({
  championData,
  active,
  onClick,
  thumbnailStyle,
  customBadge,
  backgroundEffect,
  imagePreview = false,
}: {
  championData: BigWinChampionDetail
  active?: boolean
  onClick: (param: any) => void
  thumbnailStyle?: React.CSSProperties
  customBadge?: JSX.Element
  backgroundEffect?: JSX.Element
  imagePreview?: boolean
}) => {
  const renderThumbnail = () => {
    const getState = () => {
      if (imagePreview && !isEmpty(championData.image_url)) {
        return 'image'
      }
      return 'default'
    }
    const state = getState()
    switch (state) {
      case 'image':
        return (
          <button
            onClick={() => onClick(championData.image_url)}
            className="ps-champion-card__thumbnail-img"
            style={{ backgroundImage: `url(${championData.image_url})` }}
          />
        )
      default:
        return (
          <>
            <img
              src={getAvatar({
                avatar: championData.user_avatar,
                avatarSeed: championData.uid,
              })}
              className="ps-champion-card__avatar"
              alt="avatar"
            />
            {customBadge ? (
              <>{customBadge}</>
            ) : (
              <div className="ps-champion-card__game-icon">
                <GameIcon
                  src={`/images/dailyquests/champion/champion-${
                    championData?.game_ref_type &&
                    championData.game_ref_type.toLowerCase()
                  }-icon.png`}
                  layout="responsive"
                  quality={75}
                  alt={`${championData.uid}-image`}
                  isCampaign={false}
                  width={40}
                  height={40}
                  refType={championData?.game_ref_type}
                  newFrameClassName="ps-champion-card__new-game-icon"
                  onClick={() => router.push('games/providers')}
                />
              </div>
            )}
          </>
        )
    }
  }

  return (
    <div
      className={`ps-champion-card ${active ? 'always-on' : ''}`}
      data-testid={`card-champion-${championData.uid}`}
    >
      <div className="ps-champion-card__thumbnail" style={thumbnailStyle}>
        {renderThumbnail()}
      </div>
      <div className="ps-champion-card__info">
        <div className="ps-champion-card__phone">
          {championData.user_display_name}
        </div>
        <div className="ps-champion-card__title">
          {`ได้รับเงินรางวัล ${championData.total_payout} บาท`}
        </div>

        <div
          className="ps-champion-card__game"
          data-testid="card-champion-to-game"
        >
          จากค่ายเกม{' '}
          <a className="ps-champion-card__sub-title" href="/games/providers">
            {championData.game_ref_type}
          </a>
        </div>
        <div className="ps-champion-card__bet-amount">{`ชนะ ${championData.round_stack} ครั้ง (เดิมพันรวม ${championData.total_bet} บาท)`}</div>
        <div className="ps-champion-card__date">
          {moment(championData?.updated_at)
            .add(7, 'hours')
            .format('Do/MM/YYYY HH:mm')}
        </div>
        {championData.image_url && !imagePreview && (
          <button
            className="ps-champion-card__button"
            onClick={() => onClick(championData.image_url)}
            data-testid="card-champion-button"
          >
            ดูรูปภาพ
            <RightOutlined className="ps-champion-icon-right" />
          </button>
        )}
      </div>
      {backgroundEffect && (
        <div className="ps-champion-card__background-effect">
          {backgroundEffect}
        </div>
      )}
    </div>
  )
}

export default ChampionCard
