import classNames from 'classnames'
import React, { CSSProperties } from 'react'

export type CardProps = {
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
  onClick?: () => void
}

const Card = ({
  children,
  className,
  style,
  onClick,
}: CardProps): JSX.Element => {
  const cardClassName = classNames('ps-card', className)
  return (
    <div onClick={onClick} className={cardClassName} style={style} aria-hidden>
      {children}
    </div>
  )
}

export default Card
