import {
  differenceInDays,
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
} from 'date-fns'

export type RelativeDateType =
  | 'future'
  | 'tomorrow'
  | 'today'
  | 'thisWeek'
  | 'lastWeek'
  | 'thisMonth'
  | 'lastMonth'
  | 'previousMonth'

export enum RelativeDateEnum {
  FUTURE = 'future',
  TOMORROW = 'tomorrow',
  TODAY = 'today',
  THISWEEK = 'thisWeek',
  LASTWEEK = 'lastWeek',
  THISMONTH = 'thisMonth',
  LASTMONTH = 'lastMonth',
  PREVIOUSMONTH = 'previousMonth',
}

const relativeDate = (
  date = new Date(),
  baseDate = new Date(),
): RelativeDateType => {
  const diffDays = differenceInDays(baseDate, date)
  if (diffDays < -1) {
    return RelativeDateEnum.FUTURE
  }
  if (diffDays === -1) {
    return RelativeDateEnum.TOMORROW
  }
  if (diffDays === 0) {
    return RelativeDateEnum.TODAY
  }
  const diffWeeks = differenceInCalendarWeeks(baseDate, date, {
    weekStartsOn: 1,
  })
  if (diffWeeks === 0) {
    return RelativeDateEnum.THISWEEK
  }
  if (diffWeeks === 1) {
    return RelativeDateEnum.LASTWEEK
  }
  const diffMonths = differenceInCalendarMonths(baseDate, date)
  if (diffMonths === 0) {
    return RelativeDateEnum.THISMONTH
  }
  if (diffMonths === 1) {
    return RelativeDateEnum.LASTMONTH
  }
  return RelativeDateEnum.PREVIOUSMONTH
}

export default relativeDate
