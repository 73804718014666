import * as React from 'react'
import { useRouter } from 'next/router'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import { AlertWithImageModal } from '@pig-common/components/Modal'
import { getPromotions, PromotionsData } from '@pig-common/services/promotions'
import { numberWithCommas } from '@pig-common/utils/number'

const KycSuccessModal = NiceModal.create(() => {
  const modal = useModal()
  const router = useRouter()
  const [freeCredit, setFreeCredit] = React.useState<number>(100)
  const onNext = () => {
    modal.hide()
    router.push('/wallet?selectBonusWallet=yes', '/wallet', { scroll: true })
  }

  const callGetPromotions = async () => {
    const promotionsData: PromotionsData | null = await getPromotions(
      '/?types=register&types=deposit&action=verified-kyc',
    )
    const verifyKycConfig = promotionsData?.results
      .filter((i) => i.is_active)
      .find((i) => i.code === 'verified-kyc')
    if (verifyKycConfig?.initial_coin_amount) {
      setFreeCredit(verifyKycConfig?.initial_coin_amount || 100)
    }
  }

  React.useEffect(() => {
    callGetPromotions()
  }, [])

  return (
    <AlertWithImageModal
      id="alert-in-game-redemption__success"
      open={modal.visible}
      image={<div className="ps-modal-congrat__img" />}
      content={
        <>
          <div>ยินดีด้วย! คุณได้รับ</div>
          <div>
            <b>{`ฟรีเครดิต ${numberWithCommas(freeCredit, 0)} บาท`}</b>
          </div>
        </>
      }
      contentClass="ps-modal-congrat text-center relative"
      closableBackDrop={false}
      footer={
        <div className="d-flex justify-content-end">
          <Button
            type={BUTTON_TYPE.PLAIN_TEXT}
            size={BUTTON_SIZE.LG}
            onClick={onNext}
            className="p-1 lh-1 ps-text-primary-1"
          >
            ตกลง
          </Button>
        </div>
      }
      buttonClose={{ fnc: onNext, txt: 'ตกลง' }}
    />
  )
})

export default KycSuccessModal
