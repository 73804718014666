import InboxComponent, { IInboxProps } from '@pig-common/components/Inbox/Inbox'
import { InboxItem } from '@pig-common/recoils/modules/inbox'
import { Divider, List, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

const InboxLoader = styled(Spin)`
  width: 100%;
  text-align: center;
  padding: 36px;
`

const renderInboxItem = (inbox: IInboxProps) => {
  return (
    <InboxComponent
      {...inbox}
      key={`${inbox.title}${inbox.date}_${inbox.uid}`}
    />
  )
}
const TransactionComponent = ({
  compKey,
  type,
  dataList,
  hasNextPage,
  isLoading,
  loadMore,
}: {
  compKey: string
  type: string
  dataList: InboxItem[]
  hasNextPage?: boolean
  isLoading?: boolean
  loadMore?: (type: string) => void
}) => {
  return (
    <InfiniteScroll
      key={compKey}
      dataLength={dataList.length}
      next={() => loadMore && loadMore(type)}
      hasMore={hasNextPage === true}
      loader={<InboxLoader spinning={isLoading} />}
      style={{ overflow: 'hidden' }}
      scrollThreshold="32px"
      endMessage={<Divider />}
    >
      <List dataSource={dataList} renderItem={renderInboxItem} />
    </InfiniteScroll>
  )
}
// const inboxSystemStausComponent = allInboxSystemStatus.map(renderInboxItem);

export default TransactionComponent
