import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { BankForm } from '@pig-common/components/BankForm'
import { BankFormCallBack } from '@pig-common/components/BankForm/BankForm'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { AlertContactModalV2 } from '@pig-common/components/Modal'
import { notification } from '@pig-frontend/uikit'
import { profileStateCommon } from '@pig-common/recoils'
import { useRecoilValue } from 'recoil'

export type SetBankPageProps = {
  redirectTo: string
}
export const SetBankPage: NextPage<SetBankPageProps> = ({ redirectTo }) => {
  const router = useRouter()
  const { callProfile, callUpdateProfile } = useLegacyProfile()
  const profile = useRecoilValue(profileStateCommon.profileState)

  React.useEffect(() => {
    callProfile()
  }, [])

  React.useEffect(() => {
    if (profile && profile?.bankAccountList) {
      const bankAccount = profile?.bankAccountList[0]
      if (bankAccount?.bankAccountName) {
        router.back()
      }
    }
  }, [profile])

  const onBankFormFinish = async (form: BankFormCallBack) => {
    if (form.result?.Status === 'Success') {
      const response = await callUpdateProfile({
        bank_code: form.selectedBank,
        bank_account_name: form.bankAccountName,
        bank_account_number: form.bankAccountNumber,
      })
      if (response.status === 'SUCCESS') {
        await callProfile()
        if (redirectTo) {
          router.push(redirectTo, redirectTo, { scroll: true })
          notification.success({ message: 'ดำเนินการสำเร็จ', duration: 6 })
        } else {
          router.back()
          notification.success({ message: 'ดำเนินการสำเร็จ', duration: 6 })
        }
        return
      }
      NiceModal.show(AlertContactModalV2, {
        header: 'ดำเนินการไม่สำเร็จ',
      })
    }
  }

  return (
    <div className="ps-layout">
      <div style={{ padding: '1.4rem', margin: 'auto', maxWidth: 500 }}>
        <h3 className="text-center">บัญชีธนาคาร</h3>
        <BankForm onFinish={onBankFormFinish} />
      </div>
    </div>
  )
}

export default SetBankPage
