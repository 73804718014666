import React from 'react'
import { OverviewData } from '@pig-common/hooks/useOverview'
import {
  TournamentContainer,
  TournamentHeader,
  TournamentContent,
  TournamentEmpty,
  TournamentName,
  HeaderReward,
  Reward,
} from './styled'
import { numberWithCommas } from '@pig-common/utils/number'

type IOverview = {
  overviewData: OverviewData
}

const Tournament = ({ overviewData }: IOverview) => {
  return (
    <TournamentContainer>
      <TournamentHeader>
        <span>วันที่</span>
        <span>กิลด์ทัวร์นาเมนต์</span>
        <HeaderReward>เงินรางวัล</HeaderReward>
      </TournamentHeader>
      {overviewData?.tournament.length === 0 && (
        <TournamentEmpty>ไม่พบข้อมูล</TournamentEmpty>
      )}
      {overviewData?.tournament.map((item) => (
        <TournamentContent key={item.tournament_name}>
          <span>{item.start_date}</span>
          <TournamentName>{item.tournament_name}</TournamentName>
          <Reward>{numberWithCommas(item.payout_amount)} ฿</Reward>
        </TournamentContent>
      ))}
    </TournamentContainer>
  )
}

export default Tournament
