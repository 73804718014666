export enum REGISTER_TYPE {
  NORMAL = 'NORMAL',
  ALIEN = 'ALIEN',
  NO_DOB = 'NO_DOB',
  OTHERS = 'OTHERS',
}

export enum REFER_TYPE {
  LINK = 'LINK',
  CODE = 'CODE',
}

export enum REGISTER_BY {
  PHONE = 'PHONE',
  LINE = 'LINE',
  ADMIN = 'ADMIN',
}

export type BankCodeName =
  | 'KBANK'
  | 'SCB'
  | 'KTB'
  | 'BBL'
  | 'BAY'
  | 'TTB'
  | 'LHBANK'
  | 'UOB'
  | 'GSB'
  | 'KK'

export type BankInfo = {
  bankId: string
  bankCode: BankCodeName
  bankLogo: string
  bankName: string
  bankNoLength: string
}

export type GameCompanyTypes =
  | 'NONE'
  | 'EVO'
  | 'JOKER'
  | 'PG'
  | 'PNG'
  | 'JILI'
  | string
export type BrandItem = {
  name: GameCompanyTypes
  diaplay_name: string
  isActive: boolean
  isHot: boolean
  isComingSoon: boolean
  isUnderConstruction: boolean
  logo: string
  id: string
  hopAttempt: number
}

export type GameBrandState = {
  list: BrandItem[]
  obj: Partial<Record<GameCompanyTypes | string, BrandItem>>
}

export type CategoryTypes = 'FISHING' | 'SLOT'
