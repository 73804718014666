import { AxiosRequestConfig } from 'axios'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

import { useAxios } from './useAxios'
import useIP from './useIP'

export type CheckPhoneRequest = {
  phone: string
  otpToken?: string
}

export enum CHECK_PHONE_RESULT {
  REGISTER = 'REGISTER',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  BLOCKED = 'BLOCKED',
  /** @description This status means OTP request error */
  ERROR = 'ERROR',
}

export type VerifyOtpData = {
  otp_ref_code: string
  otp_token: string
  otp_number: string
  otp_expire_date: string
  otp_block_minutes: number | null
}

export type VerifyOtpResponse = BaseApiResponse<VerifyOtpData>

export type CheckPhoneResponse = BaseApiResponse<
  {
    phone_number: string
    phone_number_status: CHECK_PHONE_RESULT
  } & VerifyOtpData
>

export type CheckPhoneHook = {
  callCheckPhone: (request: CheckPhoneRequest) => Promise<CheckPhoneResponse>
  callRequestOtp: (request: CheckPhoneRequest) => Promise<VerifyOtpResponse>
}

export const useCheckPhone = (): CheckPhoneHook => {
  const { pigspinApiInstance } = useAxios()
  const { userIP } = useIP()

  const callCheckPhone = (request: CheckPhoneRequest) => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/phone/verify',
      method: 'POST',
      validateStatus: (status) => status >= 200 && status <= 403,
      headers: {
        ip_address: userIP,
      },
    }
    const payload = {
      phone_number: request.phone,
    }
    return fetchWithJSON<CheckPhoneResponse>(
      pigspinApiInstance,
      requestConfig,
      payload,
    )
  }

  const callRequestOtp = (
    request: CheckPhoneRequest,
  ): Promise<VerifyOtpResponse> => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/phone/verify-forget-pin',
      method: 'POST',
    }
    const payload = {
      phone_number: request.phone,
    }
    return fetchWithJSON<VerifyOtpResponse>(
      pigspinApiInstance,
      requestConfig,
      payload,
    )
  }

  return {
    callCheckPhone,
    callRequestOtp,
  }
}
