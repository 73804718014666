import React, { useState } from 'react'
import router from 'next/router'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import NiceModal from '@ebay/nice-modal-react'

import { profileStateCommon } from '@pig-common/recoils'
import { useRecoilValue } from 'recoil'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { DepositBankInfo } from '@pig-common/hooks/useDepositBank'

import { SelectCard } from '@pig-common/components/SelectCard'
import { ErrorNotice } from '@pig-common/components/ErrorNotice'
import checkUserType, { UserType } from '@pig-common/utils/checkUser'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { useMediaQuery } from '@pig-frontend/uikit'
import {
  QRDepositImage,
  TransferDepositImage,
  TruemoneyDepositImage,
} from '@pig-common/constants/images'

export type DepositSectionProps = {
  title: string
  cardDetail: string[]
  qrSection?: React.ReactNode
  transferSection?: React.ReactNode
  trueMoneySection?: React.ReactNode
  isLoading: boolean
  qrList?: DepositBankInfo[]
  bankList?: DepositBankInfo[]
  trueList?: DepositBankInfo[]
  qrErrorTitle: string
  qrErrorDetail: string
  transferErrorTitle: string
  transferErrorDetail: string
  trueMoneyErrorTitle: string
  trueMoneyErrorDetail: string
}

const variants: Variants = {
  hidden: { opacity: 0, position: 'absolute', display: 'none' },
  visible: { opacity: 1, position: 'relative', display: 'flex' },
}

const DepositSection = ({
  title,
  cardDetail,
  qrSection,
  transferSection,
  trueMoneySection,
  isLoading,
  qrList,
  bankList,
  trueList,
  qrErrorTitle,
  qrErrorDetail,
  transferErrorTitle,
  transferErrorDetail,
  trueMoneyErrorTitle,
  trueMoneyErrorDetail,
}: DepositSectionProps) => {
  const isMD = useMediaQuery('md')
  const [select, setSelect] = useState(0)
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
  const profileInfo = useRecoilValue(profileStateCommon.profileState)
  const { lineUserId, phoneNumber, bankAccountNumber } = profileInfo
    ? profileInfo
    : {
        lineUserId: '',
        phoneNumber: '',
        bankAccountNumber: '',
      }
  const checkUserAction = (response: UserType, current: number) => {
    switch (response) {
      case UserType.PHONE_USER:
        if (current < 2) {
          router.push('/verify/verify-bank')
        } else {
          setSelect(current)
          refreshSystemStatus()
        }
        break
      case UserType.PHONE_BANK_USER:
        setSelect(current)
        refreshSystemStatus()
        break
      case UserType.LINE_USER:
        if (current === 2) {
          router.push('/verify/verify-phone-pin')
        } else {
          router.push('/verify/full-verify')
        }
        break
      case UserType.LINE_PHONE_USER:
        if (current < 2) {
          router.push('/verify/verify-bank')
        } else {
          setSelect(current)
          refreshSystemStatus()
        }
        break
      case UserType.LINE_PHONE_BANK_USER:
        setSelect(current)
        refreshSystemStatus()
        break
      default:
        setSelect(current)
        refreshSystemStatus()
    }
  }

  const onSelect = (current: number) => {
    const isSelectingTrueWallet = current === 2

    if (isSelectingTrueWallet && !profileInfo?.phoneNumber) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        header: 'เติมเงินด้วย ทรูมันนี่ วอลเล็ต',
        content: 'กรุณาตั้งค่าหมายเลขโทรศัพท์',
        buttonCloseText: 'ยกเลิก',
        buttonConfirmText: 'ตกลง',
        onClose: () => {
          router.back()
        },
        onConfirm: () => {
          router.push('/profile/set-phone', '/profile/set-phone', {
            scroll: true,
          })
        },
      })
    }
    if (current !== 3) {
      checkUserAction(
        checkUserType(
          lineUserId !== '',
          phoneNumber || '',
          bankAccountNumber || '',
        ),
        current,
      )
    }
  }

  const getAnimate = (index: number) => {
    return select === index ? 'visible' : 'hidden'
  }

  return (
    <div className="ps-deposit-layout">
      <div>
        <div data-testid="deposit__title" className="ps-deposit-label">
          {title}
        </div>
        <SelectCard cardDetail={cardDetail} onSelect={onSelect} select={select}>
          <div>
            <QRDepositImage
              data-testid="select-qr-deposit__image"
              className="ps-select-qr-icon"
            />
            {qrList &&
              qrList[0] &&
              qrList[0].Interval &&
              qrList[0].Interval < 10 && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: isMD ? 2 : '-4px',
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                    color: '#0573F3',
                    fontSize: isMD ? 16 : 14,
                    marginTop: '2px',
                  }}
                >
                  {`ภายใน ${qrList[0].Interval} นาที!`}
                </div>
              )}
          </div>
          <TransferDepositImage
            data-testid="select-transfer__image"
            className="ps-select-transfer-icon"
          />
          <TruemoneyDepositImage
            data-testid="select-true-money__image"
            className="ps-select-truemoney-icon"
          />
        </SelectCard>
      </div>
      {isLoading ? (
        ''
      ) : (
        <AnimatePresence>
          <motion.div
            initial="hidden"
            animate={getAnimate(0)}
            variants={variants}
            transition={{ duration: 0.3 }}
            key="qr"
          >
            {qrList && qrList.length !== 0 ? (
              qrSection
            ) : (
              <ErrorNotice title={qrErrorTitle} subTitle={qrErrorDetail} />
            )}
          </motion.div>
          <motion.div
            className="ps-deposit-banklist"
            initial="hidden"
            animate={getAnimate(1)}
            variants={variants}
            transition={{ duration: 0.3 }}
            key="transfer"
          >
            {bankList && bankList.length !== 0 ? (
              transferSection
            ) : (
              <ErrorNotice
                title={transferErrorTitle}
                subTitle={transferErrorDetail}
              />
            )}
          </motion.div>
          <motion.div
            className="ps-deposit-banklist"
            initial="hidden"
            animate={getAnimate(2)}
            variants={variants}
            transition={{ duration: 0.3 }}
            key="trueMoney"
          >
            {trueList && trueList.length !== 0 ? (
              trueMoneySection
            ) : (
              <ErrorNotice
                title={trueMoneyErrorTitle}
                subTitle={trueMoneyErrorDetail}
              />
            )}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default DepositSection
