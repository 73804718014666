import { AxiosRequestConfig } from 'axios'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { BaseResponse } from '@pig-common/services/service.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'

export type PromotionsResult = {
  uid: string
  name: string
  code: string
  type: string
  initial_coin_amount: number
  bonus_ratio: number
  turnover_ratio: number
  maximum_bonus: number
  begin_at: Date
  end_at: Date
  duration_in_hours: number
  is_active: boolean
}

export type PromotionsData = {
  total: number
  results: [PromotionsResult]
}

export type PromotionsResponse = BaseResponse & {
  status: string
  data: PromotionsData
}

export const getPromotions = async (
  queryString?: string,
): Promise<PromotionsData | null> => {
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/promotions${queryString || ''}`,
    method: 'GET',
  }
  const result = await fetchWithJSON<PromotionsResponse>(
    pigspinApiInstance,
    requestConfig,
  )
  const promotionsData = (result?.data as PromotionsData) || null
  return promotionsData
}
