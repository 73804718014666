import { Progress } from 'antd'
import styled from 'styled-components'
import { CountUp } from 'use-count-up'
import { useEffect, useState } from 'react'
import { screen } from '@pig-common/utils/responsive'
import BonusTransferAnimate from './BonusTransferAnimate'

export enum IFRAME_CARD_TYPE {
  WALLET,
  BONUS,
}
type iFrameCardProp = {
  type: IFRAME_CARD_TYPE
  animate: boolean
  finish?: boolean
}
const CardContainer = styled.div`
  position: relative;
`
const Coin = styled.div`
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  width: 16px;
  height: 16px;
  background: url('/images/coin.svg') center no-repeat;
  transform-origin: center;
`
export const CardInfo = styled.div`
  height: min-content;
  line-height: 16px;
`
export const WalletAmount = styled.div`
  display: flex;
  column-gap: 6px;
`

export const WalletTitle = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  padding: 0px;
`
export const RunningNumber = styled(CountUp)`
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
`
const ProgressBar = ({
  progress,
  strokeColor,
}: {
  progress: number
  strokeColor: string
}) => {
  const ProgressBarStyled = styled(Progress)`
    margin-right: -2px;
    text-align: right;
    .ant-progress-inner {
      width: 32px !important;
      height: 32px !important;
    }
  `
  return (
    <ProgressBarStyled
      className="ms-1"
      type="circle"
      percent={progress}
      showInfo={false}
      strokeWidth={14}
      strokeColor={strokeColor}
      trailColor="white"
      size="default"
      strokeLinecap="butt"
    />
  )
}

const Card = styled.div<iFrameCardProp>`
  position: relative;
  z-index: 3;
  display: flex;
  height: 42px;
  min-width: 100px;
  padding: 2px 6px;
  background-color: transparent;
  border: 2px solid #6729ff;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  transition-timing-function: ease-out;
  transition: 500ms;
  user-select: none;
  animation-iteration-count: 70;

  @media ${screen.sm} {
    min-width: 132px;
  }
  @keyframes bonus-glowing {
    0% {
      box-shadow: 0px 0px 8px #ffae00;
    }
    50% {
      box-shadow: 0px 0px 12px #ffae00;
    }
    100% {
      box-shadow: 0px 0px 8px #ffae00;
    }
  }
  ${({ type, animate, finish }) => {
    const isAnimate = !!animate
    const isBonus = type === IFRAME_CARD_TYPE.BONUS
    const isFinish = finish
    const color = isBonus ? '#FFBF23' : isFinish ? '#0DC14C' : '#6729FF'
    let stylingConfig: any = { 'border-color': color }
    switch (type) {
      case IFRAME_CARD_TYPE.WALLET:
        stylingConfig = {
          ...stylingConfig,
          color: '#FFFFF',
          'margin-right': '24px',
          'background-color': color,
        }
        break
      case IFRAME_CARD_TYPE.BONUS:
        stylingConfig = {
          ...stylingConfig,
          animation: isAnimate ? 'bonus-glowing 0.2s 2s 20 linear' : '',
          color: isAnimate ? '#2A2A2E' : '#FFFFF',
          'background-color': isAnimate ? color : 'transparent',
          'border-color': color,
        }
        break
      default:
        break
    }
    return stylingConfig
  }}
`
const WalletCard = ({
  title,
  amount,
  from,
  type,
  animate = false,
  progress,
  gain,
}: {
  title: string
  amount: number
  from?: number
  type: IFRAME_CARD_TYPE
  progress?: number
  animate?: boolean
  gain?: number
}) => {
  const [animateFinish, setAnimateFinish] = useState(false)
  const [getProgress, setProgress] = useState<number>(0)
  const [isCounting, setCounting] = useState<boolean>(false)

  const RunningNumberDelay = (delay?: number) => {
    setTimeout(() => setCounting(animate), delay || 1000)
  }
  const ProgressionDelay = (delay?: number) => {
    setTimeout(
      () => setProgress(progress && progress > 5 ? progress : 5),
      delay || 1000,
    )
  }
  const WalletUnit = ({ unit }: { unit: string }) => {
    return (
      <div style={{ position: 'absolute', right: '4px', bottom: '4px' }}>
        {unit}
      </div>
    )
  }
  useEffect(() => {
    RunningNumberDelay(2000)
    ProgressionDelay(1500)
  }, [])
  return (
    <CardContainer>
      <Card animate={animate} type={type} finish={animateFinish}>
        <CardInfo>
          <WalletTitle>{title}</WalletTitle>
          <WalletAmount>
            {type === IFRAME_CARD_TYPE.WALLET && animate ? (
              <>
                +
                <RunningNumber
                  key={`wallet-gain-${title}`}
                  isCounting={isCounting}
                  start={0}
                  end={amount}
                  duration={4}
                  thousandsSeparator=","
                  decimalPlaces={2}
                  onComplete={() => setAnimateFinish(true)}
                />
                <WalletUnit unit="฿" />
              </>
            ) : (
              <>
                <RunningNumber
                  key={`wallet-amount-${title}`}
                  isCounting={isCounting}
                  start={from || amount}
                  end={amount}
                  duration={4}
                  thousandsSeparator=","
                  decimalPlaces={2}
                />
                <Coin style={{ marginTop: '-1px' }} />
              </>
            )}
          </WalletAmount>
        </CardInfo>
        {(progress || progress === 0) && (
          <ProgressBar
            progress={getProgress}
            strokeColor={animate ? '#2a2a2e' : '#FFBF23'}
          />
        )}
      </Card>
      {type === IFRAME_CARD_TYPE.BONUS && isCounting && (
        <BonusTransferAnimate />
      )}
    </CardContainer>
  )
}

export default WalletCard
