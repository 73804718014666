import * as React from 'react'
import { Image } from '@pig-frontend/uikit'
import { CLOUD_STORAGE_CDN } from '@pig-common/models/buildtime-constant'

export const IconDailySpin: React.FunctionComponent = () => {
  return (
    <Image
      src={`${CLOUD_STORAGE_CDN}/pigspin-assets/icon/iconslot.svg`}
      alt=""
      width={40}
      height={40}
    />
  )
}
