import {
  COIN_HISTORY_STATUS,
  CashTransactionHistory,
} from '@pig-common/hooks/useWalletHistory'
import { AlertModal, ALERT_MODAL_TYPE } from '@pig-common/components/Modal'
import BonusDetail from '@pig-common/components/TransactionCollapse/BonusDetail'

const BonusHistoryContent = ({
  id,
  detail,
  statusLabel,
  status,
  remark,
  isSomethingWentWrong,
  expandingTransactions,
  setExpandingTransactions,
}: CashTransactionHistory & {
  expandingTransactions: string[]
  setExpandingTransactions: (list: string[]) => void
}) => {
  const isConvertedBonus = detail?.includes('payout-converted-bonus')
  const isShowContactModal = expandingTransactions.some((i) => i === id)
  const toggleExpandContactModal = (_id: string) => {
    if (isShowContactModal) {
      setExpandingTransactions(expandingTransactions.filter((i) => i !== _id))
    } else {
      setExpandingTransactions([...expandingTransactions, _id])
    }
  }

  function pickAlertType(): ALERT_MODAL_TYPE {
    switch (status) {
      case COIN_HISTORY_STATUS.CANCELED:
        return ALERT_MODAL_TYPE.WALLET_CANCELED
      case COIN_HISTORY_STATUS.REVOKED:
        return ALERT_MODAL_TYPE.WALLET_REVOKED
      default:
        return ALERT_MODAL_TYPE.CONTACT_US
    }
  }

  return (
    <div className="ps-bonus-history-content">
      <div className="ps-bonus-history-content__status">
        {isConvertedBonus ? (
          <BonusDetail detail={detail} />
        ) : (
          <div>{detail}</div>
        )}
        <div>{statusLabel}</div>
      </div>
      <div className="ps-bonus-history-content__detail">
        <div>{`หมายเหตุ : ${remark}`}</div>
        <div>
          {isSomethingWentWrong && (
            <>
              <div
                className="p-0 m-0 ps-text-blue-2 text-right"
                onClick={() => toggleExpandContactModal(id)}
                aria-hidden
              >
                ติดต่อแอดมิน
              </div>
              {isShowContactModal && (
                <>
                  {/* TODO : use NiceModal on dev-kraken branch */}
                  <AlertModal
                    open={isShowContactModal}
                    onClose={() => toggleExpandContactModal(id)}
                    type={pickAlertType()}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default BonusHistoryContent
