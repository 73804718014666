import { AxiosRequestConfig } from 'axios'
import qs from 'qs'

import { lineApiInstance } from '@pig-common/utils/api/AxiosInstance'

import { SCOPE_ENUM } from '.'

export type AccessTokenData = {
  access_token: string
  expires_in: number
  id_token: string
  refresh_token: string
  scope: SCOPE_ENUM
  token_type: string
}

export type GetAccessTokenParams = {
  code: string
  client_id: string
  redirect_uri: string
  client_secret: string
}

export type GetAccessTokenResponse = {
  access_token: string
  expires_in: number
  id_token: string
  refresh_token: string
  scope: SCOPE_ENUM
  token_type: string
}

const getLineAccessToken = async (
  params: GetAccessTokenParams,
): Promise<AccessTokenData> => {
  const form = {
    grant_type: 'authorization_code',
    ...params,
  }
  const requestConfig: AxiosRequestConfig = {
    method: 'POST',
    url: '/oauth2/v2.1/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(form),
  }
  const result = await lineApiInstance
    .request(requestConfig)
    .catch((err) => err.response?.data || { data: {} })
  return result.data
}

export default getLineAccessToken
