import NiceModal from '@ebay/nice-modal-react'
import RegisterV2, {
  RegisterPageV2Props,
  getRegisterServerSideProps,
} from '@pig-common/pages-common/register'
import { ThemeProvider } from 'styled-components'

import styledTheme from '@pig-common/styles/scss/abstracts/theme.json'

function RegisterV2Page(props: RegisterPageV2Props) {
  return (
    <ThemeProvider theme={styledTheme}>
      <NiceModal.Provider>
        <RegisterV2 {...props} />
      </NiceModal.Provider>
    </ThemeProvider>
  )
}

export { RegisterV2Page, getRegisterServerSideProps }
export type { RegisterPageV2Props }
