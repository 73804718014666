import React from 'react'

export const IconChampionCup: React.FunctionComponent = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11944_17191)">
        <path
          d="M20.0493 39.9452C31.0558 39.9452 39.9784 31.0226 39.9784 20.0161C39.9784 9.0095 31.0558 0.0869141 20.0493 0.0869141C9.0427 0.0869141 0.120117 9.0095 0.120117 20.0161C0.120117 31.0226 9.0427 39.9452 20.0493 39.9452Z"
          fill="#DECAFB"
        />
        <path
          d="M29.8403 14.8731C29.8403 16.0959 29.038 17.1348 27.9306 17.4868V11.2925H29.8403V14.8731ZM10.2666 14.8731V11.2925H12.1763V17.4868C11.622 17.3096 11.1386 16.9611 10.7957 16.4913C10.4517 16.0216 10.2677 15.4548 10.2666 14.8731ZM30.6757 9.14391H27.9306V7.95077C27.9306 7.81934 27.8231 7.71191 27.6917 7.71191H12.4151C12.2837 7.71191 12.1763 7.81934 12.1763 7.95077V9.14391H9.43114C9.08257 9.14391 8.74885 9.2822 8.50314 9.52791C8.25743 9.77363 8.11914 10.1085 8.11914 10.4559V14.8719C8.11914 17.3096 9.90885 19.3359 12.2426 19.7051C12.7054 23.1725 15.4894 25.9028 18.9797 26.2845V29.4239H13.1317C12.6037 29.4239 12.1774 29.8502 12.1774 30.3782V31.3416C12.1774 31.4731 12.2849 31.5805 12.4163 31.5805H27.6917C27.8231 31.5805 27.9306 31.4731 27.9306 31.3416V30.3782C27.9306 29.8502 27.5043 29.4239 26.9763 29.4239H21.1283V26.2856C24.6197 25.9039 27.4026 23.1736 27.8654 19.7062C30.1991 19.3359 31.9889 17.3108 31.9889 14.8731V10.4571C31.9889 10.1085 31.8506 9.77477 31.6049 9.52906C31.358 9.2822 31.0243 9.14391 30.6757 9.14391Z"
          fill="#F3EFF5"
        />
        <path
          d="M29.8344 14.8732C29.8344 16.096 29.0321 17.1349 27.9247 17.4869V11.2926H29.8344V14.8732ZM30.6698 9.14404H27.9247C27.9269 11.9246 27.9281 14.7063 27.9304 17.4869C27.9087 18.2263 27.8869 18.9669 27.8652 19.7063C28.2504 19.6435 29.6778 19.3646 30.7955 18.0652C31.9338 16.7429 31.9818 15.2572 31.9818 14.8732V10.4572C31.9829 10.3715 31.9772 9.90519 31.6035 9.52919C31.2264 9.14861 30.7544 9.14404 30.6698 9.14404Z"
          fill="#AAAAAB"
        />
        <path
          d="M20.1668 19.1487C19.6766 19.319 19.2274 19.5784 18.7737 19.823C18.3657 20.0436 17.9611 20.0916 17.592 19.8173C17.2514 19.5647 17.1326 19.2036 17.2354 18.759C17.3394 18.3121 17.3577 17.8458 17.4606 17.3978C17.5097 17.1841 17.4274 17.0813 17.3006 16.9567C16.9543 16.6173 16.6148 16.2698 16.2686 15.9293C16.0114 15.6756 15.8891 15.3716 15.9908 15.0218C16.104 14.6344 16.36 14.367 16.7748 14.2973C17.2286 14.2218 17.6846 14.1578 18.1406 14.0973C18.3886 14.0641 18.5726 13.983 18.68 13.7236C18.832 13.3556 19.0491 13.015 19.2023 12.6481C19.3851 12.2058 19.7166 12.0081 20.1668 11.959L20.1726 11.9647C20.1726 14.3578 20.1737 16.751 20.1737 19.1441L20.1668 19.1487Z"
          fill="#FFBF23"
        />
        <path
          d="M20.174 19.1428C20.174 16.7497 20.1729 14.3565 20.1729 11.9634C20.6197 11.9942 20.9351 12.2 21.1203 12.6205C21.2757 12.976 21.4929 13.3051 21.6289 13.6662C21.7489 13.9862 21.9763 14.0582 22.2654 14.0971C22.7111 14.1565 23.1569 14.2217 23.5991 14.3017C23.9831 14.3714 24.2289 14.6137 24.3386 14.9874C24.446 15.3542 24.3363 15.6639 24.0723 15.9279C23.7134 16.2868 23.3569 16.6468 22.9957 17.0011C22.8894 17.1062 22.8426 17.1942 22.878 17.3634C22.9729 17.8239 22.998 18.3005 23.1066 18.7577C23.2163 19.2194 23.0837 19.5794 22.7237 19.8342C22.3431 20.1051 21.9249 20.0365 21.534 19.8171C21.0894 19.5714 20.6437 19.3314 20.174 19.1428Z"
          fill="#FFBF23"
        />
        <path
          d="M26.731 29.3086H13.131C12.6042 29.3086 12.1768 29.736 12.1768 30.2629V31.5817H27.931V30.5063C27.9299 29.8446 27.3939 29.3086 26.731 29.3086Z"
          fill="#6200EE"
        />
        <path
          d="M29.2957 32.2172H11.2031V29.9577C11.2031 29.2263 11.802 28.6274 12.5334 28.6274H27.9654C28.6968 28.6274 29.2957 29.2263 29.2957 29.9577V32.2172Z"
          fill="#6729FF"
        />
        <path
          d="M29.2957 32.217H24.9414V28.6685H28.3757C28.882 28.6685 29.2957 29.0822 29.2957 29.5885V32.217Z"
          fill="#2B116B"
        />
        <path
          d="M10.2813 11.3053H12.191V17.4996C11.0836 17.1476 10.2813 16.1087 10.2813 14.8859V11.3053ZM8.51216 9.54188C8.13844 9.91788 8.13273 10.3842 8.13387 10.4699V14.8859C8.13501 15.2699 8.18187 16.7556 9.32016 18.0779C10.4379 19.3773 11.8664 19.6562 12.2504 19.719C12.2287 18.9796 12.207 18.239 12.1853 17.4996C12.1876 14.719 12.1887 11.9373 12.191 9.15674H9.44587C9.3613 9.15674 8.8893 9.16131 8.51216 9.54188Z"
          fill="#AAAAAB"
        />
        <path
          d="M21.6162 31.5747H18.6768V26.2627C19.0882 26.3347 19.5979 26.3941 20.1842 26.3907C20.7373 26.3884 21.2208 26.3324 21.615 26.2627C21.6162 28.033 21.6162 29.8044 21.6162 31.5747Z"
          fill="#6729FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_11944_17191">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
