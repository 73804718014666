import { AxiosRequestConfig } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { camelizeKeys } from '@pig-frontend/utils'

export type BigWinRequest = {
  customerCode: string
}

export enum BIG_WIN_STATE {
  NONE_FILE = 'none_file',
  CLAIMABLE = 'claimable',
}

export type BigWinDetail = {
  bigwintransaction_uid: string
  title: string
  sub_title: string
  bet_amount: string
  tickets: string
  state: BIG_WIN_STATE
}

export type BigwinDataType = {
  created_at: string
  customer_code: string
  game_ref_type?: string
  image_url?: string
  round_stack: number
  total_bet: number
  total_payout: number
  uid: string
  updated_at: string
  user_avatar?: string
  user_display_name?: string
  user_uuid: string
  tickets?: string
}
export type BigwinQuestItem = {
  customerCode: string
  gameRefType?: string
  imageUrl?: string
  roundStack: number
  totalBet: number
  totalPayout: number
  uid: string
  updatedAt: string
  userAvatar?: string
  userDisplayName?: string
  userUuid: string
  tickets?: string
}

export type QuestBigWinData = {
  data?: Partial<BigwinDataType>[] | null
}

export type Pagination = {
  page: number
  size: number
}

export const useQuest = ({ pagination }: { pagination: Pagination }) => {
  const auth = useRecoilValue(authStateCommon.authState)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const {
    data: questsBigWin = [],
    refetch: fetchBigWin,
    isFetching: loadingBigwin,
  } = useQuery<BigwinQuestItem[]>(
    ['quest-bigwin'],
    async () => {
      const requestConfig: AxiosRequestConfig = {
        url: `/v1/loyalty/casino/bigwin-stack?user_uuid=${auth.userUID}&claimed=false&page=${currentPage}&limit=10`,
        method: 'GET',
      }
      const result = await fetchWithJSON<BaseApiResponse<QuestBigWinData>>(
        pigspinApiInstance,
        requestConfig,
      )
      return camelizeKeys(result?.data || []) as BigwinQuestItem[]
    },
    {
      enabled: false,
      initialData: undefined,
    },
  )
  const fetchBigWinMore = () => {
    setCurrentPage((prev) => prev + 1)
  }
  useEffect(() => {
    fetchBigWin()
  }, [currentPage])

  return {
    questsBigWin,
    fetchBigWin,
    fetchBigWinMore,
    loadingBigwin,
  }
}
