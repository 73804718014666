import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react'
import {
  StepperChildrenProps,
  StepperChildrenHandles,
} from '@pig-common/components/Stepper'

import { BankForm } from '@pig-common/components/BankForm'
import { BankFormCallBack } from '@pig-common/components/BankForm/BankForm'

export type SetPinProps = StepperChildrenProps

const BankFormStep = forwardRef<StepperChildrenHandles, SetPinProps>(
  ({ next }, ref) => {
    const [bankForm, setBankForm] = useState<BankFormCallBack>()

    useEffect(() => {
      if (bankForm) {
        next()
      }
    }, [bankForm])

    const getChildrenData = () => {
      return { bankForm }
    }

    useImperativeHandle(ref, () => ({
      getChildrenData,
    }))

    return (
      <div style={{ margin: 'auto', maxWidth: 500 }}>
        <BankForm
          onFinish={(_bankForm) => {
            setBankForm(_bankForm)
          }}
        />
      </div>
    )
  },
)

BankFormStep.displayName = 'BankFormStep'
export default BankFormStep
