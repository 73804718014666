import React from 'react'

export const IconWithdraw: React.FunctionComponent = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11944_17124)">
        <path
          d="M20.0493 39.9452C31.0558 39.9452 39.9784 31.0226 39.9784 20.0161C39.9784 9.0095 31.0558 0.0869141 20.0493 0.0869141C9.0427 0.0869141 0.120117 9.0095 0.120117 20.0161C0.120117 31.0226 9.0427 39.9452 20.0493 39.9452Z"
          fill="#DECAFB"
        />
        <path
          d="M29.2399 11.777H9.45478C9.33364 11.777 9.23535 11.6775 9.23535 11.5575V9.90839C9.23535 9.78725 9.33478 9.68896 9.45478 9.68896H29.2399C29.3611 9.68896 29.4594 9.78839 29.4594 9.90839V11.5575C29.4605 11.6775 29.3611 11.777 29.2399 11.777Z"
          fill="#6200EE"
        />
        <path
          d="M29.5642 11.8308H9.44763C9.3242 11.8308 9.22363 11.7268 9.22363 11.6V9.86513C9.22363 9.73828 9.3242 9.63428 9.44763 9.63428H29.5631C29.6865 9.63428 29.7871 9.73828 29.7871 9.86513V11.6C29.7871 11.7268 29.6865 11.8308 29.5642 11.8308Z"
          fill="#F0F1F1"
        />
        <path
          d="M29.5351 11.8308H26.8037V9.63428H29.5351C29.6849 9.63428 29.8083 9.75656 29.8083 9.90742V11.5588C29.8083 11.7086 29.686 11.8308 29.5351 11.8308Z"
          fill="#AAAAAB"
        />
        <path
          d="M20.2965 35.2433C20.2382 35.321 20.1205 35.321 20.0622 35.2433L16.1731 30.329C16.0954 30.2341 16.1662 30.0912 16.2908 30.0912H19.0725V25.5312H21.2874V30.0912H24.0691C24.1914 30.0912 20.2965 35.2433 20.2965 35.2433Z"
          fill="#6729FF"
        />
        <path
          d="M30.5153 11.8216H8.77239C8.63982 11.8216 8.53125 11.7096 8.53125 11.5713V9.69706C8.53125 9.55992 8.63982 9.44678 8.77239 9.44678H30.5141C30.6467 9.44678 30.7552 9.55878 30.7552 9.69706V11.5725C30.7564 11.7096 30.6478 11.8216 30.5153 11.8216Z"
          fill="#FFBF23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9656 28.2571C25.8879 28.2308 30.1691 22.8754 30.2936 17.9291C30.3691 14.9703 28.9451 12.7085 28.1976 11.6914C22.6948 11.7108 17.1919 11.7303 11.6891 11.7497C11.2628 12.3531 8.86164 15.872 9.87193 20.2423C10.9108 24.7314 15.1245 28.2788 19.9656 28.2571Z"
          fill="#F0F1F1"
        />
        <path
          d="M19.4355 13.8741C19.4355 13.7404 19.5235 13.6318 19.6333 13.6318H20.623C20.7315 13.6318 20.8207 13.7404 20.8207 13.8741V24.7176C20.8207 24.8513 20.7327 24.9598 20.623 24.9598H19.6333C19.5247 24.9598 19.4355 24.8513 19.4355 24.7176V13.8741Z"
          fill="#6729FF"
        />
        <path
          d="M20.9523 22.7071C21.1797 22.7071 21.3809 22.6729 21.5546 22.6054C21.7306 22.538 21.8769 22.4443 21.9923 22.3254C22.1111 22.2031 22.2014 22.0603 22.262 21.898C22.3226 21.7311 22.3534 21.5517 22.3534 21.3574C22.3534 21.1369 22.3054 20.9494 22.2094 20.7974C22.1134 20.6409 21.9866 20.514 21.8289 20.4157C21.6711 20.3174 21.4929 20.2454 21.294 20.202C21.0986 20.154 20.8986 20.1311 20.6963 20.1311H18.5249V22.7094H20.9523V22.7071ZM19.9694 14.4626C20.1809 14.4626 20.4129 14.466 20.6631 14.4729C20.9134 14.4763 21.1626 14.4911 21.4094 14.5186C21.6597 14.5426 21.8974 14.5814 22.1226 14.6363C22.35 14.6911 22.5466 14.7689 22.71 14.8706C22.8837 14.9791 23.0471 15.1071 23.2014 15.2523C23.3557 15.3986 23.4906 15.5643 23.606 15.7517C23.7214 15.938 23.8129 16.1437 23.8803 16.3677C23.9477 16.5917 23.9809 16.8363 23.9809 17.1014C23.9809 17.3254 23.95 17.5391 23.8894 17.7437C23.8311 17.9471 23.75 18.138 23.6437 18.314C23.5409 18.4877 23.4186 18.6454 23.278 18.7883C23.1397 18.9311 22.9911 19.0534 22.83 19.1551C23.1031 19.226 23.3409 19.3414 23.5431 19.5014C23.7454 19.658 23.9123 19.8431 24.0437 20.0569C24.1786 20.2706 24.278 20.5037 24.342 20.7551C24.4094 21.0031 24.4426 21.2523 24.4426 21.5037C24.4426 21.8809 24.3706 22.2477 24.2254 22.6043C24.0814 22.9574 23.8677 23.2706 23.5854 23.5414C23.3066 23.8134 22.9591 24.0317 22.5454 24.1986C22.134 24.362 21.662 24.4431 21.1294 24.4431H16.4346V14.4614H19.9694V14.4626ZM20.3214 18.5346C20.614 18.5346 20.8609 18.5071 21.0631 18.4534C21.2654 18.3951 21.4289 18.3163 21.5546 18.2134C21.6803 18.1083 21.7694 17.9826 21.8243 17.8363C21.8826 17.69 21.9111 17.5277 21.9111 17.3471C21.9111 17.106 21.8689 16.9106 21.7854 16.7609C21.7054 16.6111 21.5923 16.4957 21.4483 16.4146C21.3066 16.33 21.1386 16.274 20.9431 16.2466C20.75 16.2157 20.5431 16.2009 20.3214 16.2009H18.5249V18.5346H20.3214Z"
          fill="#6729FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_11944_17124">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
