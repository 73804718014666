import { useState, useRef, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import NiceModal from '@ebay/nice-modal-react'
import { motion, AnimatePresence } from 'framer-motion'
import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil'
import {
  authStateCommon,
  registerStateCommon,
  backButtonStateCommon,
  championStateCommon,
} from '@pig-common/recoils'
import Head from 'next/head'

import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import {
  CheckPhoneResponse,
  CHECK_PHONE_RESULT,
  useCheckPhone,
} from '@pig-common/hooks/useCheckPhone'

import { LoginResponse, LOGIN_STATUS } from '@pig-common/services/login'
import { sleep } from '@pig-common/utils/time'

import {
  Button,
  TextLink,
  BUTTON_TYPE,
  LineButton,
} from '@pig-common/components/Button'
import { Images } from '@pig-common/components/Images'
import { InputPhone } from '@pig-common/components/InputPhone'
import {
  AlertContactModal,
  AlertContactModalV2,
  AlertModal,
  AlertModalV2,
  ALERT_MODAL_TYPE,
} from '@pig-common/components/Modal'
import {
  InputDigit,
  InputDigitHandles,
} from '@pig-common/components/InputDigit'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import useReferrer from '@pig-common/hooks/useReferrer'
import loginRegisterImage from '@pig-common/public/images/login/login-register-pig.png'
import { useLogin } from '@pig-common/hooks/useLogin'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import useStorage from '@pig-common/hooks/useStorage'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { checkPsCode } from '@pig-common/services/profile'
import classNames from 'classnames'
import { notification } from '@pig-frontend/uikit'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

export type LoginPageProps = {
  serverSideloginResponse?: LoginResponse
  lineIdToken: string
  existingPhone: string
  existingPin: string
  isReferrerValid: boolean
  referrer: string
}

export default function Login({
  serverSideloginResponse,
  lineIdToken,
  existingPhone,
  existingPin,
  referrer,
  isReferrerValid: isReferrerValidProps,
}: LoginPageProps) {
  const hasLineIdToken = lineIdToken?.length > 0
  const hasExistingPhone = existingPhone?.length > 0
  const hasExistingPin = existingPin?.length > 0
  const showLoginLine = !hasLineIdToken
  const showLoginPhone = !hasExistingPhone || !hasExistingPin
  const router = useRouter()
  const { setItem } = useStorage()
  const { callLogin } = useLogin()
  const [, setAuth] = useRecoilState(authStateCommon.authState)
  const [, setChampion] = useRecoilState(championStateCommon.championState)
  const updateIsAuthenticated = useRecoilRefresher_UNSTABLE(
    authStateCommon.isAuthenState,
  )
  const updateChampion = useRecoilRefresher_UNSTABLE(
    championStateCommon.championState,
  )
  const [register, setRegister] = useRecoilState(
    registerStateCommon.registerState,
  )
  const [, setBackButton] = useRecoilState(
    backButtonStateCommon.backButtonState,
  )
  const { data: systemStatus, refetch: refreshSystemStatus } = useSystemStatus({
    enabled: false,
  })
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [disabled, setDisabled] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [shouldShowContactModal, setShouldShowContactModal] = useState(false)
  const [redirectConfig, setRedirectConfig] = useState<string>()
  const [isLoginPinStep, setIsLoginPinStep] = useState(false)
  const { callLinkLineWithoutAuth } = useLegacyProfile()
  const [alertModalType, setAlertModalType] = useState<ALERT_MODAL_TYPE>(
    ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS,
  )
  const [, setCookies] = useCookies<string>([
    COOKIES_KEY.CFID,
    COOKIES_KEY.UID,
    COOKIES_KEY.LINE_DATA,
    COOKIES_KEY.CUSTOMER_CODE,
  ])
  const modalCallbackRef = useRef<() => void>(() => {})
  const digiInputRef = useRef<InputDigitHandles>(null)
  const { saveReferrerCookie } = useReferrer()
  const { callCheckPhone } = useCheckPhone()

  const getValidPhoneNumber = () => {
    let checkPhoneNumber = phoneNumber
    if (phoneNumber.length < 10) {
      checkPhoneNumber = `0${phoneNumber}`
    }
    return checkPhoneNumber
  }

  const onPhoneNumberChange = (val: string, isInvalid: boolean) => {
    setDisabled(isInvalid)
    setPhoneNumber(val)
  }

  const showContactModal = () => {
    setShouldShowContactModal(true)
    setOpenModal(true)
  }
  const showAlertModal = (type: ALERT_MODAL_TYPE) => {
    setShouldShowContactModal(false)
    setAlertModalType(type)
    setOpenModal(true)
  }

  const goToTop = async () => {
    await sleep(0.5)
    window.scroll(0, 0)
    if (window.screenY > 0) {
      // NOTE : Make sure with scroll delay
      await sleep(0.2)
      window.scroll(0, 0)
    }
  }

  const redirectoToRegisterPage = (response: CheckPhoneResponse) => {
    setRegister({
      ...register,
      phoneNumber: getValidPhoneNumber(),
      otpCode: response.data.otp_ref_code,
      otpToken: response.data.otp_token,
    })
    router.push('/register')
  }
  const checkPhoneNumberStatus = async (response: CheckPhoneResponse) => {
    let redirectRegisterTimeout: NodeJS.Timeout
    switch (response.data.phone_number_status) {
      case CHECK_PHONE_RESULT.REGISTER:
        if (hasLineIdToken) {
          await router.push('/login', '/login', { scroll: true })
          NiceModal.show(AlertConfirmModal, {
            hideOnClose: true,
            header: 'สมัครสมาชิกไม่สำเร็จ',
            content: 'ไม่พบบััญชีผู้ใช้ที่สมัครด้วยหมายเลขโทรศัพท์นี้',
            buttonConfirmText: 'ตกลง',
          })
          return
        }
        // go to register page...
        showAlertModal(ALERT_MODAL_TYPE.OTP_SENT)
        redirectRegisterTimeout = setTimeout(() => {
          setOpenModal(false)
          redirectoToRegisterPage(response)
        }, 3000)
        modalCallbackRef.current = () => {
          clearTimeout(redirectRegisterTimeout)
          redirectoToRegisterPage(response)
        }
        break
      case CHECK_PHONE_RESULT.ACTIVE:
        // go to relogin...
        setIsLoginPinStep(true)
        setBackButton({
          onBack: () => {
            setIsLoginPinStep(false)
            setPhoneNumber('')
          },
        })
        goToTop()
        break
      case CHECK_PHONE_RESULT.ERROR:
        // show block otp modal
        showAlertModal(ALERT_MODAL_TYPE.TOO_MANY_OTP_REQUEST_OR_ATTEMPT)
        break
      case CHECK_PHONE_RESULT.BLOCKED:
        // show block pin modal
        showAlertModal(ALERT_MODAL_TYPE.PIN_LOCKED)
        modalCallbackRef.current = () => {
          Router.push(
            { pathname: '/reset-pin', query: { phone: phoneNumber } },
            '/reset-pin',
          )
        }
        break
      case CHECK_PHONE_RESULT.DISABLED:
      case undefined: // for case existing user and inactive user
        // show contact modal
        showAlertModal(ALERT_MODAL_TYPE.ACCOUNT_DISABLED)
        break
      default:
        // show unexpected modal
        showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
    }
  }

  const checkPhoneHandler = async () => {
    setDisabled(true)
    refreshSystemStatus()

    const checkPhoneNumber = getValidPhoneNumber()
    // request check phone api
    const response = (await callCheckPhone({
      phone: checkPhoneNumber,
    })) as CheckPhoneResponse
    await refreshSystemStatus()

    if (
      systemStatus?.register &&
      response?.data?.phone_number_status === CHECK_PHONE_RESULT.REGISTER
    ) {
      // show close register modal
      showAlertModal(ALERT_MODAL_TYPE.CLOSE_REGISTER)
      setDisabled(false)
      return
    }

    if (response) {
      setPhoneNumber(checkPhoneNumber)
      checkPhoneNumberStatus(response)
    }
    setDisabled(false)
  }

  const setAuthorization = (response: LoginResponse) => {
    const expires = dayjs().add(3, 'months').toDate()
    setCookies(COOKIES_KEY.CFID, response.cfid, { expires })
    setCookies(COOKIES_KEY.UID, response.uid || '0000000000')
    setCookies(COOKIES_KEY.CUSTOMER_CODE, response.CustomerCode || '')
    setAuth({
      cfid: response.cfid,
      customerCode: response.CustomerCode,
      gameToken: response.GameToken,
      name: response.CustomerName,
      isLoginPhoneSuccess: true,
      userUID: response.UserUid || '',
    })
    updateIsAuthenticated()
    setChampion({
      triggered: false,
    })
    updateChampion()
    notification.success({ message: 'ยินดีต้อนรับ', duration: 3 })
  }

  const checkLoginStatus = async (response: LoginResponse) => {
    const status = response.Status
    switch (status) {
      case LOGIN_STATUS.SUCCESS:
        if (hasLineIdToken) {
          const linkLineResponse = await callLinkLineWithoutAuth({
            line_id_token: lineIdToken,
            user_code: response.UserUid,
            bearer_token: response.cfid,
          })
          if (linkLineResponse.status === 'SUCCESS') {
            setAuthorization(response)
            notification.success({ message: 'ดำเนินการสำเร็จ', duration: 6 })
            router.replace('/profile', '/profile', { scroll: true })
          } else if (linkLineResponse.service_code === 'E40000') {
            notification.error({
              message: linkLineResponse.service_message,
            })
          } else {
            NiceModal.show(AlertContactModalV2, {
              content: linkLineResponse.service_message,
            })
          }
          return
        }
        setAuthorization(response)
        setBackButton({ onBack: -1 })
        router.replace('/lobby')

        break
      case LOGIN_STATUS.WRONG_PASSWORD:
        digiInputRef.current?.error('รหัส PIN ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
        break
      case LOGIN_STATUS.INACTIVE:
      case LOGIN_STATUS.BLOCK:
        showAlertModal(ALERT_MODAL_TYPE.ACCOUNT_DISABLED)
        digiInputRef.current?.error('')
        modalCallbackRef.current = () => {
          setIsLoginPinStep(false)
          setPhoneNumber('')
        }
        break
      case undefined:
        // show block modal
        digiInputRef.current?.error('')
        showContactModal()
        modalCallbackRef.current = () => {
          setIsLoginPinStep(false)
          setPhoneNumber('')
        }
        break
      case LOGIN_STATUS.BLOCK_PIN:
        // show block pin modal
        digiInputRef.current?.error('')
        showAlertModal(ALERT_MODAL_TYPE.PIN_LOCKED)
        modalCallbackRef.current = () => {
          Router.push(
            { pathname: '/reset-pin', query: { phone: phoneNumber } },
            '/reset-pin',
          )
        }
        break
      default:
        digiInputRef.current?.error('')
        showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
        modalCallbackRef.current = () => {}
    }
  }

  const loginPinHandler = async (pin: string) => {
    setDisabled(true)
    refreshSystemStatus()

    try {
      const response: LoginResponse = await callLogin({ phoneNumber, pin })
      if (response.Status !== LOGIN_STATUS.CLIENT_EXCEPTION) {
        if (response) checkLoginStatus(response)
      } else {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS
      }
    } catch (_error) {
      digiInputRef.current?.error('')
      showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
    }
    setDisabled(false)
  }

  const onCloseModal = () => {
    setOpenModal(false)
    modalCallbackRef.current()
    setRedirectConfig(undefined)
  }

  useEffect(() => {
    saveReferrerCookie()
    if (referrer && !isReferrerValidProps) {
      NiceModal.show(AlertModalV2, {
        type: ALERT_MODAL_TYPE.REFERRER_NOT_VALID,
      })
    }
    setItem(
      'referrer',
      JSON.stringify({
        isValid: isReferrerValidProps,
        psCode: referrer,
      }),
      'session',
    )
  }, [referrer])

  return (
    <>
      <Head>
        <title>ฝาก50รับ100ถอนไม่อั้น สมัครสมาชิก | PIGBET</title>
        <meta
          name="title"
          content="ฝาก50รับ100ถอนไม่อั้น สมัครสมาชิก | PIGBET"
        />
      </Head>
      <section
        className={classNames('container space-top-1 space-bottom-1 w-100', {
          'overflow-hidden': isLoginPinStep,
        })}
      >
        <AnimatePresence initial>
          {isLoginPinStep ? (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={{
                initial: { opacity: 0, x: 300 },
                animate: { opacity: 1, x: 0 },
                exit: { opacity: 0, x: -300 },
              }}
              transition={{ bounce: 0 }}
            >
              <section className="row justify-content-center">
                <h3
                  id="login-pin-title"
                  data-testid="login__pin-title"
                  className="ps-login__title text-center"
                >
                  เข้าสู่ระบบ
                </h3>
                <p
                  id="login-pin-description"
                  data-testid="login__pin-description"
                  className="text-center my-0 mt-1"
                >
                  กรอกรหัส PIN ของคุณ
                </p>
                <div className="col-12">
                  <InputDigit
                    id="login__pin-input"
                    ref={digiInputRef}
                    onComplete={loginPinHandler}
                    disabled={disabled}
                  />
                </div>
                <div className="col-4 justify-center pb-5">
                  <TextLink
                    id="login__forgot-pin-link"
                    disabled={disabled}
                    className="ps-forget-pin-link text-center mx-auto"
                    onClick={() =>
                      Router.push({
                        pathname: '/reset-pin',
                        query: { phone: phoneNumber },
                      })
                    }
                  >
                    ลืมรหัสPIN
                  </TextLink>
                </div>
              </section>
            </motion.div>
          ) : (
            <form className="ps-login row" onSubmit={(e) => e.preventDefault()}>
              <h3 className="ps-login__title text-center">
                สมัครสมาชิก/เข้าสู่ระบบ
              </h3>
              <Images
                className="ps-login__banner-image col-12 col-md-6 col-lg-4"
                src={loginRegisterImage}
                width={343}
                height={240}
                priority
                quality={100}
                alt="login-title-pig"
              />
              {showLoginPhone && (
                <>
                  <div className="col-12 col-md-6 col-lg-4">
                    <InputPhone
                      id="login-checkphone-input"
                      className="ps-login__phone-input"
                      title="หมายเลขโทรศัพท์"
                      placeholder="กรอกหมายเลขโทรศัพท์"
                      errorMessage="กรุณากรอกเบอร์มือถือให้ถูกต้อง เช่น 0100000000"
                      onInputChange={onPhoneNumberChange}
                      isError={
                        phoneNumber.length > 0 &&
                        phoneNumber.trim().length === 0
                      }
                    />
                  </div>
                  <Button
                    id="checkphone-next-button"
                    type={BUTTON_TYPE.CTA_1}
                    className="ps-login__button-submit"
                    disabled={disabled}
                    onClick={checkPhoneHandler}
                    nativeType="submit"
                    overrideHeight={40}
                  >
                    ดำเนินการต่อ
                  </Button>
                  <div
                    role="button"
                    className="ps-login__forgetphone"
                    onClick={() => {
                      NiceModal.show(AlertContactModalV2, {
                        header: 'ต้องการเปลี่ยนแปลงข้อมูล',
                      })
                    }}
                    aria-hidden
                  >
                    ลืมเบอร์โทรศัพท์ ?
                  </div>
                </>
              )}
              {showLoginLine && showLoginPhone && (
                <div
                  className="ps-login__divider col-12 col-md-6 col-lg-4"
                  key="divider"
                >
                  <hr />
                  <span>หรือ</span>
                </div>
              )}
              {showLoginLine && (
                <div className="col-12 col-md-6 col-lg-4" key="login-line">
                  <LineButton redirectPath="/line/verify" />
                </div>
              )}
            </form>
          )}
        </AnimatePresence>
        {shouldShowContactModal ? (
          <AlertContactModal open={openModal} onClose={onCloseModal} />
        ) : (
          <AlertModal
            open={openModal}
            onClose={onCloseModal}
            type={alertModalType}
            redirectToOnClose={redirectConfig}
          />
        )}
      </section>
    </>
  )
}

export const getLoginServerSideProps = async (context: any) => {
  const { req, res, query } = context
  const setCookies = [`${COOKIES_KEY.LINE_STATE}=deleted; Max-Age=0;`]
  const { referrer = '', line_id_token, pin, phone } = query
  const getLineData = () => {
    try {
      return JSON.parse(req.cookies?.line_data || '')
    } catch (e) {
      return null
    }
  }
  const lineData = getLineData()
  if (lineData) {
    setCookies.push(`${COOKIES_KEY.LINE_DATA}=deleted; Max-Age=-1`)
  }
  res.setHeader('set-cookie', setCookies)
  let isReferrerValid = false
  if (!isEmpty(referrer)) {
    const referrerUser = await checkPsCode(`${referrer}`)
    isReferrerValid = referrerUser.service_code === 'PIG-2008'
  }
  const props = {
    data: {
      lineData,
    },
    isReferrerValid,
    referrer,
    lineIdToken: line_id_token || '',
    existingPin: pin || '',
    existingPhone: phone || '',
    loginStatus: null,
  }

  return {
    props,
  }
}
