import { forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import classNames from 'classnames'

import { BankCodeName, BankInfo } from '@pig-common/models/shared-types'
import { BANK_IMAGE_PATH } from '@pig-common/models/buildtime-constant'
import { bankList } from '@pig-common/models/static-constant'
import {
  COOKIES_KEY,
  RegisterCookieType,
  useCookies,
} from '@pig-common/utils/cookies'

import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import { Input } from '@pig-common/components/Input'
import {
  SelectInput,
  SelectOptionProps,
} from '@pig-common/components/SelectInput'
import {
  StepperChildrenProps,
  StepperChildrenHandles,
} from '@pig-common/components/Stepper'
import { AlertModal, ALERT_MODAL_TYPE } from '@pig-common/components/Modal'

export type VerifyBankProps = StepperChildrenProps

const VerifyBank = forwardRef<StepperChildrenHandles, VerifyBankProps>(
  ({ next, getStepData }, ref) => {
    const [cookies] = useCookies<RegisterCookieType>([COOKIES_KEY.REGISTER])

    const [fullName, setFullName] = useState('')
    const [banks, setBanks] = useState<BankInfo[]>([])
    const [bankListOptions, setBankListOptions] = useState<SelectOptionProps[]>(
      [],
    )
    const [isValidBank, setIsValidBank] = useState(true)
    const [selectedBank, setSelectedBank] = useState<BankCodeName | undefined>(
      undefined,
    )
    const [selectedBankName, setSelectedBankName] = useState<
      string | undefined
    >('')
    const [selectedBankLogo, setSelectedBankLogo] = useState<
      string | undefined
    >('')
    const [bankLength, setBankLength] = useState(12)
    const [bankAccountNumber, setBankAccountNumber] = useState<
      string | undefined
    >('')
    const [isErrorBankAccount, setIsErrorBankAccount] = useState(false)
    const [disabledButton, setDisabledButton] = useState(true)
    const [openAlertModal, setOpenAlertModal] = useState(false)

    const getChildrenData = () => {
      const { firstName = '', lastName = '' } = getStepData(0) || {}
      const verifyBankData: Partial<any> = {
        bankID: selectedBank,
        bankAccountNumber,
        firstNameTH: firstName,
        firstNameEN: firstName,
        lastNameTH: lastName,
        lastNameEN: lastName,
      }

      return {
        ...verifyBankData,
        bankName: selectedBankName,
        bankLogo: selectedBankLogo,
      }
    }

    useImperativeHandle(ref, () => ({
      getChildrenData,
    }))

    const handleClickButton = () => {
      next()
    }

    const numberValidator = (value: any) => {
      return !Number.isNaN(+value)
    }

    const getBankByCode = (bankCode: BankCodeName | undefined) => {
      return banks.find((bank) => bank.bankCode === bankCode)
    }

    const checkValidBank = () => {
      if (selectedBank) {
        setIsValidBank(true)
      } else {
        setIsValidBank(false)
      }
    }

    const updateBankLength = (bankCode: BankCodeName) => {
      const bank = getBankByCode(bankCode)
      setBankLength(Number(bank?.bankNoLength))
    }

    const validateBankAccount = (allowEmpty = true) => {
      if (selectedBank) {
        if (!bankAccountNumber) {
          const isError = !allowEmpty && !bankAccountNumber
          setIsErrorBankAccount(isError)
        } else {
          const bank = getBankByCode(selectedBank)
          setIsErrorBankAccount(
            bankAccountNumber.length !== Number(bank?.bankNoLength),
          )
        }
      } else {
        setIsErrorBankAccount(false)
      }
    }

    const enabledButton = () => {
      const bank = getBankByCode(selectedBank)
      const isValidBankAccountLength =
        bankAccountNumber?.length === Number(bank?.bankNoLength)

      if (
        selectedBank &&
        isValidBankAccountLength &&
        isValidBank &&
        !isErrorBankAccount
      ) {
        setDisabledButton(false)
      } else {
        setDisabledButton(true)
      }
    }

    const generateBankList = () => {
      const options: SelectOptionProps[] = []
      bankList.forEach((bank) => {
        const { bankCode, bankName, bankLogo } = bank
        const iconImageSrc = `${BANK_IMAGE_PATH}/${bankLogo}`
        const label = bankName.replace(/ธนาคาร\s/i, '')
        options.push({ value: bankCode, label, iconImageSrc })
      })

      setBanks(bankList)
      setBankListOptions(options)
    }

    const handleSelectInputChange = (selectedBankItem: SelectOptionProps) => {
      const bankCode = selectedBankItem.value as BankCodeName
      const bankName = selectedBankItem.label
      const bankLogo = selectedBankItem.iconImageSrc

      setSelectedBank(bankCode)
      setSelectedBankName(bankName)
      setSelectedBankLogo(bankLogo)
      updateBankLength(bankCode)

      if (bankCode && !isValidBank) {
        setIsValidBank(true)
      }
    }

    const handleSelectedInputBlur = () => {
      checkValidBank()
    }

    const handleInputChange = (value: string) => {
      setIsErrorBankAccount(false)
      setBankAccountNumber(value)
    }

    const handleInputBlur = () => {
      validateBankAccount(false)
    }

    const assignDataFromCookies = () => {
      const verifyBankCookies = cookies[COOKIES_KEY.REGISTER]

      setFullName(
        `${verifyBankCookies?.firstName} ${verifyBankCookies?.lastName}`,
      )
      setSelectedBank(verifyBankCookies?.bankCode)
      setSelectedBankName(verifyBankCookies?.bankName)
      setSelectedBankLogo(verifyBankCookies?.bankLogo)
      setBankAccountNumber(verifyBankCookies?.bankAccountNumber || '')

      if (selectedBank && bankAccountNumber) {
        checkValidBank()
        updateBankLength(selectedBank)
        validateBankAccount()
        enabledButton()
      }
    }

    useEffect(() => {
      generateBankList()
      assignDataFromCookies()
    }, [])

    useEffect(() => {
      validateBankAccount()
    }, [bankLength])

    useEffect(() => {
      enabledButton()
    }, [selectedBank, bankAccountNumber, isValidBank, isErrorBankAccount])

    return (
      <>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-md-6 col-lg-4">
            <Input
              disabled
              title={'step2.accountName'}
              type="text"
              value={fullName}
              width="100%"
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-md-6 col-lg-4">
            <SelectInput
              title={'step2.bank'}
              placeholder={'step2.placeholderSelectBankAccount'}
              errorMsg={'step2.errorMsgSelectBankAccont'}
              isValid={isValidBank}
              options={bankListOptions}
              optionClassName="col-xs-3"
              selectedValue={selectedBank}
              onChange={handleSelectInputChange}
              onBlur={handleSelectedInputBlur}
              className="mt-2"
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-md-6 col-lg-4">
            <Input
              id="bank-account-number"
              type="tel"
              length={12}
              title={'step2.bankAccountNumber'}
              placeholder={'step2.placeholderBankAccountNumber'}
              isError={isErrorBankAccount}
              errorMsg={'step2.errorMsgBankAccountNumber'}
              width="100%"
              className={classNames({
                'mt-2': isValidBank,
                'mt-4': !isValidBank,
              })}
              value={bankAccountNumber}
              validator={numberValidator}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              replacerOnPaste={(value) => value.replace(/[^0-9]+/g, '')}
              autoRollback
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Button
              buttonForm
              id="verify-bank-next-button"
              size={BUTTON_SIZE.MD}
              type={BUTTON_TYPE.CTA_1}
              disabled={disabledButton}
              className="mx-auto mt-3 mt-md-4"
              onClick={handleClickButton}
            >
              {'nextButton'}
            </Button>
          </div>
        </div>
        <AlertModal
          open={openAlertModal}
          type={ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS}
          onClose={() => setOpenAlertModal(false)}
        />
      </>
    )
  },
)

VerifyBank.displayName = 'VerifyBank'
export default VerifyBank
