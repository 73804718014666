import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { Checkbox, Tooltip, Form } from 'antd'
import { useRecoilValue, useRecoilState } from 'recoil'
import { profileStateCommon } from '@pig-common/recoils'
import { Button, BUTTON_TYPE, TextLink } from '../Button'
import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'
import { TermAndConditionModal } from '../Modal'
import { Input } from '../Input'
import { InformationBox } from '../InformationBox'
import useStorage from '@pig-common/hooks/useStorage'
import { registerStateCommon } from '@pig-common/recoils'
import { useResponsiveState } from '@pig-common/hooks/useResponsive'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getEditDisplayNameInSeconds } from '@pig-common/utils/profile'
import {
  ProfileAvatar,
  ProfileAvatarTypes,
  profileAvatarMapper,
} from '@pig-frontend/uikit'
import theme from '@pig-common/styles/scss/abstracts/theme.json'

export type AvatarDetail = {
  nickName: string
  avatar: string
  isValidNickName: boolean
}

export type AvatarHandles = {
  setName: (name: string) => void
  setAvatar: (avatar?: ProfileAvatarTypes | undefined) => void
}

export type AvatarProps = {
  onSubmit: (data: AvatarDetail) => Promise<void>
  onNickNameChange?: (nickName: string) => void
  isLoading?: boolean
  isError?: boolean
  errorMessage?: string
  hideReferral?: boolean
  hideTAC?: boolean
  initialNickName?: string
  initialAvatar?: ProfileAvatarTypes
}

const NAME_LENGTH_MAX = 15
const NAME_LENGTH_MIN = 3
const AVATAR_WIDTH = 100
const AVATAR_HEIGHT = 100

type FormType = {
  referrer: string
}

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled {
    .ant-checkbox-inner {
      background: white;
      border-color: ${({ theme }) => theme['color-greyscale-600']} !important;
      border: 2px solid;
    }
    .ant-checkbox-inner:after {
      border-color: ${({ theme }) => theme['color-greyscale-600']};
      transform: rotate(-45deg) scale(-1, 1) translate(-20%, -70%);
      width: 16px;
    }
  }
`

const CreateAvatar = forwardRef<AvatarHandles, AvatarProps>(
  (
    {
      onSubmit = async () => {},
      onNickNameChange = () => {},
      isLoading = false,
      isError = false,
      errorMessage,
      hideReferral,
      hideTAC,
      initialNickName,
      initialAvatar,
    },
    ref,
  ): JSX.Element => {
    const { getItem } = useStorage()
    const [register, setRegister] = useRecoilState(
      registerStateCommon.registerState,
    )
    const [form] = Form.useForm<FormType>()
    const [showTACModal, setShowTACModal] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(true)
    const [btnDisable, setBtnDisable] = useState<boolean>(false)
    const [nickName, setNickName] = useState<string>(initialNickName || '')
    const [isNickNameError, setIsNickNameError] = useState<boolean>(false)
    const [avatar, setAvatar] = useState<ProfileAvatarTypes>(
      initialAvatar || 'pixel',
    )
    const { isMD } = useResponsiveState()
    const validateData = (value: string) => {
      return /^[a-zA-Z0-9\u0E00-\u0E7F]*$/g.test(value)
    }
    const referrerObj = JSON.parse(getItem('referrer', 'session') || '{}')
    const referrePsCode = referrerObj?.psCode || ''
    const isReferLink = referrerObj?.isValid && referrerObj?.psCode?.length > 0
    const profile = useRecoilValue(profileStateCommon.profileState)
    let editNameInSeconds = 0
    if (profile) {
      editNameInSeconds = getEditDisplayNameInSeconds(profile)
    }

    const handleNickNameChange = (value: string) => {
      setIsNickNameError(false)
      setNickName(value)
      onNickNameChange(value)
    }

    const handleNickNameBlur = () => {
      if (nickName === '') return
      setIsNickNameError(nickName.length < NAME_LENGTH_MIN)
    }

    const handleAvatar = (avatarName: ProfileAvatarTypes) => {
      setAvatar(avatarName)
    }

    const handleButtonDisable = () => {
      if (isNickNameError || !isChecked || !nickName) {
        setBtnDisable(true)
      } else {
        setBtnDisable(false)
      }
    }

    const handleSubmit = async () => {
      const result = {
        nickName,
        avatar,
        isValidNickName: validateData(nickName),
        referrer: form.getFieldsValue().referrer,
      }
      await onSubmit(result)
    }

    const clearReferInputError = () => {
      setRegister({
        ...register,
        isReferrerValid: true,
      })
    }

    useEffect(() => {
      handleButtonDisable()
    }, [nickName, isChecked])

    useEffect(() => {
      form.setFieldsValue({
        referrer: referrePsCode,
      })
    }, [])

    const renderAvatarItems = (width: number, height: number) => {
      return (Object.keys(profileAvatarMapper) as ProfileAvatarTypes[]).map(
        (avatarItem) => {
          if (avatarItem !== 'admin') {
            return (
              <div className="col-6" key={avatarItem}>
                <div
                  className={cx('ps-create-avatar__avatar', {
                    'ps-create-avatar__avatar--active': avatar === avatarItem,
                  })}
                  onClick={() => handleAvatar(avatarItem)}
                  aria-hidden
                >
                  <ProfileAvatar
                    name={avatarItem}
                    width={width}
                    height={height}
                  />
                </div>
                <div className="ps-create-avatar__avatar-name">
                  {profileAvatarMapper[avatarItem].name}
                </div>
              </div>
            )
          } else {
            return null
          }
        },
      )
    }
    useImperativeHandle(ref, () => ({
      setName: (name: string) => {
        setNickName(name)
      },
      setAvatar: (_avatar?: ProfileAvatarTypes) => {
        setAvatar(_avatar || 'pixel')
      },
    }))
    return (
      <div className="ps-create-avatar">
        <Form form={form}>
          <div className="col-12">
            <Input
              id="first-name"
              className="ps-kyc-margin-input mx-auto"
              title="ชื่อผู้ใช้"
              placeholder="กรอกชื่อ/ฉายา/นามแฝง"
              errorMsg={
                isError
                  ? errorMessage
                  : 'โปรดกรอกชื่อเล่นให้ถูกต้อง อย่างน้อย 3 ตัวอักษร'
              }
              value={nickName}
              onChange={handleNickNameChange}
              length={NAME_LENGTH_MAX}
              validator={(value) => validateData(value)}
              isError={isNickNameError || isError}
              onBlur={handleNickNameBlur}
              skipCheckValue
              disabled={isLoading || editNameInSeconds > 0}
            />
            <div className="ps-create-avatar__title">เลือกรูปโปรไฟล์ของคุณ</div>
            <div className="mx-auto col-10 row justify-content-around row-cols-2 ps-create-avatar__avatar-container">
              {renderAvatarItems(AVATAR_WIDTH, AVATAR_HEIGHT)}
            </div>
          </div>
          {!hideReferral && (
            <div className="col-12">
              <Form.Item name="referrer">
                <Input
                  id="register-referal"
                  disabled={isReferLink}
                  isError={!register.isReferrerValid}
                  errorMsg="รหัสผู้แนะนำไม่ถูกต้อง"
                  onChange={(value) => {
                    if (!value) {
                      clearReferInputError()
                    }
                  }}
                  onBlur={() => {
                    if (form.getFieldsValue().referrer === '') {
                      clearReferInputError()
                    }
                  }}
                  title={
                    <>
                      รหัสแนะนำ (ถ้ามี)
                      <Tooltip
                        placement="topLeft"
                        className="ps-tooltip-icon"
                        trigger={['click']}
                        title={
                          <InformationBox
                            titleTextJustify="center"
                            contentTextJustify="center"
                            isShowArrowIndicator
                            content={
                              <>
                                ไม่สามารถแก้ไขรหัส
                                <a
                                  href="/faq?selectedTab=2"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  &nbsp;แนะนำเพื่อน
                                </a>
                                <br />
                                หลังจากสมัครสมาชิกได้
                              </>
                            }
                            id="rtp-high"
                          />
                        }
                      >
                        {' '}
                        <ExclamationCircleOutlined
                          style={{
                            margin: '0px',
                            marginLeft: '16px',
                            fontSize: 14,
                            lineHeight: '1px',
                            color: theme['color-blue-200'],
                          }}
                        />
                      </Tooltip>
                    </>
                  }
                  placeholder="กรอกรหัสแนะนำ"
                />
              </Form.Item>
            </div>
          )}
          <div className="col-12 mt-4">
            <div className="ps-create-avatar__info-container">
              {!hideTAC && (
                <>
                  <div className="ps-privacy-layout mx-auto row justify-content-center align-items-center flex-wrap">
                    <StyledCheckbox
                      id="consent-tac"
                      style={{
                        transform: 'scale(0.7)',
                      }}
                      className="ps-privacy-layout__button col-xs-1 col-md-1"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      disabled
                    />
                    <div
                      style={{ paddingLeft: '8px', textAlign: 'left' }}
                      className="ps-privacy-layout__content col-xs-7 col-md-auto text-nowrap"
                    >
                      ฉันยอมรับเงื่อนไข และข้อตกลงภายใต้
                    </div>
                    <TextLink
                      className="ps-privacy-layout__text-link col-xs-9 col-md-auto"
                      disabled={showTACModal || isLoading}
                      onClick={() => setShowTACModal(true)}
                    >
                      นโยบายความเป็นส่วนตัว
                    </TextLink>
                  </div>
                  <div
                    style={{ fontSize: isMD ? 20 : 16 }}
                    className="ps-kyc-privacy-message-layout"
                  >
                    {autoLineBreakerWithBr(
                      isMD
                        ? 'PIGBET จะปกป้องข้อมูลส่วนตัวของคุณภายใต้มาตรการและหลักเกณฑ์ที่บริษัทกำหนด\nโดยสอดคล้องกับกฎหมาย เพื่อให้คุณสามารถลงชื่อเข้าใช้งาน\nและเข้าถึงข้อมูลของคุณได้อย่างปลอดภัยและมีประสิทธิภาพ'
                        : 'PIGBET จะปกป้องข้อมูลส่วนตัวของคุณภายใต้มาตรการและหลักเกณฑ์\nที่บริษัทกำหนดโดยสอดคล้องกับกฎหมาย เพื่อให้คุณสามารถ\nลงชื่อเข้าใช้งาน และเข้าถึงข้อมูลของคุณได้อย่างปลอดภัยและมีประสิทธิภาพ',
                    )}
                  </div>
                </>
              )}
              <div className="ps-kyc-create-avatar__link-container">
                <Button
                  id="checkphone-next-button"
                  type={BUTTON_TYPE.CTA_1}
                  className="ps-login__button-submit"
                  onClick={() => handleSubmit()}
                  nativeType="submit"
                  buttonForm
                  disabled={
                    btnDisable ||
                    isLoading ||
                    isError ||
                    isNickNameError ||
                    !validateData(nickName)
                  }
                >
                  ดำเนินการต่อ
                </Button>
              </div>
            </div>
          </div>
        </Form>
        <TermAndConditionModal
          open={showTACModal}
          onClose={() => setShowTACModal(false)}
        />
      </div>
    )
  },
)

CreateAvatar.displayName = 'CreateAvatar'
export default CreateAvatar
