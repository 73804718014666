import { useMemo } from 'react'
import { QuestionOutlined } from '@ant-design/icons'
import { Image } from '@pig-frontend/uikit'
import useAchievementHighlight from '@pig-common/hooks/useAchievementHighlight'
import { achievementImageUrl } from '@pig-common/constants/achievement'
import handleShowModalAchievementInfo from '@pig-common/pages-common/achievement/modal'
import styles from './index.module.scss'

export default function Achievement() {
  const { data: achievementHighlights = [] } = useAchievementHighlight({})

  /** Fill array to 3  */
  const filledHighlights = useMemo(() => {
    const targetLength = 3
    if (achievementHighlights?.length < targetLength) {
      const fillArray = new Array(
        targetLength - achievementHighlights?.length,
      ).fill({})
      return achievementHighlights?.concat(fillArray)
    } else if (achievementHighlights?.length > targetLength) {
      return achievementHighlights?.slice(0, targetLength)
    } else {
      return achievementHighlights
    }
  }, [achievementHighlights])

  return (
    <div className={styles.container}>
      {filledHighlights?.map((achievement, i) => (
        <>
          {achievement?.achievementUid ? (
            <div
              key={achievement?.achievementUid ?? i}
              role="button"
              aria-hidden
              onClick={() => handleShowModalAchievementInfo(achievement)}
            >
              <Image
                src={achievementImageUrl(achievement?.achievementUid)}
                width={120}
                height={120}
                className={styles.img}
                alt={achievement?.name || ''}
              />
            </div>
          ) : (
            <div
              key={achievement?.achievementUid ?? i}
              className={styles.empty}
            >
              <QuestionOutlined className={styles.icon} />
            </div>
          )}
        </>
      ))}
    </div>
  )
}
