import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'

export enum REDEEM_IN_GAME_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type RedeemInGameRewardData = {
  message: string
  detail: string
  game?: string
}

export type RedeemInGameRewardRequest = {
  userCode: string
  userUid: string
  rewardUid: string
}

export type RedeemInGameRewardResponse = BaseResponse & {
  status: REDEEM_IN_GAME_STATUS
  data: RedeemInGameRewardData
}

const redeemInGameReward = async (
  request: RedeemInGameRewardRequest,
): Promise<RedeemInGameRewardResponse | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${request.userCode}/${request.userUid}/shop/redeem/${request.rewardUid}`,
      method: 'POST',
    }

    const result = await fetchWithJSON<RedeemInGameRewardResponse>(
      pigspinApiInstance,
      requestConfig,
    )

    return result
  } catch (error) {
    return null
  }
}

export default redeemInGameReward
