import { AxiosRequestConfig } from 'axios'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

export type TopBigWinRequest = {
  game_id: number
}

export type TopBigWinItem = {
  user_phone: string
  game_code: string
  game_icon_url: string
  title: string
  sub_title: string
  bet_amount: string
  image_url: string
  uid: string
  transaction_date: string
}

export const getBigWinTopUser = async (
  game_id: string,
): Promise<TopBigWinItem[]> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/bigwin/topuser?game_id=${game_id}`,
      method: 'POST',
    }

    const result = await fetchWithJSON<TopBigWinItem[]>(
      pigspinApiInstance,
      requestConfig,
    )
    return result
  } catch (error) {
    return Promise.resolve([])
  }
}

export const getBigWinLastUser = async (
  game_id: string,
): Promise<TopBigWinItem[]> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/bigwin/lastuser?game_id=${game_id}`,
      method: 'POST',
    }

    const result = await fetchWithJSON<TopBigWinItem[]>(
      pigspinApiInstance,
      requestConfig,
    )
    return result
  } catch (error) {
    return Promise.resolve([])
  }
}
