import { useRef, useState } from 'react'
import cx from 'classnames'
import { AlertContactModalV2 } from '@pig-common/components/Modal'
import CommonModal from '../CommonModal/CommonModal'
import NiceModal from '@ebay/nice-modal-react'
import { LOGIN_STATUS } from '@pig-common/services/login'
import { useLogin } from '@pig-common/hooks/useLogin'
import { Spin } from 'antd'
import { notification } from '@pig-frontend/uikit'
import useLegacySystemStatus from '@pig-common/hooks/useLegacySystemStatus'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { InputDigit } from '@pig-common/components/InputDigit'
import { CloseOutlined } from '@ant-design/icons'
import styles from './index.module.scss'

export type PinModalProps = {
  open: boolean
  closableBackDrop?: boolean
  onClose: () => void
  phoneNumber: string
}

export enum MODAL_TYPE {
  CHANGE_PIN,
  BLOCKED_PIN,
  BANNED_USER,
  CONFIRM_PIN_DOES_NOT_MATCH,
  SOMETHING_WENT_WRONG,
  CHANGE_PIN_SUCCESS,
}
const UnlinkLineModal = ({
  open,
  closableBackDrop = true,
  onClose,
  phoneNumber,
}: PinModalProps): JSX.Element => {
  const { refreshSystemStatus } = useLegacySystemStatus()
  const [disabled, setDisabled] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const digitInputRef = useRef<any>(null)
  const { callLinkLine } = useLegacyProfile()

  const { mutateLogin } = useLogin()

  const onSomethingWentWrong = () => {
    NiceModal.show(AlertContactModalV2, {
      header: 'ดำเนินการไม่สำเร็จ',
    })
    setDisabled(false)
  }
  const verifyCurrentPinHandler = async (pin: string) => {
    setDisabled(true)
    setLoading(true)
    refreshSystemStatus()
    const requestLogin = {
      phoneNumber: phoneNumber || '',
      pin,
    }
    const lineResult = await callLinkLine()
    if (lineResult.service_code === 'E40000') {
      notification.error({
        message: lineResult.service_message,
      })
    } else {
      // line success
      const verifyResponse = await mutateLogin.mutateAsync(requestLogin)
      if (verifyResponse.Status !== LOGIN_STATUS.CLIENT_EXCEPTION) {
        notification.success({
          message: 'ดำเนินการสำเร็จ',
          duration: 6000,
        })
        onClose()
      } else {
        digitInputRef.current?.error('')
        onSomethingWentWrong()
      }
    }
    onClose()
    setDisabled(false)
  }

  return (
    <CommonModal
      open={open}
      closableBackDrop={closableBackDrop}
      onClose={onClose}
      columnClass="col-lg-4"
    >
      <div className={styles['pin-modal']}>
        <Spin spinning={isLoading}>
          <div className={styles.header}>ถอดการเชื่อมต่อ LINE</div>
          <div className={styles.sub}>กรอกรหัส PIN ของคุณ</div>
          <CloseOutlined
            className={cx([styles['close-icon']])}
            onClick={onClose}
            role="button"
            aria-hidden
          />
          <InputDigit
            onComplete={(inputPin) => {
              verifyCurrentPinHandler(inputPin)
            }}
            disabled={disabled || !phoneNumber}
            ref={digitInputRef}
          />
        </Spin>
      </div>
    </CommonModal>
  )
}

export default UnlinkLineModal
