import * as React from 'react'

export const KYCFreeCredit: React.FunctionComponent = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.9992 14.0733C28.9992 20.1889 24.0395 25.1466 17.9215 25.1466C11.8034 25.1466 6.84375 20.1889 6.84375 14.0733C6.84375 7.9577 11.8034 3 17.9215 3C24.0395 3 28.9992 7.9577 28.9992 14.0733Z"
        fill="#FCFCFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9215 22.7394C22.7095 22.7394 26.591 18.8595 26.591 14.0733C26.591 9.28718 22.7095 5.40724 17.9215 5.40724C13.1334 5.40724 9.25195 9.28718 9.25195 14.0733C9.25195 18.8595 13.1334 22.7394 17.9215 22.7394ZM17.9215 25.1466C24.0395 25.1466 28.9992 20.1889 28.9992 14.0733C28.9992 7.9577 24.0395 3 17.9215 3C11.8034 3 6.84375 7.9577 6.84375 14.0733C6.84375 20.1889 11.8034 25.1466 17.9215 25.1466Z"
        fill="#2A2A2E"
      />
      <path
        d="M25.1349 17.9342C25.1349 24.0441 20.1799 28.9973 14.0675 28.9973C7.95507 28.9973 3 24.0441 3 17.9342C3 11.8242 7.95507 6.87109 14.0675 6.87109C20.1799 6.87109 25.1349 11.8242 25.1349 17.9342Z"
        fill="#FCFCFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0675 26.59C18.8499 26.59 22.7267 22.7147 22.7267 17.9342C22.7267 13.1537 18.8499 9.27834 14.0675 9.27834C9.28509 9.27834 5.4082 13.1537 5.4082 17.9342C5.4082 22.7147 9.28509 26.59 14.0675 26.59ZM14.0675 28.9973C20.1799 28.9973 25.1349 24.0441 25.1349 17.9342C25.1349 11.8242 20.1799 6.87109 14.0675 6.87109C7.95507 6.87109 3 11.8242 3 17.9342C3 24.0441 7.95507 28.9973 14.0675 28.9973Z"
        fill="#2A2A2E"
      />
      <path
        d="M14.1651 17.2657C14.4219 17.2657 14.6393 17.2418 14.8171 17.194C14.995 17.1433 15.1389 17.0731 15.249 16.9836C15.3591 16.891 15.4381 16.7805 15.4861 16.6522C15.5369 16.5238 15.5623 16.3805 15.5623 16.2222C15.5623 16.0103 15.5256 15.8386 15.4523 15.7072C15.3817 15.5759 15.2829 15.4744 15.1559 15.4027C15.0317 15.3281 14.8835 15.2788 14.7113 15.2549C14.5419 15.2281 14.3598 15.2146 14.1651 15.2146H12.5857V17.2657H14.1651ZM13.856 13.6875C14.0423 13.6875 14.2455 13.6905 14.4657 13.6965C14.6859 13.6994 14.9046 13.7129 15.122 13.7368C15.3422 13.7577 15.5511 13.792 15.7486 13.8398C15.9491 13.8875 16.1213 13.9562 16.2652 14.0458C16.4177 14.1413 16.5616 14.2533 16.6971 14.3816C16.8326 14.51 16.9512 14.6563 17.0528 14.8205C17.1544 14.9847 17.2349 15.1654 17.2941 15.3624C17.3534 15.5595 17.3831 15.7744 17.3831 16.0073C17.3831 16.2043 17.3562 16.3924 17.3026 16.5716C17.2518 16.7507 17.1798 16.9179 17.0867 17.0731C16.9963 17.2254 16.8891 17.3642 16.7649 17.4896C16.6435 17.615 16.5122 17.7225 16.3711 17.8121C16.611 17.8748 16.8199 17.9763 16.9977 18.1166C17.1756 18.2539 17.3224 18.4166 17.4381 18.6047C17.5567 18.7928 17.6442 18.9973 17.7006 19.2183C17.7599 19.4362 17.7895 19.6556 17.7895 19.8766C17.7895 20.208 17.726 20.5304 17.599 20.8439C17.472 21.1544 17.2843 21.4291 17.0358 21.6679C16.7903 21.9068 16.4854 22.0993 16.1213 22.2456C15.7599 22.3889 15.345 22.4606 14.8764 22.4606H10.748V13.6875H13.856ZM14.7197 20.9335C14.9202 20.9335 15.0966 20.9036 15.249 20.8439C15.4043 20.7842 15.5327 20.7021 15.6343 20.5976C15.7388 20.4901 15.8178 20.3647 15.8714 20.2214C15.9251 20.0751 15.9519 19.9169 15.9519 19.7467C15.9519 19.5526 15.9095 19.3884 15.8249 19.2541C15.7402 19.1168 15.6287 19.0048 15.4904 18.9182C15.352 18.8316 15.1954 18.7689 15.0204 18.7301C14.8482 18.6883 14.6732 18.6674 14.4953 18.6674H12.5857V20.9335H14.7197Z"
        fill="#2A2A2E"
      />
      <path
        d="M13.2422 13.17C13.2422 13.0524 13.3378 12.957 13.4558 12.957H14.5264C14.6444 12.957 14.74 13.0524 14.74 13.17V22.7008C14.74 22.8184 14.6444 22.9138 14.5264 22.9138H13.4558C13.3378 22.9138 13.2422 22.8184 13.2422 22.7008V13.17Z"
        fill="#2A2A2E"
      />
    </svg>
  )
}

export const HalfOfDownlineDeposit: React.FunctionComponent = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9999 27.9999C21.3997 27.9999 25.7772 23.6225 25.7772 18.2227C25.7772 15.0508 24.2668 12.2317 21.9259 10.4453H10.0739C7.73308 12.2317 6.22266 15.0508 6.22266 18.2227C6.22266 23.6225 10.6001 27.9999 15.9999 27.9999Z"
        fill="#FCFCFC"
      />
      <mask
        id="mask0_226_19777"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="9"
        width="24"
        height="22"
      >
        <path d="M4 9.33594H27.9987V30.2237H4V9.33594Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_226_19777)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9994 28.0032C21.3992 28.0032 25.7766 23.6258 25.7766 18.2259C25.7766 12.8261 21.3992 8.44867 15.9994 8.44867C10.5995 8.44867 6.2221 12.8261 6.2221 18.2259C6.2221 23.6258 10.5995 28.0032 15.9994 28.0032ZM15.9994 30.2253C22.6264 30.2253 27.9987 24.853 27.9987 18.2259C27.9987 11.5989 22.6264 6.22656 15.9994 6.22656C9.3723 6.22656 4 11.5989 4 18.2259C4 24.853 9.3723 30.2253 15.9994 30.2253Z"
          fill="#2A2A2E"
        />
        <path
          d="M16.0993 18.3888C16.3778 18.3888 16.6134 18.3629 16.8062 18.3111C16.999 18.2561 17.1551 18.18 17.2745 18.0828C17.3938 17.9824 17.4795 17.8626 17.5316 17.7234C17.5867 17.5841 17.6142 17.4287 17.6142 17.2571C17.6142 17.0272 17.5744 16.841 17.4948 16.6985C17.4183 16.556 17.3112 16.4459 17.1735 16.3682C17.0388 16.2872 16.8782 16.2338 16.6915 16.2079C16.5078 16.1787 16.3104 16.1642 16.0993 16.1642H14.3869V18.3888H16.0993ZM15.7641 14.5078C15.9661 14.5078 16.1865 14.5111 16.4252 14.5175C16.6639 14.5208 16.9011 14.5353 17.1368 14.5612C17.3755 14.5839 17.602 14.6212 17.8162 14.673C18.0335 14.7248 18.2202 14.7993 18.3763 14.8964C18.5415 15 18.6976 15.1215 18.8445 15.2607C18.9914 15.3999 19.12 15.5586 19.2301 15.7367C19.3403 15.9148 19.4275 16.1107 19.4918 16.3245C19.5561 16.5382 19.5882 16.7713 19.5882 17.0239C19.5882 17.2376 19.5591 17.4417 19.501 17.636C19.4459 17.8302 19.3679 18.0116 19.2669 18.18C19.1689 18.3451 19.0526 18.4957 18.918 18.6317C18.7864 18.7677 18.6441 18.8843 18.491 18.9814C18.7512 19.0494 18.9776 19.1595 19.1705 19.3117C19.3633 19.4607 19.5224 19.6372 19.6479 19.8412C19.7764 20.0452 19.8713 20.267 19.9325 20.5067C19.9968 20.743 20.0289 20.9811 20.0289 21.2207C20.0289 21.5801 19.9601 21.9299 19.8223 22.2699C19.6846 22.6067 19.4811 22.9046 19.2118 23.1636C18.9455 23.4227 18.615 23.6316 18.2202 23.7902C17.8284 23.9457 17.3785 24.0234 16.8705 24.0234H12.3945V14.5078H15.7641ZM16.7006 22.367C16.9179 22.367 17.1092 22.3346 17.2745 22.2699C17.4428 22.2051 17.5821 22.1161 17.6922 22.0027C17.8055 21.8861 17.8912 21.7501 17.9493 21.5947C18.0075 21.436 18.0365 21.2644 18.0365 21.0798C18.0365 20.8693 17.9906 20.6912 17.8988 20.5455C17.807 20.3966 17.6861 20.2751 17.5362 20.1812C17.3862 20.0873 17.2163 20.0193 17.0266 19.9772C16.8399 19.9319 16.6501 19.9092 16.4573 19.9092H14.3869V22.367H16.7006Z"
          fill="#2A2A2E"
        />
        <path
          d="M15.1016 13.9459C15.1016 13.8183 15.2053 13.7148 15.3333 13.7148H16.4945C16.6224 13.7148 16.7261 13.8183 16.7261 13.9459V24.2832C16.7261 24.4108 16.6224 24.5143 16.4945 24.5143H15.3333C15.2053 24.5143 15.1016 24.4108 15.1016 24.2832V13.9459Z"
          fill="#2A2A2E"
        />
      </g>
      <path
        d="M27.7391 11.8125L4.26213 11.8125C4.11866 11.8125 4.00127 11.6951 4.00127 11.5516L4.00127 9.59522C4.00127 9.45175 4.11866 9.33437 4.26213 9.33437L27.7391 9.33437C27.8826 9.33437 28 9.45176 28 9.59523L28 11.5516C28 11.6951 27.8826 11.8125 27.7391 11.8125Z"
        fill="#262626"
      />
      <path
        d="M12.4737 6.40769L16.0917 1.83521C16.1462 1.76248 16.2553 1.76248 16.3098 1.83521L19.9278 6.40769C20.0006 6.49557 19.9339 6.62889 19.8187 6.62889L17.231 6.62889L17.231 10.8711L15.1705 10.8711L15.1705 6.62889L12.5828 6.62889C12.4676 6.62889 12.401 6.4986 12.4737 6.40769Z"
        fill="#262626"
      />
      <path
        d="M27.9996 26.2224C27.9996 28.4315 26.2088 30.2222 23.9998 30.2222C21.7908 30.2222 20 28.4315 20 26.2224C20 24.0134 21.7908 22.2227 23.9998 22.2227C26.2088 22.2227 27.9996 24.0134 27.9996 26.2224Z"
        fill="#2A2A2E"
      />
      <path
        d="M25.9998 26.6288C25.9998 26.6975 25.9435 26.7538 25.8748 26.7538H24.5311V28.0975C24.5311 28.1662 24.4749 28.2224 24.4061 28.2224H23.5937C23.5249 28.2224 23.4687 28.1662 23.4687 28.0975V26.7538H22.125C22.0562 26.7538 22 26.6975 22 26.6288V25.8163C22 25.7476 22.0562 25.6913 22.125 25.6913H23.4687V24.3476C23.4687 24.2789 23.5249 24.2227 23.5937 24.2227H24.4061C24.4749 24.2227 24.5311 24.2789 24.5311 24.3476V25.6913H25.8748C25.9435 25.6913 25.9998 25.7476 25.9998 25.8163V26.6288Z"
        fill="#FCFCFC"
      />
    </svg>
  )
}

export const UplineGetDownlineLoss: React.FunctionComponent = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6662 16.5812C26.6662 22.3495 21.9901 27.0256 16.2218 27.0256C10.4535 27.0256 5.77734 22.3495 5.77734 16.5812C5.77734 10.8129 10.4535 6.13672 16.2218 6.13672C21.9901 6.13672 26.6662 10.8129 26.6662 16.5812Z"
        fill="#FCFCFC"
      />
      <path
        d="M15.982 4.35939C9.355 4.36839 4 9.72939 4 16.3564C4 20.1964 5.806 23.6164 8.614 25.8124L7.489 27.2524C7.366 27.4114 7.48 27.6424 7.678 27.6394L12.688 27.6154C12.844 27.6154 12.958 27.4684 12.919 27.3184L11.734 22.4494C11.7233 22.4055 11.7004 22.3655 11.668 22.334C11.6356 22.3026 11.595 22.2809 11.5508 22.2715C11.5066 22.2621 11.4606 22.2653 11.4182 22.2809C11.3758 22.2964 11.3386 22.3237 11.311 22.3594L10.09 23.9224C9.784 23.6824 9.49 23.4214 9.211 23.1424C8.33386 22.2683 7.63491 21.2321 7.153 20.0914C6.652 18.9094 6.4 17.6524 6.4 16.3564C6.4 15.0604 6.652 13.8034 7.153 12.6214C7.636 11.4784 8.329 10.4524 9.211 9.57039C10.093 8.68839 11.119 7.99539 12.262 7.51239C13.447 7.01139 14.704 6.75939 16 6.75939C17.296 6.75939 18.553 7.01139 19.735 7.51239C20.878 7.99539 21.904 8.68839 22.786 9.57039C23.668 10.4524 24.361 11.4784 24.844 12.6214C25.345 13.8034 25.597 15.0604 25.597 16.3564C25.597 17.6524 25.345 18.9094 24.844 20.0914C24.3621 21.2321 23.6631 22.2683 22.786 23.1424C22.561 23.3674 22.327 23.5774 22.084 23.7784C22.0353 23.8179 22.0042 23.8751 21.9975 23.9375C21.9907 23.9998 22.0089 24.0623 22.048 24.1114L23.23 25.6264C23.314 25.7314 23.467 25.7494 23.572 25.6654C26.275 23.4634 28 20.1124 28 16.3564C28 9.72339 22.618 4.35039 15.982 4.35939Z"
        fill="#262626"
      />
      <path
        d="M16.5936 15.2562C16.8721 15.2562 17.1078 15.2303 17.3006 15.1785C17.4934 15.1235 17.6495 15.0474 17.7689 14.9502C17.8883 14.8498 17.9739 14.73 18.026 14.5907C18.0811 14.4515 18.1086 14.296 18.1086 14.1244C18.1086 13.8945 18.0688 13.7083 17.9893 13.5658C17.9127 13.4233 17.8056 13.3132 17.6679 13.2355C17.5332 13.1545 17.3725 13.1011 17.1858 13.0752C17.0022 13.046 16.8048 13.0314 16.5936 13.0314H14.8812V15.2562H16.5936ZM16.2585 11.375C16.4605 11.375 16.6808 11.3782 16.9196 11.3847C17.1583 11.388 17.3955 11.4025 17.6312 11.4284C17.8699 11.4511 18.0964 11.4883 18.3106 11.5402C18.5279 11.592 18.7146 11.6665 18.8707 11.7636C19.036 11.8672 19.1921 11.9887 19.339 12.1279C19.4859 12.2672 19.6145 12.4259 19.7246 12.604C19.8348 12.7821 19.9221 12.978 19.9863 13.1917C20.0506 13.4055 20.0827 13.6386 20.0827 13.8912C20.0827 14.105 20.0537 14.309 19.9955 14.5033C19.9404 14.6976 19.8624 14.879 19.7614 15.0474C19.6634 15.2125 19.5471 15.3631 19.4125 15.4991C19.2808 15.6351 19.1385 15.7517 18.9855 15.8489C19.2457 15.9169 19.4721 16.027 19.665 16.1792C19.8578 16.3282 20.0169 16.5046 20.1424 16.7087C20.271 16.9127 20.3658 17.1345 20.4271 17.3742C20.4913 17.6106 20.5235 17.8486 20.5235 18.0882C20.5235 18.4477 20.4546 18.7974 20.3169 19.1375C20.1791 19.4743 19.9756 19.7722 19.7063 20.0313C19.44 20.2903 19.1095 20.4992 18.7146 20.6579C18.3229 20.8134 17.8729 20.8911 17.3649 20.8911H12.8887V11.375H16.2585ZM17.195 19.2346C17.4123 19.2346 17.6036 19.2022 17.7689 19.1375C17.9372 19.0727 18.0765 18.9836 18.1867 18.8703C18.2999 18.7537 18.3856 18.6177 18.4438 18.4623C18.5019 18.3036 18.531 18.1319 18.531 17.9474C18.531 17.7369 18.4851 17.5587 18.3933 17.413C18.3014 17.2641 18.1805 17.1426 18.0306 17.0487C17.8806 16.9548 17.7107 16.8868 17.521 16.8447C17.3343 16.7993 17.1445 16.7767 16.9517 16.7767H14.8812V19.2346H17.195Z"
        fill="#2A2A2E"
      />
      <path
        d="M15.5977 10.8131C15.5977 10.6855 15.7014 10.582 15.8294 10.582H16.9906C17.1186 10.582 17.2223 10.6855 17.2223 10.8131V21.151C17.2223 21.2786 17.1186 21.382 16.9906 21.382H15.8294C15.7014 21.382 15.5977 21.2786 15.5977 21.151V10.8131Z"
        fill="#2A2A2E"
      />
    </svg>
  )
}
