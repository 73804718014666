import React, { StrictMode, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { Images } from '../Images'
import QuestCard from '@pig-common/components/DailyQuests/QuestCard'
import Tutorial from '@pig-common/components/DailyQuests/Tutorial'
import { LoadMoreButton } from '../Button'
import { QUEST_LIST_TYPE } from '@pig-common/hooks/useLoyaltyProgram'
import { BigwinQuestItem } from '@pig-common/hooks/useQuest'
import gameBrandStateAtom from '@pig-common/recoils/modules/game-brand'

type QuestListProps = {
  id?: string
  type: QUEST_LIST_TYPE
  data?: BigwinQuestItem[]
  label?: string | React.ReactNode
  isResetting?: boolean
  showProgressBar?: boolean
  isUploadButton?: boolean
  buttonText?: string
  isLoading?: boolean
  isNoMore?: boolean
  header?: JSX.Element
  placeHolderNumber?: number
  openTutorial?: () => void
  onClick?: (claimQuestProps: BigwinQuestItem) => void
  fetchBigWin?: () => void
  loadMore?: () => void
}

const QuestList = ({
  id = 'quest-list',
  type,
  data,
  label,
  isResetting,
  showProgressBar = true,
  isUploadButton = false,
  buttonText,
  isLoading,
  isNoMore,
  header,
  placeHolderNumber,
  openTutorial,
  onClick,
  fetchBigWin,
  loadMore,
}: QuestListProps) => {
  const isDataEmpty = !data || data?.length <= 0
  const gameBrandState = useRecoilValue(gameBrandStateAtom)

  const renderList = useCallback(() => {
    if (!isDataEmpty) {
      return (
        <StrictMode>
          <div className="ps-gamequests__list row" data-testid={`${type}-list`}>
            {data?.map((quest: BigwinQuestItem, index: number) => {
              return (
                <div className="col-12 col-md-6" key={`${id}-${quest.uid}`}>
                  <QuestCard
                    index={index}
                    type={type}
                    quest={quest}
                    showProgressBar={showProgressBar}
                    isUploadButton={isUploadButton}
                    buttonText={buttonText}
                    onClick={onClick}
                    fetchBigWin={fetchBigWin}
                  />
                </div>
              )
            })}
          </div>
        </StrictMode>
      )
    } else {
      return (
        <StrictMode>
          <div className="ps-gamequests__list row" data-testid={`${type}-list`}>
            <div className="col-12 col-md-6">
              <Tutorial openTutorial={openTutorial} />
            </div>
          </div>
        </StrictMode>
      )
    }
  }, [isDataEmpty, gameBrandState, data])

  return (
    <div className="ps-gamequests" data-testid={`${type}`}>
      <div
        className={`ps-gamequests__header-section ps-gamequests__header-section${
          type === QUEST_LIST_TYPE.GAME_QUEST ? '--normal' : '--center'
        } col-12`}
      >
        {header}
      </div>
      {isResetting ? (
        <div
          data-testid="ps-gamequests__reset-status"
          className="text-center my-5"
        >
          <Images
            src="/images/dailyquests/pig-waiting-time.png"
            alt="daily-quest-reset-time"
            width={200}
            height={200}
          />
          <h5 className="ps-gamequests__reset-title">กำลังรีเซ็ตภารกิจ</h5>
          <p className="m-0 ps-gamequests__reset-sub-title">กรุณารอสักครู่</p>
        </div>
      ) : (
        <>
          {!isLoading && renderList()}
          {!isDataEmpty && loadMore && (
            <LoadMoreButton
              isLoading={isLoading}
              isNoMore={isNoMore}
              loadMoreText="ดูเพิ่มเติม"
              noMoreText="ไม่พบรายการเพิ่มเติม"
              onLoadMore={loadMore}
            />
          )}
        </>
      )}
    </div>
  )
}

export default QuestList
