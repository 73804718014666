import React, { useEffect, useState, useRef } from 'react'
import cx from 'classnames'
import { motion, AnimatePresence, Variants } from 'framer-motion'
import Image from 'next/image'

import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

export type SelectOptionProps = {
  label: string
  value: string
  iconImageSrc?: string
}

export type SelectInputProps = {
  id?: string
  title?: string
  placeholder?: string | React.ReactNode
  options: SelectOptionProps[]
  focused?: boolean
  isValid?: boolean
  errorMsg?: string
  className?: string
  optionClassName?: string
  selectedValue?: string
  onChange: (option: SelectOptionProps) => void
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
}

const AnimateVariants: Variants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { pointerEvents: 'none', opacity: 0, y: -20 },
}

const AnimateTransition = {
  duration: 1,
  bounce: 0,
  type: 'spring',
}

const SelectInput = ({
  id = 'select-input',
  title,
  placeholder = '',
  options,
  focused = false,
  isValid = true,
  errorMsg = '',
  selectedValue,
  className = '',
  optionClassName = '',
  onChange,
  onBlur = () => {},
}: SelectInputProps) => {
  const [opened, setOpened] = useState(focused)
  const [inputValue, setInputValue] = useState(selectedValue)
  const [bankName, setBankName] = useState('')
  const [bankLogo, setBankLogo] = useState('')
  const toggleIconClass = () => {
    setOpened(!opened)
  }

  const selectInputWrapperRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: React.FocusEvent<HTMLDivElement>) => {
    setOpened(false)
    onBlur(event)
  }

  const onSelectItem = (option: SelectOptionProps) => {
    if (option.iconImageSrc) setBankLogo(option.iconImageSrc)
    setBankName(option.label)
    setInputValue(option.value)
    toggleIconClass()
    onChange(option)
  }

  const assignSelectedValue = (selectedOptionValue: string) => {
    const selectedOption: SelectOptionProps | undefined = options.find(
      (option) => {
        return option.value === selectedOptionValue
      },
    )

    if (selectedOption) {
      const { label, value, iconImageSrc } = selectedOption

      if (iconImageSrc) setBankLogo(iconImageSrc)
      setBankName(label)
      setInputValue(value)
    }
  }

  useEffect(() => {
    if (selectedValue) {
      assignSelectedValue(selectedValue)
    }
  }, [selectedValue, options])

  return (
    <div
      id={id}
      data-testid={id}
      className={`ps-input-layout ${className}`}
      onBlur={handleClickOutside}
      tabIndex={-1}
    >
      {title ? (
        <div data-testid={`${id}__title`} className="ps-input-title">
          {title}
        </div>
      ) : (
        ''
      )}
      <div
        className={`ps-input ps-input-select ${
          !isValid ? 'ps-input--error' : ''
        }`}
      >
        <input type="hidden" name="bank-name" value={inputValue || ''} />
        <div
          className="ps-input-select__select-wrapper"
          onClick={toggleIconClass}
          aria-hidden
        >
          <div className="row col ps-input-select__input-wrapper">
            {inputValue ? (
              <>
                {bankLogo && (
                  <div
                    data-testid={`${id}__selected-logo`}
                    className="col-auto ps-input-select__bank-logo"
                  >
                    <Image src={bankLogo} alt="" width={32} height={32} />
                  </div>
                )}
                <div
                  data-testid={`${id}__selected-option`}
                  className="col ps-input-select__bank-name"
                >
                  {bankName}
                </div>
              </>
            ) : (
              <div className="col ps-input-select__placeholder">
                {placeholder}
              </div>
            )}
          </div>
          <div className="col-auto">
            <div className="ps-input-select__icon">
              <DownOutlined
                className={cx({
                  'ps-input-select__icon--toggle-down': opened,
                  'ps-input-select__icon--toggle-up': !opened,
                })}
              />
            </div>
          </div>
        </div>
        {!isValid ? (
          <div className="ps-input-error-layout">
            <ExclamationCircleOutlined className="ps-input-icon-caution-outline--error" />
            <span className="ps-input-message">{errorMsg}</span>
          </div>
        ) : (
          ''
        )}
        <AnimatePresence>
          {opened && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={AnimateVariants}
              transition={AnimateTransition}
              ref={selectInputWrapperRef}
              className="ps-input-select__menu-container"
            >
              <div
                data-testid={`${id}__menu`}
                className="ps-input-select__menu"
              >
                <div className="row ps-input-select__menu-wrapper">
                  {options.map((option, index) => (
                    <div
                      data-testid={`${id}__item-${option.value}`}
                      className={`ps-input-select__item ${
                        inputValue === option.value
                          ? 'ps-input-select__item--active'
                          : ''
                      } ${optionClassName}`}
                      data-value={option.value}
                      key={option.value}
                      onClick={() => onSelectItem(option)}
                      aria-hidden
                    >
                      {option.iconImageSrc && (
                        <Image
                          src={option.iconImageSrc}
                          style={{ margin: 'auto' }}
                          alt=""
                          width={44}
                          height={44}
                        />
                      )}
                      <div className="ps-input-select__label-wrapper">
                        <span
                          data-testid={`${id}__label-${index}`}
                          className="ps-input-select__label"
                        >
                          {autoLineBreakerWithBr(option.label)}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default SelectInput
