import { AxiosRequestConfig } from 'axios'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { BaseResponse } from '@pig-common/services/service.type'

export enum PROMOTION_CODE {
  REGISTER = 'register',
  DEPOSIT = 'deposit',
  FIRST_DEPOSIT = 'first_deposit',
  // REFERRAL = 'referral',
}

export type PromotionDetailData = {
  uid: string
  name: string
  code: string
  type: string
  initial_coin_amount: number
  bonus_ratio: number
  turnover_ratio: number
  maximum_bonus: number
  begin_at?: Date
  end_at?: Date
  duration_in_hours: number
  is_active: boolean
  description: string
  promotion_meta_title: string
  promotion_meta_description: string
  promotion_title: string
}

export type PromotionDetailResponse = BaseResponse & {
  data: PromotionDetailData
}

type GetPromotionDetail = PromotionDetailData | null

export const getPromotionDetail = async (
  promotionCode: string,
): Promise<GetPromotionDetail> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/promotions/code/${promotionCode}`,
      method: 'GET',
    }
    const result = await fetchWithJSON<PromotionDetailResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    return result.data as PromotionDetailData
  } catch (error) {
    return null
  }
}
