import React, { useState } from 'react'

type RedemptionRefreshButtonProp = {
  extraClass?: string
  buttonFunction?: () => void
}
const RedemptionRefreshButton = ({
  extraClass,
  buttonFunction,
}: RedemptionRefreshButtonProp) => {
  const [isActive, setActive] = useState<boolean>(false)
  const delayRefresh = () => {
    if (buttonFunction) {
      setActive(true)
      buttonFunction()
      setTimeout(() => {
        setActive(false)
      }, 1500)
    }
  }

  return (
    <div
      className={`ps-daily-quest-refresh-button ${extraClass} ${
        isActive ? 'ps-daily-quest-refresh-button--refreshing' : ''
      }`}
      data-testid="daily-quest-refresh-button"
      onClick={() => delayRefresh()}
      onKeyDown={() => delayRefresh()}
    >
      <span className="ps-icon-refresh" />
    </div>
  )
}

export default RedemptionRefreshButton
