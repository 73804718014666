/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import NiceModal from '@ebay/nice-modal-react'
import { useRecoilState } from 'recoil'
import { HTMLMotionProps, motion } from 'framer-motion'

import { authStateCommon, registerStateCommon } from '@pig-common/recoils'
import {
  useCookies,
  RegisterCookieType,
  COOKIES_KEY,
  UtmCookiesType,
} from '@pig-common/utils/cookies'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'

import {
  StepperChildrenType,
  IStepperData,
  StepperController,
} from '@pig-common/components/Stepper'
import {
  AlertContactModalV2,
  ALERT_MODAL_TYPE,
} from '@pig-common/components/Modal'
import {
  VerifyOtp,
  SetPinV2,
  RegisterInfo,
} from '@pig-common/components/Register'
import { useRegister, RegisterRequest } from '@pig-common/hooks/useRegister'
import { useLogin } from '@pig-common/hooks/useLogin'
import { LoginResponse, LOGIN_STATUS } from '@pig-common/services/login'
import {
  REFER_TYPE,
  REGISTER_BY,
  REGISTER_TYPE,
} from '@pig-common/models/shared-types'
import useStorage from '@pig-common/hooks/useStorage'
import { useLoading } from '@pig-common/hooks/useLoading'

enum REGISTER_STEP {
  VERIFY_OTP,
  SETUP_PIN,
  USER_INFO,
}

const motionProps: HTMLMotionProps<'div'> = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  variants: {
    initial: { opacity: 0, x: 500 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -500, position: 'absolute' },
  },
  transition: { bounce: 0 },
}

type RegisterWithPhoneProps = {
  onError: (modalType?: ALERT_MODAL_TYPE) => void
}

const RegisterWithPhone = ({ onError }: RegisterWithPhoneProps) => {
  const [register, setRegister] = useRecoilState(
    registerStateCommon.registerState,
  )
  const { removeItem, getItem } = useStorage()
  const { callRegister } = useRegister()
  const { callLogin } = useLogin()
  const { loading, stopLoading } = useLoading()
  const [isLoading, setIsLoading] = React.useState(false)
  const [step, setStep] = useRecoilState(registerStateCommon.registerStepState)
  const [registerPhoneData, setRegisterPhoneData] =
    React.useState<Partial<RegisterRequest>>()

  const [cookies, setCookies, removeCookies] = useCookies<
    string | Partial<RegisterCookieType>
  >([COOKIES_KEY.REGISTER, COOKIES_KEY.OTP, COOKIES_KEY.CFID, COOKIES_KEY.UTM])
  const [, setAuth] = useRecoilState(authStateCommon.authState)

  const router = useRouter()
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
  const referrerObj = JSON.parse(getItem('referrer', 'session') || '{}')
  const isReferLink = referrerObj?.isValid && referrerObj?.psCode?.length > 0

  const stepTitleList = [
    { title: 'register:step1.title', subtitle: 'register:step1.subtitle' },
    { title: 'register:step2.title', subtitle: 'register:step2.subtitle' },
    { title: 'register:step3.title', subtitle: 'register:step3.subtitle' },
  ]

  const children: StepperChildrenType[] = [VerifyOtp, SetPinV2, RegisterInfo]
  const data: IStepperData[] = []

  const onRegisterSuccess = async (requestData: RegisterRequest) => {
    const autoLogin = async (
      phoneNumber = '',
      pin = '',
    ): Promise<LoginResponse> => {
      const loginResponse = await callLogin({ phoneNumber, pin })
      if (loginResponse.Status !== LOGIN_STATUS.SUCCESS) {
        throw new Error('Login fail (after registered)')
      }
      return loginResponse
    }
    const loginResponse = await autoLogin(
      requestData.phoneNumber,
      requestData.userPin,
    )
    setCookies(COOKIES_KEY.CFID, loginResponse.cfid)
    setCookies(COOKIES_KEY.UID, loginResponse.uid || '0000000000')
    setCookies(COOKIES_KEY.CUSTOMER_CODE, loginResponse.CustomerCode)
    removeCookies(COOKIES_KEY.UTM)
    removeItem('referrer', 'session')
    setAuth({
      cfid: loginResponse.cfid,
      customerCode: loginResponse.CustomerCode,
      gameToken: loginResponse.GameToken,
      name: loginResponse.CustomerName,
      isFirstLogin: true,
      userUID: loginResponse.UserUid,
    })
    await router.push('/lobby', '/lobby', { scroll: true })
  }

  useEffect(() => {
    return () => setStep(REGISTER_STEP.VERIFY_OTP)
  }, [])

  const registerWithPhoneNumber = async (
    displayName: string,
    avatar: string,
    referrer: string,
  ) => {
    try {
      // TODO : fix it utm is null
      loading()
      const utm = cookies?.utm as UtmCookiesType
      const requestData: RegisterRequest = {
        displayName,
        avatar,
        referrer,
        referrerType: !referrer
          ? undefined
          : isReferLink
          ? REFER_TYPE.LINK
          : REFER_TYPE.CODE,
        phoneNumber: registerPhoneData?.phoneNumber || '',
        firstName: '',
        lastName: '',
        userPin: registerPhoneData?.userPin || '',
        registerType: REGISTER_TYPE.NORMAL,
        utm: utm
          ? {
              url: utm.firstVisitUrl,
              utm_id: utm.utmId,
              utm_source: utm.utmSource,
              utm_medium: utm.utmMedium,
              utm_campaign: utm.utmCampaign,
              utm_content: utm.utmContent,
              utm_term: utm.utmTerm,
            }
          : undefined,
        registerBy: REGISTER_BY.PHONE,
      }
      const response = await callRegister(requestData)
      if (response.status === 'SUCCESS') {
        onRegisterSuccess(requestData)
      } else {
        stopLoading()
      }
    } catch (_error) {
      stopLoading()
      NiceModal.show(AlertContactModalV2, {
        header: 'Technical error',
        content: _error,
      })
      onError()
    }
  }

  const onNextStep = async (
    currentStep: number,
    _nextStep: number,
    currentData?: IStepperData,
  ) => {
    // call API
    setIsLoading(true)
    await refreshSystemStatus()

    if (currentData) {
      switch (currentStep) {
        case REGISTER_STEP.VERIFY_OTP:
          setRegisterPhoneData({
            phoneNumber: currentData.phoneNumber,
          })
          setRegister({
            ...register,
            phoneNumber: currentData.phoneNumber,
          })
          setStep(REGISTER_STEP.SETUP_PIN)
          break
        case REGISTER_STEP.SETUP_PIN:
          setRegisterPhoneData({
            ...registerPhoneData,
            userPin: currentData.pin,
          })
          // NOTE : for link phone number to existing line user
          setRegister({
            ...register,
            pin: currentData.pin,
          })
          setStep(REGISTER_STEP.USER_INFO)
          break
        case REGISTER_STEP.USER_INFO:
          // if (existingUser) {
          //   updateUser
          // }
          await registerWithPhoneNumber(
            currentData.nickName,
            currentData.avatar,
            currentData.referrer,
          )
          break
        default:
          break
      }
    }

    setIsLoading(false)
    return false
  }

  return (
    <motion.div key="motion-stepper" {...motionProps}>
      <StepperController
        title="สมัครสมาชิก"
        isLoading={isLoading}
        isShowBackButton={false}
        stepperDetails={stepTitleList}
        currentStep={step}
        data={data}
        onNextStep={onNextStep}
      >
        {children}
      </StepperController>
    </motion.div>
  )
}

export default RegisterWithPhone
