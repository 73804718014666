import { AxiosRequestConfig } from 'axios'
import { useRecoilValue } from 'recoil'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { authStateCommon } from '../recoils'
import { useAxios } from './useAxios'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

export type ChangePinResponse = BaseApiResponse<{
  uid: string
  game_token: string
  currency_code: string
  status: string
}>

export type ChangePinRequest = {
  oldPin: string
  newPin: string
}

export enum CHANGE_PIN_STATUS {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export type ChangePinResult = {
  Status: CHANGE_PIN_STATUS
  Message?: string
}

export type ChangePinHook = {
  callChangePin: (request: ChangePinRequest) => Promise<ChangePinResult>
}

export const useChangePin = (): ChangePinHook => {
  const { pigspinApiInstance } = useAxios()
  const auth = useRecoilValue(authStateCommon.authState)

  const callChangePin = async (
    request: ChangePinRequest,
  ): Promise<ChangePinResult> => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/pin',
      method: 'PUT',
      data: {
        user_code: auth.userUID,
        user_old_pin: request.oldPin,
        user_new_pin: request.newPin,
      },
    }
    const response = await fetchWithJSON<ChangePinResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    if (response.status === 'SUCCESS') {
      return {
        Status: CHANGE_PIN_STATUS.SUCCESS,
        Message: 'เปลี่ยน PIN เรียบร้อยแล้ว',
      }
    }
    return {
      Status: CHANGE_PIN_STATUS.ERROR,
    }
  }

  return {
    callChangePin,
  }
}
