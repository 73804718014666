import cx from 'classnames'
import { Collapse } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import useVIPSummary from '@pig-common/hooks/useVipSummary'
import { VIPRewardItem } from '@pig-common/components/vip'
import { vipTierMapper } from '@pig-common/types/Vip.type'
import styles from './index.module.scss'

const { Panel } = Collapse

export default function VIPRewards() {
  const { data: vipSummary } = useVIPSummary({ enabled: false })

  return (
    <Collapse
      className={styles.container}
      bordered={false}
      expandIcon={({ isActive }) => (
        <UpOutlined
          style={{
            transform: isActive ? 'rotate(180deg)' : undefined,
            transitionDuration: '0.24s',
          }}
        />
      )}
      expandIconPosition="start"
      accordion
    >
      {vipSummary?.vipMaster?.map((vipMaster, i) => (
        <Panel
          key={vipMaster?.tier ?? i}
          className={styles.panel}
          header={
            <div className={styles.title}>
              <div
                className={cx(
                  styles['panel-badge'],
                  styles[vipMaster?.tier?.toLowerCase() || 'none'],
                )}
              />
              {vipTierMapper[vipMaster?.tier || 'bronze']}
            </div>
          }
        >
          {vipMaster?.levels?.map((level, i) => (
            <VIPRewardItem
              key={level?.vipMasterUid ?? i}
              reward={vipMaster}
              level={level}
            />
          ))}
        </Panel>
      ))}
    </Collapse>
  )
}
