import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'

export type PhysicalRedemptionRewardDataProps = {
  index: number
  reward_uid: string
  title: string
  tickets: number
  category: string
  is_out_of_stock: boolean
  is_limited: boolean
  image_url: string[]
}

export type PhysicalRedemptionHistoryDataProps = {
  abc: any
}

export type PhysicalRedemptionData = {
  physical: PhysicalRedemptionRewardDataProps[]
  history: PhysicalRedemptionHistoryDataProps[]
}
export type PhysicalRedemptionRequest = {
  userCode: string
}

type PhysicalRedemptionDataResponse = BaseResponse & {
  status: string
  data: any
}

const getPhysicalRedemption = async (
  request: PhysicalRedemptionRequest,
): Promise<PhysicalRedemptionData[] | []> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${request.userCode}/shop/rewards/physical`,
      method: 'GET',
    }

    const result = await fetchWithJSON<PhysicalRedemptionDataResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    const { data } = result

    return data || []
  } catch (error) {
    return []
  }
}

export default getPhysicalRedemption
