import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { Progress } from 'antd'
import { Divider, Image, Section } from '@pig-frontend/uikit'
import useAchievementHighlight from '@pig-common/hooks/useAchievementHighlight'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons'
import HintBox from '@pig-common/components/HintButton/HintButton'
import { achievementImageUrl } from '@pig-common/constants/achievement'
import useAchievement from '@pig-common/hooks/useAchievement'
import find from 'lodash/find'
import useAchievementSetHighlight from '@pig-common/hooks/useAchievementSetHighlight'
import { IAchievementItemProps } from '@pig-common/types/Achievement.type'
import { useResponsiveState } from '@pig-common/hooks/useResponsive'
import handleShowModalInfo from './modal'
import styles from './index.module.scss'

export default function Achievement() {
  const { data: achievementHighlights = [], refetch: getAchievementHighlight } =
    useAchievementHighlight({})
  const { data: achievement } = useAchievement({})
  const [selectedRemoveUserInventoryUid, setSelectedRemoveUserInventoryUid] =
    useState<{ position: number; userInventoryUid?: string }>()
  const { isMD } = useResponsiveState()
  const {
    mutate: setHighlight,
    isSuccess: isSetHighlightSuccess,
    reset: resetSetHighlightState,
  } = useAchievementSetHighlight()

  /** Fill array to 3  */
  const filledHighlights = useMemo(() => {
    const targetLength = 3
    if (achievementHighlights?.length < targetLength) {
      const fillArray = new Array(
        targetLength - achievementHighlights?.length,
      ).fill({})
      return achievementHighlights?.concat(fillArray)
    } else if (achievementHighlights?.length > targetLength) {
      return achievementHighlights?.slice(0, targetLength)
    } else {
      return achievementHighlights
    }
  }, [achievementHighlights])

  const isHighlight = (uid?: string) => {
    return find(achievementHighlights, ['achievementUid', uid])
  }

  const handleRemoveHighlight = (
    position: number,
    userInventoryUid?: string,
  ) => {
    setSelectedRemoveUserInventoryUid({ position, userInventoryUid })
  }

  const handleSetHighlight = (highlightItem: IAchievementItemProps) => {
    setHighlight({
      position: selectedRemoveUserInventoryUid?.position,
      userInventoryUid: highlightItem?.userInventoryUid,
    })
  }

  useEffect(() => {
    if (isSetHighlightSuccess) {
      getAchievementHighlight()
      resetSetHighlightState()
    }
    return () => {
      resetSetHighlightState()
    }
  }, [isSetHighlightSuccess])

  useEffect(() => {
    setSelectedRemoveUserInventoryUid(undefined)
  }, [achievementHighlights])

  return (
    <Section
      className={styles.container}
      margin={false}
      sectionId="achievement"
    >
      <Divider margin={30} />
      <div className={styles.header}>
        <h4 className={styles.title}>ไฮไลท์</h4>
        <HintBox message="สามารถแก้ไขไฮไลท์ได้เมื่อมีมากกว่า 3 ความสำเร็จขึ้นไป" />
      </div>
      <div className={styles.achievement}>
        {filledHighlights?.map((highlight, i) => (
          <div
            key={highlight?.achievementUid}
            className={cx({
              [styles.unfocused]:
                selectedRemoveUserInventoryUid &&
                selectedRemoveUserInventoryUid?.userInventoryUid !==
                  highlight?.userInventoryUid,
            })}
          >
            {(achievement?.completed?.length ?? 0) > 3 &&
              achievementHighlights?.length > 2 &&
              highlight?.achievementUid &&
              selectedRemoveUserInventoryUid?.userInventoryUid !==
                highlight?.userInventoryUid &&
              !selectedRemoveUserInventoryUid && (
                <CloseCircleFilled
                  className={styles['close-icon']}
                  role="button"
                  onClick={() =>
                    handleRemoveHighlight(i + 1, highlight?.userInventoryUid)
                  }
                />
              )}
            {selectedRemoveUserInventoryUid?.userInventoryUid !==
            highlight?.userInventoryUid ? (
              <Image
                src={achievementImageUrl(highlight?.achievementUid)}
                alt={highlight?.name ?? 'achievement'}
                width={isMD ? 120 : 100}
                height={isMD ? 120 : 100}
                className={cx([styles.img, styles.completed])}
              />
            ) : (
              <>
                {highlight?.achievementUid ? (
                  <div className={styles['selected-highlight-container']}>
                    <div className={styles['selected-highlight']}>
                      <PlusCircleOutlined />
                    </div>
                  </div>
                ) : (
                  <div
                    key={highlight?.achievementUid ?? i}
                    className={styles.empty}
                  >
                    <QuestionOutlined className={styles.icon} />
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>

      <Divider margin={30} />
      <div className={styles.header}>
        <h4 className={styles.title}>
          สำเร็จแล้ว ({achievement?.completed?.length ?? '0'})
        </h4>
      </div>
      <div className={styles.achievement}>
        {achievement?.completed?.map((item, i) => (
          <div
            key={item?.achievementUid}
            aria-hidden
            role="button"
            onClick={() => {
              if (
                selectedRemoveUserInventoryUid?.userInventoryUid &&
                selectedRemoveUserInventoryUid?.userInventoryUid !==
                  item?.userInventoryUid
              ) {
                handleSetHighlight(item)
              } else {
                handleShowModalInfo(item)
              }
            }}
          >
            {selectedRemoveUserInventoryUid?.userInventoryUid &&
              selectedRemoveUserInventoryUid?.userInventoryUid !==
                item?.userInventoryUid && (
                <CheckCircleFilled
                  className={cx([styles['is-highlight'], styles.select])}
                />
              )}
            {isHighlight(item?.achievementUid) &&
              selectedRemoveUserInventoryUid?.userInventoryUid !==
                item?.userInventoryUid && (
                <CheckCircleFilled className={cx([styles['is-highlight']])} />
              )}
            <Image
              src={achievementImageUrl(item?.achievementUid)}
              alt={item?.name ?? 'achievement completed'}
              width={isMD ? 120 : 100}
              height={isMD ? 120 : 100}
              className={cx([styles.img, styles.completed])}
            />
          </div>
        ))}
      </div>

      <Divider margin={30} />
      <div className={styles.header}>
        <h4 className={styles.title}>
          ยังไม่สำเร็จ ({achievement?.incomplete?.length ?? '0'})
        </h4>
      </div>
      <div className={styles.achievement}>
        {achievement?.incomplete?.map((item) => (
          <div
            key={item?.achievementUid}
            role="button"
            aria-hidden
            onClick={() => handleShowModalInfo(item)}
          >
            <Progress
              type="circle"
              percent={item?.percentProgress ?? 0}
              strokeColor={styles.colorYellow200}
              trailColor="transparent"
              className={styles.progress}
              format={() => (
                <Image
                  src={achievementImageUrl(item?.achievementUid)}
                  alt={item?.name ?? 'achievement incompleted'}
                  width={isMD ? 120 : 100}
                  height={isMD ? 120 : 100}
                  className={cx(styles.img, {
                    [styles.progress]: !item?.percentProgress,
                  })}
                />
              )}
            />
          </div>
        ))}
      </div>
    </Section>
  )
}
