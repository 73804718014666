import React, { useEffect, useState } from 'react'
import { parse2Digit } from '@pig-common/utils/getPerfect2Digit'

export type TimeData = {
  day?: number
  hour?: number
  minute?: number
  second?: number
}
export enum TIMER_TYPE {
  DEFAULT = 'default',
  COMPACT = 'compact',
  COMPACT_DATE = 'compact_date',
  SHORT_DATE = 'short_date',
}
type TimerType = {
  time: TimeData
  warningHour?: number
  variants?: TIMER_TYPE
  onTimeOut?: () => void
  style?: React.CSSProperties
  className?: string
}

export const maptoTimeData = (second: number): TimeData => {
  return {
    day: Math.floor(second / 60 / 60 / 24),
    hour: Math.floor((second / 60 / 60) % 24),
    minute: Math.floor((second / 60) % 60),
    second: Math.floor(second % 60),
  }
}

const Timer = ({
  time,
  warningHour,
  variants = TIMER_TYPE.DEFAULT,
  onTimeOut,
  style,
  className,
}: TimerType) => {
  const [getTime, setTime] = useState<TimeData>(time)
  const [getDay, setDay] = useState<number>(time?.day || 0)
  const [getHour, setHour] = useState<number>(time?.hour || 0)
  const [getMin, setMin] = useState<number>(time?.minute || 0)
  const [getSec, setSec] = useState<number>(time?.second || 0)
  let worker: any = null

  const countDownTime = (timeDefault: TimeData) => {
    const { day = 0, hour = 0, minute = 0, second = 0 } = timeDefault
    let newTime: TimeData

    if (day && day > 0 && hour === 0 && minute === 0 && second === 0) {
      newTime = {
        day: day - 1,
        hour: 23,
        minute: 59,
        second: 59,
      }
    } else if (hour && hour > 0 && minute === 0 && second === 0) {
      newTime = {
        day,
        hour: hour - 1,
        minute: 59,
        second: 59,
      }
    } else if (minute && minute > 0 && second === 0) {
      newTime = {
        day,
        hour,
        minute: minute - 1,
        second: 59,
      }
    } else if (second && second >= 0) {
      newTime = {
        day,
        hour,
        minute,
        second: second - 1,
      }
    } else {
      newTime = {
        day: 0,
        hour: 0,
        minute: 0,
        second: 0,
      }
    }
    if (!worker) {
      worker = setInterval(() => {
        setTime(newTime)
        clearInterval(worker)
      }, 1000)
    }
    return newTime
  }

  const isWarning = getHour && warningHour && getHour < warningHour

  useEffect(() => {
    const isTimeOut = getDay <= 0 && getHour <= 0 && getMin <= 0 && getSec <= 0
    setDay(getTime.day || 0)
    setHour(getTime.hour || 0)
    setMin(getTime.minute || 0)
    setSec(getTime.second || 0)
    if (isTimeOut && onTimeOut) {
      onTimeOut()
    } else {
      countDownTime(getTime)
    }
  }, [getTime])

  const renderCompact = (): JSX.Element => {
    return (
      <>
        <span>{parse2Digit(getHour) || '00'}</span>:
        <span>{parse2Digit(getMin) || '00'}</span>:
        <span>{parse2Digit(getSec) || '00'}</span>
      </>
    )
  }

  const renderCompactDate = (): JSX.Element => {
    return (
      <div className={TIMER_TYPE.COMPACT_DATE}>
        <span style={{ padding: '0 5px' }}>{`${parse2Digit(getDay)} วัน`}</span>
        {renderCompact()}
      </div>
    )
  }

  return (
    <div className={className}>
      {variants === TIMER_TYPE.COMPACT && renderCompact()}
      {variants === TIMER_TYPE.COMPACT_DATE && renderCompactDate()}
      {variants === TIMER_TYPE.SHORT_DATE && (
        <div
          className={`ps-section-timer ${
            isWarning ? 'ps-section-timer--warning' : ''
          }`}
          style={style}
        >
          <i className="ps-icon-clock ps-section-timer__timer-icon" />
          <span className="ps-section-timer__text">
            {getDay > 0 && (
              <>
                <span data-testid="dailyquests-timer-day">
                  {parse2Digit(getDay)}
                </span>
                day
              </>
            )}
            &nbsp; &nbsp;
            {renderCompact()}
          </span>
        </div>
      )}
      {variants === TIMER_TYPE.DEFAULT && (
        <div
          className={`ps-section-timer ${
            isWarning ? 'ps-section-timer--warning' : ''
          }`}
          style={style}
        >
          <i className="ps-icon-clock ps-section-timer__timer-icon" />
          <span className="ps-section-timer__text">
            {getDay > 0 && (
              <>
                <span data-testid="dailyquests-timer-day">
                  {parse2Digit(getDay)}
                </span>
                day
              </>
            )}
            <span data-testid="dailyquests-timer-hour">
              {parse2Digit(getHour)}
            </span>
            hour
            <span data-testid="dailyquests-timer-min">
              {/* {time.minute} */}
              {/* {parse2Digit(getMin)} */}
              {parse2Digit(getMin)}
            </span>
            min
          </span>
        </div>
      )}
    </div>
  )
}

export default Timer
