import React from 'react'
import Image from 'next/image'
import { CLOUD_STORAGE_CDN } from '@pig-common/models/buildtime-constant'
import useWindowSize from '@pig-common/utils/useWindowSize'

export enum BANNER_ON_PAGE {
  SHOP = 'pig-shop-banner-animated',
  QUEST = 'pig-quest-banner-animated',
  PROMOTION = 'promotion-banner',
}
const AnimatedBanner = ({
  content,
  page,
}: {
  content?: JSX.Element
  page: BANNER_ON_PAGE
}) => {
  const { mq } = useWindowSize()
  const srcImage = () => {
    switch (mq) {
      case 'xs':
      case 'sm':
        return `${CLOUD_STORAGE_CDN}/pigspin-assets/promotions/banner/promo-banner-xs-sm.webp`
      default:
        return `${CLOUD_STORAGE_CDN}/pigspin-assets/promotions/banner/promo-banner-md-lg-xl.webp`
    }
  }
  switch (page) {
    case BANNER_ON_PAGE.QUEST:
      return (
        <div className="ps-animated-banner ps-quests-banner">
          {content}
          <div className="ps-quests-banner__stage">
            <div className="ps-quests-banner__props ps-quests-banner__space" />
            <div className="ps-quests-banner__props ps-quests-banner__ship" />
            <div className="ps-quests-banner__props ps-quests-banner__screen ps-quests-banner__screen--1" />
            <div className="ps-quests-banner__props ps-quests-banner__screen ps-quests-banner__screen--2" />
            <div className="ps-quests-banner__props ps-quests-banner__screen ps-quests-banner__screen--3" />
            <div className="ps-quests-banner__props ps-quests-banner__screen ps-quests-banner__screen--4" />
            <div className="ps-quests-banner__props ps-quests-banner__screen ps-quests-banner__screen--5" />
            <div className="ps-quests-banner__props ps-quests-banner__pig" />
          </div>
        </div>
      )
    case BANNER_ON_PAGE.PROMOTION:
      return (
        <div className="ps-animated-banner ps-promotion-banner">
          <div className="ps-promotion-banner__stage">
            <Image
              src={srcImage()}
              layout="fill"
              placeholder="blur"
              blurDataURL={srcImage()}
              className="ps-promotion-banner__image"
              alt="promotion-banner"
            />
            <div className="ps-promotion-banner__info">
              <div className="ps-promotion-banner__title">
                โปรโมชั่น
                <div className="ps-promotion-banner__sub-title">
                  PIGSPIN จัดเต็ม!
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    case BANNER_ON_PAGE.SHOP:
      return (
        <div className="ps-animated-banner ps-shop-banner">
          {content}
          <div className="ps-shop-banner__stage">
            <div className="ps-shop-banner__props ps-shop-banner__space" />
            <div className="ps-shop-banner__props ps-shop-banner__lighting" />
            <div className="ps-shop-banner__props ps-shop-banner__ship" />
            <div className="ps-shop-banner__props ps-shop-banner__shop-neon" />
            <div className="ps-shop-banner__highlight" />
          </div>
        </div>
      )
    default:
      return <>WHAT</>
  }
}

export default AnimatedBanner
