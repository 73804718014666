import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import { useSetRecoilState } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import {
  InputDigit,
  InputDigitHandles,
} from '@pig-common/components/InputDigit'
import { LOGIN_STATUS, LoginResponse } from '@pig-common/services/login'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'

import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import { sleep } from '@pig-common/utils/time'
import { useLogin } from '@pig-common/hooks/useLogin'
import { CloseOutlined } from '@ant-design/icons'
import {
  ChangePinResult,
  CHANGE_PIN_STATUS,
  useChangePin,
} from '@pig-common/hooks/useChangePin'

export enum CHANGE_PIN_STEP {
  VERIFY_CURRENT_PIN,
  SET_NEW_PIN,
  CONFIRM_NEW_PIN,
}

export type ChangePinProps = {
  phoneNumber?: string
  onClose: () => void
  onVerifyCurrentSuccess: (pin: string) => void
  initialStep: CHANGE_PIN_STEP
  initialCurrentPin: string
  isBlockedPin: () => void
  isBannedUser: () => void
  isConfirmPinDoesNotMatch: () => void
  isSomethingWentWrong: () => void
  isChangePinSuccess: () => void
}

const ChangePin = ({
  phoneNumber = '',
  onClose,
  onVerifyCurrentSuccess,
  initialStep,
  initialCurrentPin,
  isBlockedPin,
  isBannedUser,
  isConfirmPinDoesNotMatch,
  isSomethingWentWrong,
  isChangePinSuccess,
}: ChangePinProps): JSX.Element => {
  const { callLogin, mutateLogin } = useLogin()
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
  const [step, setStep] = useState<CHANGE_PIN_STEP>(initialStep)
  const [currentPin, setCurrentPin] = useState(initialCurrentPin)
  const [newPin, setNewPin] = useState('')
  const [disabled, setDisabled] = useState(false)
  const digitInputRef = useRef<InputDigitHandles>(null)
  const [, , removeCookies] = useCookies<string>([
    COOKIES_KEY.CFID,
    COOKIES_KEY.UID,
    COOKIES_KEY.CUSTOMER_CODE,
  ])
  const setAuth = useSetRecoilState(authStateCommon.authState)
  const changePinHook = useChangePin()

  const closeHandler = () => {
    setStep(CHANGE_PIN_STEP.VERIFY_CURRENT_PIN)
    onClose()
  }

  const removeSession = () => {
    removeCookies(COOKIES_KEY.CFID)
    removeCookies(COOKIES_KEY.UID)
    removeCookies(COOKIES_KEY.CUSTOMER_CODE)
    setAuth({})
  }

  const verifyChangePinStatus = (response: ChangePinResult) => {
    const status = response.Status

    switch (status) {
      case CHANGE_PIN_STATUS.ERROR:
        isSomethingWentWrong()
        break
      case CHANGE_PIN_STATUS.SUCCESS:
        mutateLogin.mutate({
          phoneNumber,
          pin: newPin,
        })
        setCurrentPin('')
        isChangePinSuccess()
        digitInputRef.current?.error('')
        break
      default:
        setCurrentPin('')
        isChangePinSuccess()
        digitInputRef.current?.error('')
    }
  }

  const verifyUserStatus = async (response: LoginResponse, pin: string) => {
    const status = response.Status

    switch (status) {
      case LOGIN_STATUS.SUCCESS:
        switch (step) {
          case CHANGE_PIN_STEP.CONFIRM_NEW_PIN:
            try {
              const changePinResponse = await changePinHook.callChangePin({
                oldPin: currentPin,
                newPin,
              })
              if (changePinResponse) verifyChangePinStatus(changePinResponse)
            } catch (_error) {
              digitInputRef.current?.error('')
              isSomethingWentWrong()
            }
            break
          case CHANGE_PIN_STEP.VERIFY_CURRENT_PIN:
          default:
            setStep(CHANGE_PIN_STEP.SET_NEW_PIN)
            setCurrentPin(pin)
            onVerifyCurrentSuccess(pin)
            setTimeout(() => {
              digitInputRef.current?.clearInput()
            }, 10)
            break
        }
        break
      case LOGIN_STATUS.WRONG_PASSWORD:
        digitInputRef.current?.error('รหัส PIN ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
        break
      case LOGIN_STATUS.BLOCK:
      case LOGIN_STATUS.INACTIVE:
        removeSession()
        isBannedUser()
        digitInputRef.current?.error('')
        break
      case LOGIN_STATUS.BLOCK_PIN:
        removeSession()
        isBlockedPin()
        digitInputRef.current?.error('')
        break
      case LOGIN_STATUS.ERROR:
      default:
        isSomethingWentWrong()
        digitInputRef.current?.error('')
    }
  }

  const verifyCurrentPinHandler = async (pin: string) => {
    setDisabled(true)
    refreshSystemStatus()
    const response = await callLogin({
      phoneNumber,
      pin,
    })
    if (response.Status !== LOGIN_STATUS.CLIENT_EXCEPTION) {
      if (response) await verifyUserStatus(response, pin)
    } else {
      digitInputRef.current?.error('')
      isSomethingWentWrong()
    }
    setDisabled(false)
  }

  const verifyNewPinHandler = (pin: string) => {
    setDisabled(true)
    if (pin === currentPin) {
      digitInputRef.current?.error(
        'ไม่สามารถใช้ PIN เดิมได้ กรุณาลองใหม่อีกครั้ง',
      )
    } else {
      setNewPin(pin)
      setStep(CHANGE_PIN_STEP.CONFIRM_NEW_PIN)
      setTimeout(() => {
        digitInputRef.current?.clearInput()
      }, 10)
    }
    setDisabled(false)
  }

  const verifyConfirmNewPin = async (pin: string) => {
    setDisabled(true)
    refreshSystemStatus()
    if (pin !== newPin) {
      setStep(CHANGE_PIN_STEP.SET_NEW_PIN)
      isConfirmPinDoesNotMatch()
    } else {
      const response = await callLogin({
        phoneNumber,
        pin: currentPin,
      })
      if (response.Status !== LOGIN_STATUS.CLIENT_EXCEPTION) {
        if (response) await verifyUserStatus(response, currentPin)
      } else {
        digitInputRef.current?.error('')
        isSomethingWentWrong()
      }
    }
    setDisabled(false)
  }

  const inputPinHandler = async (pin: string) => {
    // NOTE : Prevent auto scroll bug
    await sleep(0.1)
    switch (step) {
      case CHANGE_PIN_STEP.SET_NEW_PIN:
        await verifyNewPinHandler(pin)
        break
      case CHANGE_PIN_STEP.CONFIRM_NEW_PIN:
        await verifyConfirmNewPin(pin)
        break
      case CHANGE_PIN_STEP.VERIFY_CURRENT_PIN:
      default:
        await verifyCurrentPinHandler(pin)
        break
    }
  }

  const stepModalText = () => {
    switch (step) {
      case CHANGE_PIN_STEP.SET_NEW_PIN:
        return {
          title: 'กรอกรหัส PIN ใหม่ของคุณ',
          subTitle: 'กรอกตัวเลข 6 หลักที่คุณต้องการ',
        }
      case CHANGE_PIN_STEP.CONFIRM_NEW_PIN:
        return {
          title: 'ยืนยันรหัส PIN อีกครั้ง',
          subTitle: 'กรอกรหัส PIN ให้ตรงกัน',
        }
      case CHANGE_PIN_STEP.VERIFY_CURRENT_PIN:
      default:
        return {
          title: 'กรอกรหัส PIN ของคุณ',
          subTitle: 'กรอกรหัส PIN 6 หลัก',
        }
    }
  }

  return (
    <div className={classNames('ps-pin')}>
      <div className="ps-pin__header">
        <div className="row">
          <div className="row ps-pin__title">
            <span>{stepModalText().title}</span>
          </div>
          <div className="row ps-pin__subtitle">
            <span>{stepModalText().subTitle}</span>
          </div>
        </div>
        <CloseOutlined
          className="ps-icon-close ps-pin__icon-close"
          onClick={closeHandler}
          aria-hidden
        />
      </div>
      <div>
        <InputDigit
          onComplete={inputPinHandler}
          disabled={disabled}
          ref={digitInputRef}
        />
      </div>
    </div>
  )
}

export default ChangePin
