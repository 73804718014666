import React, { useEffect, useState } from 'react'
import Router from 'next/router'
import { useRecoilValue } from 'recoil'
import { featureToggleCommon } from '@pig-common/recoils'
import DailyQuestHeader from '@pig-common/components/DailyQuests/Header'
import { QuestList } from '@pig-common/components/DailyQuests/Index'
import { FEATURE_TOGGLE } from '@pig-common/recoils/modules/feature-toggle'
import { claimBigWin } from '@pig-common/services/bigWin'
import ModalSuccession from '@pig-common/components/DailyQuests/modalSuccession'
import TutorialCarousel from '@pig-common/components/Carousel/TutorialCarousel'
import { numberWithCommas } from '@pig-common/utils/number'
import SectionHeader from '@pig-common/components/DailyQuests/SectionHeader'
import { toggleLockScroll } from '@pig-common/services/lockScroll/toggleLockScroll'
import { BigwinDataType } from '@pig-common/hooks/useQuest'
import {
  DailyQuestsProps,
  RESPONSE_STATE,
  QUEST_LIST_TYPE,
  useLoyaltyProgram,
} from '@pig-common/hooks/useLoyaltyProgram'
import AnimatedBanner, {
  BANNER_ON_PAGE,
} from '@pig-common/components/AnimatedBanner/AnimatedBanner'
import { useAuth } from '@pig-common/hooks/useAuth'
import { useQuest } from '@pig-common/hooks/useQuest'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import useCreateReadFlag from '@pig-common/hooks/useCreateReadFlag'

export default function DailyQuests({
  dailyQuestData,
  brandsData,
}: DailyQuestsProps) {
  const { callDailyQuest } = useLoyaltyProgram()
  const { mutate: createDailyQuestReadFlag } = useCreateReadFlag()
  const { questsBigWin, fetchBigWin, fetchBigWinMore, loadingBigwin } =
    useQuest({ pagination: { page: 1, size: 10 } })
  const isAuthorize = useAuth()
  const featureToggle = useRecoilValue(featureToggleCommon.featureToggleState)
  const [openModal, setOpenModal] = useState(false)
  const [shouldShowClaimedSuccessModal, setShouldShowClaimedSuccessModal] =
    useState(false)
  const [messageError, setMessageError] = useState('')
  const [errorCode, setErrorCode] = useState<any>(0)
  const [getModaltickets, setModalTickets] = useState(0)
  const [getTotalTickets, setTotalTickets] = useState<string>('0')
  const [isTutorialActive, setTutorialActive] = useState<boolean>(false)

  useEffect(() => {
    createDailyQuestReadFlag({ flagStatus: 1, fieldName: 'DAILY_QUESTS' })
  }, [])

  const openTutorial = () => {
    setTutorialActive(true)
  }

  const fetchDailyQuest = async () => {
    const response = await callDailyQuest()
    if (response) {
      setTotalTickets(numberWithCommas(response?.total_tickets || 0))
    }
  }

  const claimQuestTicketContent = (
    <div className="ps-quest-modal">
      <div data-testid="dailyquests-modal__congratulations-label">
        ยินดีด้วย! คุณได้รับ
      </div>
      <div
        data-testid="dailyquests-modal__claim-tickets-label"
        className="ps-quest-modal__content"
      >
        {`${numberWithCommas(getModaltickets)} Tickets!`}
      </div>
    </div>
  )

  const claimBigWinHandler = async (
    claimQuestProps: Partial<BigwinDataType>,
  ) => {
    try {
      const response = await claimBigWin({
        bigwinUid: claimQuestProps.uid,
      })
      setOpenModal(true)
      if (response?.status === RESPONSE_STATE.SUCCESS) {
        setShouldShowClaimedSuccessModal(true)
        setModalTickets(response.data.ticket)
      } else {
        setShouldShowClaimedSuccessModal(false)
        setMessageError(response?.data?.message || 'เกิดข้อผิดพลาด')
        setErrorCode(response?.code || 0)
        setModalTickets(0)
      }
    } finally {
      fetchBigWin()
    }
  }

  const closeModalHandler = async () => {
    fetchDailyQuest()
    setOpenModal(false)
    if (document.body.classList.contains('lock-screen')) {
      toggleLockScroll(false)
    }
  }

  useEffect(() => {
    isTutorialActive
      ? (document.getElementsByTagName('body')[0].style.overflowY = 'hidden')
      : (document.getElementsByTagName('body')[0].style.overflowY = 'auto')
  }, [isTutorialActive])
  useEffect(() => {
    fetchBigWin()
    fetchDailyQuest()
    if (featureToggle.dailyQuest === FEATURE_TOGGLE.OFF) {
      if (isAuthorize) {
        Router.push('/lobby')
      } else {
        Router.push('/')
      }
    }
  }, [])

  return (
    <section className="container ps-dailyquest">
      <ModalSuccession
        active={shouldShowClaimedSuccessModal && openModal}
        errorCode={errorCode}
        closeModalHandler={closeModalHandler}
        claimQuestTicketContent={claimQuestTicketContent}
        messageError={messageError}
      />

      <AnimatedBanner
        content={<DailyQuestHeader total_tickets={getTotalTickets} />}
        page={BANNER_ON_PAGE.QUEST}
      />

      {isTutorialActive && (
        <TutorialCarousel
          triggerActive={isTutorialActive}
          onClose={() => {
            setTutorialActive(false)
          }}
        />
      )}

      <QuestList
        id="bigwin-quest"
        type={QUEST_LIST_TYPE.BIGWIN_QUEST}
        data={questsBigWin}
        showProgressBar={false}
        isUploadButton
        isLoading={questsBigWin === undefined || loadingBigwin}
        isNoMore={false}
        buttonText="อัพโหลดรูปภาพ"
        onClick={claimBigWinHandler}
        openTutorial={openTutorial}
        placeHolderNumber={1}
        fetchBigWin={fetchBigWin}
        loadMore={fetchBigWinMore}
        header={
          <SectionHeader
            id="bigwin-quest-header"
            header="ภารกิจแชมป์เปี้ยน"
            linkTo={() => Router.push(pageLinkCommon.champion)}
            linkText="แชมป์เปี้ยน"
          />
        }
      />
    </section>
  )
}
