import React, { CSSProperties } from 'react'

const ARROW_ORIGIN_OFFSET = 9
const DEFAULT_ARROW_OFFSET = 25
const defaultArrowStyle: CSSProperties = {
  bottom: -ARROW_ORIGIN_OFFSET,
  left: '8%',
  transform: 'rotate(135deg) skew(-10deg, -10deg)',
}

export enum INFO_BOX_VARIANT {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

export type TextJustify = 'left' | 'center' | 'right'
export type Place = 'top' | 'right' | 'bottom' | 'left'

export type InformationBoxProps = {
  id?: string
  prependContent?: string | React.ReactNode
  title?: string
  content: string | React.ReactNode
  variant?: INFO_BOX_VARIANT
  width?: string | number
  titleTextJustify?: TextJustify
  contentTextJustify?: TextJustify
  isShowArrowIndicator?: boolean
  arrowOffset?: number
  arrowDirection?: Place
}

const InformationBox = ({
  id = 'information-box',
  prependContent,
  title,
  content,
  width,
  variant = INFO_BOX_VARIANT.INFO,
  titleTextJustify = 'left',
  contentTextJustify = 'left',
  arrowDirection,
  arrowOffset = 20,
  isShowArrowIndicator,
}: InformationBoxProps) => {
  const getArrowStyle = (): CSSProperties => {
    let offset: number
    if (arrowOffset >= 0 && arrowOffset <= 100) {
      offset = arrowOffset
    } else {
      offset = DEFAULT_ARROW_OFFSET
    }
    switch (arrowDirection) {
      case 'top':
        return {
          ...defaultArrowStyle,
          left: `${offset}%`,
        }
      case 'right':
        return {
          left: `-${ARROW_ORIGIN_OFFSET}px`,
          top: `${offset}%`,
          transform: 'rotate(-135deg) skew(-10deg, -10deg)',
        }
      case 'bottom':
        return {
          top: -ARROW_ORIGIN_OFFSET,
          left: `${offset}%`,
          transform: 'rotate(-45deg) skew(-10deg, -10deg)',
        }
      case 'left':
        return {
          left: `${107.25 - ARROW_ORIGIN_OFFSET}%`,
          top: `${offset}%`,
          transform: 'rotate(45deg) skew(-10deg, -10deg)',
        }
      default:
        return defaultArrowStyle
    }
  }
  const arrowStyle = React.useMemo(getArrowStyle, [arrowDirection, arrowOffset])

  return (
    <div
      id={id}
      data-testid={id}
      className={`ps-information-box ps-information-box--${variant}`}
      style={{ width }}
    >
      {isShowArrowIndicator && (
        <div
          style={arrowStyle}
          className={`ps-information-box__arrow ps-information-box__arrow--${variant}`}
        />
      )}
      {prependContent && (
        <span className="ps-information-box__prepend">{prependContent}</span>
      )}
      <div className="ps-information-box__content">
        {title && (
          <h6
            className={`ps-information-box__title ps-information-box__title--${titleTextJustify}`}
          >
            {title}
          </h6>
        )}
        <div
          className={`ps-information-box__detail ps-information-box__detail--${contentTextJustify}`}
        >
          {content}
        </div>
      </div>
    </div>
  )
}

export default InformationBox
