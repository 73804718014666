import React from 'react'
import {
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from '@tanstack/react-query'
import dayjs, { Dayjs } from 'dayjs'
import { useRecoilValue } from 'recoil'
import { CashTransactionHistoryGroup } from '@pig-common/components/TransactionHistorySection'
import { buildCashTransactionGroupList } from '@pig-common/components/TransactionHistorySection/TransactionHistorySectionBuilder'
import { useAxios } from './useAxios'
import { CallWalletHistoryParams, useWalletHistory } from './useWalletHistory'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { WITHDRAW_TYPE } from './useWithdraw'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { authStateCommon } from '../recoils'
import { numberWithoutCommas } from '@pig-common/utils/number'
import { forceDateToISO, THAI_SHORT_MONTHS } from '@pig-common/utils/datetime'
import { useResponsiveState } from './useResponsive'

export enum TABS {
  INVITE_LINK = 'INVITE_LINK',
  INCOME = 'INCOME',
  NONE = 'NONE',
}

export enum INCOME_TABS {
  OVERVIEW = 'OVERVIEW',
  DONWLINE = 'DONWLINE',
  HISTORY = 'HISTORY',
}

export enum INCOME_TYPE {
  KYC = 'KYC',
  FIRST_DEPOSIT = 'FIRST_DEPOSIT',
  DOWNLINE_LOSSING = 'DOWNLINE_LOSSING',
}

export type IncomeItem = {
  id: string
  downlineCode: string
  downlineName: string
  incomeDate: Date
  amount: number
  bonus?: number
}

export enum CustomerStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  BLOCKED = 'BLOCKED',
}

export enum AFFILIATE_TYPE {
  FIRST_DEPOSIT = 'FIRST_DEPOSIT',
  KYC = 'VERIFY_KYC',
  LOSSING = 'REACH_TARGET',
  WITHDRAW = 'WITHDRAW',
  NONE = 'NONE',
}

export enum AFFILIATE_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export type AffiliateTransactionItem = {
  uid: string
  updated_at: Date
  created_at: Date
  affiliate_user_balance_user_uid: string
  transaction_type: 'DEBIT' | 'CREDIT'
  affiliate_type: AFFILIATE_TYPE
  remaining_balance?: string
  turnover_amount?: string
  turnover_success_amount?: string
  income_amount?: string
  amount?: string
  bonus?: string
  status: AFFILIATE_STATUS
  downline_info: {
    created_at: string
    user_nickname: string
    user_uid: string
    display_name?: string // TODO : missing field
  }
}

export type AffiliateTransactionData = {
  results: AffiliateTransactionItem[]
}

export type AffiliateUserBalance = Partial<{
  uid: string
  updated_at: string
  user_uid: string
  created_at: string
  deleted_at: Date
  total_first_deposit: number | null
  total_reach_target: number | null
  balance: number | null
  total_kyc_verified: number | null
  total_withdraw: number | null
}>

export type ApiAffiliateCustomer = {
  uid: string
  display_name: string
  player_username: string
  updated_at: Date
  created_at: string
  downline_user_uid: string
  downline_info: {
    summary_income: number
    user_nickname: string
    user_uid: string
  }
  ref_by: 'LINK' | 'CODE'
  is_expired: boolean
  expired_at: Date
}

export type MonthlyDataItem = {
  first_deposit: number
  kyc_verified: number
  month: string
  reach_target: number
}

export type BarData = {
  kycAffiliate: number
  firstDepositAffiliate: number
  downlineLossing: number
  total: number
  monthLabel: string
  monthYearLabel: string
}

export type MonthlyGraphData = {
  totalKycAffiliate: number
  totalDownlineLossing: number
  totalFirstDepositAffiliate: number
  graph: BarData[]
}

export type AffiliateState = {
  downlineItems: IncomeItem[]
  overviewDownlineList: IncomeItem[]
  historyGroups: CashTransactionHistoryGroup
  cash: number
  isFetchingCash: boolean
  callCash: () => void
  getAffiliateHistory: (params: CallWalletHistoryParams) => void
  callAffiliateTransaction: (
    options?: RefetchOptions & RefetchQueryFilters<any>,
  ) => void
  callAffiliateMonthlyTransaction: (
    options?: RefetchOptions & RefetchQueryFilters<any>,
  ) => void
  callOrgChart: () => void
  cashUpdatedAt: Date
  totalWithdraw: number
  isDownlineItemLoading: boolean
  isAffilateTransactionFetching: boolean
  isAffilateMonthlyTransactionFetching: boolean
  setIsDownlineItemLoading: (_isLoading: boolean) => void
  setTextSearch: (textSearch: string) => void
  overviewDate: Dayjs
  setOverviewDate: (date: Dayjs) => void
  overview: MonthlyGraphData & {
    isEmpty: boolean
    isOverviewLoading: boolean
    setIsOverviewLoading: (_isLoading: boolean) => void
  }
}

export type AffiliateSearchForm = {
  historyFrom: Dayjs
  historyTo: Dayjs
  overviewDate: Dayjs
  textSearch: string
}

const mapCustomer = (customer: ApiAffiliateCustomer): IncomeItem => {
  return {
    id: customer.uid,
    downlineCode: customer.player_username,
    downlineName: customer.display_name,
    incomeDate: customer.created_at
      ? forceDateToISO(customer.created_at)
      : new Date(),
    amount: customer.downline_info ? customer.downline_info.summary_income : 0,
  }
}

const mapToIncomeItem = (item: AffiliateTransactionItem): IncomeItem => {
  return {
    id: item.uid,
    downlineCode: item.downline_info.user_nickname || '',
    downlineName: item.downline_info.display_name || '',
    incomeDate:
      item.downline_info && item.downline_info.created_at
        ? forceDateToISO(item.downline_info.created_at)
        : new Date(),
    amount: numberWithoutCommas(item.income_amount || 0),
    bonus: numberWithoutCommas(item.bonus || 0),
  }
}

const aggregateToMonthly = (items: IncomeItem[]): IncomeItem[] => {
  const aggregateData: IncomeItem[] = items.reduce((acc, current) => {
    const existingUser: IncomeItem | undefined = acc.find(
      (row: IncomeItem) => row.downlineCode === current.downlineCode,
    )
    if (existingUser) {
      return [
        ...acc.filter(
          (row: IncomeItem) =>
            row.downlineCode !== existingUser.downlineCode && !row.bonus,
        ),
        {
          ...current,
          amount: existingUser.amount + current.amount,
          bonus: (existingUser.bonus || 0) + (current.bonus || 0),
          incomeDate: dayjs(existingUser.incomeDate).isAfter(
            dayjs(current.incomeDate),
          )
            ? dayjs(existingUser.incomeDate).toDate()
            : dayjs(current.incomeDate).toDate(),
        },
      ]
    }
    return [...acc, current]
  }, [] as IncomeItem[])
  return aggregateData
}

const buildGraphData = (
  data: MonthlyDataItem[],
  selectedMonth: Dayjs,
): MonthlyGraphData => {
  const result = data
    .slice()
    .reverse()
    .reduce(
      (acc, current) => {
        const [year, month] = current.month.split('-')
        const isSelectingMonth =
          numberWithoutCommas(year) === dayjs(selectedMonth).year() &&
          numberWithoutCommas(month) === dayjs(selectedMonth).month() + 1
        const monthLabel =
          THAI_SHORT_MONTHS[numberWithoutCommas(month || 1) - 1]
        return {
          totalKycAffiliate: isSelectingMonth
            ? current.kyc_verified
            : acc.totalKycAffiliate,
          totalDownlineLossing: isSelectingMonth
            ? current.reach_target
            : acc.totalDownlineLossing,
          totalFirstDepositAffiliate: isSelectingMonth
            ? current.first_deposit
            : acc.totalFirstDepositAffiliate,
          graph: [
            ...acc.graph,
            {
              monthLabel,
              monthYearLabel: `${monthLabel} ${dayjs(year).format('YY')}`,
              kycAffiliate: current.kyc_verified,
              firstDepositAffiliate: current.first_deposit,
              downlineLossing: current.reach_target,
              total:
                current.kyc_verified +
                current.first_deposit +
                current.reach_target,
            },
          ],
        }
      },
      {
        graph: [],
        totalKycAffiliate: 0,
        totalDownlineLossing: 0,
        totalFirstDepositAffiliate: 0,
      } as MonthlyGraphData,
    )
  return result
}

export type AffiliateBalanceResult = {
  cash: number
  totalWithdraw: number
  updatedAt: Date
}

export type UseAffiliateParams = {
  form: AffiliateSearchForm
}

export const useAffiliate = (): AffiliateState => {
  const auth = useRecoilValue(authStateCommon.authState)
  const { isMD } = useResponsiveState()
  const { pigspinApiInstance, boApiInstance } = useAxios()
  const [textSearch, setTextSearch] = React.useState<string>('')
  const [overviewDate, setOverviewDate] = React.useState<Dayjs>(dayjs())
  const [configWalletHistory, setConfigWalletHistory] =
    React.useState<CallWalletHistoryParams>({})
  const [isOverviewLoading, setIsOverviewLoading] = React.useState(false)
  const [isDownlineItemLoading, setIsDownlineItemLoading] =
    React.useState(false)
  const { walletHistory } = useWalletHistory(configWalletHistory)

  const affiliateHistory = React.useMemo((): CashTransactionHistoryGroup => {
    return buildCashTransactionGroupList(walletHistory)
  }, [walletHistory])

  const {
    data: affilateTransaction,
    refetch: callAffiliateTransaction,
    isFetching: isAffilateTransactionFetching,
  } = useQuery<IncomeItem[], any>(
    ['transactions'],
    async () => {
      const params = new URLSearchParams({
        start: overviewDate.startOf('month').format(),
        end: overviewDate.endOf('month').format(),
        user_code: auth.userUID || '',
        transaction_type: 'DEBIT',
        order_by: '-created_at',
      })
      const response = await fetchWithJSON<
        BaseApiResponse<AffiliateTransactionData>
      >(pigspinApiInstance, {
        url: `v1/affiliate/overview/transactions?${params.toString()}`,
        method: 'GET',
      })
      const descSort = (a: IncomeItem, b: IncomeItem): number => {
        if (dayjs(a.incomeDate).isAfter(dayjs(b.incomeDate))) {
          return -1
        }
        return 1
      }
      return aggregateToMonthly(
        response.data.results.map(mapToIncomeItem),
      ).sort(descSort)
    },
    {
      enabled: false,
    },
  )

  const {
    data: affilateMonthlyTransaction,
    refetch: callAffiliateMonthlyTransaction,
    isFetching: isAffilateMonthlyTransactionFetching,
  } = useQuery<MonthlyGraphData, any>(
    ['monthlyTransactions'],
    async () => {
      const params = new URLSearchParams({
        start: dayjs(overviewDate)
          .subtract(3, 'months')
          .startOf('month')
          .format(),
        end: dayjs(overviewDate).add(3, 'months').endOf('month').format(),
        user_code: auth.userUID || '',
      })
      const response = await fetchWithJSON<BaseApiResponse<MonthlyDataItem[]>>(
        pigspinApiInstance,
        {
          url: `v1/affiliate/overview/monthly?${params.toString()}`,
          method: 'GET',
        },
      )
      return buildGraphData(response.data, dayjs(overviewDate))
    },
    {
      enabled: false,
    },
  )

  const { data: orgChart, refetch: callOrgChart } = useQuery<
    BaseApiResponse<{ downline: ApiAffiliateCustomer[] }>,
    any,
    IncomeItem[]
  >(
    ['organization'],
    async () => {
      const response = await fetchWithJSON<BaseApiResponse<any>>(
        boApiInstance,
        {
          url: `v1/affiliate/downline?user_code=${auth.userUID}&player_username=${auth.customerCode}`,
          method: 'GET',
        },
      )
      return {
        status: 'SUCCESS',
        service_code: '',
        service_message: '',
        code: 200,
        data: response.data,
      }
    },
    {
      enabled: false,
      select: (
        response: BaseApiResponse<{ downline: ApiAffiliateCustomer[] }>,
      ) => {
        return response.data.downline.map(mapCustomer)
      },
    },
  )

  const {
    data: affiliateBalance,
    refetch: callCash,
    isLoading: isFetchingCash,
  } = useQuery<AffiliateUserBalance, any, AffiliateBalanceResult>(
    ['affiliateBalance'],
    async () => {
      const response = await fetchWithJSON<
        BaseApiResponse<AffiliateUserBalance>
      >(pigspinApiInstance, {
        url: `v1/affiliate/balance?user_code=${auth.userUID}`,
        method: 'GET',
      })
      if (response.status === 'SUCCESS') {
        return response.data
      }
      return {}
    },
    {
      select: (responseData: AffiliateUserBalance) => {
        const { balance, total_withdraw } = responseData
        return {
          cash: balance || 0,
          updatedAt: dayjs().toDate(),
          totalWithdraw: total_withdraw || 0,
        }
      },
    },
  )

  const getAffiliateHistory = (params: CallWalletHistoryParams) => {
    setConfigWalletHistory({
      start: dayjs(params.start).startOf('month').format(),
      end: dayjs(params.end).endOf('month').format(),
      withdrawType: WITHDRAW_TYPE.AFFILIATE,
    })
  }

  React.useEffect(() => {
    callOrgChart()
  }, [])

  React.useEffect(() => {
    callAffiliateTransaction()
    callAffiliateMonthlyTransaction()
  }, [overviewDate])

  const filterDownline = (item: IncomeItem, _textSearch: string): boolean => {
    if (_textSearch === '') return true
    const lowercaseTextSearch = _textSearch.toLocaleLowerCase()
    return (
      item.downlineCode.toLocaleLowerCase().indexOf(lowercaseTextSearch) > -1 ||
      item.downlineName.toLocaleLowerCase().indexOf(lowercaseTextSearch) > -1
    )
  }

  const offsetDataBoundary = (
    monthlyData: MonthlyGraphData,
  ): MonthlyGraphData => {
    return {
      ...monthlyData,
      graph: isMD ? monthlyData.graph : monthlyData.graph.slice(1, 6),
    }
  }
  const responsiveMonthlyData = React.useMemo(() => {
    return offsetDataBoundary({
      totalKycAffiliate: affilateMonthlyTransaction?.totalKycAffiliate || 0,
      totalDownlineLossing:
        affilateMonthlyTransaction?.totalDownlineLossing || 0,
      totalFirstDepositAffiliate:
        affilateMonthlyTransaction?.totalFirstDepositAffiliate || 0,
      graph: affilateMonthlyTransaction?.graph || [],
    })
  }, [affilateMonthlyTransaction, affilateTransaction])

  const isMonthlyDataEmpty: boolean = React.useMemo(
    () =>
      (affilateTransaction ? affilateTransaction.length < 1 : true) &&
      (responsiveMonthlyData
        ? responsiveMonthlyData?.graph.every((month) => month.total === 0)
        : true),
    [responsiveMonthlyData, affilateTransaction],
  )

  return {
    downlineItems: orgChart?.filter((i) => filterDownline(i, textSearch)) || [],
    isDownlineItemLoading,
    setIsDownlineItemLoading,
    setTextSearch,
    getAffiliateHistory,
    callAffiliateTransaction,
    callAffiliateMonthlyTransaction,
    callOrgChart,
    historyGroups: affiliateHistory,
    cash: affiliateBalance?.cash || 0,
    isFetchingCash,
    overviewDate,
    setOverviewDate,
    callCash,
    cashUpdatedAt: affiliateBalance?.updatedAt || dayjs().toDate(),
    totalWithdraw: affiliateBalance?.totalWithdraw || 0,
    isAffilateTransactionFetching,
    isAffilateMonthlyTransactionFetching,
    overview: {
      ...responsiveMonthlyData,
      isEmpty: isMonthlyDataEmpty,
      isOverviewLoading,
      setIsOverviewLoading,
    },
    overviewDownlineList: affilateTransaction || [],
  }
}
