import React from 'react'
import { Collapse as AntdCollapse } from 'antd'

import { LanguageType } from '@pig-common/utils/common'

import {
  BonusInformation,
  TransactionMode,
  TRANSACTION_COLLAPSE_VARIANT,
} from './TransactionCollapse.type'
import {
  buildCashTransactionHeader,
  buildBonusInformationHeader,
  buildBonusInformationContent,
  mapCashTransactionDisplayVariant,
  buildHistoryTransactionHeader,
  getBonusHistoryStatus,
  checkDisabledBonusHistory,
} from './TransactionItemBuilder'
import { BonusInformationHeaderLocaleText } from './BonusInformationHeader'
import { BonusInformationContentLocaleText } from './BonusInformationContent'
import { CashTransactionHistory } from '@pig-common/hooks/useWalletHistory'
import BonusHistoryContent from './BonusHistoryContent'

const { Panel } = AntdCollapse

export type LocaleText = BonusInformationHeaderLocaleText &
  BonusInformationContentLocaleText

type UniversalTransactionType = CashTransactionHistory | BonusInformation

export type TransactionCollapseProps = {
  id?: string
  mode: TransactionMode
  dataList: UniversalTransactionType[]
  localeText?: LocaleText
  accordion?: boolean
  defaultActiveKey?: string
  defaultDisplayVariant?: TRANSACTION_COLLAPSE_VARIANT
  language?: LanguageType
}

const TransactionCollapse = ({
  id = 'transaction-collapse',
  dataList,
  mode,
  localeText,
  accordion = false,
  defaultActiveKey,
  defaultDisplayVariant = TRANSACTION_COLLAPSE_VARIANT.DEFAULT,
  language = 'th',
}: TransactionCollapseProps) => {
  const [expandingTransactions, setExpandingTransactions] = React.useState<
    string[]
  >([])
  const renderHeader = (
    transactionItem: UniversalTransactionType,
    index: number,
  ) => {
    if (mode === 'cash') {
      return buildCashTransactionHeader(
        transactionItem as CashTransactionHistory,
      )
    }
    if (mode === 'bonusInformation') {
      return buildBonusInformationHeader(
        transactionItem as BonusInformation,
        localeText,
        language,
        index,
      )
    }
    if (mode === 'bonusHistory') {
      return buildHistoryTransactionHeader(
        transactionItem as CashTransactionHistory,
      )
    }
    return ''
  }
  const renderContent = (transactionItem: UniversalTransactionType) => {
    if (mode === 'cash') {
      return (
        <BonusHistoryContent
          {...(transactionItem as CashTransactionHistory)}
          expandingTransactions={expandingTransactions}
          setExpandingTransactions={setExpandingTransactions}
        />
      )
    }
    if (mode === 'bonusInformation') {
      return buildBonusInformationContent(
        transactionItem as BonusInformation,
        localeText,
      )
    }
    if (mode === 'bonusHistory') {
      return (
        <BonusHistoryContent
          {...(transactionItem as CashTransactionHistory)}
          expandingTransactions={expandingTransactions}
          setExpandingTransactions={setExpandingTransactions}
        />
      )
    }
    return ''
  }

  let displayDataList = dataList
  if (mode === 'cash') {
    displayDataList = mapCashTransactionDisplayVariant(
      dataList as CashTransactionHistory[],
    )
  }

  const checkDisplayVariant = (transactionItem: UniversalTransactionType) => {
    if (
      mode === 'bonusHistory' &&
      !checkDisabledBonusHistory(
        getBonusHistoryStatus(transactionItem as BonusInformation),
      )
    ) {
      return TRANSACTION_COLLAPSE_VARIANT.SUCCESS_OUTLINE
    }
    return transactionItem?.displayVariant || defaultDisplayVariant
  }

  const checkDisabled = (
    transactionItem: UniversalTransactionType,
  ): boolean => {
    if (mode === 'bonusHistory') {
      return checkDisabledBonusHistory(
        getBonusHistoryStatus(transactionItem as BonusInformation),
      )
    }
    return false
  }

  return (
    <AntdCollapse
      className="ps-transaction-collapse"
      bordered={false}
      defaultActiveKey={defaultActiveKey}
      accordion={accordion}
    >
      {displayDataList.map((transactionItem, index) => (
        <Panel
          id={id}
          key={index} // eslint-disable-line
          className={`
            ps-transaction-collapse__panel-item
            ps-transaction-collapse__panel-item--${checkDisplayVariant(
              transactionItem,
            )}
            ${
              checkDisabled(transactionItem)
                ? 'ps-transaction-collapse__panel-item--disabled'
                : ''
            }
          `}
          header={renderHeader(transactionItem, index)}
          showArrow
        >
          {renderContent(transactionItem)}
        </Panel>
      ))}
    </AntdCollapse>
  )
}

export default TransactionCollapse
