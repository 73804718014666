import { useEffect, useState } from 'react'
import { AxiosRequestConfig } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import { useAxios } from './useAxios'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { parse2Decimal } from '@pig-common/utils/getPerfect2Digit'
import { forceDateToISO } from '@pig-common/utils/datetime'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { CoinWalletResultType } from '@pig-common/types/Wallet.type'
import { WITHDRAW_TYPE } from './useWithdraw'
import { TransactionIcon } from '@pig-common/components/TransactionCollapse/TransactionCollapse.type'

export enum WALLET_CONNECTION_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum WALLET_HISTORY_TYPE {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

export enum WALLET_HISTORY_STATUS {
  WAITING_TRANSFER = 'WAITING_TRANSFER',
  PENDING = 'PENDING',
  HOLD_ON = 'HOLD_ON',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  TRANSFER_BACK = 'TRANSFER_BACK',
}
export enum COIN_HISTORY_STATUS {
  ACTIVE = 'ACTIVE',
  CONVERTED = 'CONVERTED',
  /** @description When the wallet is cancelled by that user. (ex. The user withdraws the money) */
  CANCELED = 'CANCELED',
  /** @description When the wallet is revoked by administer. */
  REVOKED = 'REVOKED',
  USED = 'USED',
  EXPIRED = 'EXPIRED',
}

export enum CASH_PAYMENT_METHOD {
  QR = 'QR Payment',
  TRUE = 'True Money Wallet',
}

export enum ADVANCE_CASH_PAYMENT_METHOD {
  LEADERBOARD_CHRISTMAS = 'leaderboard-christmas',
}

export type CashTransactionHistory = {
  title: string
  amount: string
  status: WALLET_HISTORY_STATUS | COIN_HISTORY_STATUS
  type: WALLET_HISTORY_TYPE | COIN_HISTORY_STATUS
  icon: TransactionIcon
  id: string
  dateTime: string
  remark: string
  detail: CASH_PAYMENT_METHOD | ADVANCE_CASH_PAYMENT_METHOD | string
  statusLabel: string
  convertLimitAmount: number
  queueDateTime?: string
  rawDateTime?: string
  displayVariant?: string
  isBonus?: boolean
  refund?: TransactionWalletResponse
  isSomethingWentWrong?: boolean
}

type TransactionWalletResponse = {
  amount: number
  channel: string | null
  created_at: Date
  customer_code: string
  customer_uid: string
  edit_transaction: null
  editing_by: null
  from_bank_account_name: null
  from_bank_account_number: string
  from_bank_code: string
  is_bonus?: boolean
  is_sent: boolean
  matching_state: 'AUTO' | 'MANUAL'
  note: string
  status: string
  to_bank_account_name: string
  to_bank_account_number: string
  to_bank_code: string
  transferred_at: Date
  type: string
  uid: string
  updated_at: Date
  refund?: TransactionWalletResponse
  transaction_deposit_type?: string
}

export type CashTransactionHistoryResponse = {
  Status: WALLET_CONNECTION_STATUS
  Message: string
  History: CashTransactionHistory[]
}

const bankCodeToBankName: Record<string, string> = {
  BAY: 'ธนาคารกรุงศรีอยุธยา',
  BBL: 'ธนาคารกรุงเทพ',
  KTB: 'ธนาคารกรุงไทย',
  KBANK: 'ธนาคารกสิกรไทย',
  KK: 'ธนาคารเกียรตินาคิน',
  CIMB: 'ธนาคารซีไอเอ็มบี ไทย',
  TTB: 'ธนาคารทีเอ็มบีธนชาต',
  SCB: 'ธนาคารไทยพาณิชย์',
  BAAC: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
  UOB: 'ธนาคารยูโอบี',
  LHBANK: 'ธนาคารแลนด์ แอนด์ เฮ้าส์',
  GSB: 'ธนาคารออมสิน',
  GHBANK: 'ธนาคารอาคารสงเคราะห์',
  IBANK: 'ธนาคารอิสลามแห่งประเทศไทย',
  TRUEWALLET: 'ทรูมันนี่ วอลเล็ท',
}

const mapWalletHistory = (
  transaction: TransactionWalletResponse,
): CashTransactionHistory => {
  const {
    amount,
    from_bank_code,
    to_bank_code,
    type,
    status,
    channel,
    is_bonus,
    refund,
    uid,
    transferred_at,
    created_at,
  } = transaction
  const isConvertFromBonus = transaction.transaction_deposit_type === 'CONVERT'
  const getTitle = (): string => {
    if (channel === 'payout-commission-baccarat-01') return 'ค่าคอมมิชชั่น'
    if (channel === 'payout-losing-streak-12-round-baccarat')
      return 'เติมเงิน (แทงเสีย 12 ไม้)'
    if (isConvertFromBonus && channel === 'payout-tournament')
      return 'ผู้ชนะทัวร์นาเมนต์'
    if (isConvertFromBonus && channel === 'payout-daily-free-spin')
      return 'สปินรายวัน'
    if (isConvertFromBonus && channel === 'payout-pighunt') return 'รับรางวัล'
    if (isConvertFromBonus && channel === 'payout-rain') return 'รับเงินโปรย'
    const transactionType = transaction.type
    const transactionStatus = transaction.status
    switch (transactionType) {
      case WALLET_HISTORY_TYPE.DEPOSIT:
        switch (transactionStatus) {
          case WALLET_HISTORY_STATUS.TRANSFER_BACK:
            return 'โอนเงินกลับต้นทาง'
          case WALLET_HISTORY_STATUS.REJECTED:
            return 'เติมเงินไม่สำเร็จ'
          case WALLET_HISTORY_STATUS.WAITING_TRANSFER:
          case WALLET_HISTORY_STATUS.COMPLETED:
            return 'เติมเงิน'
          default:
            return '---'
        }
      case WALLET_HISTORY_TYPE.WITHDRAW:
        switch (transactionStatus) {
          case WALLET_HISTORY_STATUS.REJECTED:
            return 'ถอนเงินไม่สำเร็จ'
          case WALLET_HISTORY_STATUS.PENDING:
          case WALLET_HISTORY_STATUS.COMPLETED:
            return 'ถอนเงิน'
          default:
            return '---'
        }
      default:
        return '--- !'
    }
  }
  const getDetail = (): string => {
    if (isConvertFromBonus && channel === 'payout-tournament')
      return 'เงินรางวัลผู้ชนะทัวร์นาเมนต์'
    if (isConvertFromBonus && channel === 'payout-daily-free-spin')
      return 'รางวัลจาก "สปินรายวัน"'
    if (isConvertFromBonus && channel === 'payout-pighunt')
      return 'รางวัลจาก "กิจกรรมล่าหมู"'
    if (isConvertFromBonus && channel === 'payout-rain') return 'ได้รับจากแชท'
    if (isConvertFromBonus) return `${channel}`
    // let resultPrefixText
    if (channel) {
      const channel_split = channel.split('-')[0]
      if (
        channel === CASH_PAYMENT_METHOD.QR ||
        channel === CASH_PAYMENT_METHOD.TRUE
      ) {
        // resultPrefixText = `${resultPrefixText} `
      } else if (channel === 'payout-shop') {
        return 'PIGSHOP - Free Credit'
      } else if (channel === 'payout-FREESPIN') {
        return 'PIGSHOP - Free Spin'
      } else if (channel === 'payout-tournament') {
        return 'Tournament - Winner Reward'
      } else if (channel === 'create-rain') {
        return ' Send Rain'
      } else if (channel === 'create-tip') {
        return ' Send a Tip'
      } else if (channel === 'payout-rain') {
        return ' Received Rain'
      } else if (channel === 'payout-tip') {
        return ' Received Tip'
      } else if (channel.search('payout-leaderboard') !== -1) {
        // payout-leaderboard-{code}
        return `${channel.split('-').pop()}`
      } else if (channel_split === 'SCB') {
        return 'ธนาคารไทยพาณิชย์'
      } else if (channel_split === 'KBANK') {
        return 'ธนาคารกสิกรไทย'
      } else if (channel_split === 'TRUEWALLET') {
        return 'ทรูมันนี่ วอลเล็ต'
      }
    }
    if (
      type === 'DEPOSIT' &&
      !['WAITING_TRANSFER', 'PENDING', 'TRANSFER_BACK'].includes(status)
    ) {
      return bankCodeToBankName[from_bank_code]
    }
    return bankCodeToBankName[to_bank_code]
  }
  const getStatusLabel = (): string => {
    let detail = ''
    const prefix = 'สถานะ : '
    switch (status) {
      case WALLET_HISTORY_STATUS.PENDING:
        detail = `${prefix}กำลังดำเนินการ`
        break
      case WALLET_HISTORY_STATUS.WAITING_TRANSFER:
        detail = `${prefix}รอทำการโอน`
        break
      case WALLET_HISTORY_STATUS.TRANSFER_BACK:
      case WALLET_HISTORY_STATUS.COMPLETED:
        detail = `${prefix}สำเร็จ`
        break
      case WALLET_HISTORY_STATUS.REJECTED:
        detail = `${prefix}ไม่สำเร็จ`
        break
      case WALLET_HISTORY_STATUS.HOLD_ON:
        detail = `${prefix}hold on`
        break
      default:
        detail = ''
        break
    }
    return detail
  }
  const getRemark = () => {
    let remark_text
    if (channel === 'create-rain') {
      remark_text = 'สร้างรายการโปรยเงิน'
    } else if (channel === 'create-tip') {
      remark_text = 'สร้างรายการส่งทิป'
    } else if (channel === 'payout-rain') {
      remark_text = 'รับเงินโปรย'
    } else if (channel === 'payout-tip') {
      remark_text = 'รับเงินทิป'
    } else {
      remark_text =
        refund && status === 'TRANSFER_BACK'
          ? `เติมเงินถูกปฏิเสธ โอนเงินกลับต้นทางสำเร็จ เลขที่บัญชี ${refund.to_bank_account_number}`
          : '-'
    }
    return remark_text
  }
  const getIcon = () => {
    switch (status) {
      case WALLET_HISTORY_STATUS.TRANSFER_BACK:
      case WALLET_HISTORY_STATUS.COMPLETED:
        return 'check-filled'
      case WALLET_HISTORY_STATUS.PENDING:
      case WALLET_HISTORY_STATUS.WAITING_TRANSFER:
        return 'processing'
      default:
        return 'caution-filled'
    }
  }
  const getAmount = (): string => {
    const aftermathAmount = `${parse2Decimal(amount)}`
    if (
      type === WALLET_HISTORY_TYPE.DEPOSIT &&
      status !== WALLET_HISTORY_STATUS.TRANSFER_BACK
    ) {
      return `+${aftermathAmount}`
    }
    if (type === WALLET_HISTORY_TYPE.WITHDRAW) {
      return `-${aftermathAmount}`
    }
    return `${aftermathAmount}`
  }
  const getStatus = (): WALLET_HISTORY_STATUS => {
    switch (status.toUpperCase()) {
      case WALLET_HISTORY_STATUS.COMPLETED:
        return WALLET_HISTORY_STATUS.COMPLETED
      case WALLET_HISTORY_STATUS.REJECTED:
        return WALLET_HISTORY_STATUS.REJECTED
      case WALLET_HISTORY_STATUS.WAITING_TRANSFER:
        return WALLET_HISTORY_STATUS.WAITING_TRANSFER
      case WALLET_HISTORY_STATUS.TRANSFER_BACK:
        return WALLET_HISTORY_STATUS.TRANSFER_BACK
      default:
        return WALLET_HISTORY_STATUS.PENDING
    }
  }
  const getType = (): WALLET_HISTORY_TYPE => {
    if (type === 'DEPOSIT' && status !== 'WAITING_TRANSFER') {
      return WALLET_HISTORY_TYPE.DEPOSIT
    }
    return WALLET_HISTORY_TYPE.WITHDRAW
  }
  const getDatetime = (): string => {
    let textDateTime = ''
    if (type === 'DEPOSIT') {
      // REFUND
      if (refund) {
        // TRANSFER_BACK
        if (refund.transferred_at) {
          textDateTime = `${refund.transferred_at}`
        }
        // WAITING_TRANSFER
        textDateTime = `${refund.created_at}`
      }
      textDateTime = `${transferred_at}`
    } else {
      // WITHDRAW
      textDateTime = `${created_at}`
    }
    return `${format(forceDateToISO(textDateTime), 'dd/MM/yyyy HH:mm')}`
  }
  const getSomethingWentWrong = (): boolean => {
    switch (getStatus()) {
      case WALLET_HISTORY_STATUS.REJECTED:
        return true
      default:
        return false
    }
  }

  const DateTime = getDatetime()
  const Icon = getIcon()
  const ID = uid
  const RawDateTime = getDatetime()
  const Remark = getRemark()
  const Status = getStatus()
  const Detail = getDetail()
  const StatusLabel = getStatusLabel()
  const Type = getType()
  const isBonus = is_bonus || false
  const isSomethingWentWrong = getSomethingWentWrong()
  return {
    title: getTitle(),
    amount: getAmount(),
    dateTime: DateTime,
    icon: Icon,
    id: ID,
    isBonus,
    rawDateTime: RawDateTime,
    detail: Detail,
    remark: Remark,
    status: Status,
    statusLabel: StatusLabel,
    type: Type,
    queueDateTime: '',
    refund,
    isSomethingWentWrong,
    convertLimitAmount: 0,
  }
}

// =================================================================
// MAP COIN HISTORY
// =================================================================
const mapCoinHistory = (
  transaction: CoinWalletResultType,
): CashTransactionHistory => {
  const {
    initial_balance,
    status,
    created_at,
    total_turnover,
    tier_ratio,
    converted_to_cash_amount,
  } = transaction
  const getTitle = (): string => {
    return transaction.name
  }
  const getAmount = (): string => {
    const formAmount = `+${parse2Decimal(initial_balance)}`
    return `${formAmount}`
  }
  const getStatus = (): COIN_HISTORY_STATUS => {
    const formStatus = status.toUpperCase()
    switch (formStatus) {
      case COIN_HISTORY_STATUS.ACTIVE:
        return COIN_HISTORY_STATUS.ACTIVE
      case COIN_HISTORY_STATUS.CONVERTED:
        return COIN_HISTORY_STATUS.CONVERTED
      case COIN_HISTORY_STATUS.CANCELED:
        return COIN_HISTORY_STATUS.CANCELED
      case COIN_HISTORY_STATUS.REVOKED:
        return COIN_HISTORY_STATUS.REVOKED
      case COIN_HISTORY_STATUS.USED:
        return COIN_HISTORY_STATUS.USED
      case COIN_HISTORY_STATUS.EXPIRED:
        return COIN_HISTORY_STATUS.EXPIRED
      default:
        return COIN_HISTORY_STATUS.CANCELED
    }
  }
  const getDetail = (): string => {
    if (tier_ratio) {
      // NOTE : this is cashback if other bonus send this ratio, we have another flag to identify more case.
      return `ได้รับ ${tier_ratio * 100}% ของยอดเสีย ${parse2Decimal(
        initial_balance / tier_ratio,
      )}`
    }
    switch (getStatus()) {
      case COIN_HISTORY_STATUS.CONVERTED:
        return `โบนัสเปลี่ยนเป็นเงินสดสำเร็จแล้ว ${parse2Decimal(
          converted_to_cash_amount || '',
        )}`
      default:
        return total_turnover
          ? `ยอดเติมเงินเพื่อรับโบนัส : ${parse2Decimal(total_turnover)}`
          : ''
    }
  }
  const getRemark = () => {
    switch (getStatus()) {
      case COIN_HISTORY_STATUS.CANCELED:
        return 'ไม่ตรงตามเงื่อนไข'
      case COIN_HISTORY_STATUS.REVOKED:
        return 'โดนยกเลิกเพราะมีบางอย่างผิดพลาด'
      case COIN_HISTORY_STATUS.ACTIVE:
      case COIN_HISTORY_STATUS.CONVERTED:
      default:
        return '-'
    }
  }
  const getIcon = () => {
    switch (getStatus()) {
      case COIN_HISTORY_STATUS.CANCELED:
      case COIN_HISTORY_STATUS.REVOKED:
        return 'caution-filled'
      case COIN_HISTORY_STATUS.USED:
      case COIN_HISTORY_STATUS.EXPIRED:
        return 'check-filled-gray'
      case COIN_HISTORY_STATUS.ACTIVE:
      case COIN_HISTORY_STATUS.CONVERTED:
      default:
        return 'check-filled'
    }
  }
  const getType = (): COIN_HISTORY_STATUS => {
    return getStatus()
  }
  const getDatetime = (): string => {
    return created_at
      ? `${format(forceDateToISO(created_at), 'dd/MM/yyyy HH:mm')}`
      : ''
  }

  const getStatusLabel = (): string => {
    let label = ''
    const prefix = 'สถานะ : '
    switch (getStatus()) {
      case COIN_HISTORY_STATUS.ACTIVE:
      case COIN_HISTORY_STATUS.CONVERTED:
        label = `${prefix}สำเร็จ`
        break
      case COIN_HISTORY_STATUS.USED:
        label = `${prefix}ใช้หมดแล้ว`
        break
      case COIN_HISTORY_STATUS.EXPIRED:
        label = `${prefix}หมดอายุ`
        break
      case COIN_HISTORY_STATUS.CANCELED:
      case COIN_HISTORY_STATUS.REVOKED:
        label = `${prefix}ไม่สำเร็จ`
        break
      default:
        label = ''
        break
    }
    return label
  }
  const getSomethingWentWrong = () => {
    switch (getStatus()) {
      case COIN_HISTORY_STATUS.CANCELED:
      case COIN_HISTORY_STATUS.REVOKED:
        return true
      case COIN_HISTORY_STATUS.ACTIVE:
      case COIN_HISTORY_STATUS.CONVERTED:
      default:
        return false
    }
  }

  const Title = getTitle()
  const Amount = getAmount()
  const DateTime = getDatetime()
  const Icon = getIcon() as TransactionIcon
  const ID = transaction.uid
  const RawDateTime = getDatetime()
  const Remark = getRemark()
  const Status = getStatus()
  const Detail = getDetail()
  const StatusLabel = getStatusLabel()
  const Type = getType()
  const isSomethingWentWrong = getSomethingWentWrong()
  return {
    title: Title,
    amount: Amount,
    dateTime: DateTime,
    icon: Icon,
    id: ID,
    rawDateTime: RawDateTime,
    detail: Detail,
    remark: Remark,
    status: Status,
    statusLabel: StatusLabel,
    type: Type,
    isSomethingWentWrong,
    queueDateTime: '',
    convertLimitAmount: transaction.convert_limit_amount,
  }
}

export type CallWalletHistoryParams = {
  withdrawType?: WITHDRAW_TYPE
  start?: string
  end?: string
}

export type typeUseWallet = {
  walletHistory: CashTransactionHistory[]
  bonusHistory: CashTransactionHistory[]
  isWalletAllFetched: boolean
  isBonusAllFetched: boolean
  isFetching: boolean
  callWalletHistory: () => void
  callBonusHistory: () => void
  setFetchAllWalletHistory: (value: boolean) => void
  setFetchAllBonusHistory: (value: boolean) => void
  callAffiliateWalletHistory: (
    params: CallWalletHistoryParams,
  ) => Promise<CashTransactionHistoryResponse>
}
export const useWalletHistory = (
  params?: CallWalletHistoryParams,
): typeUseWallet => {
  const { boApiInstance, pigspinApiInstance } = useAxios()
  const { customerCode, userUID } = useRecoilValue(authStateCommon.authState)
  const [fetchAllWalletHistory, setFetchAllWalletHistory] =
    useState<boolean>(false)
  const [fetchAllBonusHistory, setFetchAllBonusHistory] =
    useState<boolean>(false)

  const callAffiliateWalletHistory =
    async (): Promise<CashTransactionHistoryResponse> => {
      const query = new URLSearchParams()
      query.append('customer_code', customerCode || '')
      query.append('page_size', '100')
      query.append('deposit_statuses', 'REJECTED')
      query.append('deposit_statuses', 'COMPLETED')
      query.append('deposit_statuses', 'WAITING_TRANSFER')
      query.append('deposit_statuses', 'TRANSFER_BACK')
      query.append('withdraw_status', 'PENDING')
      query.append('withdraw_status', 'REJECTED')
      query.append('withdraw_status', 'COMPLETED')
      query.append('order_by', '-created_at')
      // url: `v1/payment/transactions?customer_code=${customerCode}&page_size=200&deposit_status=REJECTED&deposit_status=COMPLETED&deposit_status=WAITING_TRANSFER&deposit_status=TRANSFER_BACK&withdraw_status=PENDING&withdraw_status=REJECTED&withdraw_status=COMPLETED&order_by=-created_at`,
      const requestConfig: AxiosRequestConfig = {
        url: `v1/payment/transactions?${query.toString()}`,
        method: 'GET',
      }
      const response = await fetchWithJSON<
        BaseApiResponse<{ results: TransactionWalletResponse[] }>
      >(boApiInstance, requestConfig)
      if (response.service_code === 'BOE-2000') {
        return {
          Status: WALLET_CONNECTION_STATUS.SUCCESS,
          Message: '',
          History: response?.data.results.map(mapWalletHistory),
        }
      }
      return {
        Status: WALLET_CONNECTION_STATUS.ERROR,
        Message: '',
        History: [],
      }
    }

  const {
    data: walletHistoryData = [],
    refetch: callWalletHistory,
    isFetching: isWalletHistoryFetching,
  } = useQuery<CashTransactionHistory[]>(
    ['WalletHistory', params?.start, params?.end],
    async () => {
      const queryDeposit = new URLSearchParams()
      const queryWithdraw = new URLSearchParams()
      const monthAgo = fetchAllWalletHistory ? 6 : 1
      const start =
        params?.start || dayjs().subtract(monthAgo, 'month').toISOString()
      const end = params?.end || dayjs().toISOString()

      if (params?.withdrawType) {
        queryDeposit.append('transfer_type', params.withdrawType)
      }
      if (start) {
        queryDeposit.append('start', start)
      }
      if (end) {
        queryDeposit.append('end', end)
      }
      queryDeposit.append('customer_code', customerCode || '')
      queryDeposit.append('page_size', '100')
      queryDeposit.append('deposit_statuses', 'REJECTED')
      queryDeposit.append('deposit_statuses', 'COMPLETED')
      queryDeposit.append('deposit_statuses', 'WAITING_TRANSFER')
      queryDeposit.append('deposit_statuses', 'TRANSFER_BACK')
      queryDeposit.append('order_by', '-created_at')
      const requestDepositConfig: AxiosRequestConfig = {
        url: `v1/payment/transactions?${queryDeposit.toString()}`,
        method: 'GET',
      }
      const responseDeposit = await fetchWithJSON<
        BaseApiResponse<{ results: TransactionWalletResponse[] }>
      >(boApiInstance, requestDepositConfig)

      if (params?.withdrawType) {
        queryWithdraw.append('transfer_type', params.withdrawType)
      }
      if (start) {
        queryWithdraw.append('start', start)
      }
      if (end) {
        queryWithdraw.append('end', end)
      }
      queryWithdraw.append('customer_code', customerCode || '')
      queryWithdraw.append('page_size', '100')
      queryWithdraw.append('withdraw_statuses', 'PENDING')
      queryWithdraw.append('withdraw_statuses', 'REJECTED')
      queryWithdraw.append('withdraw_statuses', 'COMPLETED')
      queryWithdraw.append('order_by', '-created_at')
      const requestWithdrawConfig: AxiosRequestConfig = {
        url: `v1/payment/transactions?${queryWithdraw.toString()}`,
        method: 'GET',
      }
      const responseWithdraw = await fetchWithJSON<
        BaseApiResponse<{ results: TransactionWalletResponse[] }>
      >(boApiInstance, requestWithdrawConfig)
      const mergedResult = [
        ...responseDeposit?.data?.results, // eslint-disable-line
        ...responseWithdraw?.data?.results, // eslint-disable-line
      ].sort((a, b) =>
        a.created_at &&
        b.created_at &&
        dayjs(a.created_at).isAfter(dayjs(b.created_at))
          ? -1
          : 1,
      )
      return mergedResult.map(mapWalletHistory)
    },
    {
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: bonusHistoryData,
    refetch: callBonusHistory,
    isFetching: isBonusHistoryFetching,
  } = useQuery<CashTransactionHistory[]>(['BonusHistory'], async () => {
    const monthAgo = fetchAllBonusHistory ? 6 : 1
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/wallet/${userUID}/coin-wallet`,
      method: 'GET',
      params: {
        event: 'history',
        limit: 50,
        start_date: dayjs().subtract(monthAgo, 'month').toISOString(),
        end_date: dayjs().toISOString(),
      },
    }
    const response = await fetchWithJSON<
      BaseApiResponse<{ results: CoinWalletResultType[] }>
    >(pigspinApiInstance, requestConfig)
    return response?.data.results.map(mapCoinHistory)
  })
  useEffect(() => {
    callWalletHistory()
  }, [fetchAllWalletHistory, params])
  useEffect(() => {
    callBonusHistory()
  }, [fetchAllBonusHistory, params])

  return {
    walletHistory: walletHistoryData || [],
    bonusHistory: bonusHistoryData || [],
    isWalletAllFetched: fetchAllWalletHistory,
    isBonusAllFetched: fetchAllBonusHistory,
    isFetching: isWalletHistoryFetching || isBonusHistoryFetching,
    callWalletHistory,
    callBonusHistory,
    setFetchAllWalletHistory,
    setFetchAllBonusHistory,
    callAffiliateWalletHistory,
  }
}
