import { useState, useCallback, useEffect } from 'react'
import thTH from 'antd/lib/date-picker/locale/th_TH'
import classNames from 'classnames'
import 'moment/locale/th'
import moment from 'moment'
import { useRecoilState } from 'recoil'
import { onInputFocusStateCommon } from '@pig-common/recoils'
import type { Moment } from 'moment'
import momentGenerateConfig from 'antd/node_modules/rc-picker/lib/generate/moment'
import generatePicker from 'antd/lib/date-picker/generatePicker'
import { CalendarOutlined } from '@ant-design/icons'

export type CalendarProps = {
  id?: string
  title?: string
  value?: string | moment.Moment
  defaultPickerValue?: string | moment.Moment
  placeholder?: string
  errorMsg?: string
  dateFormat?: string
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year'
  disabled?: boolean
  isError?: boolean
  className?: string
  onChange?: (value: moment.Moment, dateString?: string) => void
  onBlur?: () => void
  validator?: (value: any) => boolean
  disabledDate?: (date: moment.Moment) => boolean
}

const DatePicker = generatePicker<Moment>(momentGenerateConfig)

const Calendar = ({
  id,
  title = 'วัน เดือน ปีเกิด (ค.ศ.)',
  placeholder = 'เลือกวันเกิดของคุณ',
  errorMsg = 'กรุณาเลือกวันของคุณ',
  value: valueProps,
  defaultPickerValue,
  disabled = false,
  isError = false,
  picker = 'date',
  dateFormat = 'DD/MM/YYYY',
  className = '',
  onChange,
  onBlur,
  validator = () => true,
  disabledDate,
}: CalendarProps) => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const [value, setValue] = useState(valueProps || defaultPickerValue)
  const [, setOnFocusInput] = useRecoilState(
    onInputFocusStateCommon.onInputFocusState,
  )
  const checkValue = useCallback(
    (val: any) => {
      return typeof validator === 'function' ? validator(val) : validator
    },
    [validator],
  )

  useEffect(() => {
    setIsValid(!isError)
  }, [isError])

  const onChangeInput = (val: any) => {
    const isValidTemp = checkValue(val)
    setValue(val)
    if (onChange) {
      setIsValid(isValidTemp)
      onChange(val)
    }
  }
  const onInputFocus = () => {
    setOnFocusInput(true)
  }
  const onInputBlur = () => {
    setOnFocusInput(false)
    if (onBlur !== undefined) {
      onBlur()
    }
  }
  return (
    <div className={classNames('ps-calendar-container', className)}>
      <div className="ps-calendar-container__title">{title}</div>
      <DatePicker
        id={id}
        locale={thTH}
        picker={picker}
        className={`ps-calendar ${!isValid ? 'ps-calendar--error' : ''}`}
        disabled={disabled}
        format={dateFormat}
        placeholder={placeholder}
        value={value ? moment(value) : moment('1900-01-01', 'YYYY-MM-DD')}
        onSelect={onChangeInput}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        suffixIcon={<CalendarOutlined />}
        disabledDate={disabledDate}
        panelRender={(panelNode) => (
          <div className="ps-calendar-panel">{panelNode}</div>
        )}
        defaultPickerValue={
          defaultPickerValue
            ? moment(defaultPickerValue)
            : moment('1900-01-01', 'YYYY-MM-DD')
        }
        allowClear={false}
      />
      {!isValid && (
        <div className="ps-calendar-error-layout">
          <i className="ps-icon-caution ps-calendar-icon-caution-outline ps-calendar-icon-caution-outline--error" />
          <span className="ps-calendar-message">{errorMsg}</span>
        </div>
      )}
    </div>
  )
}
export default Calendar
