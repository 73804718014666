import { AxiosRequestConfig } from 'axios'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

import { useAxios } from '@pig-common/hooks/useAxios'
import { dateBeFormatConverter } from '@pig-common/utils/datetime'
import { UpdateProfileRequest } from '@pig-common/hooks/useLegacyProfile'

export type KycFormType = {
  cid: string // ID card
  laserNumber: string
  firstName: string
  lastName: string
  birthday: string
}

export type KycRequest = UpdateProfileRequest & {
  first_name: string
  last_name: string
  birth_date: string
  identity_number: string
  laser_code: string
}

export type KycResponse = BaseApiResponse<any>

export enum COMMON_STATUS {
  SUCCESS = 'Success',
  ERROR = 'Error',
  UNEXPECTED = 'UnexpectedError',
}

export enum CHECK_IDENTITY_STATUS {
  CLOSE_REGISTER = 'CloseRegister',
}

export enum CHECK_IDENTITY_ERROR_CODE {
  ALREADY_EXIST = 'ALREADY_EXIST',
  VERIFIED_SUCCESSFUL = 'VERIFIED_SUCCESSFUL',
  INFORMATION_INVALID = 'INFORMATION_INVALID',
  LASER_CODE_INVALID = 'LASER_CODE_INVALID',
  EXPIRED_CARD = 'EXPIRED_CARD',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  WRONG_DATA_TRY_AGAIN = 'WRONG_DATA_TRY_AGAIN',
  TIME_OUT = 'TIME_OUT',
  VERIFIED_UNSUCCESSFUL = 'VERIFIED_UNSUCCESSFUL',
  CITIZEN_ID_INVALID = 'CITIZEN_ID_INVALID',
  CITIZEN_INFO_INVALID = 'CITIZEN_INFO_INVALID',
}

export type KycResult = {
  Status: COMMON_STATUS | CHECK_IDENTITY_STATUS
  ErrorCode?: CHECK_IDENTITY_ERROR_CODE
  Code?: string
  CustomerName?: string
  IDCard?: string
  Message?: string
  Token2?: string
}

export type SetupKycResult = {
  status: 'SUCCESS' | 'ERROR'
  message?: string
}

export type CheckPhoneHook = {
  callCheckKyc: (request: KycFormType) => Promise<KycResult>
  callSetupKyc: (
    request: KycFormType & UpdateProfileRequest,
  ) => Promise<KycResponse>
}

export const mapToKycResult = (
  kycForm: KycFormType,
  kycResponse: KycResponse,
): KycResult => {
  const { service_code } = kycResponse
  const isKycSuccess = service_code === 'PIG-2005'
  const isInfomationInvalid = service_code === 'PIG-4008'
  const isLaserCodeInvalid = service_code === 'PIG-4010'
  const isWrongDataTryAgain = service_code === 'PIG-4003'
  const isUserAlreadyExist = service_code === 'PIG-4001' // REMINDE : Does not exist on new api
  const isExpiredCard = service_code === 'PIG-4011' // REMIND : Does not exist on new api
  //   const isInternalError = service_code === 'PIG-5001' // REMIND : Does not exist on new api
  //   const isTmeOut = service_code === 'PIG-5002' // REMIND : Does not exist on new api
  //   const isKycUnsuccess = service_code === 'PIG-5004' // REMIND : Does not exist on new api

  if (isKycSuccess) {
    return {
      Status: COMMON_STATUS.SUCCESS,
      Message: 'ข้อมูลถูกต้องทั้งหมด',
      Token2: '', // TODO : find token,
    }
  }
  if (isWrongDataTryAgain) {
    return {
      Status: COMMON_STATUS.ERROR,
      Message: 'การตรวจสอบผิดพลาดกรุณาลองใหม่อีกครั้ง',
      Code: '500',
    }
  }
  if (isInfomationInvalid) {
    return {
      Status: COMMON_STATUS.ERROR,
      ErrorCode: CHECK_IDENTITY_ERROR_CODE.INFORMATION_INVALID,
      Code: '400',
    }
  }
  if (isUserAlreadyExist) {
    return {
      Status: COMMON_STATUS.ERROR,
      ErrorCode: CHECK_IDENTITY_ERROR_CODE.ALREADY_EXIST,
      Code: '400',
    }
  }
  if (isLaserCodeInvalid) {
    return {
      Status: COMMON_STATUS.ERROR,
      ErrorCode: CHECK_IDENTITY_ERROR_CODE.LASER_CODE_INVALID,
      Message: 'เลขหลังบัตรไม่ถูกต้อง',
      Code: '802',
    }
  }
  if (isExpiredCard) {
    return {
      Status: COMMON_STATUS.ERROR,
      ErrorCode: CHECK_IDENTITY_ERROR_CODE.EXPIRED_CARD,
      Code: '400',
    }
  }
  return {
    Status: COMMON_STATUS.ERROR,
    Message: 'มีบางอย่างผิดพลาด',
    Code: '501',
  }
}

export const useKyc = (): CheckPhoneHook => {
  const { pigspinApiInstance } = useAxios()

  const callCheckKyc = async (kycForm: KycFormType) => {
    // TODO: add support for saved register data with request.otpToken
    const data: KycRequest = {
      first_name: kycForm.firstName,
      last_name: kycForm.lastName,
      birth_date: dateBeFormatConverter(kycForm.birthday),
      identity_number: kycForm.cid,
      laser_code: kycForm.laserNumber,
    }
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/kyc/verify',
      method: 'POST',
      data,
    }
    const kycResponse = await fetchWithJSON<KycResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    return mapToKycResult(kycForm, kycResponse)
  }

  const callSetupKyc = async (
    kycForm: KycFormType & UpdateProfileRequest,
  ): Promise<KycResponse> => {
    const { cid, birthday, lastName, firstName, laserNumber, ...restForm } =
      kycForm
    const data: KycRequest = {
      ...restForm,
      first_name: firstName,
      last_name: lastName,
      birth_date: dateBeFormatConverter(birthday),
      identity_number: cid,
      laser_code: laserNumber,
    }
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/kyc/setup',
      method: 'POST',
      data,
    }
    const kycResponse = await fetchWithJSON<KycResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    return kycResponse
  }

  return {
    callCheckKyc,
    callSetupKyc,
  }
}
