import NiceModal from '@ebay/nice-modal-react'
import { ThemeProvider } from 'styled-components'
import LineVerify, {
  RegisterLineProps,
  getLineVerifyServerSideProps,
} from '@pig-common/pages-common/line/verify'

import styledTheme from '@pig-common/styles/scss/abstracts/theme.json'

function LineVerifyPage(props: RegisterLineProps) {
  return (
    <ThemeProvider theme={styledTheme}>
      <NiceModal.Provider>
        <LineVerify {...props} />
      </NiceModal.Provider>
    </ThemeProvider>
  )
}

export { LineVerifyPage, getLineVerifyServerSideProps }
export type { RegisterLineProps }
