import React from 'react'

export const IconSetting: React.FunctionComponent = (props) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11944_17219)">
        <path
          d="M18.0446 35.9506C27.9505 35.9506 35.9809 27.9203 35.9809 18.0144C35.9809 8.10845 27.9505 0.078125 18.0446 0.078125C8.13872 0.078125 0.108398 8.10845 0.108398 18.0144C0.108398 27.9203 8.13872 35.9506 18.0446 35.9506Z"
          fill="#DECAFB"
        />
        <path
          d="M20.0059 19.7031C19.7456 19.9644 19.436 20.1711 19.0956 20.3121C18.7551 20.453 18.39 20.525 18.0218 20.525C17.273 20.525 16.5684 20.2318 16.0376 19.7031C15.7764 19.4429 15.5696 19.1333 15.4287 18.7929C15.2878 18.4524 15.2158 18.0873 15.2158 17.719C15.2158 16.9702 15.509 16.2657 16.0376 15.7349C16.5684 15.2042 17.273 14.9131 18.0218 14.9131C18.7706 14.9131 19.4751 15.2042 20.0059 15.7349C20.2671 15.9951 20.4739 16.3047 20.6148 16.6452C20.7557 16.9857 20.8277 17.3508 20.8277 17.719C20.8277 18.4678 20.5346 19.1714 20.0059 19.7031Z"
          fill="#2A2A2E"
        />
        <path
          d="M28.3627 20.8173L26.7222 19.4144C26.7993 18.9381 26.8394 18.4527 26.8394 17.9661C26.8394 17.4807 26.7993 16.9941 26.7222 16.5179L28.3627 15.1149C28.4862 15.009 28.6507 14.3867 28.5952 14.2335L28.5726 14.1687C28.121 12.9066 27.4442 11.7361 26.5761 10.7147L26.5309 10.6623C26.4249 10.5388 26.285 10.4493 26.1277 10.4072C25.9703 10.365 25.8047 10.3712 25.6514 10.4247L23.6149 11.1488C22.863 10.5327 22.0237 10.0461 21.1175 9.70568L20.7235 7.57654C20.6937 7.41608 20.6166 7.269 20.5003 7.1538C20.3851 7.0386 20.237 6.96145 20.0766 6.93265L20.0087 6.92031C18.7034 6.68477 17.3303 6.68477 16.0261 6.92031L15.9582 6.93265C15.7977 6.96145 15.6506 7.0386 15.5344 7.1538C15.4192 7.269 15.341 7.41608 15.3112 7.57654L14.9152 9.71597C14.0152 10.0564 13.1779 10.5429 12.4353 11.1539L10.3833 10.4247C10.2301 10.3701 10.0634 10.364 9.90606 10.4061C9.74869 10.4483 9.6088 10.5378 9.50389 10.6623L9.45863 10.7147C8.59154 11.7371 7.91474 12.9076 7.46217 14.1697L7.43954 14.2345C7.3264 14.5472 7.42 14.8979 7.672 15.116L9.33314 16.5333C9.256 17.0044 9.21794 17.4848 9.21794 17.9641C9.21794 18.4455 9.256 18.9258 9.33314 19.3948L7.672 20.8132C7.54857 20.9192 7.46011 21.0601 7.41794 21.2175C7.3768 21.3748 7.384 21.5415 7.43851 21.6947L7.46114 21.7595C7.91989 23.0226 8.59051 24.188 9.46274 25.2145L9.508 25.2669C9.61291 25.3904 10.2342 25.5591 10.3874 25.5045L12.4394 24.7753C13.1862 25.3893 14.0203 25.8748 14.9193 26.2132L15.3153 28.3527C15.3451 28.5131 15.4223 28.6602 15.5385 28.7754C15.6537 28.8906 15.8018 28.9677 15.9623 28.9965L16.0302 29.0089C17.3478 29.2455 18.6962 29.2455 20.0128 29.0089L20.0807 28.9965C20.2411 28.9677 20.6968 28.5131 20.7266 28.3527L21.1195 26.2235C22.0257 25.8841 22.8702 25.3965 23.6169 24.7804C23.6169 24.7804 25.9734 25.5652 26.1307 25.522C26.2881 25.4799 26.428 25.3904 26.5329 25.2659L26.5782 25.2135C27.4504 24.1839 28.121 23.0216 28.5746 21.7585L28.5973 21.6937C28.7083 21.3861 28.6158 21.0354 28.3627 20.8173Z"
          fill="#6200EE"
        />
        <path
          d="M18.0217 22.1275C20.4565 22.1275 22.4302 20.1537 22.4302 17.719C22.4302 15.2843 20.4565 13.3105 18.0217 13.3105C15.587 13.3105 13.6133 15.2843 13.6133 17.719C13.6133 20.1537 15.587 22.1275 18.0217 22.1275Z"
          fill="#AAAAAB"
        />
        <path
          d="M16.334 21.7831C16.4276 21.6977 16.5448 21.7306 16.6456 21.7419C17.8419 21.8695 18.9054 21.5341 19.8476 20.8111C20.7795 20.0972 21.3606 19.1581 21.5643 17.9948C21.843 16.3985 21.3709 15.0346 20.2621 13.8826C19.9803 13.5895 19.6378 13.358 19.2829 13.1513C19.25 13.1317 19.2263 13.0947 19.1986 13.0659C20.9286 13.5452 22.0559 14.6633 22.4951 16.3851C22.9354 18.108 22.4653 19.6344 21.1806 20.8728C20.4092 21.6164 19.4691 22.0176 18.3911 22.0978C17.6773 22.1503 16.9943 22.0546 16.334 21.7831Z"
          fill="#7824FC"
        />
        <path
          d="M14.8764 26.2139C15.008 26.9267 15.1407 27.6406 15.2724 28.3534C15.3022 28.5138 15.3793 28.6609 15.4956 28.7761C15.6108 28.8913 15.7589 28.9685 15.9193 28.9973L15.9872 29.0096C17.3048 29.2462 18.6533 29.2462 19.9698 29.0096L20.0377 28.9973C20.1159 28.9829 20.3298 28.9335 20.5109 28.7442C20.6538 28.5951 20.7063 28.4326 20.7258 28.3534C20.8565 27.6437 20.9881 26.9339 21.1188 26.2242C21.523 26.0854 22.0116 25.8786 22.532 25.568C22.9702 25.3057 23.3302 25.03 23.6161 24.7811C24.3814 25.0259 25.1466 25.2718 25.9119 25.5166C25.9849 25.5186 26.0569 25.5207 26.13 25.5227C26.2441 25.4754 26.3583 25.4281 26.4725 25.3808C26.7882 25.0043 27.3396 24.307 27.7808 23.4954C28.0626 22.9781 28.329 22.3609 28.3578 22.2899C28.4298 22.113 28.5018 21.9371 28.5738 21.7602C28.5944 21.7283 28.7621 21.4578 28.6469 21.1523C28.5718 20.9528 28.4175 20.851 28.363 20.8191C27.8158 20.3511 27.2686 19.8841 26.7224 19.4161C26.7965 19.0438 26.8561 18.5922 26.8633 18.0779C26.8726 17.469 26.8057 16.9403 26.7224 16.5197C27.2696 16.0517 27.8158 15.5847 28.363 15.1167C28.4268 15.0344 28.5286 14.8811 28.579 14.6631C28.6201 14.4831 28.6098 14.3309 28.5965 14.2342C28.4535 13.8238 28.2663 13.365 28.0184 12.8795C27.8065 12.463 27.5864 12.0947 27.3725 11.7759C21.1445 15.9149 14.9165 20.0538 8.68848 24.1928C8.96208 24.5518 9.23465 24.9097 9.50825 25.2687C9.57099 25.3283 9.68722 25.425 9.86105 25.4836C10.0966 25.5639 10.3002 25.5279 10.3877 25.5063C11.0717 25.2635 11.7557 25.0198 12.4397 24.777C12.6197 25.0187 12.9252 25.3685 13.3942 25.6698C13.9846 26.0483 14.5513 26.1697 14.8764 26.2139Z"
          fill="#310077"
        />
        <path
          d="M16.3333 21.783C14.7761 21.1062 13.7958 19.9511 13.5161 18.2674C13.2589 16.7214 13.6827 15.3503 14.8059 14.2508C16.0361 13.0463 17.5234 12.6791 19.1989 13.0659C19.2267 13.0947 19.2493 13.1327 19.2833 13.1512C19.6381 13.358 19.9806 13.5894 20.2625 13.8826C21.3713 15.0356 21.8434 16.3995 21.5646 17.9948C21.362 19.1581 20.7798 20.0972 19.848 20.811C18.9048 21.5331 17.8412 21.8684 16.646 21.7419C16.5442 21.7306 16.428 21.6976 16.3333 21.783ZM15.4056 17.5134C15.4066 18.9699 16.5792 20.1568 18.0284 20.1682C19.4674 20.1795 20.6862 18.9678 20.6945 17.5206C20.7027 16.0621 19.5137 14.8803 18.0377 14.8803C16.573 14.8792 15.4056 16.0477 15.4056 17.5134Z"
          fill="#6200EE"
        />
        <path
          d="M18.2334 23.3725C21.2515 23.3725 23.6982 20.9258 23.6982 17.9077C23.6982 14.8895 21.2515 12.4429 18.2334 12.4429C15.2152 12.4429 12.7686 14.8895 12.7686 17.9077C12.7686 20.9258 15.2152 23.3725 18.2334 23.3725Z"
          fill="#F0F1F1"
        />
        <path
          d="M22.7147 14.8081C24.3974 17.3003 23.7803 20.6843 21.2541 22.3907C18.7701 24.0683 15.4139 23.4368 13.6982 20.9415C14.3648 20.4889 15.0313 20.0363 15.6978 19.5838C15.776 19.6753 15.8541 19.7679 15.9333 19.8594C17.2334 21.3632 19.6372 21.2161 20.7172 19.5488C21.4382 18.4348 21.392 17.2962 20.7172 16.1699C21.3837 15.7153 22.0492 15.2617 22.7147 14.8081Z"
          fill="#AAAAAB"
        />
        <path
          d="M18.2098 20.9075C17.0537 20.8643 16.163 20.3593 15.5736 19.3616C14.5111 17.5667 15.6857 15.1558 17.7501 14.8811C17.9023 14.8606 18.0566 14.8513 18.2098 14.8369C19.5202 14.913 20.4881 15.5147 21.0034 16.7377C21.7748 18.5686 20.5436 20.6391 18.5698 20.8685C18.4505 20.8818 18.3302 20.8952 18.2098 20.9075Z"
          fill="#FFBF23"
        />
      </g>
      <defs>
        <clipPath id="clip0_11944_17219">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
