import { AxiosRequestConfig } from 'axios'
import { useRecoilValue } from 'recoil'

import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

import { authStateCommon } from '@pig-common/recoils'
import { useAxios } from '@pig-common/hooks/useAxios'

export type QRDepositRequest = {
  amount: number
}

export type QRCodeInfo = {
  id: number
  generate_amount: string
  request_amount: string
  customer_code: string
  bank_account_number: string
  bank_code: string
  bank_name: string
  bank_promptpay_no: string
  is_transferred: number
  qr_ref_code: string
  qr_url: string
  created_timestamp: string
  expired_at: string
  last_updated_timestamp: string
}

export type QRDepositResponse = BaseApiResponse<QRCodeInfo>

export type QRHook = {
  callGenerateDepositQR: (
    request: QRDepositRequest,
  ) => Promise<QRDepositResponse>
}

export const useQR = (): QRHook => {
  const { boApiInstance } = useAxios()
  const auth = useRecoilValue(authStateCommon.authState)
  const callGenerateDepositQR = async (
    request: QRDepositRequest,
  ): Promise<any> => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/payment/deposit/generate-qr',
      method: 'POST',
      data: {
        player_name: auth.customerCode,
        request_amount: request.amount,
      },
    }
    const response = await fetchWithJSON<any>(boApiInstance, requestConfig)
    return response
  }
  return {
    callGenerateDepositQR,
  }
}
