import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

export type UploadFileBigWinRequest = {
  uid: string
  formData: any
}

export type UploadFileBigWinData = {
  message: string
  image_url: string
}

type UploadFileBigWinResponse = BaseResponse & {
  status: string
  data: UploadFileBigWinData
}

const uploadBigWinFile = async (
  request: UploadFileBigWinRequest,
): Promise<UploadFileBigWinResponse | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/casino/bigwin-upload-file/${request.uid}`,
      method: 'POST',
      data: request.formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }

    const result = await fetchWithJSON<UploadFileBigWinResponse>(
      pigspinApiInstance,
      requestConfig,
    )

    return result
  } catch (error) {
    return null
  }
}

export default uploadBigWinFile
