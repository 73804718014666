import React from 'react'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { getBankFormat, getPhoneFormat } from '@pig-common/utils/text-operation'
import { Image } from '@pig-frontend/uikit'
import { walletAssetUrl } from '@pig-common/utils/utils'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import { Row, Col } from 'antd'
import { notification } from '@pig-frontend/uikit'
import styled from 'styled-components'

export enum DEPOSIT_TYPE {
  TRUE = 'true',
  BANK = 'bank',
}

export type DepositInfoProps = {
  id?: string
  bank?: string
  accountName?: string
  bankNumber?: string
  type?: DEPOSIT_TYPE
  logoName: string
}

const DepositInfoCol = styled(Col)`
  flex-flow: row nowrap;
  padding: 8px;
  line-height: 1;
  font-size: 20px;
`

const CopyButton = styled(Button)`
  height: 36px;
`

const DepositInfo = ({
  id = 'deposit-info',
  bank = '',
  accountName = '',
  bankNumber = '',
  type = DEPOSIT_TYPE.BANK,
  logoName,
}: DepositInfoProps): JSX.Element => {
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })

  const copyToClipBoard = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    text: string,
  ) => {
    const button = event.currentTarget
    const buttonContainer = event.currentTarget.closest('.flasher')
    button?.classList.remove('flashing')
    buttonContainer?.classList.remove('flashing')
    setTimeout(() => {
      button?.classList.add('flashing')
      buttonContainer?.classList.add('flashing')
    }, 0.1)

    const textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    refreshSystemStatus()
    notification.success({
      message: 'คัดลอกสำเร็จแล้ว',
      duration: 6,
    })
  }
  const getBankName = (text: string) => {
    const index = text.indexOf(' ')
    if (index === -1) {
      return text
    }
    return text.substring(index, text.length)
  }

  return (
    <div id={id} data-testid={id} className="ps-deposit-info flasher">
      <Row align="middle">
        <DepositInfoCol flex="100px">
          <Image
            className="ps-deposit-info__cover"
            src={walletAssetUrl(`bank-asset/${logoName}`)}
            width={100}
            height={100}
            alt="bank-cover"
          />
        </DepositInfoCol>
        <DepositInfoCol flex="1 1 0">
          <div>{type === DEPOSIT_TYPE.TRUE ? bank : getBankName(bank)}</div>
          {type === DEPOSIT_TYPE.BANK && <div>{accountName}</div>}
          <div>
            {type === DEPOSIT_TYPE.TRUE
              ? getPhoneFormat(bankNumber)
              : getBankFormat(bankNumber)}
          </div>
          {/* <div className="ps-deposit-info__texts">
			</div> */}
        </DepositInfoCol>
        <DepositInfoCol flex="none">
          <CopyButton
            id={`${id}__copy-btn`}
            onClick={(e) => {
              copyToClipBoard(e, bankNumber)
            }}
            type={BUTTON_TYPE.OUTLINE_GREY}
            size={BUTTON_SIZE.LG}
          >
            {type === DEPOSIT_TYPE.TRUE ? 'คัดลอกหมายเลข' : 'คัดลอก'}
          </CopyButton>
        </DepositInfoCol>
      </Row>
      {/* <div className="row mb-1">
		  <div className="col-4">
			<img className="ps-deposit-info__cover" src={walletAssetUrl(`bank-asset/${logoName}`)} alt="bank-cover" />
		  </div>
		  <div className="col-4">
			<div className="ps-deposit-info__texts">
			  <div className="ps-deposit-info__text ps-deposit-info__text--info">{type === DEPOSIT_TYPE.TRUE ? bank : getBankName(bank)}</div>
			  {type === DEPOSIT_TYPE.BANK && <div className="ps-deposit-info__text ps-deposit-info__text--info">{accountName}</div>}
			  <div className="ps-deposit-info__text ps-deposit-info__text--info">
				{type === DEPOSIT_TYPE.TRUE ? getPhoneFormat(bankNumber) : getBankFormat(bankNumber)}
			  </div>
			</div>
		  </div>
		  <div className="col-4">
			<Button
			  id={`${id}__copy-btn`}
			  onClick={(e) => { copyToClipBoard(e, bankNumber); }}
			  type={BUTTON_TYPE.OUTLINE_GREY}
			  size={BUTTON_SIZE.LG}
			>
			  {type === DEPOSIT_TYPE.TRUE ? t('deposit.copyPhoneNumber') : t('deposit.copyAccountNumber')}
			</Button>
		  </div>
		</div> */}
      {/* <div className="ps-deposit-info__btn-container">
		</div> */}
    </div>
  )
}

export default DepositInfo
