import { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { Section, Image, ProfileAvatar } from '@pig-frontend/uikit'
import { UserProfile, UserExpBar } from '@pig-common/components/User'
import useProfile from '@pig-common/hooks/useProfile'
import castArray from 'lodash/castArray'
import {
  VIPRewards,
  VIPBenefitListing,
  Achievement,
} from '@pig-common/components/vip'
import useVIPStatus from '@pig-common/hooks/useVipStatus'
import useGuild from '@pig-common/hooks/useGuild'
import { useVip } from '@pig-common/hooks/useVip'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { CloseOutlined, RightOutlined } from '@ant-design/icons'
import styles from './index.module.scss'
import UserBadge from '@pig-common/components/User/avatar/UserBadge'
import useVIPSummary from '@pig-common/hooks/useVipSummary'
import { useRouter } from 'next/router'

export default function VipPage() {
  const router = useRouter()
  useVIPStatus({})
  const { vipTierList } = useVip()
  const { data: guildData } = useGuild({})
  const { data: vipSummary } = useVIPSummary({})
  const { data: profile } = useProfile({})
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const AllBenefitModal = useCallback(() => {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    return (
      <>
        <div className={styles['benefit-modal']}>
          <div className={styles.title}>
            <h3>สิทธิประโยชน์ทั้งหมด</h3>
            <CloseOutlined
              className={styles['close-icon']}
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div className={styles['benefit-list']}>
            <ul>
              {vipTierList.map((rewards) => (
                <li key={rewards.vip_master.level}>
                  <div className={styles['badge-row']}>
                    <UserBadge
                      tier={rewards?.vip_master.tier}
                      level={rewards?.vip_master.level}
                    />
                  </div>
                  {`${rewards?.vip_reward.map((reward) => `${reward.detail}`)}`}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <button
          className={styles['modal-backdrop']}
          onClick={() => setModalOpen(false)}
        />
      </>
    )
  }, [vipTierList])
  useEffect(() => {
    isModalOpen
      ? (document.getElementsByTagName('body')[0].style.overflowY = 'hidden')
      : (document.getElementsByTagName('body')[0].style.overflowY = 'auto')
  })
  return (
    <Section className={styles.container} margin={false} sectionId="vip">
      <div className={styles.avatar}>
        <ProfileAvatar name={profile?.avatar} width={56} height={56} />
        {guildData?.myGuild?.info?.iconUrl && (
          <Image
            src={guildData?.myGuild?.info?.iconUrl || ''}
            width={28}
            height={28}
            alt="guild"
            className={styles.guild}
          />
        )}
      </div>
      <UserProfile />
      <UserExpBar className={styles.exp} />

      <div className={cx(styles.benefits, styles.divider)}>
        <div className={styles['title-group']}>
          <h4>สิทธิประโยชน์</h4>
          <span
            className={styles.benefit}
            aria-hidden
            role="button"
            onClick={() => setModalOpen(true)}
          >
            ดูสิทธิประโยชน์ทั้งหมด
          </span>
          {isModalOpen && <AllBenefitModal />}
        </div>
        <VIPBenefitListing benefits={vipSummary?.nextVip} />
      </div>

      <div className={styles.benefits}>
        <h4>ได้รับแล้ว</h4>
        <VIPBenefitListing
          benefits={castArray(vipSummary?.currentVip)}
          isClaimed
        />
      </div>

      <div className={cx([styles.achievement, styles.divider])}>
        <div className={styles.header}>
          <h4>ความสำเร็จ</h4>
          <RightOutlined
            role="button"
            className={styles['right-icon']}
            onClick={() => router.push(pageLinkCommon.achievement)}
          />
        </div>
        <Achievement />
      </div>

      <div className={cx([styles.rewards, styles.divider])}>
        <h4>ระดับ VIP</h4>
        <VIPRewards />
      </div>
    </Section>
  )
}
