import { useQuery } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IGuildConfigResponse } from '@pig-common/types/Guild.type'
import { camelizeKeys } from '@pig-frontend/utils'

interface IGetGuildConfigTypes {
  select?: (data: CommonResponse<IGuildConfigResponse>) => IGuildConfigResponse
  enabled?: boolean
  refetchInterval?: number
}

export default function useGuildConfig({
  select,
  enabled,
}: IGetGuildConfigTypes) {
  return useQuery({
    queryKey: [guildApi.key.getConfig],
    queryFn: () => guildApi.getConfig(),
    enabled,
    select: (data) => {
      return select
        ? select(data)
        : (camelizeKeys(data.data) as IGuildConfigResponse) || {}
    },
  })
}
