import React, { CSSProperties } from 'react'
import classNames from 'classnames'

export enum RANKING_CARD_STATE {
  DEFAULT = 'default',
  EMPTY = 'empty',
  ACTIVE = 'active',
  ACTIVE_AND_REWARD = 'active-and-reward',
}

export type RankingCardProps = {
  position: number | string
  state?: RANKING_CARD_STATE
  title: string
  description?: string
  turnOverValue?: number | string
  className?: string
  style?: CSSProperties
}

const RankingCard = ({
  position,
  state = RANKING_CARD_STATE.DEFAULT,
  title,
  description = '',
  turnOverValue = 0,
  className = '',
  style = {},
}: RankingCardProps): JSX.Element => {
  const rankingCardClassNames = classNames(
    {
      'ps-ranking-card': state === RANKING_CARD_STATE.DEFAULT,
      'ps-ranking-card ps-ranking-card--empty':
        state === RANKING_CARD_STATE.EMPTY,
      'ps-ranking-card ps-ranking-card--active':
        state === RANKING_CARD_STATE.ACTIVE,
      'ps-ranking-card ps-ranking-card--active ps-ranking-card--reward':
        state === RANKING_CARD_STATE.ACTIVE_AND_REWARD,
    },
    className,
  )

  const hasReward =
    state === RANKING_CARD_STATE.ACTIVE_AND_REWARD && description?.length > 0

  return (
    <div className={rankingCardClassNames} style={style}>
      <div className="ps-ranking-card__label">
        <strong className="ps-ranking-card__position">{position}</strong>
      </div>
      <div className="ps-ranking-card__content">
        <div className="ps-ranking-card__wrapper">
          <div className="ps-ranking-card__title">{title}</div>
          {hasReward && (
            <div className="ps-ranking-card__description">{description}</div>
          )}
        </div>
        <div className="ps-ranking-card__value">{turnOverValue}</div>
      </div>
    </div>
  )
}

export default RankingCard
