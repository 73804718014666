import React from 'react'
import classNames from 'classnames'

export enum SKELETON_SIZE {
  S = 0.5,
  M = 0.8,
  L = 1,
  XL = 1.5,
}

export const SkeletonCover = ({ active }) => {
  return <div className="ps-skeleton-cover" />
}

{
  /*
export const SkeletonCover = ({
  active,
  width,
  height,
  ...props
}: {
  active: boolean,
  width?: string;
  height?: string;
}): JSX.Element => {
  const style = { 
    width: width || undefined,
    height: height || undefined,
  };  
  return active ? ( 
    <div
      {...props}
      className="ps-skeleton-cover"
      style={style}
    />
  ) : (<></>)
}
*/
}

export const SkeletonText = ({
  size = SKELETON_SIZE.M,
  char,
  ...props
}: {
  size: SKELETON_SIZE
  char?: number
}): JSX.Element => {
  const style = {
    margin: `${size * 1}rem 0rem`,
    width: char ? `${char * 1}rem` : undefined,
  }
  return <div className="ps-skeleton-text" style={style} {...props} />
}
export const SkeletonByClass = ({
  className,
  amount = 1,
}: {
  className: string
  amount?: number
}): JSX.Element => {
  const result: JSX.Element[] = []
  for (let i = 0; i < amount; i += 1) {
    result.push(
      <div className={classNames('ps-skeleton-element', className)} />,
    )
  }
  return <>{result}</>
}
export const SkeletonBox = ({
  className,
  amount = 1,
  width = '100%',
  height = '16px',
}: {
  className?: string
  amount?: number
  width?: string
  height?: string
}): JSX.Element => {
  const result: JSX.Element[] = []
  for (let i = 0; i < amount; i += 1) {
    result.push(
      <div
        className={classNames('ps-skeleton', className)}
        style={{ width, height }}
      />,
    )
  }
  return <>{result}</>
}
