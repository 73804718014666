import React from 'react'

export const IconBonus: React.FunctionComponent = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11944_7677)">
        <path
          d="M20.0493 39.9452C31.0558 39.9452 39.9784 31.0226 39.9784 20.0161C39.9784 9.0095 31.0558 0.0869141 20.0493 0.0869141C9.0427 0.0869141 0.120117 9.0095 0.120117 20.0161C0.120117 31.0226 9.0427 39.9452 20.0493 39.9452Z"
          fill="#DECAFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0348 33.9473C26.4371 33.9187 31.0645 28.129 31.2005 22.7816C31.2816 19.5827 29.7422 17.1382 28.9342 16.0376C22.9856 16.0582 17.0359 16.0799 11.0873 16.1005C10.6268 16.753 8.03019 20.5565 9.12391 25.281C10.2462 30.1359 14.8016 33.9702 20.0348 33.9473Z"
          fill="#F0F1F1"
        />
        <path
          d="M19.9075 6.0583C19.9692 5.97601 20.0937 5.97601 20.1555 6.0583L24.2732 11.2629C24.3555 11.3634 24.28 11.5143 24.1486 11.5143H21.2035V16.3429H18.8583V11.5143H15.9132C15.7829 11.5143 19.9075 6.0583 19.9075 6.0583Z"
          fill="#FFBF23"
        />
        <path
          d="M30.7437 16.7292H9.34709C9.2168 16.7292 9.10938 16.6184 9.10938 16.4835V14.6378C9.10938 14.5029 9.2168 14.3921 9.34709 14.3921H30.7437C30.874 14.3921 30.9814 14.5029 30.9814 14.6378V16.4835C30.9814 16.6184 30.874 16.7292 30.7437 16.7292Z"
          fill="#6729FF"
        />
        <path
          d="M19.4912 18.8058C19.4912 18.6743 19.5781 18.5669 19.6855 18.5669H20.6604C20.7678 18.5669 20.8546 18.6732 20.8546 18.8058V29.48C20.8546 29.6115 20.7678 29.7189 20.6604 29.7189H19.6855C19.5781 29.7189 19.4912 29.6126 19.4912 29.48V18.8058Z"
          fill="#FFBF23"
        />
        <path
          d="M20.984 27.5015C21.208 27.5015 21.4057 27.4684 21.5771 27.4009C21.7508 27.3335 21.8948 27.2421 22.008 27.1255C22.1245 27.0055 22.2137 26.8649 22.2731 26.7038C22.3337 26.5404 22.3634 26.3632 22.3634 26.1724C22.3634 25.9552 22.3165 25.7712 22.2217 25.6204C22.1268 25.4661 22.0023 25.3415 21.8468 25.2444C21.6925 25.1472 21.5165 25.0775 21.3211 25.0341C21.128 24.9872 20.9325 24.9644 20.7337 24.9644H18.5943V27.5026H20.984V27.5015ZM20.0171 19.3849C20.2251 19.3849 20.4537 19.3884 20.6994 19.3952C20.9463 19.3986 21.1908 19.4135 21.4343 19.4398C21.6811 19.4626 21.9143 19.5015 22.136 19.5552C22.36 19.6089 22.5531 19.6855 22.7143 19.7861C22.8845 19.8935 23.0457 20.0181 23.1977 20.1621C23.3497 20.3061 23.4823 20.4695 23.5954 20.6535C23.7097 20.8375 23.7988 21.0398 23.8651 21.2604C23.9314 21.4809 23.9645 21.7221 23.9645 21.9826C23.9645 22.2032 23.9348 22.4135 23.8743 22.6146C23.8171 22.8158 23.7371 23.0021 23.632 23.1769C23.5314 23.3472 23.4103 23.5026 23.272 23.6432C23.136 23.7838 22.9885 23.9038 22.8308 24.0044C23.0994 24.0741 23.3337 24.1884 23.5325 24.3449C23.7314 24.4992 23.896 24.6809 24.0251 24.8912C24.1577 25.1015 24.256 25.3312 24.3188 25.5781C24.3851 25.8226 24.4183 26.0684 24.4183 26.3152C24.4183 26.6866 24.3474 27.0478 24.2045 27.3986C24.0628 27.7461 23.8525 28.0535 23.5737 28.3221C23.2983 28.5895 22.9577 28.8055 22.5497 28.9689C22.1451 29.1289 21.6811 29.2101 21.1565 29.2101H16.5371V19.3838H20.0171V19.3849ZM20.3623 23.3929C20.6503 23.3929 20.8937 23.3666 21.0925 23.3129C21.2914 23.2558 21.4525 23.1781 21.576 23.0775C21.6994 22.9735 21.7874 22.8501 21.8411 22.7061C21.8983 22.5621 21.9268 22.4021 21.9268 22.2249C21.9268 21.9872 21.8857 21.7952 21.8034 21.6478C21.7245 21.5004 21.6137 21.3872 21.472 21.3072C21.3325 21.2238 21.1668 21.1689 20.9748 21.1415C20.7851 21.1118 20.5817 21.0969 20.3634 21.0969H18.5943V23.3941H20.3623V23.3929Z"
          fill="#FFBF23"
        />
      </g>
      <defs>
        <clipPath id="clip0_11944_7677">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
