import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import { numberWithCommas } from '@pig-common/utils/number'
import { TRANSACTION_COLLAPSE_VARIANT } from './TransactionCollapse.type'
import BigNumber from 'bignumber.js'

export type BonusInformationContentLocaleText = {
  turnOverText?: string
  turnOverDetailText?: string
  fromTotalText?: string
  remainText?: string
  betText?: string
  currency?: string
}

export type BonusInformationContentProps = {
  variant?: TRANSACTION_COLLAPSE_VARIANT
  turnOverText: string
  turnOverDetailText: string
  remainText: string
  fromTotalText: string
  betText: string
  remain: number
  total: number
  percentBet: number
  currency: string
}

const BonusInformationContent = ({
  variant = TRANSACTION_COLLAPSE_VARIANT.PRIMARY,
  turnOverText,
  turnOverDetailText,
  fromTotalText,
  total,
  remainText,
  remain,
  betText,
  percentBet,
  currency = '฿',
}: BonusInformationContentProps) => {
  return (
    <div className="ps-bonus-information-content">
      <div className="ps-bonus-information-content__left">
        <span>{turnOverText}</span>
        <span className="ps-bonus-information-content__left--turnover-detail">{`(${turnOverDetailText})`}</span>
        <div className="ps-bonus-information-content__left--bonus-layout">
          <div className="ps-bonus-information-content__left--bonus-title">
            <span>{remainText} : </span>
            <span
              data-testid="bonus-information-content__remain"
              className="ps-bonus-information-content__remain"
            >{`${numberWithCommas(remain, 2)} ${currency}`}</span>
            <br />
            <span>{fromTotalText} : </span>
            <span
              data-testid="bonus-information-content__total"
              className="ps-bonus-information-content__total"
            >{`${numberWithCommas(total, 2)} ${currency}`}</span>
          </div>
        </div>
      </div>
      <div className="ps-bonus-information-content__right">
        <CircularProgressbarWithChildren
          value={percentBet}
          className="ps-bonus-information-content__progressbar"
          backgroundPadding={0}
          styles={buildStyles({
            trailColor: '#6B6B6B',
            pathColor: '#FFBF23',
          })}
        >
          <span
            data-testid="bonus-information-content__right--title"
            className="ps-bonus-information-content__right--title"
          >
            {betText}
          </span>
          <span
            data-testid="bonus-information-content__right--percent"
            className="ps-bonus-information-content__right--percent"
          >
            {`${BigNumber(total ?? 0)
              .minus(remain ?? 0)
              .toFormat(2)} ฿`}
          </span>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  )
}

export default BonusInformationContent
