import NiceModal from '@ebay/nice-modal-react'
import Vip from '@pig-common/pages-common/vip'

function VipPage() {
  return (
    <NiceModal.Provider>
      <Vip />
    </NiceModal.Provider>
  )
}

export { VipPage }
