import { screen } from '@pig-common/utils/responsive'
import styled from 'styled-components'

export const AchivementContainer = styled.div`
  padding-top: 24px;
`

export const TournamentContainer = styled.div`
  max-width: 600px;
  padding-top: 24px;
  margin: auto auto 64px auto;
  font-size: 0.8333rem;
  min-height: 290px;

  @media ${screen.sm} {
    min-height: 320px;
  }

  @media ${screen.md} {
    min-height: 500px;
  }
`

export const TournamentEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  height: 250px;
  color: ${({ theme }) => theme['color-greyscale-300']};
`

export const TournamentHeader = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 120px;
  padding: 12px 24px;
  color: ${({ theme }) => theme['color-primary-600']};
`

export const TournamentContent = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 120px;
  background-image: ${({ theme }) =>
    `linear-gradient(90deg, ${theme['color-butterscotch-400']}, ${theme['color-white']}, ${theme['color-white']}, ${theme['color-white']})`};
  border-radius: 4px;
  padding: 12px 24px;
  margin-top: 5px;
`

export const TournamentName = styled.span`
  font-weight: 900;
`

export const HeaderReward = styled.span`
  text-align: right;
`

export const Reward = styled(HeaderReward)`
  font-weight: 900;
  color: ${({ theme }) => theme['color-primary-1']};
`

export const OverviewContainer = styled.div`
  padding: 100px 0;
  text-align: center;
  color: ${({ theme }) => theme['color-white']};
  background: url('/images/assets/page-profile-details-overview.webp');
  background-repeat: no-repeat;
  background-size: cover;
`

export const OverviewBody = styled.div`
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const SubContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Divider = styled.div`
  border: 0.5px solid ${({ theme }) => theme['color-primary-100']};
`

export const TotalTurnover = styled.span`
  font-size: 60px;
  font-weight: 500;
`

export const OverviewText = styled.span`
  color: ${({ theme }) => theme['color-primary-200']};
`

export const OverviewTextContent = styled.span`
  font-size: 42px;
  font-weight: 400;
`

export const Space = styled.div`
  margin-bottom: 30px;
`
