import React from 'react'
import Router from 'next/router'

const ShopHeader = ({
  total_tickets,
}: {
  total_tickets: string
}): JSX.Element => {
  return (
    <div className="ps-shop-banner__info">
      <div className="ps-shop-banner__title">แลกรางวัล</div>
      <div className="ps-shop-banner__redemption">
        <div className="ps-shop-banner__tickets">
          <img
            alt="redeem-ticket"
            src="/images/dailyquests/white-ticket.png"
            className="ps-shop-banner__icon"
          />
          <span>{total_tickets}</span>
          Tickets
        </div>
        <button
          data-testid="shop-bubble-redeem-button"
          className="ps-shop-banner__button ps-button ps-button--cta-1 ps-button--sm d-block"
          onClick={() => Router.push('/daily-quests')}
        >
          <span>ดูภารกิจ</span>
        </button>
      </div>
    </div>
  )
}

export default ShopHeader
