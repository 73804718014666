import moment from 'moment'

function getLatestMonday(date: Date) {
  const today = new Date(date)
  const day = today.getDay()
  const lastMonday = new Date(date)
  lastMonday.setDate(today.getDate() - ((day + 6) % 7))
  return moment(lastMonday)
}

function getNextMonday(date: Date) {
  const today = new Date(date)
  const day = today.getDay()
  const todayIsMonday = day === 1
  if (todayIsMonday) return moment(date).add(1, 'weeks')
  const nextMonday = new Date(date)
  nextMonday.setDate(today.getDate() + ((7 - day + 1) % 7))
  return moment(nextMonday)
}

export function findCashbackDateRangeOf(
  date: Date,
  range?: 'latest' | 'next',
): Date[] {
  const startDate =
    range === 'next' ? getNextMonday(date) : getLatestMonday(date)
  return [
    startDate.startOf('days').toDate(),
    startDate.add(6, 'days').endOf('days').toDate(),
  ]
}
