import { Button } from '@pig-frontend/uikit'

type AddMoneyButtonProps = {
  id?: string
  className?: string
  amount: number
  onClick?: () => void
  disabled?: boolean
}

const AddMoneyButton = ({
  id = 'add-money-btn',
  amount,
  className = '',
  onClick = () => {},
  disabled,
}: AddMoneyButtonProps) => {
  return (
    <Button
      id={id}
      data-testid={id}
      className={`ps-add-money-button ${className}`}
      type="primary"
      onClick={onClick}
      disabled={disabled}
      tabIndex={-1}
    >
      +{amount}
    </Button>
  )
}

export default AddMoneyButton
