import {
  LoginResponse,
  LOGIN_STATUS,
  requestLogin,
} from '@pig-common/services/login'
import { mapToLoginResponse } from '@pig-common/mappers/mapToLoginResponse'
import { requestLineLogin } from '@pig-common/services/login/login'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'
import { authStateCommon } from '@pig-common/recoils'
import { useRecoilState } from 'recoil'

export type LoginRequest = {
  phoneNumber: string
  pin: string
}

export type LoginLineRequest = {
  idToken: string
}

export type LoginHook = {
  callLogin: (request: LoginRequest) => Promise<LoginResponse>
  callLineLogin: (request: LoginLineRequest) => Promise<LoginResponse>
  mutateLogin: UseMutationResult<LoginResponse, unknown, LoginRequest, unknown>
}

export const useLogin = (): LoginHook => {
  const [auth, setAuth] = useRecoilState(authStateCommon.authState)
  const [, setCookies] = useCookies<string>([
    COOKIES_KEY.CFID,
    COOKIES_KEY.UID,
    COOKIES_KEY.LINE_DATA,
    COOKIES_KEY.CUSTOMER_CODE,
  ])

  const callLogin = async (request: LoginRequest): Promise<LoginResponse> => {
    const { phoneNumber, pin } = request
    let response: LoginResponse = {
      Status: LOGIN_STATUS.CLIENT_EXCEPTION,
      cfid: '',
      uid: '',
      Phone: '',
      CustomerCode: '',
    }
    const newResponse = await requestLogin({
      phone: phoneNumber,
      pin,
    })
    response = mapToLoginResponse(newResponse)
    return response
  }
  const callLineLogin = async (
    request: LoginLineRequest,
  ): Promise<LoginResponse> => {
    const { idToken } = request
    let response: LoginResponse = {
      Status: LOGIN_STATUS.CLIENT_EXCEPTION,
      cfid: '',
      uid: '',
      Phone: '',
      CustomerCode: '',
    }
    const newResponse = await requestLineLogin({
      id_token: idToken,
    })
    response = mapToLoginResponse(newResponse)
    return response
  }
  const mutateLogin = useMutation({
    mutationFn: callLogin,
    onSuccess: (response) => {
      const expires = dayjs().add(3, 'months').toDate()
      setCookies(COOKIES_KEY.CFID, response.cfid, { expires, path: '/' })
      setCookies(COOKIES_KEY.UID, response.uid || '0000000000', { path: '/' })
      setCookies(COOKIES_KEY.CUSTOMER_CODE, response.CustomerCode || '', {
        path: '/',
      })
      setAuth({
        ...auth,
        cfid: response.cfid,
        customerCode: response.CustomerCode,
        gameToken: response.GameToken,
        name: response.CustomerName,
        isLoginPhoneSuccess: true,
        userUID: response.UserUid || '',
      })
    },
  })

  return {
    callLogin,
    callLineLogin,
    mutateLogin,
  }
}
