import React, { useImperativeHandle, useState } from 'react'
import { useRecoilState } from 'recoil'
import router from 'next/router'

import { registerStateCommon } from '@pig-common/recoils'
import {
  StepperChildrenProps,
  StepperChildrenHandles,
} from '@pig-common/components/Stepper'
import { TextLink } from '@pig-common/components/Button'
import { AvatarDetail, CreateAvatar } from '@pig-common/components/CreateAvatar'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'

type RegisterInfoProps = StepperChildrenProps

const RegisterInfo = React.forwardRef<
  StepperChildrenHandles,
  RegisterInfoProps
>(({ next, isLoading }, ref) => {
  const [register, setRegister] = useRecoilState(
    registerStateCommon.registerState,
  )
  const [, setCookies] = useCookies()
  const [registerInfoData, setRegisterInfoData] = useState<AvatarDetail>({
    avatar: '',
    nickName: '',
    isValidNickName: false,
  })

  const getChildrenData = () => {
    return registerInfoData
  }

  useImperativeHandle(ref, () => ({
    getChildrenData,
  }))

  const submitDataHandler = async (data: AvatarDetail) => {
    setRegisterInfoData(data)
    next()
  }

  const onClickTextLink = () => {
    setCookies(COOKIES_KEY.EXISTING_PHONE, register.phoneNumber)
    setCookies(COOKIES_KEY.EXISTING_PIN, register.pin)
    router.push(
      {
        pathname: '/login',
        query: { phone: register.phoneNumber, pin: register.pin },
      },
      '/login',
    )
  }

  return (
    <div className="ps-register-with-phone">
      <CreateAvatar
        isLoading={isLoading}
        onSubmit={submitDataHandler}
        onNickNameChange={(nickName: string) => {
          if (nickName) setRegister({ ...register, isError: false })
        }}
        isError={register.isError}
        errorMessage={register.errorMessage}
      />
      <TextLink
        className="ps-register-with-phone__already-member"
        onClick={onClickTextLink}
      >
        เคยสมัครด้วย LINE แล้ว ?
      </TextLink>
    </div>
  )
})

RegisterInfo.displayName = 'RegisterInfo'

export default RegisterInfo
