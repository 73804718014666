// import { Skeleton } from 'antd';
import React from 'react'
import Link from 'next/link'
import RedemptionRefreshButton from '../ShopRedemption/RedemptionRefreshButton'
import {
  SKELETON_SIZE,
  SkeletonText,
} from '@pig-common/components/Skeleton/skeleton'
import { RightOutlined } from '@ant-design/icons'

export const SectionHeader = ({
  id,
  header,
  subheader,
  linkText,
  skeleting,
  refresher,
  linkTo,
}: {
  id: string
  header: string
  subheader?: JSX.Element
  linkText?: string
  skeleting?: boolean
  refresher?: () => void
  linkTo?: () => void
}) => {
  return (
    <>
      {skeleting ? (
        <>
          <SkeletonText size={SKELETON_SIZE.L} char={6} />
          <SkeletonText size={SKELETON_SIZE.L} char={5} />
        </>
      ) : (
        <>
          <div
            className="ps-section-header col-12 d-flex justify-content-between"
            data-testid="section-header"
          >
            {header}
            {refresher && (
              <div className="ps-section-header__refresh">
                <RedemptionRefreshButton
                  //id={id}
                  buttonFunction={refresher}
                />
              </div>
            )}
            {linkTo && (
              <Link className="ps-gamequests__title-link" href="/champion">
                <span className="d-flex" style={{ gap: '5px' }}>
                  {linkText || 'เพิ่มเติม'}
                  <RightOutlined style={{ fontSize: '0.5em' }} />
                </span>
              </Link>
            )}
          </div>
          {subheader}
        </>
      )}
    </>
  )
}

export default SectionHeader
