import {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react'

import {
  StepperChildrenProps,
  StepperChildrenHandles,
} from '@pig-common/components/Stepper'
import {
  InputDigit,
  InputDigitHandles,
} from '@pig-common/components/InputDigit'

export type SetPinProps = StepperChildrenProps

const SetPin = forwardRef<StepperChildrenHandles, SetPinProps>(
  ({ next, getStepData, previousStep }, ref) => {
    const [userPin, setUserPin] = useState<string>('')
    const [disabled, setDisabled] = useState(false)
    const digitInputRef = useRef<InputDigitHandles>(null)
    const stepConfirmPinData = getStepData(4)

    useEffect(() => {
      // NOTE : To confirm created Ref.
      if (stepConfirmPinData?.isError === true && previousStep === 4) {
        setTimeout(() => {
          digitInputRef.current?.errorWithOutAnimation(
            'รหัส PIN ไม่ตรงกัน กรุณาลองใหม่อีกครั้ง',
          )
        })
      }
    }, [])

    const getChildrenData = () => {
      const pin = userPin

      return { pin }
    }

    useImperativeHandle(ref, () => ({
      getChildrenData,
    }))

    const setPinHandler = async (pin: string) => {
      setDisabled(true)
      setUserPin(pin)
      next()
    }

    return (
      <div className="text-center">
        <InputDigit
          label=""
          ref={digitInputRef}
          onComplete={setPinHandler}
          disabled={disabled}
        />
      </div>
    )
  },
)

SetPin.displayName = 'SetPin'
export default SetPin
