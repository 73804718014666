import { CashTransactionHistory } from '@pig-common/hooks/useWalletHistory'
import groupRelativeDate from '@pig-common/utils/groupRelativeDate'
import { CashTransactionHistoryGroup } from './TransactionHistorySection'
import { BonusInformation } from '@pig-common/components/TransactionCollapse'

export const buildCashTransactionGroupList = (
  rawList: CashTransactionHistory[] | BonusInformation[],
): CashTransactionHistoryGroup => {
  return groupRelativeDate<CashTransactionHistoryGroup>(
    rawList,
    null,
    new Date(),
    true,
    'rawDateTime',
  ) as CashTransactionHistoryGroup
}

export const buildBonusTransactionGroupList = (
  rawList: CashTransactionHistory[],
): CashTransactionHistoryGroup => {
  return groupRelativeDate<CashTransactionHistoryGroup>(
    rawList,
    null,
    new Date(),
    true,
    'rawDateTime',
  ) as CashTransactionHistoryGroup
}
