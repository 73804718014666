import { CoinWalletResultType } from '@pig-common/hooks/useCoinWallet'

export type TransactionIcon = 'check-filled' | 'caution-filled' | 'processing'
export enum TRANSACTION_COLLAPSE_VARIANT {
  NONE = 'none', // NOTE : For Solid black color
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  SUCCESS_OUTLINE = 'success-outline',
  WARNING = 'warning',
  DANGER = 'danger',
}

// TODO : Implement for bonus mode soon...
export type TransactionMode = 'cash' | 'bonusInformation' | 'bonusHistory'

export type BonusInformation = {
  displayVariant?: TRANSACTION_COLLAPSE_VARIANT
} & CoinWalletResultType
export type BonusInformationGroup = {
  dataList: BonusInformation[]
}[]
