import React, { useEffect, useState } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { Spin } from 'antd'
import classNames from 'classnames'
import { useRecoilValue } from 'recoil'
import { profileStateCommon } from '@pig-common/recoils'
import {
  SelectInput,
  SelectOptionProps,
} from '@pig-common/components/SelectInput'
import { Input } from '@pig-common/components/Input'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import { BankCodeName, BankInfo } from '@pig-common/models/shared-types'
import { bankList } from '@pig-common/models/static-constant'
import { BANK_IMAGE_PATH } from '@pig-common/models/buildtime-constant'
import {
  useCheckBank,
  VerifyBankAccountResult,
  VERIFY_BANK_ACCOUNT_RESPONSE_STATUS,
  VERIFY_BANK_ACCOUNT_STATUS_CODE,
} from '@pig-common/hooks/useCheckBank'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { AlertConfirmModal } from '../Modal/AlertConfirmationModal/AlertConfirmationModal'

export type BankFormType = {
  selectedBank?: BankCodeName
  bankAccountNumber?: string
  selectedBankName?: string
  selectedBankLogo?: string
  bankAccountName?: string
}

export type BankFormCallBack = BankFormType & {
  result: VerifyBankAccountResult
}

export type IBankFormProps = {
  onFinish?: (result: BankFormCallBack) => void
}

export type BankFormHandler = {
  setBankAccount: (form: BankFormType) => void
  disableBankAccount: (disabled: boolean) => void
}

const BankFormComponent = React.forwardRef<BankFormHandler, IBankFormProps>(
  (props, ref) => {
    const profile = useRecoilValue(profileStateCommon.profileState)
    const fullName =
      profile?.firstName && profile?.lastName
        ? `${profile?.firstName} ${profile?.lastName}`
        : ''
    const bankFullName =
      profile?.bankAccountList && profile?.bankAccountList.length > 0
        ? profile?.bankAccountList[0].bankAccountName
        : ''
    const [disabledAccountBank, setDisabledAccountBank] = useState(false)
    const [selectedBankName, setSelectedBankName] = useState<
      string | undefined
    >('')
    const [selectedBankLogo, setSelectedBankLogo] = useState<
      string | undefined
    >('')
    const [selectedBank, setSelectedBank] = useState<BankCodeName | undefined>(
      undefined,
    )
    const [bankListOptions, setBankListOptions] = useState<SelectOptionProps[]>(
      [],
    )
    const [bankAccountNumber, setBankAccountNumber] = useState<
      string | undefined
    >('')
    const [bankAccountName, setBankAccountName] = useState<string | undefined>(
      '',
    )
    const [banks, setBanks] = useState<BankInfo[]>([])
    const [bankLength, setBankLength] = useState(12)
    const [isValidBank, setIsValidBank] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isErrorBankAccount, setIsErrorBankAccount] = useState(false)
    const { callProfile } = useLegacyProfile()
    const { callCheckBank } = useCheckBank()
    const getBankByCode = (bankCode: BankCodeName | undefined) => {
      return banks.find((bank) => bank.bankCode === bankCode)
    }
    const updateBankLength = (bankCode: BankCodeName) => {
      const bank = getBankByCode(bankCode)
      setBankLength(Number(bank?.bankNoLength))
    }
    const handleSelectInputChange = (selectedBankItem: SelectOptionProps) => {
      const bankCode = selectedBankItem.value as BankCodeName
      const bankName = selectedBankItem.label
      const bankLogo = selectedBankItem.iconImageSrc

      setSelectedBank(bankCode)
      setSelectedBankName(bankName)
      setSelectedBankLogo(bankLogo)
      updateBankLength(bankCode)

      if (bankCode && !isValidBank) {
        setIsValidBank(true)
      }
    }
    const checkValidBank = () => {
      if (selectedBank) {
        setIsValidBank(true)
      } else {
        setIsValidBank(false)
      }
    }
    const handleSelectedInputBlur = () => {
      checkValidBank()
    }
    const numberValidator = (value: any) => {
      return !Number.isNaN(+value)
    }
    const handleInputChange = (value: string) => {
      setIsErrorBankAccount(false)
      setBankAccountNumber(value)
    }

    const validateBankAccount = (allowEmpty = true) => {
      if (selectedBank) {
        if (!bankAccountNumber) {
          const isError = !allowEmpty && !bankAccountNumber
          setIsErrorBankAccount(isError)
        } else {
          const bank = getBankByCode(selectedBank)
          setIsErrorBankAccount(
            bankAccountNumber.length !== Number(bank?.bankNoLength),
          )
        }
      } else {
        setIsErrorBankAccount(false)
      }
    }

    const handleInputBlur = () => {
      validateBankAccount(false)
    }
    const generateBankList = () => {
      const options: SelectOptionProps[] = []
      bankList.forEach((bank) => {
        const { bankCode, bankName, bankLogo } = bank
        const iconImageSrc = `${BANK_IMAGE_PATH}/${bankLogo}`
        const label = bankName.replace(/ธนาคาร\s/i, '')
        options.push({ value: bankCode, label, iconImageSrc })
      })

      setBanks(bankList)
      setBankListOptions(options)
    }

    const onValidate = async () => {
      setIsLoading(true)
      const response = await callCheckBank({
        bank_account_number: bankAccountNumber || '',
        bank_account_name: bankAccountName || '',
        bank_code: selectedBank?.toString() || '',
      })
      setIsLoading(false)
      if (response.Status === VERIFY_BANK_ACCOUNT_RESPONSE_STATUS.ERROR) {
        switch (response.ErrorCode) {
          case VERIFY_BANK_ACCOUNT_STATUS_CODE.INVALID_ACCOUNT_INFO:
            setIsErrorBankAccount(true)
            break
          case VERIFY_BANK_ACCOUNT_STATUS_CODE.DUPLICATED_ACCOUNT:
          default:
            NiceModal.show(AlertConfirmModal, {
              open: true,
              hideOnClose: true,
              header: 'ตั้งค่าธนาคารไม่สำเร็จ',
              content: response.Message,
              onClose: () => {},
              onConfirm: () => {},
            })
            break
        }
      }
      if (props.onFinish) {
        props.onFinish({
          selectedBank,
          bankAccountNumber,
          selectedBankName,
          selectedBankLogo,
          bankAccountName,
          result: response,
        })
      }
    }

    const disabledButton = React.useMemo(() => {
      const bank = getBankByCode(selectedBank)
      const isValidBankAccountLength =
        bankAccountNumber?.length === Number(bank?.bankNoLength)
      const isValidBankAccountName =
        bankAccountName && bankAccountName.length > 0
      const isValidForm =
        selectedBank &&
        isValidBankAccountLength &&
        isValidBank &&
        !isErrorBankAccount &&
        isValidBankAccountName
      return !isValidForm
    }, [
      selectedBank,
      bankAccountNumber,
      isValidBank,
      isErrorBankAccount,
      bankAccountName,
    ])

    useEffect(() => {
      if (fullName) {
        setBankAccountName(fullName || '')
        setDisabledAccountBank(true)
      } else if (bankFullName) {
        setBankAccountName(bankFullName || '')
        setDisabledAccountBank(true)
      }
    }, [fullName, bankFullName])

    useEffect(() => {
      generateBankList()
      callProfile()
    }, [])

    useEffect(() => {
      validateBankAccount()
    }, [bankLength])

    React.useImperativeHandle(ref, () => ({
      setBankAccount: (form) => {
        if (form.bankAccountName) {
          setBankAccountName(form.bankAccountName)
        }
      },
      disableBankAccount: (disabled) => {
        setDisabledAccountBank(disabled)
      },
    }))

    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-xs-12">
            <SelectInput
              title="ธนาคาร"
              placeholder="เลือกธนาคาร"
              errorMsg="กรุณาเลือกธนาคาร"
              isValid={isValidBank}
              options={bankListOptions}
              optionClassName="col-xs-3"
              selectedValue={selectedBank}
              onChange={handleSelectInputChange}
              onBlur={handleSelectedInputBlur}
              className="mt-2"
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12">
            <Input
              id="bank-account-number"
              type="tel"
              length={12}
              title="เลขบัญชี"
              placeholder="กรอกเลขที่บัญชี"
              isError={isErrorBankAccount}
              errorMsg="กรุณากรอกเลขที่บัญชีที่ถูกต้อง"
              width="100%"
              className={classNames({
                'mt-2': isValidBank,
                'mt-4': !isValidBank,
              })}
              value={bankAccountNumber}
              validator={numberValidator}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              replacerOnPaste={(value) => value.replace(/[^0-9]+/g, '')}
              autoRollback
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12">
            <Input
              id="bank-account-name"
              type="text"
              title="ชื่อบัญชี"
              disabled={disabledAccountBank}
              placeholder="กรอกชื่อบัญชี"
              isError={isErrorBankAccount}
              errorMsg="กรุณากรอกชื่อบัญชีที่ถูกต้อง"
              width="100%"
              className={classNames({
                'mt-2': isValidBank,
                'mt-4': !isValidBank,
              })}
              value={bankAccountName}
              onChange={(value: string) => {
                setIsErrorBankAccount(false)
                setBankAccountName(value)
              }}
              onBlur={handleInputBlur}
              autoRollback
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Spin spinning={isLoading}>
              <Button
                buttonForm
                id="verify-bank-next-button"
                size={BUTTON_SIZE.MD}
                type={BUTTON_TYPE.CTA_1}
                disabled={disabledButton}
                className="mx-auto mt-3 mt-md-4"
                onClick={() => {
                  onValidate()
                }}
              >
                ดำเนินการต่อ
              </Button>
            </Spin>
          </div>
        </div>
      </div>
    )
  },
)

BankFormComponent.displayName = 'BankFormComponent'
export default BankFormComponent
