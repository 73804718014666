import { differenceInMinutes, parse, parseISO, startOfDay } from 'date-fns'
import moment from 'moment'
import { forceDateToISO } from './datetime'
import relativeDate from './relativeDate'

export interface IRearrangeObject {
  DateTime?: string | Date // should be date or ISO string.
  [key: string]: any
}

export const mapKeyToOrder = {
  future: 0,
  tomorrow: 1,
  today: 2,
  thisWeek: 3,
  lastWeek: 4,
  thisMonth: 5,
  lastMonth: 6,
  previousMonth: 7,
}

export const mapOrderToKey = [
  'future',
  'tomorrow',
  'today',
  'thisWeek',
  'lastWeek',
  'thisMonth',
  'lastMonth',
  'previousMonth',
]
export interface IRearrangeArrayByDateResult<T = unknown> {
  future?: (IRearrangeObject | T)[]
  tomorrow?: (IRearrangeObject | T)[]
  today?: (IRearrangeObject | T)[]
  thisWeek?: (IRearrangeObject | T)[]
  lastWeek?: (IRearrangeObject | T)[]
  thisMonth?: (IRearrangeObject | T)[]
  lastMonth?: (IRearrangeObject | T)[]
  previousMonth?: (IRearrangeObject | T)[]
}

type RearrangSortType = 'asc' | 'desc' | 'ASC' | 'DESC' | null

const regexDateStr =
  /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}[ ]{1}([01]?[0-9]|2?[0123])[:]{1}[012345][0-9]$/

const groupRelativeDate = <T = unknown>(
  arr: IRearrangeObject[],
  sort: RearrangSortType = 'asc',
  baseDate = new Date(),
  shouldIgnoreTime = false,
  targetDateTimeKey = 'rawDateTime',
): IRearrangeArrayByDateResult<T> => {
  const result: any = {}
  const mapResultByIndex: Array<any> = []
  arr.forEach((item, index) => {
    let date: Date
    let realBaseDate = baseDate
    if (
      typeof item[targetDateTimeKey] === 'string' &&
      regexDateStr.test(item[targetDateTimeKey])
    ) {
      date = parse(
        item[targetDateTimeKey],
        'dd/MM/yyyy HH:mm',
        moment().toDate(),
      )
    } else {
      const dateStr =
        typeof item[targetDateTimeKey] === 'string' &&
        item[targetDateTimeKey].toUpperCase()
      if (dateStr && dateStr.includes('T') && !dateStr.includes('Z')) {
        date = new Date(forceDateToISO(item[targetDateTimeKey]))
      } else {
        // Now, Date from PHP is UTC+7, so this line it will only work in region UTC+7.
        date = new Date(parseISO(item[targetDateTimeKey]))
      }
    }
    const originalDate = moment(date).toDate()
    if (shouldIgnoreTime) {
      date = startOfDay(date)
      realBaseDate = startOfDay(baseDate)
    }
    const key = relativeDate(date, realBaseDate)
    const diffMinutes = differenceInMinutes(baseDate, originalDate)
    mapResultByIndex[index] = { diffMinutes, index, key }
  })

  if (sort != null) {
    const sortAscFunction = (a: any, b: any) => a.diffMinutes - b.diffMinutes
    const sortDescFunction = (a: any, b: any) => b.diffMinutes - a.diffMinutes
    mapResultByIndex.sort(
      ['asc', 'ASC'].includes(sort) ? sortAscFunction : sortDescFunction,
    )
  }

  // sorted array...
  mapResultByIndex.forEach(({ index, key }) => {
    if (!result[key]) {
      result[key] = []
    }
    result[key].push(arr[index])
  })
  return result as IRearrangeArrayByDateResult<T>
}

export default groupRelativeDate
