/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import { screen } from '@pig-common/utils/responsive'

import styled from 'styled-components'
import { sleep } from '@pig-common/utils/time'
import { getOS, OS } from '@pig-common/utils/device'
import { addCommas } from '@pig-common/utils/number'
import Images from 'next/image'

import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  TextLink,
} from '@pig-common/components/Button'
import {
  CaptureArea,
  CaptureAreaHandles,
} from '@pig-common/components/CaptureArea'
import {
  InformationBox,
  INFO_BOX_VARIANT,
} from '@pig-common/components/InformationBox'
import { Loading } from '@pig-common/components/Loading'

import CountDownTimer from './CountDownTimer'
import { ReloadOutlined, MobileOutlined } from '@ant-design/icons'
import { notification } from '@pig-frontend/uikit'

const QRCode = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: auto;
  min-height: 160px;
  width: 100%;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 300ms ease-out;
    cursor: pointer;
    filter: none;
    touch-action: manipulation;
    font-size: 16px;
  }

  &.expired {
    > img {
      filter: opacity(0.1);
      cursor: pointer;
      transition: filter 300ms linear;
      &:hover {
        filter: opacity(0.25);
      }
    }
  }
`
const QRContainer = styled.div`
  position: relative;
  height: min-content;
  display: grid;
  min-height: 160px;
  grid-template-columns: 1fr;
  gap: 12px;
  @media ${screen.sm} {
    grid-template-columns: 160px 1fr;
  }
`

const PLACEHOLDER_QR_CODE =
  'https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg'
const SAVING_IMAGE_DELAY_SECOUNDS = 2

type QRDisplayInfo = {
  qrCodeURL?: string
  accountName?: string
  expireAt?: string
  generatedAmount?: string
}

type DepositQRSecondStepProps = {
  onClickTopUpAgainButton?: () => void
  onTapExpiredQRCode?: () => void
  isLoading?: boolean
  qrInfo?: QRDisplayInfo
  isBlockRequestQR?: boolean
  phoneNumber?: string
}

const motionProps: HTMLMotionProps<'div'> = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  variants: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
}

const DepositQRSecondStep = ({
  onClickTopUpAgainButton = () => {},
  onTapExpiredQRCode = () => {},
  isLoading,
  isBlockRequestQR,
  qrInfo,
  phoneNumber,
}: DepositQRSecondStepProps) => {
  const captureAreaRef = React.useRef<CaptureAreaHandles>(null)
  const [isQRExpire, setIsQRExpire] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  // NOTE : i18n
  const scanQRCodeTitle = 'คิวอาร์โค้ดสำหรับเติมเงิน'
  const scanQRAccountTitle = 'ปลายทาง'
  const scanQRAmountTitle = 'จำนวนเงิน'
  const currencyText = 'บาท'
  const scanQRTimeRemaningPrefix = 'กรุณาเติมเงินภายใน'
  const scanQRNoticeText =
    'คิวอาร์โค้ดนี้สามารถสแกนจ่ายได้เพียงครั้งเดียวเท่านั้น'
  const expireQRFootNote = {
    prefix: 'คิวอาร์โค้ดหมดอายุ กรุณากด',
    suffix: 'ใหม่',
  }
  const normalFootNote = 'หากต้องการเติมเงินอีกครั้ง กรุณาสร้างคิวอาร์โค้ดใหม่'
  const iosCaptureInfoTitle = 'ถ่ายภาพหน้าจอ'
  const iosCaptureInfoContent =
    'โดยกดที่ปุ่ม Home พร้อมกับปุ่มปิดหน้าจอมือถือเพื่อเซฟไปยังอัลบั้มภาพของคุณ'
  const saveButtonText = 'บันทึกรูปคิวอาร์โค้ด'
  const restartText = 'เติมเงินอีกครั้ง'
  const saveSuccessText = 'บันทึกคิวอาร์โค้ดไปที่อัลบั้มภาพของคุณแล้ว'

  const currentOS = getOS()

  const saveQRCode = async () => {
    setIsSaving(true)
    await captureAreaRef.current?.save()
    notification.success({
      message: saveSuccessText,
      duration: 3,
    })
    await sleep(SAVING_IMAGE_DELAY_SECOUNDS)
    setIsSaving(false)
  }

  React.useEffect(() => {
    if (!isLoading && qrInfo) setIsQRExpire(false)
  }, [qrInfo])

  const renderFooterNotice = () => {
    if (isQRExpire) {
      return (
        <p className="ps-deposit-qr__footer-notice ps-text-danger">
          {expireQRFootNote.prefix} <ReloadOutlined /> {expireQRFootNote.suffix}
        </p>
      )
    }
    return <p className="ps-deposit-qr__footer-notice">{normalFootNote}</p>
  }

  const renderSaveSection = () => {
    if (currentOS === OS.IOS) {
      return (
        <div className="ps-deposit-qr__save-section">
          <InformationBox
            id="top-up-information-box"
            width="100%"
            title={iosCaptureInfoTitle}
            content={iosCaptureInfoContent}
            prependContent={<MobileOutlined />}
            variant={INFO_BOX_VARIANT.INFO}
          />
        </div>
      )
    }
    return (
      <Button
        id="capture-button"
        className="ps-deposit-qr__save-section mx-auto"
        type={BUTTON_TYPE.CTA_1}
        size={BUTTON_SIZE.LG}
        onClick={saveQRCode}
        disabled={isLoading || isSaving || isQRExpire || isBlockRequestQR}
      >
        {saveButtonText}
      </Button>
    )
  }

  const renderLoading = () => (
    <motion.div
      key="motion-qr-loading"
      id="wallet-qr-deposit-loader"
      className="ps-deposit-qr__loader"
      {...motionProps}
    >
      <Loading />
    </motion.div>
  )

  if (isLoading) return renderLoading()
  return (
    <>
      <p id="qr-deposit-title" className="ps-deposit-qr__title">
        {scanQRCodeTitle}
      </p>
      <CaptureArea
        ref={captureAreaRef}
        className="ps-deposit-qr__snapshot-area"
        fileNamePrefix={phoneNumber}
      >
        <AnimatePresence exitBeforeEnter>
          {!isLoading && (
            <motion.div
              key="motion-qr-code-area"
              className="align-items-center ps-deposit-qr__motion"
              {...motionProps}
            >
              {!isLoading && (
                <QRContainer>
                  <QRCode>
                    <Images
                      src={`${qrInfo?.qrCodeURL || PLACEHOLDER_QR_CODE}`}
                      alt={'qr-code'}
                      fill
                      quality={100}
                    />
                    {isQRExpire && (
                      <ReloadOutlined
                        style={{
                          position: 'absolute',
                          top: '45%',
                          left: '40%',
                        }}
                        onClick={() => {
                          if (!isLoading && isQRExpire) onTapExpiredQRCode()
                        }}
                      />
                    )}
                  </QRCode>
                </QRContainer>
              )}
              <ul className="ps-deposit-qr__info-data">
                <li className="ps-deposit-qr__info-data-item">
                  <span className="ps-deposit-qr__info-data-item--fade">
                    {scanQRAccountTitle}
                  </span>{' '}
                  {qrInfo?.accountName}
                </li>
                <li className="ps-deposit-qr__info-data-item">
                  <span className="ps-deposit-qr__info-data-item--fade">
                    {scanQRAmountTitle}
                  </span>{' '}
                  {addCommas(String(qrInfo?.generatedAmount || ''))}{' '}
                  {currencyText}
                </li>
                <li
                  className="ps-deposit-qr__info-data-item"
                  data-html2canvas-ignore
                >
                  {scanQRTimeRemaningPrefix}{' '}
                  <strong className="ps-deposit-qr__info-data-item--emphasize">
                    {!isQRExpire && !isBlockRequestQR ? (
                      <CountDownTimer
                        hours={0}
                        minutes={5}
                        seconds={0}
                        onTimeIsUp={() => setIsQRExpire(true)}
                      />
                    ) : (
                      '00:00:00'
                    )}
                  </strong>
                </li>
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="row">
          <p className="ps-deposit-qr__footer-caption">{scanQRNoticeText}</p>
        </div>
        <div className="row">{renderFooterNotice()}</div>
      </CaptureArea>
      {renderSaveSection()}
      <TextLink
        id="top-up-again-button"
        className="ps-deposit-qr__restart-button mx-auto"
        onClick={onClickTopUpAgainButton}
        disabled={isLoading || isBlockRequestQR}
      >
        {restartText}
      </TextLink>
    </>
  )
}

export default DepositQRSecondStep
