import * as React from 'react'
import { Input, InputProps } from 'antd'
import classnames from 'classnames'
import styled from 'styled-components'
import { CloseOutlined as AntdCloseOutlined } from '@ant-design/icons'

export const StyledInput = styled(Input)`
  .ant-input {
    font-size: 1rem;
  }
  .ps-icon-close {
    font-size: 0.7rem;
    vertical-align: middle;
  }
`

const CloseOutlined = styled(AntdCloseOutlined)`
  display: flex;
`

const CommonInput: React.FunctionComponent<InputProps> = ({
  className,
  ...props
}) => {
  return (
    <StyledInput
      {...props}
      className={classnames('ps-input', className)}
      allowClear={props.allowClear ? { clearIcon: <CloseOutlined /> } : false}
    />
  )
}

export default CommonInput
