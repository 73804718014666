import { Tooltip } from 'antd'
import React, { useState } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import moment, { Moment } from 'moment'
import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'
import { InformationBox } from '@pig-common/components/InformationBox'
import { Input } from '@pig-common/components/Input'
import { isValidCID } from '@pig-common/utils/cid'
import { validateNumber } from '@pig-common/utils/number'
import { Calendar } from '@pig-common/components/Calendar'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import { profileStateCommon } from '@pig-common/recoils'
import { useRecoilValue } from 'recoil'
import { IBankAccountData } from '@pig-common/types/Profile.type'
import {
  KycResult,
  KycFormType as KycRequest,
  useKyc,
  CHECK_IDENTITY_ERROR_CODE,
} from '@pig-common/hooks/useKyc'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { useLoading } from '@pig-common/hooks/useLoading'
import { AlertModalV2, ALERT_MODAL_TYPE } from '../Modal'

export type KycResultCallBack = {
  form: KycRequest
  result: KycResult
}

export type IKycFormProps = {
  onFinish?: (result: KycResultCallBack) => void
  onSubmit?: (result: KycRequest, skipBank: boolean) => void
}

export type KycFormHandler = {
  handleVerifyKYCResponse: (result: KycResult) => void
}

const NAME_LENGTH = 100
const LASER_LENGTH = 12
const CID_LENGTH = 13
const DEFAULT_YEAR = 1990

export const KycForm = React.forwardRef<KycFormHandler, IKycFormProps>(
  (props, ref) => {
    const { callCheckKyc } = useKyc()
    const { callProfile } = useLegacyProfile()
    const profile = useRecoilValue(profileStateCommon.profileState)
    const { loading, stopLoading } = useLoading()
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [disabledName, setDisabledName] = useState<boolean>(false)
    const [cid, setCid] = useState<string>('')
    const [laserNumber, setLaserNumber] = useState<string>('')
    const [birthday, setBirthday] = useState<string>(
      moment().year(DEFAULT_YEAR).startOf('year').format('YYYY-MM-DD'),
    )
    const [isFirstNameError, setIsFirstNameError] = useState<boolean>(false)
    const [isLastNameError, setIsLastNameError] = useState<boolean>(false)
    const [isCidError, setIsCidError] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLaserNumberError, setIsLaserNumberError] = useState<boolean>(false)
    const [isBirthdayError, setIsBirthdayError] = useState<boolean>(false)

    const handleFirstNameChange = (value: string) => {
      setIsFirstNameError(false)
      setFirstName(value)
    }
    const handleFirstNameBlur = () => {
      setIsFirstNameError(firstName === '')
    }

    const handleLastNameChange = (value: string) => {
      setIsLastNameError(false)
      setLastName(value)
    }
    const handleLastNameBlur = () => {
      if (lastName) {
        setLastName(lastName.trim())
      }
      setIsLastNameError(lastName === '')
    }

    const handleCidChange = (value: string) => {
      setIsCidError(false)
      setCid(value)
    }

    const handleCidBlur = () => {
      setIsCidError(cid == null ? false : cid.length < 13 || !isValidCID(cid))
    }

    const handleLaserNumberChange = (value: string) => {
      setIsLaserNumberError(false)
      setLaserNumber(value.toUpperCase())
    }
    const handleLaserNumberBlur = () => {
      setIsLaserNumberError(
        laserNumber == null ? false : !/^[a-zA-Z]{2}\d{10}/g.test(laserNumber),
      )
    }

    const handleBirthdayChange = (value: Moment) => {
      setIsBirthdayError(false)
      if (value && value.isValid()) {
        setBirthday(value.format('YYYY-MM-DD'))
      }
    }
    const handleBirthdayBlur = () => {
      setIsBirthdayError(!birthday)
    }

    const shouldDisableButton = () => {
      return (
        isFirstNameError ||
        !firstName ||
        isLastNameError ||
        !lastName ||
        isCidError ||
        !cid ||
        isLaserNumberError ||
        !laserNumber ||
        isBirthdayError ||
        !birthday ||
        isLoading
      )
    }

    const showAlertModal = (type: ALERT_MODAL_TYPE) => {
      NiceModal.show(AlertModalV2, { type })
    }

    const handleVerifyKYCError = (
      errorCode: CHECK_IDENTITY_ERROR_CODE | undefined,
    ) => {
      switch (errorCode) {
        case CHECK_IDENTITY_ERROR_CODE.CITIZEN_ID_INVALID:
          showAlertModal(ALERT_MODAL_TYPE.KYC_INVALID_CID)
          break
        case CHECK_IDENTITY_ERROR_CODE.CITIZEN_INFO_INVALID:
        case CHECK_IDENTITY_ERROR_CODE.INFORMATION_INVALID:
          showAlertModal(ALERT_MODAL_TYPE.KYC_INVALID_INFO)
          break
        case CHECK_IDENTITY_ERROR_CODE.ALREADY_EXIST:
          showAlertModal(ALERT_MODAL_TYPE.KYC_ALREADY_EXIST_CID)
          break
        case CHECK_IDENTITY_ERROR_CODE.EXPIRED_CARD:
          showAlertModal(ALERT_MODAL_TYPE.EXPIRED_CARD)
          break
        case CHECK_IDENTITY_ERROR_CODE.LASER_CODE_INVALID:
          showAlertModal(ALERT_MODAL_TYPE.KYC_INVALID_LASER_ID)
          break
        default:
          showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
      }
    }

    const onSubmit = async () => {
      const form: KycRequest = {
        cid,
        laserNumber,
        firstName,
        lastName,
        birthday,
      }
      if (props.onSubmit) {
        props.onSubmit(form, disabledName)
        return
      }
      setIsLoading(true)
      loading()
      const response = await callCheckKyc(form)
      setIsLoading(false)
      stopLoading()
      if (response.ErrorCode) {
        handleVerifyKYCError(response.ErrorCode)
      }
      if (props.onFinish) {
        props.onFinish({
          form,
          result: response,
        })
      }
    }

    const renderInputTitle = (
      title: string,
      key: string,
      tooltipContent: string,
    ) => (
      <div className="ps-step-kyc-input-title">
        {title}
        &nbsp;
        <Tooltip
          placement="topLeft"
          overlayClassName="ps-kyc-tooltip"
          title={
            <InformationBox
              titleTextJustify="center"
              contentTextJustify="center"
              isShowArrowIndicator
              content={autoLineBreakerWithBr(tooltipContent)}
              id="info-box-arrow"
            />
          }
        >
          <ExclamationCircleOutlined
            className="ps-icon-info"
            style={{
              margin: '0px',
              fontSize: 14,
              lineHeight: '1px',
              color: '#0573F3',
            }}
          />
        </Tooltip>
      </div>
    )

    React.useEffect(() => {
      if (profile) {
        profile?.bankAccountList?.map((account: IBankAccountData) => {
          if (account?.bankAccountName) {
            const [bankFirstName, bankLastName] =
              account.bankAccountName.split(/ (.*)/g)
            if (bankFirstName || bankLastName) {
              setFirstName(bankFirstName)
              setLastName(bankLastName)
              setDisabledName(true)
            }
          }
        })
      }
    }, [profile])

    React.useEffect(() => {
      callProfile()
    }, [])

    React.useImperativeHandle(ref, () => ({
      handleVerifyKYCResponse: (_kycResult) => {
        handleVerifyKYCError(_kycResult.ErrorCode)
      },
    }))

    return (
      <>
        {/* FirstName input */}
        <Input
          id="first-name"
          className="ps-kyc-margin-input mx-auto"
          title={renderInputTitle(
            'ชื่อ',
            'firstname',
            'ชื่อและนามสกุลต้องตรงกับบัญชีธนาคาร\nและหากมีชื่อกลางสามารถกรอกได้ในช่องนี้',
          )}
          placeholder={'กรอกชื่อภาษาไทย'}
          errorMsg={'โปรดกรอกชื่อเป็นภาษาไทย'}
          value={firstName}
          onChange={handleFirstNameChange}
          length={NAME_LENGTH}
          validator={(value) => /^[\u0E00-\u0E7F]*$/g.test(value)}
          isError={isFirstNameError}
          onBlur={handleFirstNameBlur}
          disabled={isLoading || disabledName}
          autoRollback
        />

        {/* LastName input */}
        <Input
          id="last-name"
          className="ps-kyc-margin-input mx-auto"
          title={'นามสกุล'}
          placeholder={'กรอกนามสกุลภาษาไทย'}
          errorMsg={'โปรดกรอกนามสกุลเป็นภาษาไทย'}
          value={lastName}
          onChange={handleLastNameChange}
          length={NAME_LENGTH}
          validator={(value) =>
            /^[\u0E00-\u0E7F]*[\u0E00-\u0E7F ]*$/g.test(value)
          }
          isError={isLastNameError}
          onBlur={handleLastNameBlur}
          disabled={isLoading || disabledName}
          autoRollback
        />

        {/* Citizen id input */}
        <Input
          id="citizen-id"
          className="ps-kyc-margin-input mx-auto"
          title={'เลขหน้าบัตร'}
          placeholder={'เลข 13 หลัก ไม่ต้องใส่ขีด'}
          errorMsg={'โปรดกรอกเลขบัตรประชาชนให้ถูกต้อง'}
          value={cid}
          onChange={handleCidChange}
          length={CID_LENGTH}
          validator={(value) => validateNumber(value)}
          type="tel"
          isError={isCidError}
          onBlur={handleCidBlur}
          disabled={isLoading}
          autoRollback
        />

        {/* Back citizen id input */}
        <Input
          id="back-citizen-id"
          className="ps-kyc-margin-input mx-auto"
          title={renderInputTitle(
            'เลขหลังบัตร',
            'laserNumber',
            'สังเกตจากตัวอักษรและหมายเลขด้านหลัง\nของบัตรประชาชนที่คุณมีอยู่ล่าสุด',
          )}
          placeholder={'ตัวอักษร 2 หลัก และเลข 10 หลัก ไม่ต้องใส่ขีด'}
          errorMsg={'โปรดกรอกเลขหลังบัตรประชาชนให้ถูกต้อง'}
          value={laserNumber}
          onChange={handleLaserNumberChange}
          validator={(value) => /^[a-zA-Z0-9]*$/g.test(value)}
          isError={isLaserNumberError}
          onBlur={handleLaserNumberBlur}
          disabled={isLoading}
          autoRollback
          length={LASER_LENGTH}
        />

        {/* Date of birth input */}
        <Calendar
          id="birth-date"
          className="mb-3 mx-auto"
          title={'วัน เดือน ปีเกิด (ค.ศ.)'}
          placeholder={'เลือกวัน เดือน ปีเกิด ของคุณ'}
          errorMsg={'วัน เดือน ปีเกิด ของท่านไม่ถูกต้อง'}
          isError={isBirthdayError}
          value={birthday}
          disabled={isLoading}
          onChange={handleBirthdayChange}
          onBlur={handleBirthdayBlur}
        />

        {/* Continue Button */}
        <Button
          buttonForm
          id="kyc-next-button"
          className="mx-auto"
          type={BUTTON_TYPE.CTA_1}
          onClick={onSubmit}
          disabled={shouldDisableButton()}
        >
          {'ดำเนินการต่อ'}
        </Button>
      </>
    )
  },
)
KycForm.displayName = 'KycForm'
export default KycForm
