import React from 'react'

import { Input } from '@pig-common/components/Input'
import flagThIcon from '@pig-common/public/images/login/flag-th.png'

export type InputPhoneProps = {
  id?: string
  className?: string
  title: string
  placeholder?: string
  flagIcon?: any
  callingCode?: string
  isError?: boolean
  errorMessage?: string
  disabled?: boolean
  onInputChange: (value: string, isInvalid: boolean) => void
}

const InputPhone = ({
  id,
  className,
  title,
  placeholder,
  flagIcon = flagThIcon,
  callingCode = '+66',
  isError,
  errorMessage,
  disabled,
  onInputChange = () => {},
}: InputPhoneProps) => {
  const [phone, setPhone] = React.useState('')

  const validatePhoneNumber = (val: string) => {
    if (val && val[0] !== '0' && val.length >= 10) {
      return false
    }
    return /^\d+$/g.test(val) || val === ''
  }

  const onChange = (val: string) => {
    const isStartWithZero = val[0] === '0'
    const isStartWithSpace = val[0] === ' '
    const endWithSpace = val[val.length] === ' '
    const trimmedVal = val.trim()
    let isWrongFormat = false
    if (isStartWithZero) {
      isWrongFormat = !validatePhoneNumber(val) || trimmedVal.length !== 10
    } else {
      isWrongFormat = !validatePhoneNumber(val) || trimmedVal.length < 9
    }
    onInputChange(trimmedVal, isWrongFormat || isStartWithSpace || endWithSpace)
    setPhone(trimmedVal)
  }

  return (
    <Input
      id={id}
      className={className}
      type="tel"
      mode="phone"
      phoneIcon={flagIcon}
      phoneText={callingCode}
      disabled={disabled}
      title={title}
      placeholder={placeholder}
      errorMsg={errorMessage}
      value={phone}
      isError={isError}
      onChange={onChange}
      validator={validatePhoneNumber}
      length={10}
    />
  )
}

export default InputPhone
