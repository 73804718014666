import { useRecoilValue } from 'recoil'
import { useMutation } from '@tanstack/react-query'
import { authStateCommon } from '@pig-common/recoils'
import { totalTicketApi } from '@pig-common/api'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal } from '@pig-frontend/uikit'

interface IRedeemRewardParams {
  rewardUID: string
}

export default function useRedeemReward() {
  const { customerCode, userUID } = useRecoilValue(authStateCommon.authState)

  return useMutation({
    mutationFn: ({ rewardUID }: IRedeemRewardParams) =>
      totalTicketApi.redeemReward({ customerCode, userUID, rewardUID }),
    onSuccess: (data) => {
      if (data?.status === 'ERROR') {
        Modal.error({
          icon: <InfoCircleOutlined />,
          title: <strong>{data?.data?.message}</strong>,
          content: data?.data?.detail,
          centered: true,
          okText: 'ตกลง',
        })
      }
    },
  })
}
