import router from 'next/router'
import { useRecoilValue } from 'recoil'
import { Images } from '@pig-common/components/Images'
import { gameAssetUrl } from '@pig-common/utils/utils'
import { GameIcon } from '@pig-common/components/GameIcon'
import { RedemptionHistoryDataProps } from '@pig-common/services/pigShop/getFreeInGame'
import { gameStateCommon } from '@pig-common/recoils/index'
import { GameItem } from '@pig-common/services/games'
import isInNewGameDuration from '@pig-common/utils/getNewGameDuration'

export type RedemptionHistoryCardProps = {
  historyData: RedemptionHistoryDataProps
}

const RedemptionHistoryCard = ({ historyData }: RedemptionHistoryCardProps) => {
  const gamesState: GameItem[] = useRecoilValue(gameStateCommon.gameState)
  const game = gamesState.find(
    (gameItem: GameItem) => gameItem.game_code === historyData.game_code,
  )

  const toTheGame = () => {
    if (historyData.game_code) {
      router.push(`/games/pg-${historyData.game_code || ''}`)
    } else {
      router.push('/wallet')
    }
  }

  return (
    <div
      id={`history-redemption__card-${historyData.transaction_uid}`}
      data-testid={`history-redemption__card-${historyData.transaction_uid}`}
      className="ps-redemption-card"
      onClick={toTheGame}
      onKeyDown={toTheGame}
    >
      <div className="ps-redemption-card__content-wrapper">
        {historyData.game_code ? (
          <GameIcon
            src={gameAssetUrl(game, 'icon')}
            className="ps-redemption-card__gameicon"
            layout="fixed"
            quality={75}
            width={44}
            height={44}
            alt="gameLogo"
            campaign="nope"
            isCampaign={false}
            isNew={isInNewGameDuration(game?.created_at)}
            refType={game?.ref_type}
          />
        ) : (
          <Images
            src="/images/pig-shop/coin.svg"
            width={44}
            height={44}
            alt={historyData.transaction_uid}
          />
        )}
        <div className="ps-redemption-card__content">
          <div className="ps-redemption-card__title">{historyData.title}</div>
          <div className="ps-redemption-card__sub-title">
            {historyData.sub_title}
          </div>
          <div className="ps-redemption-card__code">
            รหัส :{historyData.transaction_code}
          </div>
        </div>
      </div>
      <div className="ps-redemption-card__history-wrapper">
        <div className="ps-redemption-card__value">
          {historyData.reward_unit}
        </div>
        <div className="ps-redemption-card__subvalue">
          {historyData.bet_amount}
        </div>
        <div className="ps-redemption-card__date">
          {historyData.transaction_date}
        </div>
      </div>
    </div>
  )
}

export default RedemptionHistoryCard
