import { DropDownProps, Dropdown } from 'antd'
import find from 'lodash/find'
import { PlusCircleOutlined } from '@ant-design/icons'
import { MenuProps } from 'antd/lib/menu'
import styles from './index.module.scss'

interface IImageSelectorProps extends DropDownProps {
  items?: MenuProps['items']
  setSelectedImageUrl: (image: string) => void
}

export default function HeaderImageSelector({
  items,
  setSelectedImageUrl,
}: IImageSelectorProps) {
  const handleSelectImage = (selectedItem: any) => {
    const item = find(items, ['key', selectedItem?.key])
    if (item) setSelectedImageUrl(item?.key as string)
  }

  return (
    <Dropdown
      placement="bottomCenter"
      arrow
      menu={{ items, onClick: handleSelectImage }}
      overlayClassName={styles['dropdown-overlay']}
      getPopupContainer={(node) => node}
    >
      <div className={styles.container}>
        <PlusCircleOutlined />
      </div>
    </Dropdown>
  )
}
