import React, { useEffect, useState } from 'react'
import { GetServerSideProps, NextPage } from 'next'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Router, { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'
import { AnimatePresence, motion, Variants } from 'framer-motion'

import { authStateCommon } from '@pig-common/recoils'
import { useRenewPin } from '@pig-common/hooks/useRenewPin'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { useCheckPhone } from '@pig-common/hooks/useCheckPhone'
import { SetupNewPin } from '@pig-common/components/SetupNewPin'
import { IdentityOtp } from '@pig-common/components/IdentityOTP'
import { AlertModal, ALERT_MODAL_TYPE } from '@pig-common/components/Modal'
import { OTP_DATA } from '@pig-common/components/Otp'
import { useLogin } from '@pig-common/hooks/useLogin'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { PigSpinLoading } from '@pig-common/components/Loading/Loading'

enum FORGET_PIN_STEP {
  IDENTITY_OTP,
  RENEW_PIN,
}

const motionVariant: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export type ResetPinProps = {
  phone: string
}

const ResetPin: NextPage<ResetPinProps> = ({ phone: serverSidePhone }) => {
  const [currentStep, setCurrentStep] = useState<FORGET_PIN_STEP>(
    FORGET_PIN_STEP.IDENTITY_OTP,
  )
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()
  const [isReady, setIsReady] = useState(false)
  const [isInvalidUser, setIsInvalidUser] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [alertModalType, setAlertModalType] = useState<ALERT_MODAL_TYPE>(
    ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS,
  )
  const [redirectPath, setRedirectPath] = React.useState<string | undefined>()
  const loadingModal = useModal(PigSpinLoading)
  const [currentOtpData, setCurrentOtpData] = useState<OTP_DATA>({
    phoneNumber: '',
    otpCode: '',
    otpToken: '',
    otpKey: '',
  })
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })

  const [, setAuth] = useRecoilState(authStateCommon.authState)
  const [, setCookies] = useCookies<string>([COOKIES_KEY.CFID, COOKIES_KEY.UID])
  const { callRequestOtp } = useCheckPhone()
  const { callRenewPin } = useRenewPin()
  const { callLogin } = useLogin()

  const requestOtp = async () => {
    const response = await callRequestOtp({ phone: serverSidePhone })
    if (response.status === 'SUCCESS') {
      setCurrentOtpData({
        phoneNumber: serverSidePhone,
        otpCode: response.data.otp_ref_code,
        otpToken: response.data.otp_token,
        otpKey: response.data.otp_number,
      })
      setIsReady(true)
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    showRetryModal()
  }

  const showRetryModal = () => {
    NiceModal.show(AlertConfirmModal, {
      header: 'การขอ OTP ไม่สำเร็จ',
      content: 'ยืนยันที่จะขอ OTP อีกครั้ง ?',
      buttonCloseText: 'กลับหน้าหลัก',
      onClose: () => {
        router.push('/login')
      },
      onConfirm: () => {
        requestOtp()
      },
    })
  }

  useEffect(() => {
    requestOtp()
    return () => {
      loadingModal.remove()
      setIsReady(false)
    }
  }, [])

  const showAlertModal = (type: ALERT_MODAL_TYPE) => {
    setAlertModalType(type)
    setOpenModal(true)
  }

  const closeModalHandler = async () => {
    if (redirectPath) {
      if (redirectPath === '/reset-pin') {
        Router.push(
          { pathname: '/reset-pin', query: { phone: serverSidePhone } },
          '/reset-pin',
        )
      } else {
        await Router.push(redirectPath)
      }
    }
    setOpenModal(false)
  }

  const renewPinHandler = async (newUserPin: string) => {
    setIsLoading(true)
    loadingModal.show()
    try {
      const renewPinResponse = await callRenewPin({
        newPin: newUserPin,
        phone: serverSidePhone,
        otpKey: currentOtpData.otpKey,
        otpToken: currentOtpData.otpToken,
      })
      if (renewPinResponse.status === 'SUCCESS') {
        const response = await callLogin({
          phoneNumber: serverSidePhone,
          pin: newUserPin,
        })
        setCookies(COOKIES_KEY.CFID, response.cfid)
        setCookies(COOKIES_KEY.UID, response.uid || '0000000000')
        setCookies(COOKIES_KEY.CUSTOMER_CODE, response.CustomerCode || '')
        setAuth({
          cfid: response.cfid,
          customerCode: response.CustomerCode,
          gameToken: response.GameToken,
          name: response.CustomerName,
          isLoginPhoneSuccess: true,
          userUID: response.UserUid || '',
        })
        router.push('/lobby', '/lobby', { scroll: true })
        return
      }
      setIsInvalidUser(true)
      throw new Error('Renew PIN Error')
    } catch (_error) {
      showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
      setRedirectPath(undefined)
    } finally {
      refreshSystemStatus()
    }
    setIsLoading(false)
  }

  const onSuccessOTP = (result: OTP_DATA) => {
    setCurrentOtpData({
      phoneNumber: result.phoneNumber,
      otpCode: result.otpCode,
      otpToken: result.otpToken,
      otpKey: result.otpKey,
    })
    setCurrentStep(FORGET_PIN_STEP.RENEW_PIN)
  }

  const renderContentSection = () => (
    <>
      {currentStep === FORGET_PIN_STEP.IDENTITY_OTP && (
        <motion.section
          id="forget-pin-step-1-wrap"
          key="motion-forget-pin-1"
          {...motionVariant}
        >
          <IdentityOtp
            title="ยืนยันตัวตน"
            currentOtpData={currentOtpData}
            onSuccess={onSuccessOTP}
          />
        </motion.section>
      )}
      {currentStep === FORGET_PIN_STEP.RENEW_PIN && (
        <motion.section
          id="forget-pin-step-2-wrap"
          key="motion-forget-pin-2"
          {...motionVariant}
        >
          <SetupNewPin
            title="ตั้งค่าความปลอดภัย"
            firstPinTitle="ตั้งรหัส PIN"
            firstPinInfo={'กรอกตัวเลข 6 ตัวหลีกเลี่ยงเลขเรียง\nเลขตอง เลขซ้ำ'}
            repeatedPinTitle="ยืนยันรหัส PIN"
            repeatedPinInfo="กรุณากรอกรหัส PIN ให้ตรงกัน"
            pinMismatch="รหัส PIN ไม่ตรงกัน กรุณาลองใหม่อีกครั้ง"
            isLoading={isLoading}
            shouldDisabled={isInvalidUser}
            onComplete={renewPinHandler}
          />
        </motion.section>
      )}
    </>
  )

  if (!isReady) return null
  return (
    <section className="container ps-forget-pin mb-5">
      <AnimatePresence exitBeforeEnter>
        {renderContentSection()}
      </AnimatePresence>
      <AlertModal
        open={openModal}
        onClose={closeModalHandler}
        type={alertModalType}
      />
    </section>
  )
}

export const getResetPinServerSideProps: GetServerSideProps<
  ResetPinProps
> = async ({ query, req }) => {
  const resetingPhone = query.phone
  const userPhone = req.cookies.uid
  const isResetYourPhone = userPhone && userPhone === query.phone
  const isResetWithoutAuth = !userPhone && resetingPhone
  const allowSetPin = isResetYourPhone || isResetWithoutAuth
  const props = {
    phone: resetingPhone as string,
  }
  if (!allowSetPin) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
      props,
    }
  }
  return { props }
}

export default ResetPin
