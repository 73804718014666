import styled from 'styled-components'
import { Tabs as AntdTabs } from 'antd'
import { screen } from '@pig-common/utils/responsive'

export const Tabs = styled(AntdTabs)`
  &.ant-tabs {
    margin: auto;
    max-width: 500px;
    @media ${screen.md} {
      max-width: 1048px;
    }
  }

  .ant-tabs-nav-wrap::before {
    width: 0;
    justify-content: flex-start !important;
  }

  .ant-tabs-tab {
    padding: 0;
    min-width: 80px;
    line-height: 1;
    margin: 0 8px;
    padding-top: 8px;
    color: ${({ theme }) => theme['color-greyscale-500']};
    &:hover,
    &:focus {
      color: ${({ theme }) => theme['color-violet-400']};
    }
    &.ant-tabs-tab-disabled {
      color: rgba(0, 0, 0, 0.25);
    }
    &.ant-tabs-tab-active {
      border-bottom: none;
    }

    div[role='tab'] {
      margin: auto;
    }

    div[aria-selected='false'] {
      font-size: 24px;
      @media ${screen.md} {
        font-size: 26px;
      }
    }

    div[aria-selected='true'] {
      font-size: 24px;
      color: ${({ theme }) => theme['color-violet-400']};
      @media ${screen.md} {
        font-size: 26px;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme['color-violet-400']};
  }

  .ant-tabs-nav::before {
    border-bottom: 2px solid ${({ theme }) => theme['color-greyscale-200']};
  }

  .ant-tabs-ink-bar {
    background-color: ${({ theme }) => theme['color-violet-400']} !important;
  }
`

export default Tabs
