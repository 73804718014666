import NiceModal from '@ebay/nice-modal-react'
import Wallet from '@pig-common/pages-common/wallet'

function WalletPage() {
  return (
    <NiceModal.Provider>
      <Wallet />
    </NiceModal.Provider>
  )
}

export { WalletPage }
