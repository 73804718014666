import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tabs, Divider, Spin } from 'antd'
import parse from 'html-react-parser'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import SecondaryTabs from '@pig-common/components/Common/Tabs'
import { TextLink } from '../Button'
import { ErrorNotice } from '../ErrorNotice'
import { RightOutlined } from '@ant-design/icons'

const { TabPane } = Tabs

type WalletMenuTitle = {
  deposit: string
  withdraw: string
  history: string
  edit?: string
  bonus: string
  bonusHistory: string
  reportAdmin: string
  seeOtherPromotion: string
}

export type WalletMenuKeyType =
  | 'cash'
  | 'deposit'
  | 'withdraw'
  | 'history'
  | 'edit'
  | 'bonus'
  | 'bonusHistory'

export type WalletMenuMode = 'cash' | 'bonus'

export enum WalletMenuEnum {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  HISTORY = 'history',
  EDIT = 'edit',
  CASH = 'cash',
  BONUS = 'bonus',
  BONUSHISTORY = 'bonusHistory',
}

export type WalletMenuProps = {
  title: WalletMenuTitle
  isEmpty: boolean
  isLoading: boolean
  mode?: WalletMenuMode
  deposit?: React.ReactNode
  withdraw?: React.ReactNode
  history?: React.ReactNode
  edit?: React.ReactNode
  cash?: React.ReactNode
  bonus?: React.ReactNode
  bonusHistory?: React.ReactNode
  empty?: React.ReactNode
  value?: WalletMenuKeyType
  onClickFooter?: (key: WalletMenuKeyType) => void
  onChange?: (key: WalletMenuKeyType) => void
  isFetching?: boolean
  setFetchMore?: () => void
  allFetched?: boolean
}

const CashTabs = styled(SecondaryTabs)`
  > .ant-tabs-nav {
    margin-bottom: 12px;
    margin: auto;
    width: 150px;
    padding-right: 2px;

    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-tab-active {
      margin: 0 8px;
    }

    .ant-tabs-nav-wrap {
      margin-top: 0;

      &::before {
        box-shadow: none;
      }

      &::after {
        box-shadow: none;
      }
    }
  }
  > .ant-tabs-content-holder {
    .ant-tabs-tabpane {
      padding: 0;
    }
  }
`

const WalletMenu = ({
  title,
  mode = 'cash',
  value = 'cash',
  isFetching,
  allFetched,
  setFetchMore,
  isEmpty,
  isLoading,
  empty,
  deposit,
  withdraw,
  history,
  edit,
  bonus,
  bonusHistory,
  onClickFooter,
  onChange,
}: WalletMenuProps) => {
  const [activeKey, setActiveKey] = useState<WalletMenuKeyType>(value)
  const { refetch: refreshSystemStatus, data: systemStatus } = useSystemStatus({
    enabled: false,
  })

  useEffect(() => {
    // if (mode === 'bonus') {
    //   setActiveKey('bonus')
    // } else {
    //   setActiveKey('cash')
    // }
    setActiveKey(value === 'history' ? 'history' : 'cash')
    refreshSystemStatus()
  }, [/*mode,*/ value])

  const renderFooterLinkButton = (content: string) => (
    <TextLink
      id="menu-footer-link-btn"
      className="ps-menu-footer-link mx-auto"
      appendContent={
        <RightOutlined className="ps-menu-footer-link__icon-right" />
      }
      onClick={() => onClickFooter && onClickFooter(activeKey)}
    >
      {content}
    </TextLink>
  )

  const renderCashPanel = () => {
    return (
      <CashTabs centered>
        <TabPane tab={title.deposit} key="deposit" className="container">
          {systemStatus?.depositError ? (
            <ErrorNotice
              subTitle={parse(
                systemStatus?.depositErrorText ||
                  'เรากำลังกำเนินการแก้ไขอย่างเร็วที่สุด\nPIGBET ต้องขออภัยในความไม่สะดวกในครั้งนี้',
              )}
            />
          ) : (
            deposit
          )}
          <Divider className="ps-menu-divider" />
          {renderFooterLinkButton(title.reportAdmin)}
        </TabPane>
        <TabPane tab={title.withdraw} key="withdraw" className="container">
          {systemStatus?.withdrawError ? (
            <ErrorNotice
              subTitle={parse(
                systemStatus?.withdrawErrorText ||
                  'เรากำลังกำเนินการแก้ไขอย่างเร็วที่สุด\nPIGBET ต้องขออภัยในความไม่สะดวกในครั้งนี้',
              )}
            />
          ) : (
            withdraw
          )}
          <Divider className="ps-menu-divider" />
          {renderFooterLinkButton(title.reportAdmin)}
        </TabPane>
      </CashTabs>
    )
  }

  const renderFetchMoreButton = () => {
    if (allFetched && !isFetching) return null
    return (
      <div>
        {isFetching ? (
          <div className="ps-wallet-menu__loading">
            <Spin />
          </div>
        ) : (
          <span
            aria-hidden
            role="button"
            onClick={() => setFetchMore && setFetchMore()}
            className="ps-wallet-menu__link"
          >
            ดูรายการเพิ่มเติม
          </span>
        )}
      </div>
    )
  }

  const renderCashTabPane = () => (
    <>
      <TabPane tab="เงินสด" key="cash" className="container">
        {renderCashPanel()}
      </TabPane>
      <TabPane tab={title.history} key="history" className="container">
        {isLoading ? (
          <div className="ps-wallet-menu__loading">
            <Spin />
          </div>
        ) : (
          <>
            {isEmpty ? empty : history}
            {renderFetchMoreButton()}
          </>
        )}
      </TabPane>
      {edit && (
        <TabPane tab={title.edit} key="edit">
          {edit}
        </TabPane>
      )}
    </>
  )
  const renderBonusTabPane = () => (
    <>
      <TabPane tab={title.bonus} key="bonus" className="container">
        {/* {isEmpty ? empty : bonus} */}
        <Divider className="ps-menu-divider" />
        {renderFooterLinkButton(title.seeOtherPromotion)}
      </TabPane>
      <TabPane
        tab={title.bonusHistory}
        key="bonusHistory"
        className="container"
      >
        {/* {isEmpty ? empty : bonusHistory} */}
        <TabPane
          tab={title.bonusHistory}
          key="bonusHistory"
          className="container"
        >
          {isEmpty ? empty : bonusHistory}
          {renderFetchMoreButton()}
        </TabPane>
      </TabPane>
    </>
  )

  const onTabChange = (key: WalletMenuKeyType) => {
    setActiveKey(key)
    if (onChange && activeKey !== key) {
      onChange(key)
    }
  }

  return (
    <section className="ps-wallet-menu ps-wallet-menu--bonus">
      <Tabs
        animated
        centered
        className="container ps-wallet-tabs"
        defaultActiveKey="cash"
        type="card"
        activeKey={activeKey}
        onChange={(key) => onTabChange(key as WalletMenuKeyType)}
      >
        {mode === 'cash' ? renderCashTabPane() : renderBonusTabPane()}
      </Tabs>
    </section>
  )
}

export default WalletMenu
