/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import classnames from 'classnames'
import NiceModal from '@ebay/nice-modal-react'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import { ColumnsType } from 'antd/lib/table'
import { useRecoilValue } from 'recoil'
import { Col, Form, Row, Space, Spin, Tabs } from 'antd'
import moment, { Moment } from 'moment'
import { useRouter } from 'next/router'
import {
  Bar as BarRechart,
  XAxis,
  BarChart,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts'
import { authStateCommon, profileStateCommon } from '@pig-common/recoils'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { screen } from '@pig-common/utils/responsive'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import { Pill } from '@pig-common/components/Pill'
import {
  AlertModalV2,
  ALERT_MODAL_TYPE,
  GenericModal,
} from '@pig-common/components/Modal'
import AffiliateTAC from '@pig-common/components/Affiliate/AffiliateTAC'
import {
  AffiliateCashCard,
  AffiliateTable,
  InviteBanner,
  NoResult,
} from '@pig-common/components/Affiliate'
import { Calendar } from '@pig-common/components/Calendar'
import { useResponsiveState } from '@pig-common/hooks/useResponsive'
import {
  AffiliateSearchForm,
  BarData,
  IncomeItem,
  INCOME_TABS,
  TABS,
  useAffiliate,
} from '@pig-common/hooks/useAffiliate'
import { CommonInput } from '@pig-common/components/Input'
import { TransactionHistorySection } from '@pig-common/components/TransactionHistorySection'
import { faqStateCommon } from '@pig-common/recoils'
import { numberWithCommas } from '@pig-common/utils/number'
import AffiliateWithdraw from '@pig-common/components/Affiliate/AffiliateWithdraw'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { Loading } from '@pig-common/components/Loading'
import { THAI_SHORT_MONTHS } from '@pig-common/utils/datetime'
import PageTitle from '@pig-common/components/Common/PageTitle'
import { WEB_URL } from '@pig-common/models/buildtime-constant'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { Modal, notification } from '@pig-frontend/uikit'
import useLegacySystemStatus from '@pig-common/hooks/useLegacySystemStatus'

const Legend = styled(Col)`
  padding: 0 0.5rem;
  color: ${({ theme }) => theme['color-greyscale-600']};
  div {
    &:first-child {
      color: ${({ theme }) => theme['color-greyscale-400']};
    }
    font-size: 0.7rem;
    line-height: 1;
    @media ${screen.md} {
      font-size: 0.85rem;
      line-height: 1;
    }
  }
`

const Intro = styled.div`
  background-image: url('/images/affiliate/invite-link-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 4px;
  @media ${screen.md} {
    padding-bottom: 0;
    background-image: none;
  }
`

const AffiliatePill = styled(Pill)`
  width: 100%;
  max-width: 343px;
  @media ${screen.md} {
    width: 100%;
    max-width: 432px;
  }
`

const FAQLinkButton = styled(Button)`
  height: 48px;
  margin: auto;
  width: 100%;
  max-width: 343px;
  margin-top: 30px;
  box-shadow: none;
  ${({ theme }) => `
    color: ${theme['color-violet-300']};
    border-color: ${theme['color-violet-100']};
  `}
`

const Label = styled.div`
  font-size: 20px;
  line-height: 1;
  ${({ theme }) => `
    color: ${theme['color-greyscale-400']};
  `}
`

const ChartWrapper = styled.div`
  max-width: 700;
  margin: auto;
  background: linear-gradient(180deg, rgba(254, 246, 227, 0) 0%, #fef6e3 100%);
`

const LoadingWrapepr = styled.div`
  margin: auto;
  margin-top: 80px;
  width: fit-content;
  min-height: 50px;
`

const StyledCloseIcon = styled(CloseOutlined)`
  font-size: 18px;
`

const { FAQ_TAB } = faqStateCommon

const Affiliate = () => {
  const [form] = Form.useForm<AffiliateSearchForm>()
  const router = useRouter()
  const target = router.query.target
    ? (`${router.query.target}` as TABS)
    : TABS.INVITE_LINK
  const incomeTab = router.query.incomeTab
    ? (`${router.query.incomeTab}` as INCOME_TABS.HISTORY)
    : INCOME_TABS.OVERVIEW
  const [activeTab, setActiveTab] = React.useState<TABS>(target)
  const [activeIncomeTab, setActiveIncomeTab] =
    React.useState<INCOME_TABS>(incomeTab)
  const [withdrawalModalVisible, setWithdrawalModalVisible] =
    useState<boolean>(false)
  const { refreshSystemStatus: refreshLegacySystemStatus } =
    useLegacySystemStatus()
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
  const { callProfile } = useLegacyProfile()
  const { customerCode = '' } = useRecoilValue(authStateCommon.authState)
  const { isSM, isMD } = useResponsiveState()
  const profile = useRecoilValue(profileStateCommon.profileState)
  const affiliateState = useAffiliate()
  const {
    downlineItems,
    historyGroups,
    totalWithdraw,
    overview,
    overviewDownlineList,
    isDownlineItemLoading,
    callCash,
    setTextSearch,
    getAffiliateHistory,
    callAffiliateTransaction,
    callAffiliateMonthlyTransaction,
    setOverviewDate,
    overviewDate,
    isAffilateTransactionFetching,
    isAffilateMonthlyTransactionFetching,
  } = affiliateState
  const {
    totalKycAffiliate,
    totalDownlineLossing,
    totalFirstDepositAffiliate,
    graph,
  } = overview

  const getHistory = () => {
    getAffiliateHistory({
      start: form.getFieldsValue().historyFrom.format(),
      end: form.getFieldsValue().historyTo.format(),
    })
  }

  const checkPhoneAndBank = () => {
    const hasBank =
      profile?.bankAccountList && profile?.bankAccountList?.length > 0
    const hasPhone = profile?.phoneNumber && profile?.phoneNumber?.length > 0
    if (profile && !hasBank && !hasPhone) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        hideOnClose: true,
        header: 'เติมเงิน/ถอนเงิน',
        content: 'กรุณายืนยันตัวตนและตั้งค่าบัญชีธนาคาร',
        onClose: () => {},
        onConfirm: () => {
          router.push('/profile/set-phone-bank', '/profile/set-phone-bank', {
            scroll: true,
          })
        },
      })
      return
    }
    if (profile && !hasBank) {
      NiceModal.show(AlertConfirmModal, {
        open: true,
        hideOnClose: true,
        header: 'เติมเงิน/ถอนเงิน',
        content: 'กรุณาตั้งค่าบัญชีธนาคาร',
        onClose: () => {},
        onConfirm: () => {
          router.push('/profile/set-bank', '/profile/set-bank', {
            scroll: true,
          })
        },
      })
    }
  }

  React.useEffect(() => {
    callProfile()
  }, [callProfile])

  React.useEffect(() => {
    callAffiliateTransaction()
    form.setFieldsValue({
      overviewDate: moment(),
      historyFrom: moment(),
      historyTo: moment(),
    })
  }, [])

  React.useEffect(() => {
    if (activeIncomeTab === INCOME_TABS.HISTORY) {
      getHistory()
    }
  }, [activeIncomeTab])

  React.useEffect(() => {
    if (activeTab === TABS.INCOME) {
      callAffiliateTransaction()
      callAffiliateMonthlyTransaction()
    }
  }, [activeTab])

  const handleCopyButtonClicked = (type: string) => {
    if (type === 'code') {
      navigator.clipboard.writeText(customerCode)
      notification.success({ message: 'คัดลอกเรียบร้อยแล้ว', duration: 2 })
    }
    if (type === 'link') {
      navigator.clipboard.writeText(`${WEB_URL}/login?referrer=${customerCode}`)
      notification.success({ message: 'คัดลอกเรียบร้อยแล้ว', duration: 2 })
    }
  }

  const incomeTableColumns: ColumnsType<IncomeItem> = [
    {
      title: 'รหัสสมาชิก',
      key: 'downlineCode',
      dataIndex: 'downlineCode',
      width: '20%',
    },
    {
      title: 'ชื่อผู้ใช้',
      key: 'downlineName',
      dataIndex: 'downlineName',
      width: '25%',
      ellipsis: true,
    },
    {
      title: 'วันที่',
      key: 'incomeDate',
      dataIndex: 'incomeDate',
      width: '15%',
      render: (_, row) => (
        <div>{moment(row.incomeDate).format('DD/MM/YY')}</div>
      ),
    },
    {
      title: 'รายได้',
      key: 'amount',
      dataIndex: 'amount',
      width: '25%',
      render: (value) => <div>{numberWithCommas(value, 2)}</div>,
    },
    {
      title: 'โบนัส',
      dataIndex: 'bonus',
      width: '15%',
      render: (value) => <div>{numberWithCommas(value, 2)}</div>,
    },
  ]

  const summaryIncomeTableColumns: ColumnsType<IncomeItem> = [
    { title: 'รหัสสมาชิก', dataIndex: 'downlineCode', width: '20%' },
    {
      title: 'ชื่อผู้ใช้',
      dataIndex: 'downlineName',
      width: '30%',
      ellipsis: true,
    },
    {
      title: 'วันที่',
      dataIndex: 'incomeDate',
      width: '20%',
      render: (value, row) => (
        <div>{dayjs(row.incomeDate).format('DD/MM/YY')}</div>
      ),
    },
    {
      title: 'รายได้',
      dataIndex: 'amount',
      width: '15%',
      render: (value) => <div>{numberWithCommas(value, 2)}</div>,
    },
  ]

  const onTextSearchChange = debounce((textSearch: string) => {
    setTextSearch(textSearch)
  }, 500)

  const formatCalendar = (date: Moment): string => {
    return `${THAI_SHORT_MONTHS[date.month()]} ${date.format('YYYY')}`
  }

  const renderCellBar = (
    barData: BarData,
    index: number,
    selectingColor: string,
    disableColor: string,
  ) => {
    const disabled =
      THAI_SHORT_MONTHS.indexOf(barData.monthLabel) !== overviewDate.month()
    return (
      <Cell
        key={`cell-${index}`}
        fill={disabled ? disableColor : selectingColor}
      />
    )
  }

  return (
    <div className="ps-affiliate">
      <PageTitle className="text-center" size="small">
        แนะนำเพื่อน
      </PageTitle>
      <Tabs
        centered
        size="large"
        type="card"
        animated={{ inkBar: false, tabPane: true }}
        className="ps-affiliate__tab"
        activeKey={activeTab}
        onChange={(activeKey) => {
          const tab = activeKey as TABS
          setActiveTab(tab)
          if (tab === TABS.INVITE_LINK) {
            setActiveIncomeTab(INCOME_TABS.OVERVIEW)
          }
        }}
        items={[
          {
            key: TABS.INVITE_LINK,
            label: 'ลิงก์',
          },
          {
            key: TABS.INCOME,
            label: 'รายได้',
          },
        ]}
      />
      {activeTab === TABS.INVITE_LINK && (
        <>
          <Intro
            className={classnames('ps-affiliate-intro', { container: !isSM })}
          >
            <InviteBanner name="get10BahtImmediately" />
            <InviteBanner name="get50PercentOfDeposit" />
            <InviteBanner name="get10PercentOfLossing" />
          </Intro>
          <div className="container ps-affiliate-intro">
            <div style={{ textAlign: 'center' }}>
              <span
                className="ps-affiliate__tac"
                onClick={() => {
                  NiceModal.show(GenericModal, {
                    children: <AffiliateTAC />,
                  })
                }}
                aria-hidden
              >
                ข้อกำหนด เงื่อนไข และตัวอย่างการคำนวณรายได้
              </span>
            </div>
            <Space
              direction="vertical"
              style={{ width: '100%', marginTop: '16px' }}
              size={15}
            >
              <AffiliatePill
                label="รหัสแนะนำ"
                content={customerCode}
                onCopy={() => handleCopyButtonClicked('code')}
              />
              <AffiliatePill
                label="ลิงก์แนะนำ"
                content="https://pigbet.invit"
                onCopy={() => handleCopyButtonClicked('link')}
              />
            </Space>
            <FAQLinkButton
              type={BUTTON_TYPE.OUTLINE_CTA_1}
              onClick={() => {
                router.push(`/faq?tab=${FAQ_TAB.INVITE_FRIEND}`, '/faq', {
                  scroll: true,
                })
              }}
            >
              คำถามที่พบบ่อย
            </FAQLinkButton>
            <br />
            <br />
          </div>
        </>
      )}
      {activeTab === TABS.INCOME && (
        <>
          <AffiliateCashCard
            {...affiliateState}
            onWithdraw={async () => {
              const hasBank =
                profile?.bankAccountList && profile.bankAccountList.length > 0
              const hasPhone =
                profile?.phoneNumber && profile.phoneNumber.length > 0
              if (hasBank && hasPhone) {
                // TODO try to remove legacy systemstatus; let it has double fetch for temporary
                refreshSystemStatus()
                const legacySystemStatus = await refreshLegacySystemStatus()
                if (legacySystemStatus?.withdrawError) {
                  NiceModal.show(AlertModalV2, {
                    type: ALERT_MODAL_TYPE.CLOSE_WITHDRAW,
                  })
                  return
                }
                setWithdrawalModalVisible(true)
                return
              }
              checkPhoneAndBank()
            }}
          />
          <div
            id="jack"
            style={{
              marginTop: '0.4rem',
              marginBottom: '0.4rem',
              textAlign: 'center',
              fontSize: '24px',
            }}
          >
            ถอนไปแล้วทั้งหมด &nbsp;
            {numberWithCommas(totalWithdraw, 2)}
          </div>
        </>
      )}
      <section className="container pt-2 pb-8">
        <Form form={form}>
          {activeTab === TABS.INCOME && (
            <>
              <Tabs
                centered
                type="line"
                className="ps-affiliate__incometabs"
                defaultActiveKey={INCOME_TABS.OVERVIEW}
                activeKey={activeIncomeTab}
                onChange={(value) => {
                  const activeKey = value as INCOME_TABS
                  setActiveIncomeTab(activeKey)
                }}
                items={[
                  {
                    key: INCOME_TABS.OVERVIEW,
                    tabKey: INCOME_TABS.OVERVIEW,
                    label: 'ภาพรวมรายได้',
                  },
                  {
                    key: INCOME_TABS.DONWLINE,
                    tabKey: INCOME_TABS.DONWLINE,
                    label: 'องค์กร',
                  },
                  {
                    key: INCOME_TABS.HISTORY,
                    tabKey: INCOME_TABS.HISTORY,
                    label: 'ประวัติการถอน',
                  },
                ]}
              />
              {activeIncomeTab === INCOME_TABS.OVERVIEW && (
                <>
                  <div
                    style={{
                      maxWidth: 360,
                      margin: 'auto',
                      marginBottom: '1rem',
                    }}
                  >
                    <Form.Item name="overviewDate">
                      <Calendar
                        id="overview-date"
                        placeholder="เลือกเดือน/ปี"
                        value={moment.unix(overviewDate.unix())}
                        title=""
                        picker="month"
                        defaultPickerValue={moment()}
                        onChange={(date) => {
                          setOverviewDate(dayjs.unix(date.unix()))
                        }}
                        dateFormat={formatCalendar(
                          moment.unix(overviewDate.unix()),
                        )}
                      />
                    </Form.Item>
                  </div>
                  {!isAffilateTransactionFetching &&
                  !isAffilateMonthlyTransactionFetching ? (
                    overview.isEmpty ? (
                      <NoResult isAffiliate />
                    ) : (
                      <>
                        <ChartWrapper style={{ height: isMD ? 300 : 200 }}>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                              data={graph}
                              stackOffset="sign"
                              margin={{ top: 30 }}
                            >
                              <XAxis
                                fontSize={18}
                                dataKey="monthYearLabel"
                                axisLine={false}
                                tickLine={false}
                              />
                              <BarRechart
                                dataKey="downlineLossing"
                                fill="#4599FC"
                                stackId="stack"
                              >
                                {graph.map((entry, index) =>
                                  renderCellBar(
                                    entry,
                                    index,
                                    '#4599FC',
                                    '#AAAAAB',
                                  ),
                                )}
                              </BarRechart>
                              <BarRechart
                                dataKey="firstDepositAffiliate"
                                fill="#914DF3"
                                stackId="stack"
                              >
                                {graph.map((entry, index) =>
                                  renderCellBar(
                                    entry,
                                    index,
                                    '#914DF3',
                                    '#CACACA',
                                  ),
                                )}
                              </BarRechart>
                              <BarRechart
                                dataKey="kycAffiliate"
                                fill="#F6C244"
                                stackId="stack"
                              >
                                {graph.map((entry, index) =>
                                  renderCellBar(
                                    entry,
                                    index,
                                    '#F6C244',
                                    '#DEDEE0',
                                  ),
                                )}
                                <LabelList
                                  fontSize={18}
                                  fill="#2A2A2E"
                                  dataKey="total"
                                  position="top"
                                  offset={15}
                                  formatter={(value: number) =>
                                    value > 0
                                      ? `฿${numberWithCommas(value)}`
                                      : ''
                                  }
                                />
                              </BarRechart>
                            </BarChart>
                          </ResponsiveContainer>
                        </ChartWrapper>
                        <div style={{ margin: 'auto', maxWidth: 500 }}>
                          <Row style={{ marginTop: '1rem' }}>
                            <Legend
                              span={8}
                              style={{ borderLeft: '4px solid #F6C244' }}
                            >
                              <div>การยืนยันตัวตน</div>
                              <div>{`${numberWithCommas(
                                totalKycAffiliate,
                                2,
                              )} บาท`}</div>
                            </Legend>
                            <Legend
                              span={9}
                              style={{ borderLeft: '4px solid #914DF3' }}
                            >
                              <div>การเติมเงินครั้งแรก</div>
                              <div>{`${numberWithCommas(
                                totalFirstDepositAffiliate,
                                2,
                              )} บาท`}</div>
                            </Legend>
                            <Legend
                              span={7}
                              style={{ borderLeft: '4px solid #4599FC' }}
                            >
                              <div>ยอดเสีย</div>
                              <div>{`${numberWithCommas(
                                totalDownlineLossing,
                                2,
                              )} บาท`}</div>
                            </Legend>
                          </Row>
                        </div>
                        <div style={{ margin: '1rem auto' }}>
                          <AffiliateTable
                            columns={incomeTableColumns}
                            dataSource={overviewDownlineList}
                            pagination={false}
                          />
                        </div>
                      </>
                    )
                  ) : (
                    <LoadingWrapepr>
                      <Loading />
                    </LoadingWrapepr>
                  )}
                </>
              )}
              {activeIncomeTab === INCOME_TABS.DONWLINE && (
                <Spin spinning={isDownlineItemLoading}>
                  <div>
                    <Form.Item name="textSearch">
                      <CommonInput
                        style={{ maxWidth: 360, margin: 'auto' }}
                        onChange={(e) => {
                          onTextSearchChange(e.target.value)
                        }}
                        placeholder="ค้นหา รหัสสมาชิก, ชื่อผู้ใช้"
                        prefix={
                          <SearchOutlined
                            style={{ fontSize: 18.75, marginLeft: 10 }}
                          />
                        }
                        allowClear
                      />
                    </Form.Item>
                  </div>
                  {downlineItems.length < 1 ? (
                    <NoResult isAffiliate />
                  ) : (
                    <div>
                      <AffiliateTable
                        columns={summaryIncomeTableColumns}
                        dataSource={downlineItems}
                        pagination={false}
                      />
                    </div>
                  )}
                </Spin>
              )}
              {activeIncomeTab === INCOME_TABS.HISTORY && (
                <>
                  <Row
                    style={{ maxWidth: 360 * 2, margin: 'auto' }}
                    gutter={[16, 16]}
                  >
                    <Col span={12}>
                      <Label>เริ่มจาก</Label>
                      <Form.Item name="historyFrom">
                        <Calendar
                          id="overview-date"
                          placeholder="เลือกเดือน/ปี"
                          title=""
                          picker="month"
                          defaultPickerValue={moment()}
                          onChange={() => {
                            getHistory()
                          }}
                          dateFormat={formatCalendar(
                            moment.unix(
                              dayjs(form.getFieldsValue().historyFrom).unix(),
                            ) || moment(),
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Label>จนถึง</Label>
                      <Form.Item name="historyTo">
                        <Calendar
                          id="overview-date"
                          placeholder="เลือกเดือน/ปี"
                          title=""
                          picker="month"
                          defaultPickerValue={moment()}
                          onChange={() => {
                            getHistory()
                          }}
                          dateFormat={formatCalendar(
                            moment.unix(
                              dayjs(form.getFieldsValue().historyFrom).unix(),
                            ) || moment(),
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ minHeight: 230 }}>
                    {isEmpty(historyGroups) ? (
                      <NoResult isAffiliate />
                    ) : (
                      <TransactionHistorySection
                        mode="cash"
                        historyList={historyGroups}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </Form>
      </section>

      <Modal
        open={withdrawalModalVisible}
        destroyOnClose
        centered
        footer={null}
        closeIcon={<StyledCloseIcon />}
        onCancel={() => setWithdrawalModalVisible(false)}
      >
        <AffiliateWithdraw
          onWithdraw={() => {
            callCash()
            if (activeIncomeTab === INCOME_TABS.HISTORY) {
              getHistory()
            } else {
              setActiveIncomeTab(INCOME_TABS.HISTORY)
            }
            NiceModal.hide(GenericModal)
          }}
          onError={() => {
            NiceModal.hide(GenericModal)
          }}
        />
      </Modal>
    </div>
  )
}

export default Affiliate
