import React from 'react'
import styled from 'styled-components'
import { NextPage } from 'next'
import Link from 'next/link'
import isEmpty from 'lodash/isEmpty'

import { Images } from '@pig-common/components/Images'
import NiceModal from '@ebay/nice-modal-react'
import { Col, Row, Grid, Space, Spin as AntdSpin } from 'antd'
import { screen } from '@pig-common/utils/responsive'
import { numberWithCommas } from '@pig-common/utils/number'
import { BUTTON_TYPE, Button } from '@pig-common/components/Button'
import { CLOUD_STORAGE_CDN } from '@pig-common/models/buildtime-constant'
import { useLoyaltyProgram } from '@pig-common/hooks/useLoyaltyProgram'
import AlertWithImageModal from '@pig-common/components/Modal/AlertWithImageModal/AlertWithImageModal'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import Roulette, { PrizeItem } from '@pig-common/components/DailySpin/Roulette'
import { useSpin } from '@pig-common/hooks/useSpin'
import { useBalance } from '@pig-common/hooks/useBalance'
import RankingSection from '@pig-common/components/DailySpin/Ranking'
import HistorySection from '@pig-common/components/DailySpin/History'
import dayjs from 'dayjs'

const Topic = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme['white-300']};
`

const PageBackground = styled.div`
  background-color: ${({ theme }) => `${theme['primary-50']}66`};
  min-height: calc(100vh - 270px);
  padding-top: 25px;
  @media ${screen.lg} {
    padding-top: 50px;
  }
`

const Slotmachine = styled.div`
  position: relative;
  display: inline-block;
  height: 280px;
  width: 280px;
  @media ${screen.md} {
    height: 400px;
    width: 400px;
  }
`
const JackpotScreen = styled.div`
  position: absolute;
  left: 50%;
  height: 36px;
  width: 210px;
  color: white;
  font-weight: 500;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;
  transform: translate(-50%, 0%);
  top: 3%;
  font-size: 28px;
  white-space: nowrap;
  @media ${screen.md} {
    top: 4.5%;
    font-size: 36px;
  }
`
const NeonText = styled.div`
  text-shadow: 0 0 7px #fff, 0 0 9px #fff, 0 0 19px #fff,
    0 0 38px ${({ theme }) => theme['primary-600']},
    0 0 74px ${({ theme }) => theme['primary-600']},
    0 0 83px ${({ theme }) => theme['primary-600']},
    0 0 92px ${({ theme }) => theme['primary-600']},
    0 0 136px ${({ theme }) => theme['primary-600']};
`
const Spin = styled(Col)`
  text-align: center;
  margin-bottom: 24px;
`

const StyledLink = styled(Link)`
  font-size: 1rem;
  margin: 0 5px;
`

const SpinDetail = styled.div`
  line-height: 1;
  margin-top: 10px;
  margin-bottom: 20px;
  > *:first-child {
    color: ${({ theme }) => theme['white-500']};
  }
  > *:last-child {
    color: ${({ theme }) => theme['white-400']};
  }
`

const Ticket = styled.div`
  text-align: right;
  line-height: 1;
  .ps-icon-ticket {
    color: ${({ theme }) => theme['primary-500']};
  }
`

const TicketImage = styled.img`
  width: 24px;
  height: 24px;
`

const PrimaryText = styled.span`
  margin: 0 4px;
  color: ${({ theme }) => theme['primary-500']};
`

const DailySpin: NextPage = () => {
  const {
    isFreeSpin,
    quotaFreespin,
    isFetchingJackpot,
    isCheckingFreespin,
    isFreeSpinLoading,
    isSpinByTicketLoading,
    jackpotAmount,
    spinByTicketResult,
    freeSpinResult,
    isSpinHistoryFetching,
    spinHistory,
    topRanking,
    isFetchingRanking,
    summaryRankingDay,
    summaryRankingMonth,
    checkQuotaFreespin,
    freeSpin,
    spinByTicket,
    fetchSpinHistory,
    fetchingRanking,
  } = useSpin()
  const { callGetBalance } = useBalance()
  const [showResultModal, setShowResultModal] = React.useState(false)
  const [rouletteActive, setRouletteActive] = React.useState<boolean>(false)
  const [totalTickets, setTotalTicket] = React.useState<number>(0)
  const [rewardPrize, setRewardPrize] = React.useState<PrizeItem | null>(null)
  const winnerTitle = 'ยินดีด้วย! คุณได้รับรางวัล'
  const { lg } = Grid.useBreakpoint()
  const { callTotalTickets } = useLoyaltyProgram()
  const ticket = (
    <Ticket>
      <Space align="start">
        <TicketImage
          alt="daily-spin-ticket"
          src="/images/dailyquests/primary-ticket.png"
          className="ps-icon-ticket"
        />
        <AntdSpin spinning={false}>
          {`${numberWithCommas(totalTickets || 0)} `}
          Tickets
        </AntdSpin>
      </Space>
    </Ticket>
  )

  const onFreeSpin = () => {
    freeSpin()
  }

  const onSpinByTickets = () => {
    if (rouletteActive || isFreeSpinLoading || isSpinByTicketLoading) return
    NiceModal.show(AlertConfirmModal, {
      open: true,
      isShowIcon: false,
      buttonConfirmText: 'ตกลง',
      content: (
        <span>
          ยืนยันการใช้
          <PrimaryText>10 Tickets</PrimaryText>
          เพื่อสปิน
        </span>
      ),
      modalComponent: 'antd',
      onConfirm: () => {
        spinByTicket()
      },
    })
  }

  const rouletteReset = () => {
    setRouletteActive(false)
    setRewardPrize(null)
    setShowResultModal(false)
    callGetBalance()
    fetchSpinHistory()
    fetchingRanking()
  }
  const toggleRouletteModal = (prize: PrizeItem) => {
    setShowResultModal(true)
  }

  const handleWinPrize = (value: number) => {
    checkQuotaFreespin()
    if (value && value > 0) {
      setRouletteActive(true)
      setRewardPrize({
        id: 'win-prize',
        text: `${value}`,
        image: '',
      })
    } else {
      setRewardPrize(null)
    }
  }

  React.useEffect(() => {
    handleWinPrize(freeSpinResult)
  }, [freeSpinResult])

  const fetchTotalTicket = async () => {
    const totalResponse = await callTotalTickets()
    if (totalResponse?.tickets && totalResponse !== undefined) {
      setTotalTicket(totalResponse.tickets)
    }
  }

  React.useEffect(() => {
    handleWinPrize(spinByTicketResult)
    fetchTotalTicket()
  }, [spinByTicketResult])

  React.useEffect(() => {
    checkQuotaFreespin()
  }, [])

  return (
    <PageBackground>
      <Row className="container">
        <Spin span={24} lg={12} xl={12}>
          {!lg && ticket}
          <Slotmachine>
            <JackpotScreen>
              {!isFetchingJackpot && (
                <NeonText>
                  {!!jackpotAmount && jackpotAmount > 0
                    ? `JACKPOT ${numberWithCommas(jackpotAmount)}`
                    : 'DAILY SPIN'}
                </NeonText>
              )}
            </JackpotScreen>
            <Roulette
              active={rouletteActive}
              loading={isFreeSpinLoading || isSpinByTicketLoading}
              winPrize={rewardPrize}
              height={34}
              amount={100}
              speed={5}
              position={{
                top: '28%',
                right: '13.5%',
                bottom: '40%',
                left: '13.5%',
              }}
              imgPointer={`${CLOUD_STORAGE_CDN}/pigspin-assets/loyalty/daily-spin/roulette-arrow.webp`}
              imgRoullette={`${CLOUD_STORAGE_CDN}/pigspin-assets/loyalty/daily-spin/roulette.webp`}
              imgRoulletteDecor={[
                `${CLOUD_STORAGE_CDN}/pigspin-assets/loyalty/daily-spin/roulette-light-1.webp`,
                `${CLOUD_STORAGE_CDN}/pigspin-assets/loyalty/daily-spin/roulette-light-2.webp`,
              ]}
              imgHandler={`${CLOUD_STORAGE_CDN}/pigspin-assets/loyalty/daily-spin/roulette-handler.webp`}
              handlerSpin={isFreeSpin ? onFreeSpin : onSpinByTickets}
              onRewardDeclare={toggleRouletteModal}
            />
          </Slotmachine>
          <SpinDetail>
            <div>ฟรีสปิน :{quotaFreespin}</div>
            <div>
              รับฟรีสปินทุกวัน ได้ที่
              <StyledLink href="/notifications">การแจ้งเตือน</StyledLink>
            </div>
          </SpinDetail>
          <AntdSpin
            spinning={
              isCheckingFreespin ||
              isFreeSpinLoading ||
              isSpinByTicketLoading ||
              rouletteActive
            }
          >
            <Space direction="vertical">
              <Button
                wrapperStyle={{ margin: 'auto', width: 240 }}
                type={BUTTON_TYPE.CTA_1}
                disabled={
                  !isFreeSpin ||
                  isCheckingFreespin ||
                  isFreeSpinLoading ||
                  isSpinByTicketLoading ||
                  rouletteActive
                }
                onClick={onFreeSpin}
              >
                ฟรีสปิน
              </Button>
              <Button
                wrapperStyle={{ margin: 'auto', width: 240 }}
                type={BUTTON_TYPE.CTA_1}
                disabled={
                  isCheckingFreespin ||
                  isFreeSpinLoading ||
                  isSpinByTicketLoading ||
                  rouletteActive
                }
                onClick={onSpinByTickets}
              >
                10 Tickets
              </Button>
            </Space>
          </AntdSpin>
        </Spin>
        <Col span={24} lg={12} xl={12}>
          {lg && ticket}
          <AntdSpin spinning={isFetchingRanking}>
            <Topic>
              รางวัลใหญ่ประจำวัน :{`${dayjs().format('DD/MM/YYYY')}`}
            </Topic>
            <RankingSection
              ranking={topRanking}
              daily={summaryRankingDay}
              monthly={summaryRankingMonth}
            />
          </AntdSpin>
          <AntdSpin spinning={isSpinHistoryFetching}>
            <Topic>ประวัติรายการ</Topic>
            <HistorySection data={spinHistory} />
          </AntdSpin>
        </Col>
      </Row>
      <AlertWithImageModal
        open={!isEmpty(rewardPrize) && showResultModal}
        image={
          <Images
            src="/images/dailyquests/claim-success.png"
            alt="image-success-modal"
            width={210}
            height={210}
            className="claim-success-modal"
          />
        }
        content={
          <div>
            <div>{winnerTitle}</div>
            <div>
              <b>
                {`${
                  rewardPrize?.text &&
                  numberWithCommas(parseFloat(rewardPrize?.text), 2)
                } บาท`}
              </b>
            </div>
          </div>
        }
        contentClass="ps-modal-congrat text-center relative"
        closableBackDrop={false}
        buttonClose={{
          fnc: () => {
            rouletteReset()
          },
          txt: 'ปิด',
        }}
        buttonAccept={{
          fnc: () => {
            rouletteReset()
          },
          txt: 'ตกลง',
        }}
      />
      <br />
      <br />
    </PageBackground>
  )
}

export default DailySpin
