import NiceModal from '@ebay/nice-modal-react'
import { ThemeProvider } from 'styled-components'
import LineLink, {
  LineLinkProps,
  getLineLinkServerSideProps,
} from '@pig-common/pages-common/line/link'

import styledTheme from '@pig-common/styles/scss/abstracts/theme.json'

function LineLinkPage(props: LineLinkProps) {
  return (
    <ThemeProvider theme={styledTheme}>
      <NiceModal.Provider>
        <LineLink {...props} />
      </NiceModal.Provider>
    </ThemeProvider>
  )
}

export { LineLinkPage, getLineLinkServerSideProps }
export type { LineLinkProps }
