import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { ALERT_MODAL_TYPE, AlertModal } from '@pig-common/components/Modal'
import { CHANGE_PIN_STEP, ChangePin } from '@pig-common/components/ChangePin'
import CommonModal from '../CommonModal/CommonModal'

export type PinModalProps = {
  open: boolean
  closableBackDrop?: boolean
  onClose: () => void
  phoneNumber?: string
}

export enum MODAL_TYPE {
  CHANGE_PIN,
  BLOCKED_PIN,
  BANNED_USER,
  CONFIRM_PIN_DOES_NOT_MATCH,
  SOMETHING_WENT_WRONG,
  CHANGE_PIN_SUCCESS,
}

const ChangePinModal = ({
  open,
  closableBackDrop = true,
  onClose,
  phoneNumber,
}: PinModalProps): JSX.Element => {
  const router = useRouter()
  const [currentModalState, setCurrentModalState] = useState<MODAL_TYPE>(
    MODAL_TYPE.CHANGE_PIN,
  )
  const [initialStep, setInitialStep] = useState<CHANGE_PIN_STEP>(
    CHANGE_PIN_STEP.VERIFY_CURRENT_PIN,
  )
  const [currentPin, setCurrentPin] = useState('')

  const BlockedPinHandler = () => {
    router.push(
      { pathname: '/reset-pin', query: { phone: phoneNumber } },
      '/reset-pin',
    )
  }

  const BannedUserHandler = () => {
    router.push('/')
  }

  const SomethingWentWrongHandler = () => {
    setCurrentModalState(MODAL_TYPE.CHANGE_PIN)
    onClose()
  }

  const ChangePinSuccess = () => {
    setCurrentModalState(MODAL_TYPE.CHANGE_PIN)
    onClose()
  }

  const ConfirmPinDoesNotMatch = () => {
    setInitialStep(CHANGE_PIN_STEP.SET_NEW_PIN)
    setCurrentModalState(MODAL_TYPE.CHANGE_PIN)
  }

  const currentModal = () => {
    switch (currentModalState) {
      case MODAL_TYPE.BLOCKED_PIN:
        return (
          <AlertModal
            type={ALERT_MODAL_TYPE.PIN_LOCKED}
            open={open}
            onClose={() => BlockedPinHandler()}
            columnClass="col-lg-4"
          />
        )
      case MODAL_TYPE.BANNED_USER:
        return (
          <AlertModal
            type={ALERT_MODAL_TYPE.BANNED_USER}
            open={open}
            onClose={() => BannedUserHandler()}
            columnClass="col-lg-4"
          />
        )
      case MODAL_TYPE.CONFIRM_PIN_DOES_NOT_MATCH:
        return (
          <AlertModal
            type={ALERT_MODAL_TYPE.CONFIRM_PIN_DOES_NOT_MATCH}
            open={open}
            onClose={() => ConfirmPinDoesNotMatch()}
            columnClass="col-lg-4"
          />
        )
      case MODAL_TYPE.SOMETHING_WENT_WRONG:
        return (
          <AlertModal
            type={ALERT_MODAL_TYPE.SOMETHING_WENT_WRONG}
            open={open}
            onClose={() => SomethingWentWrongHandler()}
            columnClass="col-lg-4"
          />
        )
      case MODAL_TYPE.CHANGE_PIN_SUCCESS:
        return (
          <AlertModal
            type={ALERT_MODAL_TYPE.CHANGE_PIN_SUCCESS}
            open={open}
            onClose={() => ChangePinSuccess()}
            columnClass="col-lg-4"
          />
        )
      case MODAL_TYPE.CHANGE_PIN:
      default:
        return (
          <CommonModal
            open={open}
            closableBackDrop={closableBackDrop}
            onClose={onClose}
            columnClass="col-lg-4"
          >
            <div className="ps-change-pin-modal">
              <ChangePin
                phoneNumber={phoneNumber}
                onClose={() => {
                  onClose()
                  setInitialStep(CHANGE_PIN_STEP.VERIFY_CURRENT_PIN)
                }}
                onVerifyCurrentSuccess={setCurrentPin}
                initialStep={initialStep}
                initialCurrentPin={currentPin}
                isBlockedPin={() => {
                  setCurrentModalState(MODAL_TYPE.BLOCKED_PIN)
                }}
                isBannedUser={() => {
                  setCurrentModalState(MODAL_TYPE.BANNED_USER)
                }}
                isConfirmPinDoesNotMatch={() => {
                  setCurrentModalState(MODAL_TYPE.CONFIRM_PIN_DOES_NOT_MATCH)
                }}
                isSomethingWentWrong={() => {
                  setCurrentModalState(MODAL_TYPE.SOMETHING_WENT_WRONG)
                }}
                isChangePinSuccess={() => {
                  setCurrentModalState(MODAL_TYPE.CHANGE_PIN_SUCCESS)
                }}
              />
            </div>
          </CommonModal>
        )
    }
  }
  return currentModal()
}

export default ChangePinModal
