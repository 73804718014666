import React, {
  useImperativeHandle,
  useState,
  useEffect,
  createRef,
  forwardRef,
} from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useRecoilState } from 'recoil'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { sleep } from '@pig-common/utils/time'
import { onInputFocusStateCommon } from '@pig-common/recoils'
import { InputDigitHandles, InputDigitProps } from './InputDigit.type'
import range from 'lodash/range'

const ERROR_DELAY = 1.5
const animations = {
  inputErrorShake: {
    x: [0, 5, -5, 0],
    transition: { repeat: 2, duration: 0.15 },
  },
  showErrorMessage: {
    opacity: 1,
    height: '30px',
    transition: { duration: 0.2 },
  },
  hideErrorMessage: {
    opacity: 0,
    height: '0px',
    transition: { duration: 0.2 },
  },
}

const InputDigit = forwardRef<InputDigitHandles, InputDigitProps>(
  (
    { id = 'digit-input', total = 6, label, disabled = false, onComplete },
    ref,
  ) => {
    const [digits, setDigits] = useState<string[]>([])
    const [focusIndex, setFocusIndex] = useState<number>(0)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isInputError, setIsInputError] = useState<boolean>(false)
    const [isErrorAnimation, setIsErrorAnimation] = useState<boolean>(false)
    const [shouldErrorMessageShow, setShouldErrorMessageShow] =
      useState<boolean>(false)
    const [inputRefs, setInputRefs] = useState<
      React.RefObject<HTMLInputElement>[]
    >([])
    const [, setOnFocusInput] = useRecoilState(
      onInputFocusStateCommon.onInputFocusState,
    )

    const error = async (message?: string) => {
      if (message) {
        setErrorMessage(message)
        setShouldErrorMessageShow(true)
      }
      setIsInputError(true)
      setIsErrorAnimation(true)
      setDigits([])
      setFocusIndex(0)
      await sleep(ERROR_DELAY)

      setIsInputError(false)
      setIsErrorAnimation(false)
      inputRefs[0].current?.focus()
    }

    const errorWithOutAnimation = async (message?: string) => {
      if (message) {
        setErrorMessage(message)
        setShouldErrorMessageShow(true)
      }
      setIsInputError(true)
      setDigits([])
      setIsInputError(false)
    }

    const clearInput = () => {
      inputRefs[0].current?.focus()
      setDigits([])
      setFocusIndex(0)
    }

    const clearError = () => {
      setErrorMessage('')
      setShouldErrorMessageShow(false)
    }

    useImperativeHandle(ref, () => ({
      error,
      clearInput,
      clearError,
      errorWithOutAnimation,
    }))

    useEffect(() => {
      setInputRefs((newInputRefs) =>
        Array(total)
          .fill(null)
          .map((_, i) => newInputRefs[i] || createRef()),
      )
    }, [total])

    useEffect(() => {
      if (onComplete && digits.length >= total) {
        onComplete(digits.join(''))
      }
    }, [digits])

    const onInputFocus = () => {
      setOnFocusInput(true)
    }
    const onInputBlur = () => {
      setOnFocusInput(false)
    }

    return (
      <div
        className="ps-input-digit"
        aria-hidden
        onClick={() => {
          // resume focus
          if (!isInputError && focusIndex < inputRefs.length) {
            inputRefs[focusIndex].current?.focus()
          }
        }}
      >
        <div data-testid={`${id}__label`} className="ps-input-digit__label">
          {label}
        </div>
        <motion.div
          variants={animations}
          animate={isErrorAnimation ? 'inputErrorShake' : ''}
        >
          <div className="ps-input-digit__input">
            {range(total).map((n) => {
              return (
                <div
                  key={`${id}-${n}`}
                  id={`${id}-${n}`}
                  className={`position-relative ${id}-${n}`}
                >
                  <span
                    className="ps-input-digit__dot"
                    data-testid={`${id}-dot-${n}`}
                    style={{
                      display: n < focusIndex - 1 ? 'initial' : 'none',
                    }}
                  >
                    ●
                  </span>
                  <input
                    ref={inputRefs[n]}
                    maxLength={1}
                    value={n === focusIndex - 1 ? digits[n] || '' : ''}
                    data-testid={`${id}-${n}`}
                    disabled={disabled}
                    autoComplete="off"
                    inputMode="numeric"
                    type="tel"
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    className={classNames('ps-input-digit__input-item', {
                      'ps-input-digit__input-item--error': isInputError,
                    })}
                    onKeyDown={(e) => {
                      // handle delete
                      if (
                        (e.key === 'Backspace' || e.key === 'Delete') &&
                        n > 0
                      ) {
                        const newDigits = [...digits]
                        newDigits[n - 1] = ''
                        setDigits(newDigits)
                        setFocusIndex(n - 1)
                        inputRefs[n - 1].current?.focus()
                      } else {
                        const regex = new RegExp(/[^0-9]/, 'g')
                        const value = e.key
                        if (n > 0 && (value.match(regex) || value === '')) {
                          setErrorMessage('กรุณากรอกเป็นตัวเลขเท่านั้น')
                          setShouldErrorMessageShow(true)
                          return
                        }
                        if (!value.match(regex) && value !== '') {
                          setShouldErrorMessageShow(false)
                          const newDigits = [...digits]
                          newDigits[n] = value
                          setDigits(newDigits)
                          // focus next input
                          setFocusIndex(n + 1)
                          if (n < total - 1) {
                            inputRefs[n + 1].current?.focus()
                          } else {
                            inputRefs[n].current?.blur()
                          }
                        }
                      }
                    }}
                    onMouseDown={(e) => {
                      // prevent manual focus
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onPaste={(e) => {
                      const regex = new RegExp(/[^0-9]/, 'g')
                      const values = e.clipboardData.getData('text')
                      if (values.match(regex) || values === '') {
                        setErrorMessage('กรุณากรอกเป็นตัวเลขเท่านั้น')
                        setShouldErrorMessageShow(true)
                        return
                      }
                      values.split('').forEach((value) => {
                        setShouldErrorMessageShow(false)
                        const newDigits = [...digits]
                        newDigits[n] = value
                        setDigits(values.split(''))
                        // focus next input
                        setFocusIndex(values.length)
                        if (values.length < total - 1) {
                          inputRefs[values.length].current?.focus()
                        } else {
                          inputRefs[values.length - 1].current?.blur()
                        }
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>
          <motion.div
            data-testid={`${id}-error-msg`}
            className="ps-input-digit__alert"
            variants={animations}
            animate={
              shouldErrorMessageShow ? 'showErrorMessage' : 'hideErrorMessage'
            }
          >
            <span className="ps-input-digit__alert-icon">
              <ExclamationCircleOutlined />
            </span>
            <span
              data-testid={`${id}-error-msg-label`}
              className="ps-input-digit__alert-message"
            >
              {errorMessage}
            </span>
          </motion.div>
        </motion.div>
      </div>
    )
  },
)

InputDigit.displayName = 'InputDigit'

export default InputDigit
