import { useRecoilValue } from 'recoil'
import { useQuery } from '@tanstack/react-query'
import { authStateCommon } from '@pig-common/recoils'
import { vipApi } from '@pig-common/api'
import { IVipSummaryData } from '@pig-common/types/Vip.type'
import { CommonResponse } from '@pig-common/types/Api.type'
import { tierMapper } from '@pig-common/utils/tierMapper'

interface IVIPSummaryHookProps<SelectReturnType> {
  //   params?: {}
  select?: (data: CommonResponse<IVipSummaryData>) => SelectReturnType
  enabled?: boolean
}

export default function useVIPSummary<SelectReturnType = IVipSummaryData>({
  //   params = {},
  select,
  enabled,
}: IVIPSummaryHookProps<SelectReturnType>) {
  const { customerCode } = useRecoilValue(authStateCommon.authState)
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)

  return useQuery({
    queryKey: [vipApi.key.getSummary],
    queryFn: async () => {
      const response = await vipApi.getSummary({ customerCode })
      const responseWithMappedTier = {
        ...response,
        data: tierMapper<IVipSummaryData>(response?.data),
      }
      return responseWithMappedTier
    },
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select ? select(data) : data?.data) || {},
  })
}
