import { CLOUD_STORAGE_CDN } from '@pig-common/models/buildtime-constant'

enum CharacterIndex {
  PIXEL = 'pixel',
  PIXXA = 'pixxa',
  RICHY = 'richy',
  SHINY = 'shiny',
}
export const getAvatar = ({
  avatar,
  avatarSeed,
  isChampion,
  onlyFileName,
}: {
  avatar?: string
  avatarSeed?: string
  isChampion?: boolean
  onlyFileName?: boolean
}) => {
  const totalRandomAvatar = 4
  let imagePath = isChampion
    ? `${CLOUD_STORAGE_CDN}/pigspin-assets/avatar/champion/`
    : `${CLOUD_STORAGE_CDN}/pigspin-assets/avatar/`
  let imageExt = '.png'
  if (onlyFileName) {
    imagePath = ''
    imageExt = ''
  }
  if (avatar) return `${imagePath}${avatar.toLowerCase()}${imageExt}`
  if (avatarSeed) {
    const index =
      (parseInt(avatarSeed.slice(-3, -2)) +
        parseInt(avatarSeed.slice(-2, -1)) +
        parseInt(avatarSeed.slice(-1))) %
      totalRandomAvatar
    const generatedAvatar = Object.keys(CharacterIndex)[index || 0]
    const generatedAvatarUrl = `${imagePath}${generatedAvatar.toLowerCase()}${imageExt}`
    return generatedAvatarUrl
  }
  return `${imagePath}pixel${imageExt}`
}
