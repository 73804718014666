import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import { map } from 'lodash'
import { ColumnType } from 'antd/es/table/interface'
import dayjs from 'dayjs'
import NoResult from '@pig-common/components/Affiliate/NoResult'
import { numberWithCommas } from '@pig-common/utils/number'
import { StyledRow, StyledTable } from './Table.styled'
import { CustomColumnType, RowStyle, TableStyle } from './Table.type'

export type TableProps<RecordType> = {
  columns: (ColumnType<RecordType> & CustomColumnType)[]
  data: RecordType[]
}

type FunctionComponentType = <RecordType>(
  props: React.PropsWithChildren<TableProps<RecordType>> &
    RowStyle &
    TableStyle,
) => React.ReactElement

const Table: FunctionComponentType = ({
  data,
  columns,
  rowStyle = 'primary',
  scrollBar,
}) => {
  const winnerDisplay = useMemo(() => {
    return map(data, (item: any) => {
      const key = Object.values(item)
        .map((i) => `${i}`)
        .join()
      return (
        <StyledRow
          key={key}
          className="ps-game-winner"
          rowStyle={rowStyle}
          initial={{ y: -32 }}
          animate={{ y: 0 }}
          columns={columns.map((col) => col.gridTemplateColumns || 'auto')}
          transition={{
            x: { duration: 1.5 },
            default: {
              ease: 'easeOut',
            },
          }}
        >
          {columns.map((col, i) => {
            let value = item[col.key || '']
            if (col.styleType === 'datetime') {
              value = dayjs(value).format('DD/MM/YYYY HH:mm')
            }
            if (col.styleType === 'amount') {
              value = numberWithCommas(value, 2)
            }
            return (
              <div
                key={`${item[col.key || '']}`}
                className={`ps-game-winner-${col.styleType || 'text'}`}
              >
                {col.render ? col.render(item[col.key || ''], item, i) : value}
              </div>
            )
          })}
        </StyledRow>
      )
    })
  }, [data])

  if (isEmpty(data)) return <NoResult />
  return (
    <StyledTable
      className="ps-game-winner-list"
      columns={columns.map((col) => col.gridTemplateColumns || 'auto')}
      scrollBar={scrollBar}
    >
      <div className="ps-game-winner-list__axis">
        {columns.map((col) => (
          <div key={`col-${col.title}`}>{`${col.title}`}</div>
        ))}
      </div>
      <div className="ps-game-winner-list__list">{winnerDisplay}</div>
    </StyledTable>
  )
}

export default Table
