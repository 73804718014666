import { AxiosRequestConfig } from 'axios'
import { BaseResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'

export type ImageType = {
  uid: string
  image_type: string
  image_url: string
}

export type PhysicalDataType = {
  reward_uid: string
  title: string
  sub_title: string
  detail: string
  tickets: number
  images: ImageType[]
  status: string
  user_tickets: number
  ticket_require: number
}

export type AddressDataType = {
  uid?: string
  first_name: string
  last_name: string
  sub_district: string
  province: string
  phone: string
  address: string
  district: string
  zip_code: string
  customer_code?: string
  created_at?: string
  updated_at?: string
}

export type PhysicalRewardDetailData = {
  physical: PhysicalDataType
  address: AddressDataType
}

export type getPhysicalRewardDetailRequest = {
  userCode: string
  rewardUid: string
}

type getPhysicalRewardDetailResponse = BaseResponse & {
  status: string
  data: PhysicalRewardDetailData
}

const getPhysicalRewardDetail = async (
  request: getPhysicalRewardDetailRequest,
): Promise<PhysicalRewardDetailData | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/loyalty/${request.userCode}/shop/rewards/physical/detail/${request.rewardUid}`,
      method: 'GET',
    }

    const result = await fetchWithJSON<getPhysicalRewardDetailResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    const { data } = result

    return data || []
  } catch (error) {
    return null
  }
}

export default getPhysicalRewardDetail
