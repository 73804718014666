import styled from 'styled-components'
import React, { useMemo, useEffect } from 'react'
import { Popover } from 'antd'
import { useRecoilValue } from 'recoil'
import MoreOutlined from '@ant-design/icons/lib/icons/MoreOutlined'
import Tabs from '@pig-common/components/Common/Tabs'
import PageTitle from '@pig-common/components/Common/PageTitle'
import NotiBadge from '@pig-common/components/Common/NotiBadge'
import { useInbox } from '@pig-common/hooks/useInbox'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import { screen } from '@pig-common/utils/responsive'
import { inboxStateCommon } from '@pig-common/recoils'
import { NoResult } from '@pig-common/components/Affiliate'
import { useCookies } from '@pig-common/utils/cookies'
import {
  getUnreadSystemStatusAmount,
  mapSystemStatusInbox,
} from '@pig-common/utils/inbox'
import { InboxItem } from '@pig-common/recoils/modules/inbox'
import dayjs from 'dayjs'
import TransactionComponent from './TransactionComponent'
import useLegacySystemStatus from '@pig-common/hooks/useLegacySystemStatus'

export enum INBOX_TABS {
  SYSTEM = 'SYSTEM',
  TRANSACTION = 'TRANSACTION',
  AFFILIATE = 'AFFILIATE',
}

const InboxPage = styled.div`
  position: relative;
  margin: auto;
  max-width: 1048px;
  padding: 0 16px;
  @media ${screen.md} {
    max-width: 600px;
  }
`

const StyledSpan = styled.span`
  position: relative;
`

const StyledPopOver = styled(Popover)`
  position: absolute;
  right: 16px;
  top: 10px;
  @media ${screen.md} {
    top: 20px;
  }
`

const PopOverContent = styled.div``

const ReadAllButton = styled(Button)`
  font-size: 24px !important;
  width: 128px;
  font-weight: 400;
  border-radius: 0;
  transition-duration: 0.2;
  &:hover {
    background-color: #f3ecfd;
  }
`

export default function Inbox() {
  const [cookies] = useCookies()
  const {
    readInbox,
    loadMore,
    isLoading,
    hasNextTransactiontPage,
    hasNextAffiliatePage,
  } = useInbox()
  const allInbox = useRecoilValue(inboxStateCommon.allInboxSelector)
  const [isShowPopover, setIsShowPopover] = React.useState(false)
  const allInboxSystemStatus = mapSystemStatusInbox(allInbox, cookies)
  const unreadSystemStatusAmount =
    getUnreadSystemStatusAmount(allInboxSystemStatus)
  const { refreshSystemStatus } = useLegacySystemStatus()

  useEffect(() => {
    refreshSystemStatus()
  }, [])

  const readAllUnreadInboxItem = React.useCallback(() => {
    const isAllRead = allInbox?.sumUnread < 1
    if (isAllRead) return
    const unreadInbox = (item: inboxStateCommon.InboxItem) => !item?.isRead
    readInbox([
      ...(allInbox?.affiliate?.inboxs?.filter(unreadInbox) || []),
      ...(allInbox?.transaction?.inboxs?.filter(unreadInbox) || []),
      ...(allInboxSystemStatus?.filter(unreadInbox) || []),
    ])
  }, [allInbox])

  const filterCashback = (inbox: InboxItem) => {
    if (inbox.icon !== '<IconCashback />') return true
    return dayjs().isAfter(inbox.start)
  }

  const inboxTransactions = React.useMemo(() => {
    const result = allInbox.transaction.inboxs.filter(filterCashback)
    return result
  }, [allInbox.transaction.inboxs, cookies.daily_free_spin])

  const transactionList = useMemo(() => {
    return [...inboxTransactions].reverse()
  }, [inboxTransactions])

  const affilaiteList = useMemo(() => {
    return [...allInbox.affiliate.inboxs].reverse()
  }, [allInbox.affiliate.inboxs])

  const unreadInboxTransaction = inboxTransactions.filter(
    (_t) => !_t.isRead,
  ).length

  return (
    <InboxPage>
      <PageTitle size="small">การแจ้งเตือน</PageTitle>
      <StyledPopOver
        className="ReadAll"
        trigger={['click']}
        placement="bottomRight"
        showArrow={false}
        open={isShowPopover}
        onOpenChange={(visible) => {
          setIsShowPopover(visible)
        }}
        content={
          <PopOverContent>
            <ReadAllButton
              type={BUTTON_TYPE.PLAIN_TEXT}
              onClick={() => {
                readAllUnreadInboxItem()
                setIsShowPopover(false)
              }}
            >
              อ่านทั้งหมด
            </ReadAllButton>
          </PopOverContent>
        }
      >
        <MoreOutlined />
      </StyledPopOver>
      <Tabs
        centered
        type="line"
        defaultActiveKey={INBOX_TABS.SYSTEM}
        className="ps-tab-menu"
        items={[
          {
            key: INBOX_TABS.SYSTEM,
            tabKey: INBOX_TABS.SYSTEM,
            label: (
              <StyledSpan>
                ระบบ
                {unreadSystemStatusAmount > 0 ? (
                  <NotiBadge amount={unreadSystemStatusAmount} />
                ) : null}
              </StyledSpan>
            ),
            children:
              allInboxSystemStatus.length > 0 ? (
                <TransactionComponent
                  compKey="system"
                  type="system"
                  dataList={allInboxSystemStatus}
                />
              ) : (
                <NoResult />
              ),
          },
          {
            key: INBOX_TABS.TRANSACTION,
            tabKey: INBOX_TABS.TRANSACTION,
            label: (
              <StyledSpan>
                รายการ
                {unreadInboxTransaction > 0 ? (
                  <NotiBadge amount={unreadInboxTransaction} />
                ) : null}
              </StyledSpan>
            ),
            children:
              allInbox.transaction.inboxs.length > 0 ? (
                <TransactionComponent
                  compKey="transaction"
                  type="transaction"
                  dataList={transactionList}
                  hasNextPage={hasNextTransactiontPage}
                  isLoading={isLoading}
                  loadMore={loadMore}
                />
              ) : (
                <NoResult />
              ),
          },
          {
            key: INBOX_TABS.AFFILIATE,
            tabKey: INBOX_TABS.AFFILIATE,
            label: (
              <StyledSpan>
                แนะนำเพื่อน
                {allInbox?.affiliate?.unread > 0 ? (
                  <NotiBadge amount={allInbox?.affiliate?.unread} />
                ) : null}
              </StyledSpan>
            ),
            children: allInbox.affiliate.inboxs.length ? (
              <TransactionComponent
                compKey="affiliate"
                type="affiliate"
                dataList={affilaiteList}
                hasNextPage={hasNextAffiliatePage}
                isLoading={isLoading}
                loadMore={loadMore}
              />
            ) : (
              <NoResult />
            ),
          },
        ]}
      />
      <div style={{ height: 100 }} />
    </InboxPage>
  )
}
