import NiceModal from '@ebay/nice-modal-react'
import Faq from '@pig-common/pages-common/faq'
import { ThemeProvider } from 'styled-components'

import styledTheme from '@pig-common/styles/scss/abstracts/theme.json'

function FaqPage() {
  return (
    <ThemeProvider theme={styledTheme}>
      <NiceModal.Provider>
        <Faq />
      </NiceModal.Provider>
    </ThemeProvider>
  )
}

export { FaqPage }
