import React, { cloneElement } from 'react'
import Images from 'next/image'
import { Carousel } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import type { CarouselProps } from 'antd/es/carousel'
import useWindowSize from '@pig-common/utils/useWindowSize'

import { toggleLockScroll } from '@pig-common/services/lockScroll/toggleLockScroll'
import { useCarousel } from './useCarousel'

export type TutorialCarouselProps = {
  settings?: CarouselProps
  triggerActive: boolean
  onClose: () => void
}

const CustomArrow = ({ onClick, children }: any) => (
  <button className="ps-tutorial-arrow" onClick={onClick}>
    {children}
  </button>
)

const customPaging = () => <div className="ps-tutorial-carousel-dot" />

const TutorialCarousel = ({
  triggerActive,
  onClose,
}: TutorialCarouselProps) => {
  const { sliderEl, next, previous, onTouchEnd, onTouchStart } = useCarousel()
  const { mq } = useWindowSize()
  const screenType = React.useMemo(() => {
    switch (mq) {
      case 'lg':
      case 'xl':
        return 'DT'
      case 'md':
        return 'DT'
      case 'xs':
      case 'ss':
      case 'sm':
      default:
        return 'MB'
    }
  }, [mq])

  toggleLockScroll(triggerActive)

  type TutorialCarouselSlide = {
    imageSrc: string
    message: string | React.ReactNode
    key: string
  }[]

  const slide: TutorialCarouselSlide = [
    {
      imageSrc: `/images/dailyquests/tutorials/Tutorial-${screenType}01.png`,
      message: (
        <div dangerouslySetInnerHTML={{ __html: 'ภารกิจแชมป์เปี้ยน' }} />
      ),
      key: 'step-1',
    },
    {
      imageSrc: `/images/dailyquests/tutorials/Tutorial-${screenType}02.png`,
      message: (
        <div
          dangerouslySetInnerHTML={{
            __html: 'เมื่อได้รับแจ็คพอต <b>อย่าลืม! จับภาพหน้าจอ (แคปจอ)</b>',
          }}
        />
      ),
      key: 'step-2',
    },
    {
      imageSrc: `/images/dailyquests/tutorials/Tutorial-${screenType}03.png`,
      message: (
        <div
          dangerouslySetInnerHTML={{
            __html:
              'จากนั้นเข้าหน้าภารกิจ และไปที่ <b>“ภารกิจแชมป์เปี้ยน”</b> เพื่ออัพโหลดรูปภาพ',
          }}
        />
      ),
      key: 'step-3',
    },
    {
      imageSrc: `/images/dailyquests/tutorials/Tutorial-${screenType}04.png`,
      message: (
        <div
          dangerouslySetInnerHTML={{
            __html: 'เมื่ออัพโหลดรูปภาพแล้ว จะได้รับฟรี 100 Tickets',
          }}
        />
      ),
      key: 'step-4',
    },
    {
      imageSrc: `/images/dailyquests/tutorials/Tutorial-${screenType}05.png`,
      message: (
        <div
          dangerouslySetInnerHTML={{
            __html:
              'สามารถดูรายชื่อผู้ที่ได้รับแจ๊คพอต ในหน้า <b>แชมป์เปี้ยน</b>',
          }}
        />
      ),
      key: 'step-5',
    },
    {
      imageSrc: '',
      message: '',
      key: 'step-6',
    },
  ]
  const onCloseHandle = () => {
    toggleLockScroll(!triggerActive)
    sliderEl.current?.goTo(0)
    onClose()
  }
  const settings: CarouselProps = {
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 15000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    beforeChange: (currentSlide: number, nextSlide: number) => {
      if (nextSlide === slide.length - 1) {
        onCloseHandle()
      }
    },
  }

  const appendDots = (dots: any[]) => {
    const children = dots.map((Item, i) => {
      return cloneElement(Item, {
        id: `tutorial-carousel__dot-${i}`,
        key: `dot-${i}`, // eslint-disable-line
        onClick: () => sliderEl?.current?.goTo(i),
      })
    })
    return (
      <div>
        <ul className="ps-tutorial-carousel-track-dots">{children}</ul>
      </div>
    )
  }

  const renderCustomArrow = () => {
    if (slide.length === 1) {
      return null
    }
    return (
      <>
        <CustomArrow onClick={previous} key="arrow-icon-left">
          <LeftOutlined className="ps-icon-left" />
        </CustomArrow>
        <CustomArrow onClick={next} ket="arrow-icon-right">
          <RightOutlined className="ps-icon-right" />
        </CustomArrow>
      </>
    )
  }

  return (
    <section
      className={`ps-tutorial-carousel ${
        triggerActive && 'ps-tutorial-carousel--active'
      }`}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <div className="position-absolute container ps-tutorial-arrow-layout">
        {renderCustomArrow()}
      </div>
      <Carousel
        {...settings}
        ref={sliderEl}
        appendDots={appendDots}
        customPaging={customPaging}
        autoplay={false}
      >
        {Array.isArray(slide) &&
          slide.map(({ message, imageSrc, key }, i) => (
            <div
              key={`tutorial-carousel-item-${key}`}
              className={`ps-tutorial-carousel-slide ${
                i === 0 ? 'ps-tutorial-carousel-slide--first-page' : ''
              }`}
            >
              <div
                data-testid={`tutorial-carousel-slide__message-${i}`}
                className="ps-tutorial-carousel-slide__message"
              >
                {message}
              </div>
              {imageSrc && (
                <Images
                  id={`tutorial-carousel-slide__image-${i}`}
                  className="ps-tutorial-carousel-slide__image"
                  src={imageSrc}
                  layout="fill"
                  alt={`${message} Banner`}
                  quality="75"
                  priority
                />
              )}
            </div>
          ))}
      </Carousel>
      <button
        className="ps-tutorial-carousel__close"
        onClick={onCloseHandle}
        data-testid="tutorial-skip"
      >
        ข้าม
      </button>
    </section>
  )
}

export default TutorialCarousel
