import { IAchievementItemProps } from '@pig-common/types/Achievement.type'
import { Modal, Image } from '@pig-frontend/uikit'
import { motion } from 'framer-motion'
import { achievementImageUrl } from '@pig-common/constants/achievement'
import styles from './index.module.scss'

const handleShowModalAchievementInfo = (item: IAchievementItemProps) => {
  Modal.image({
    className: styles['modal-image-container'],
    closable: true,
    okButtonProps: { hidden: true },
    content: (
      <div className={styles.content}>
        <h4>{item?.name}</h4>
        <span>{item?.description}</span>
      </div>
    ),
    maskClosable: true,
    title: (
      <motion.div
        initial={{
          opacity: 0,
          scale: 0,
          width: 'fit-content',
          margin: 'auto',
        }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.2,
          type: 'spring',
          bounce: 0.5,
        }}
      >
        <Image
          src={achievementImageUrl(item?.achievementUid)}
          alt={'modal image'}
          width={120}
          height={120}
          className={styles.img}
        />
      </motion.div>
    ),
  })
}

export default handleShowModalAchievementInfo
