import NiceModal from '@ebay/nice-modal-react'
import Login, {
  LoginPageProps,
  getLoginServerSideProps,
} from '@pig-common/pages-common/login'
import { ThemeProvider } from 'styled-components'

import styledTheme from '@pig-common/styles/scss/abstracts/theme.json'

function LoginPage(props: LoginPageProps) {
  return (
    <ThemeProvider theme={styledTheme}>
      <NiceModal.Provider>
        <Login {...props} />
      </NiceModal.Provider>
    </ThemeProvider>
  )
}

export { LoginPage, getLoginServerSideProps }
export type { LoginPageProps }
