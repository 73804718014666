import { Tooltip } from 'antd'
import styled from 'styled-components'
import { InformationBox } from '../InformationBox'
import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'
import { InfoCircleOutlined } from '@ant-design/icons'

const HintBoxStyled = styled(Tooltip)`
  font-size: 1.3ch;
  width: 40px;
  padding: 0 1.5ch;
  color: #0573f3 !important;
  vertical-align: middle;
  transform: translateX(-5px);
  cursor: pointer;
`

const HintBox = ({
  message,
  content,
  iconSize = 16,
  arrowOffset = 12,
}: {
  message: string
  content?: JSX.Element
  iconSize?: number
  arrowOffset?: number
}) => {
  return (
    <HintBoxStyled
      arrow={false}
      className="ps-tooltip-icon"
      placement="topLeft"
      title={
        <InformationBox
          titleTextJustify="center"
          contentTextJustify="center"
          isShowArrowIndicator
          arrowOffset={arrowOffset}
          content={
            <>
              {autoLineBreakerWithBr(message)}
              {content}
            </>
          }
        />
      }
    >
      <InfoCircleOutlined style={{ fontSize: `${iconSize}px` }} />
    </HintBoxStyled>
  )
}

export default HintBox
