/* eslint-disable @typescript-eslint/return-await */
import { AxiosRequestConfig } from 'axios'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

import { useAxios } from './useAxios'

export type CheckBankRequest = {
  bank_account_number: string
  bank_account_name: string
  bank_code: string
}

export enum CHECK_BANK_RESULT {
  REGISTER = 'REGISTER',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  BLOCKED = 'BLOCKED',
  /** @description This status means OTP request error */
  ERROR = 'ERROR',
}

export enum VERIFY_BANK_ACCOUNT_RESPONSE_STATUS {
  SUCCESS = 'Success',
  ERROR = 'Error',
  /**
   * @deprecated Please use VERIFY_BANK_ACCOUNT_STATUS_CODE.INVALID_ACCOUNT_INFO instead
   */
  INVALID_BANK = 'BankInvalid',
}

export enum VERIFY_BANK_ACCOUNT_STATUS_CODE {
  DUPLICATED_ACCOUNT = 'DUPLICATED_ACCOUNT',
  INVALID_BANK_ACCOUNT_NUMBER = 'INVALID_BANK_ACCOUNT_NUMBER',
  INVALID_ACCOUNT_INFO = 'INVALID_ACCOUNT_INFO',

  /**
   * @deprecated
   */
  CORE_VERIFY_BANK_ERROR = 'CORE_VERIFY_BANK_ERROR',
  NO_REGISTER_BANK_RESULT = 'NO_REGISTER_BANK_RESULT',
}

export type CheckBankResponse = BaseApiResponse<{
  error_code: VERIFY_BANK_ACCOUNT_STATUS_CODE
  message: string
}>

export type VerifyBankAccountResult = {
  Status?: VERIFY_BANK_ACCOUNT_RESPONSE_STATUS
  ErrorCode?: VERIFY_BANK_ACCOUNT_STATUS_CODE
  Message: string
}

export type CheckBankHook = {
  callCheckBank: (request: CheckBankRequest) => Promise<VerifyBankAccountResult>
}

const mapToCheckBankResult = (
  response: CheckBankResponse,
): VerifyBankAccountResult => {
  const { service_code, data } = response
  switch (service_code) {
    case 'PIG-2000':
      return {
        Status: VERIFY_BANK_ACCOUNT_RESPONSE_STATUS.SUCCESS,
        Message: response.data.message,
      }
    case 'PIG-4003':
      return {
        Status: VERIFY_BANK_ACCOUNT_RESPONSE_STATUS.ERROR,
        ErrorCode: VERIFY_BANK_ACCOUNT_STATUS_CODE.INVALID_BANK_ACCOUNT_NUMBER,
        Message: response?.data?.message || '',
      }
    case 'PIG-4000':
      if (
        data.error_code === VERIFY_BANK_ACCOUNT_STATUS_CODE.DUPLICATED_ACCOUNT
      ) {
        return {
          Status: VERIFY_BANK_ACCOUNT_RESPONSE_STATUS.ERROR,
          ErrorCode: VERIFY_BANK_ACCOUNT_STATUS_CODE.DUPLICATED_ACCOUNT,
          Message: response?.data?.message || '',
        }
      }
      if (
        data.error_code === VERIFY_BANK_ACCOUNT_STATUS_CODE.INVALID_ACCOUNT_INFO
      ) {
        return {
          Status: VERIFY_BANK_ACCOUNT_RESPONSE_STATUS.ERROR,
          ErrorCode: VERIFY_BANK_ACCOUNT_STATUS_CODE.INVALID_ACCOUNT_INFO,
          Message: response?.data?.message || '',
        }
      }
      return {
        Status: VERIFY_BANK_ACCOUNT_RESPONSE_STATUS.ERROR,
        Message: 'ไม่ผ่านการตรวจสอบ',
      }
    default:
      return {
        Status: VERIFY_BANK_ACCOUNT_RESPONSE_STATUS.ERROR,
        Message: 'ไม่ผ่านการตรวจสอบ',
      }
  }
}

export const useCheckBank = (): CheckBankHook => {
  const { pigspinApiInstance } = useAxios()

  const callCheckBank = async (
    request: CheckBankRequest,
  ): Promise<VerifyBankAccountResult> => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/bank/verify',
      method: 'POST',
    }
    const payload = request
    const response = await fetchWithJSON<CheckBankResponse>(
      pigspinApiInstance,
      requestConfig,
      payload,
    )
    return mapToCheckBankResult(response)
  }

  return {
    callCheckBank,
  }
}
