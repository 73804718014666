import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import useGuild from '@pig-common/hooks/useGuild'

export default function useCreateGuild() {
  const { customerCode } = useRecoilValue(authStateCommon.authState)
  const { refetch: getGuild } = useGuild({ enabled: false })

  return useMutation({
    mutationFn: (params: guildApi.ICreateGuildParams) =>
      guildApi.create({ customerCode, ...params }),
    onSuccess: () => {
      getGuild()
    },
  })
}
