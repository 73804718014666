import { AxiosRequestConfig } from 'axios'
import { useRecoilValue } from 'recoil'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { useAxios } from '@pig-common/hooks/useAxios'
import { authStateCommon } from '@pig-common/recoils'
import { BaseApiResponse } from '@pig-common/services/service.type'

export type DepositBankInfo = {
  ID: string
  BankLogo: string
  BankAccountName: string
  BankName: string
  BankNoLength: string
  BankUrlLogo: string
  BankNo: string
  SCBPremium: boolean
  Interval: number
}

export enum FETCH_DEPOSIT_BANK_LIST_STATUS {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export type DepositBankListResponse = {
  Status: FETCH_DEPOSIT_BANK_LIST_STATUS
  Message: string
  QRList: DepositBankInfo[] | null
  BankList: DepositBankInfo[] | null
  TrueList: DepositBankInfo[] | null
  MemCached: string
}

export type DepositBankHook = {
  callDepositBank: () => Promise<DepositBankListResponse>
}

export const useDepositBank = (): DepositBankHook => {
  const { boApiInstance } = useAxios()
  const auth = useRecoilValue(authStateCommon.authState)

  const callDepositBank = async (): Promise<DepositBankListResponse> => {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/payment/customer/get-deposit-bank?user_code=${auth.userUID}`,
      method: 'GET',
    }
    const depositBankResponse = await fetchWithJSON<
      BaseApiResponse<DepositBankListResponse>
    >(boApiInstance, requestConfig)
    if (depositBankResponse.service_code === 'BOE-2000') {
      return depositBankResponse.data
    }
    return {
      Status: FETCH_DEPOSIT_BANK_LIST_STATUS.ERROR,
      Message: '',
      QRList: [],
      BankList: [],
      TrueList: [],
      MemCached: '',
    }
  }
  return {
    callDepositBank,
  }
}
