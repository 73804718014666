import { useState, useEffect } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import router from 'next/router'
import classnames from 'classnames'

import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import {
  CHECK_PHONE_RESULT,
  useCheckPhone,
} from '@pig-common/hooks/useCheckPhone'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import { removeCommas, addCommas } from '@pig-common/utils/number'
import {
  ALERT_MODAL_TYPE,
  AlertConfirmationModal,
  AlertContactModal,
  AlertModal,
  AlertModalV2,
} from '@pig-common/components/Modal'
import { BALANCE_STATUS, useBalance } from '@pig-common/hooks/useBalance'
import {
  useWithdraw,
  WITHDRAW_STATUS,
  WITHDRAW_TYPE,
} from '@pig-common/hooks/useWithdraw'
import WithdrawalForm from './WithdrawalForm'
import WithdrawalContent from './WithdrawalContent'

export type WithdrawalProps = {
  onWithdraw: () => void
  onError?: () => void
  fullWidthForm?: boolean
  className?: string
  withdrawType?: WITHDRAW_TYPE
}

const MIN_WITHDRAWAL = 20
const MAX_WITHDRAWAL = 100_000

const Withdrawal = ({
  onWithdraw,
  onError,
  fullWidthForm,
  className,
  withdrawType,
}: WithdrawalProps): JSX.Element => {
  const [cookies, , removeCookies] = useCookies<string>([
    COOKIES_KEY.UID,
    COOKIES_KEY.CFID,
    COOKIES_KEY.CUSTOMER_CODE,
  ])
  const [lostBonus, setLostBonus] = useState('')
  const [showForm, setShowForm] = useState(true)
  const [inputValue, setInputValue] = useState<number | undefined>(undefined)
  const [inputError, setInputError] = useState(false)
  const balanceHook = useBalance()
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [openAlertContactModal, setOpenAlertContactModal] = useState(false)
  const [openAlertConfirmationModal, setOpenAlertConfirmationModal] =
    useState(false)
  const {
    data: systemStatus,
    refetch: refreshSystemStatus,
    isFetching: isLoading,
    isSuccess,
  } = useSystemStatus({
    queryKey: ['withdrawal'],
    enabled: false,
  })
  const { callCheckPhone } = useCheckPhone()
  const { callWithdraw } = useWithdraw()
  const [alertModalType, setAlertModalType] = useState<ALERT_MODAL_TYPE>(
    ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS,
  )

  const validateInput = (value: number | undefined) => {
    const isMoreThanOrEqualMinWithdrawal = Number(value) >= MIN_WITHDRAWAL
    const isLessThanOrEqualMaxWithdrawal = Number(value) <= MAX_WITHDRAWAL
    const isValueEmpty = value === undefined

    if (
      (isMoreThanOrEqualMinWithdrawal && isLessThanOrEqualMaxWithdrawal) ||
      isValueEmpty
    ) {
      setInputError(false)
      setButtonDisabled(isValueEmpty)
    } else {
      setInputError(true)
      setButtonDisabled(true)
    }
  }

  const handleInputChange = (value: number | undefined) => {
    setInputValue(value)
    validateInput(value)
  }

  const handleInputBlur = (value: number | undefined) => {
    setInputValue(value)
  }

  const checkUserStatus = async (phone: string) => {
    const response = await callCheckPhone({ phone })
    const {
      data: { phone_number_status: status },
    } = response

    if (status !== CHECK_PHONE_RESULT.ACTIVE) {
      switch (status) {
        case CHECK_PHONE_RESULT.DISABLED:
        case undefined: // for case inactive user
          removeCookies(COOKIES_KEY.UID)
          removeCookies(COOKIES_KEY.CFID)
          removeCookies(COOKIES_KEY.CUSTOMER_CODE)
          setOpenAlertContactModal(true)
          break
        case CHECK_PHONE_RESULT.BLOCKED:
          removeCookies(COOKIES_KEY.UID)
          removeCookies(COOKIES_KEY.CFID)
          removeCookies(COOKIES_KEY.CUSTOMER_CODE)
          setAlertModalType(ALERT_MODAL_TYPE.PIN_LOCKED)
          setOpenAlertModal(true)
          break
        default:
          setAlertModalType(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
          setOpenAlertModal(true)
      }

      return false
    }

    return true
  }

  const checkUserBalance = async () => {
    const response = await balanceHook.callGetBalance()
    const { Status: status } = response

    if (status === BALANCE_STATUS.SUCCESS) {
      const { Balance: balance, Coin: coin } = response

      return {
        balance,
        coin,
      }
    }

    return false
  }

  const displayWalletErrorMessage = (message: string) => {
    if (onError) {
      onError()
    }
    if (message.includes('ถอนไม่สำเร็จ กรุณาเล่นเกม ก่อนทำรายการถอน')) {
      NiceModal.show(AlertModalV2, {
        type: ALERT_MODAL_TYPE.REQUIRE_PLAY_GAME,
      })
      return
    }

    if (
      message.includes('ถอนไม่สำเร็จ เพราะมีเครดิตไม่ถึง') ||
      message.includes('ยอดเงินคงเหลือไม่เพียงพอ')
    ) {
      NiceModal.show(AlertModalV2, {
        type: ALERT_MODAL_TYPE.CASH_BALANCE_NOT_ENOUGH,
      })
    }
  }

  const withdraw = async () => {
    try {
      const wallet = await callWithdraw({
        amount: Number(inputValue),
        transfer_type: withdrawType,
      })

      const { Status: status, Message: message } = wallet

      if (status === WITHDRAW_STATUS.SUCCESS) {
        setShowForm(false)
        onWithdraw()
      } else {
        displayWalletErrorMessage(message)
      }
    } catch (error) {
      setAlertModalType(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
      setOpenAlertModal(true)
    }
  }

  const checkUserStatusAndBalance = async () => {
    try {
      const uid = cookies.uid || ''

      // STEP 1: check ban/block/inactive user.
      const isActiveUser = await checkUserStatus(uid)
      if (!isActiveUser) return

      // STEP 2: check user balance.
      const userBalance = await checkUserBalance()
      if (!userBalance) return

      // STEP 3: check bonus and withdraw.
      const coin = userBalance.coin || ''
      const hasBonus = Number(removeCommas(coin)) > 0

      if (hasBonus && withdrawType !== WITHDRAW_TYPE.AFFILIATE) {
        setLostBonus(coin)
        setOpenAlertConfirmationModal(true)
      } else {
        await withdraw()
      }
    } catch (error) {
      setAlertModalType(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS)
      setOpenAlertModal(true)
    }
  }

  const handleButtonClick = async () => {
    setButtonDisabled(true)
    refreshSystemStatus()
  }
  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (!(systemStatus?.withdrawError || systemStatus?.underConstruction)) {
        LegacyhandleButtonClick2()
      }
    }
  }, [systemStatus, isLoading, isSuccess])
  const LegacyhandleButtonClick2 = async () => {
    await checkUserStatusAndBalance()
    setButtonDisabled(false)
  }

  const handleBackToWithdrawalFormButtonClick = () => {
    setInputValue(undefined)
    setShowForm(true)
  }

  const handleCloseAlertModal = () => {
    setOpenAlertModal(false)
    const uid = cookies.uid || ''
    if (alertModalType && alertModalType === ALERT_MODAL_TYPE.PIN_LOCKED) {
      router.push(
        { pathname: '/reset-pin', query: { phone: uid } },
        '/reset-pin',
      )
    }
  }

  const handleCloseAlertContactModal = () => {
    setOpenAlertContactModal(false)
    router.push('/login')
  }

  const handleCloseAlertConfirmationModal = () => {
    setOpenAlertConfirmationModal(false)
  }

  const handleConfirmAlertConfirmationModal = async () => {
    setOpenAlertConfirmationModal(false)
    await withdraw()
  }

  const getConfirmationModalContent = () => {
    const amount = lostBonus
    const html = `โบนัสของคุณจะหายไป <strong>${amount} บาท</strong> เนื่องจากยังไม่ครบเงื่อนไขการเดิมพัน<br />คุณยืนยันที่จะถอนไหม?`

    return <p dangerouslySetInnerHTML={{ __html: html }} />
  }

  return (
    <div className={classnames(className)}>
      {showForm ? (
        <WithdrawalForm
          fullWidth={fullWidthForm}
          labelText="ระบุจำนวนเงิน"
          inputPlaceholder="500"
          inputErrorMessage={`คุณสามารถถอนเงินได้ตั้งแต่ ${MIN_WITHDRAWAL} - ${addCommas(
            MAX_WITHDRAWAL?.toString(),
          )} บาทต่อครั้ง`}
          informationText={`ขั้นต่ำ ${MIN_WITHDRAWAL?.toString()} บาท สูงสุด ${addCommas(
            MAX_WITHDRAWAL?.toString(),
          )} บาท`}
          buttonText="ยืนยัน"
          isButtonDisabled={buttonDisabled}
          isInputError={inputError}
          onInputChange={handleInputChange}
          onInputBlur={handleInputBlur}
          onButtonClick={handleButtonClick}
        />
      ) : (
        <WithdrawalContent
          imageSrc="success"
          imageAlt="withdraw success image"
          content={
            'ระบบเริ่มดำเนินการถอนเงินแล้ว<br />โปรดตรวจสอบบัญชีของท่านอีกครั้ง'
          }
          buttonText="ถอนเงินอีกครั้ง"
          onButtonClick={handleBackToWithdrawalFormButtonClick}
        />
      )}
      <AlertModal
        open={openAlertModal}
        type={alertModalType}
        onClose={handleCloseAlertModal}
      />
      <AlertContactModal
        open={openAlertContactModal}
        onClose={handleCloseAlertContactModal}
      />
      <AlertConfirmationModal
        open={openAlertConfirmationModal}
        header="การถอนเงินจะทำให้โบนัสของคุณหายไป"
        content={getConfirmationModalContent()}
        onClose={handleCloseAlertConfirmationModal}
        onConfirm={handleConfirmAlertConfirmationModal}
      />
    </div>
  )
}

export default Withdrawal
