import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { RecommendBadge } from '@pig-common/components/Icon/recommendBadge'

import { Card } from '../Card'

export type SelectCardProps = {
  children: React.ReactNode[]
  cardDetail?: string[]
  select?: number
  onSelect?: (select: number) => void
}

const SelectCard = ({
  children,
  cardDetail,
  select = 0,
  onSelect,
}: SelectCardProps) => {
  const [value, setValue] = useState(select)

  useEffect(() => {
    setValue(select)
  }, [select])

  useEffect(() => {
    if (onSelect) {
      onSelect(value)
    }
  }, [value])

  const onClickCard = (index: number) => {
    setValue(index)
  }

  return (
    <div className="ps-select-card">
      <AnimatePresence>
        {Array.isArray(children) &&
          children.map((Child, i) => (
            <div
              className={classNames('ps-select-card__layout', {
                'ps-select-card__layout--active': i === select,
              })}
              key={`select-card-${Child?.toString()}`}
            >
              <Card
                className="ps-select-card__item"
                onClick={() => onClickCard(i)}
              >
                {i === 0 && <RecommendBadge />}
                {Child}
                {i === select && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  />
                )}
              </Card>
              {(Array.isArray(cardDetail) && (
                <div className="ps-select-card__detail">{cardDetail[i]}</div>
              )) ||
                ''}
            </div>
          ))}
      </AnimatePresence>
    </div>
  )
}

export default SelectCard
