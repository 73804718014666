import React from 'react'
import classNames from 'classnames'
import { StepperProgressProps } from './Stepper.type'

const StepperProgress = ({
  id = 'stepper-progress',
  title = '',
  subtitle = '',
  currentNumber = 1,
  length = 1,
  className = '',
}: StepperProgressProps) => {
  let steps = new Array(length < 0 ? 0 : length).fill(null)
  steps = steps.map((_value, i) => {
    const currentIndex = i + 1
    const stepClassNames = classNames('ps-stepper__step-title-number', {
      'ps-stepper__step-title-number--current': currentNumber === currentIndex,
      'ps-stepper__step-title-number--completed': currentNumber > currentIndex,
    })
    const stepNumber =
      currentNumber > currentIndex ? (
        <i className="ps-icon-check-only" />
      ) : (
        currentIndex
      )
    const final_title = currentNumber === currentIndex ? title : ''
    const final_subtitle = currentNumber === currentIndex ? subtitle : ''

    return (
      <div className="ps-stepper__step-title" key={currentIndex}>
        <div className={stepClassNames}>{stepNumber}</div>
        {final_title}
        <div className="ps-stepper__step-subtitle">{final_subtitle}</div>
      </div>
    )
  })

  return (
    <div
      id={id}
      data-testid={id}
      className={classNames('ps-stepper', className)}
    >
      <div className="ps-stepper__stepper-progress">
        <div className="ps-stepper__stepper-progress-wrapper">
          <div className="ps-stepper__stepper-progress-bar" />
          {steps}
        </div>
      </div>
    </div>
  )
}

export default StepperProgress
