import React, { useEffect, useState } from 'react'
import { Divider } from 'antd'
import { useCookies } from 'react-cookie'
import Link from 'next/link'
import {
  BonusConfig,
  BONUS_STATE,
  FETCH_STATUS,
  useProfileBonus,
} from '@pig-common/hooks/useProfileBonus'
import { ALERT_TYPE } from '@pig-common/components/Alert'
import CommonAlertModal from '@pig-common/components/Modal/CommonAlertModal/CommonAlertModal'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import BonusToggle from './BonusToggle'
import { TimerType } from '@pig-common/hooks/useLoyaltyProgram'

const BonusTogglerSection = (): JSX.Element => {
  const [isBonusToggle, setBonusToggle] = useState<boolean>() // TODO : move to use "profileBonusState" soon
  const [profileBonusState, setProfileBonusState] = useState<BonusConfig>()
  const [isEnable, setEnable] = useState<boolean>(false)
  const { callProfileBonus } = useProfileBonus()
  const [getTimerBonusToggle, setTimerBonusToggle] = useState<TimerType>() // TODO : move to use "profileBonusState" soon

  const [isModalBonusInfoActive, setModalBonusInfoActive] =
    useState<boolean>(false)
  const [cookies, , removeCookies] = useCookies<string>([
    COOKIES_KEY.TUTORIAL_BONUS_TOGGLE,
  ])
  const [isContainerFlashing, setContainerFlashing] = useState<boolean>(false)
  const refBonusToggle = React.useRef<HTMLDivElement>(null)

  const shouldEnabled = () => {
    const isNotCounting = !(
      getTimerBonusToggle &&
      (getTimerBonusToggle.hour > 0 ||
        getTimerBonusToggle.minute > 0 ||
        getTimerBonusToggle.second > 0)
    )
    setEnable(isNotCounting)
  }
  const fetchProfileBonus = async () => {
    const profileBonus = await callProfileBonus()
    if (profileBonus.status === FETCH_STATUS.SUCCESS) {
      setBonusToggle(profileBonus.data?.config[0].status === BONUS_STATE.ON) // TODO : move to use "profileBonusState" soon
      setTimerBonusToggle(profileBonus.data?.config[0].time_left) // TODO : move to use "profileBonusState" soon
      setProfileBonusState(profileBonus.data?.config[0])
    }
  }
  const reactivateToggler = () => {
    setEnable(true)
  }
  const openModalBonusInfo = () => {
    setModalBonusInfoActive(!isModalBonusInfoActive)
  }
  const scrollTo = () => {
    setTimeout(() => {
      if (refBonusToggle.current != null) {
        window.scrollTo({
          top: refBonusToggle.current.offsetTop - 150,
          behavior: 'smooth',
        })
      }
    }, 500)
  }
  const bonusInfomationContent = (
    <div className="ps-bonus-toggle-information-content">
      <b>รายละเอียด</b>
      <Divider />
      <span>เติมเงิน</span>
      โบนัสจากการเติมเงินในทุกๆช่องทาง
      <span>คืนยอดเสีย</span>
      <div>
        โบนัสรายสัปดาห์โดยคำนวณจากยอดเสียของผู้เล่นในแต่ละรอบสัปดาห์
        <ul style={{ margin: 0, paddingLeft: 25 }}>
          <li>
            คำนวณยอดเสียเป็นรายสัปดาห์ ตั้งแต่วันจันทร์ 00:00:01 น.
            ถึงวันอาทิตย์ 23:59:59 น.
          </li>
          <li>สามารถรับ เงินคืนยอดเสีย ได้ทุกวันจันทร์ หลังเวลา 05:00:00 น.</li>
          <li>กดรับ เงินคืนยอดเสีย ได้จากการเมนู การแจ้งเตือน</li>
          <li>
            ผู้เล่นจะต้องเปิดรับโบนัสยอดเสียก่อนเริ่มรอบสัปดาห์
            และหากมีการปิดรับภายในรอบสัปดาห์ ยอดเสียที่คำนวณของรอบนั้นจะหายไป
            โดยจะเริ่มนับใหม่ในรอบถัดไป
          </li>
        </ul>
        ดูรายละเอียดเพิ่มเติมและตัวอย่างการคำนวณได้ที่หน้า
        <Link href="/promotions/return-bonus">
          <div className="d-inline-block ps-text-blue-2 mx-1">
            โปรโมชั่นคืนยอดเสีย
          </div>
        </Link>
      </div>
      <span>แนะนำเพื่อน</span>
      เมื่อผู้เล่นมีการแนะนำเพื่อนด้วยรหัสแนะนำเพื่อน
      ผู้เล่นจะได้รับโบนัสแนะนำเพื่อน โดยผู้เล่นสามารถตรวจสอบรายการได้ที่หน้า
      <Link href="/affiliate">
        <div className="d-inline-block ps-text-blue-2 mx-1">แนะนำเพื่อน</div>
      </Link>
      <span>โปรโมชั่นอื่นๆ</span>
      <div>
        โบนัสจากการเข้าร่วมกิจกรรมกับทาง PIGBET เช่น กิจกรรม Leaderboard หรือ
        โปรค่าคอมมิชชั่นบาคาร่า 0.1%
      </div>
    </div>
  )

  useEffect(() => {
    fetchProfileBonus()
    if (cookies[COOKIES_KEY.TUTORIAL_BONUS_TOGGLE]) {
      const shouldTutorialActive =
        cookies[COOKIES_KEY.TUTORIAL_BONUS_TOGGLE].includes('bonus_toggle')
      if (shouldTutorialActive) {
        setContainerFlashing(true)
        scrollTo()
        removeCookies(COOKIES_KEY.TUTORIAL_BONUS_TOGGLE)
      }
    }
  }, [])
  useEffect(() => {
    shouldEnabled()
  }, [getTimerBonusToggle])

  return (
    <div
      ref={refBonusToggle}
      className="ps-profile__container ps-profile__container-section"
      style={{ animationPlayState: isContainerFlashing ? 'running' : 'paused' }}
    >
      <div className="ps-profile__section">
        <div
          className="ps-profile__title"
          data-testid="profile__bank-account-info-title"
        >
          ตั้งค่าโบนัส
        </div>
        <div
          data-testid="profile__pin-info-edit--button"
          className="ps-profile__button-edit"
          onClick={() => openModalBonusInfo()}
          onKeyDown={() => openModalBonusInfo()}
        >
          รายละเอียด
        </div>
      </div>
      <BonusToggle
        profileBonusState={profileBonusState}
        onChange={fetchProfileBonus}
        isBonusToggle={isBonusToggle}
        isEnable={isEnable}
        getTimerBonusToggle={getTimerBonusToggle}
        reactivateToggler={reactivateToggler}
        fetchProfileBonus={fetchProfileBonus}
      />
      <CommonAlertModal
        id="alert-in-game-redemption__error"
        open={isModalBonusInfoActive}
        type={ALERT_TYPE.NONE}
        onClose={openModalBonusInfo}
      >
        {bonusInfomationContent}
      </CommonAlertModal>
    </div>
  )
}

export default BonusTogglerSection
