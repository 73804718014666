import { motion } from 'framer-motion'
import styled from 'styled-components'
import { screen } from '@pig-common/utils/responsive'
import { RowStyle, TableStyle } from './Table.type'

type StyledProps = {
  columns: string[]
}

export const StyledRow = styled(motion.div)<StyledProps & RowStyle>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns.reduce((acc, current) => `${acc} ${current || 'auto'}`, '')};
  width: 100%;
  padding: 10px 20px;
  ${({ theme, rowStyle }) => {
    if (rowStyle === 'white') {
      return `
        background-color: ${theme['white-opacity-50']};
        border-radius: 4px;
      `
    }
    return `background-color: ${theme['primary-fade-1']};`
  }}
  @media ${screen.md} {
    padding: 12px 40px;
  }

  .ps-game-winner-datetime {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: ${({ theme }) => theme['color-greyscale-400']};
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .ps-game-winner-text {
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    color: ${({ theme }) => theme['color-greyscale-400']};
  }

  .ps-game-winner-amount {
    text-align: right;
    font-weight: 900;
    font-size: 22px;
    line-height: 22px;
    color: ${({ theme }) => theme['color-violet-400']};
    &:after {
      content: '฿';
      margin-left: 4px;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
`

export const StyledTable = styled.div<StyledProps & TableStyle>`
  .ps-game-winner-list__axis {
    display: grid;
    grid-template-columns: ${({ columns }) =>
      columns.reduce((acc, current) => `${acc} ${current || 'auto'}`, '')};
    padding: 10px 20px;
    @media ${screen.md} {
      padding: 16px 40px;
    }
    * {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: ${({ theme }) => theme['color-violet-400']};
      &:last-child {
        text-align: right;
      }
    }
  }
  .ps-game-winner-list__list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 8px;
    ${({ scrollBar }) =>
      scrollBar
        ? `
      max-height: 400px;
      height: auto;
      overflow-y: scroll;
    `
        : `

    `}
  }
`
