import * as React from 'react'
import styled from 'styled-components'

type IBadgeProps = {
  onClick?: () => void
  children?: React.ReactNode
}

const BadgeWrapper = styled.span<IBadgeProps>`
  background: ${({ theme }) => theme['color-violet-300']};
  color: #fcfcfc;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 0.1rem 0.4rem;
  ${({ onClick }) => {
    if (onClick) {
      return 'cursor: pointer;'
    }
    return ''
  }}
`

const Badge = ({ children, onClick }: IBadgeProps) => {
  return <BadgeWrapper onClick={onClick}>{children}</BadgeWrapper>
}

export default Badge
