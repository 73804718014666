import { useEffect, useState } from 'react'
import cx from 'classnames'
import BigNumber from 'bignumber.js'
import { Button, Modal, ProfileAvatar } from '@pig-frontend/uikit'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import useGuild from '@pig-common/hooks/useGuild'
import useReviewMemberRequestGuild from '@pig-common/hooks/useReviewMemberRequestGuild'
import { IGuildMemberRequested } from '@pig-common/types/Guild.type'
import { IReviewMemberRequestGuildParams } from '@pig-common/api/guild'
import isEmpty from 'lodash/isEmpty'
import useGuildVIPMember from '@pig-common/hooks/useGuildVIPMember'
import styles from './index.module.scss'

interface IHandleReviewMemberRequestProps {
  request: IGuildMemberRequested
  type: IReviewMemberRequestGuildParams['type']
}

export default function RequestListModal() {
  const { data: guildData, refetch: getGuildData } = useGuild({
    enabled: false,
  })
  const myGuild = guildData?.myGuild
  const myMemRequested = guildData?.myGuild?.requestApply
  const hasList = (myMemRequested?.total || 0) > 0
  const [isOpen, setOpen] = useState<boolean>(false)
  const { mutate: getVIPMember, data: memberVIP } = useGuildVIPMember()
  const {
    mutate: reviewMemberRequested,
    isIdle,
    isLoading,
  } = useReviewMemberRequestGuild()
  const handleReviewMemberRequested = ({
    request,
    type,
  }: IHandleReviewMemberRequestProps) => {
    reviewMemberRequested({
      type,
      guildUid: myGuild?.info?.guildUid || '',
      requestUid: request?.uid || '',
    })
  }

  const handleModalOpen = () => {
    getGuildData()
    setOpen(true)
  }
  useEffect(() => {
    const customerCodes: string[] = []
    if (!isEmpty(myMemRequested?.list)) {
      myMemRequested?.list?.map((member) =>
        customerCodes.push(member?.customerCode || ''),
      )
      getVIPMember({ memberCustomerCodes: customerCodes })
    }
  }, [myMemRequested?.list])
  useEffect(() => {
    if (!isIdle && !isLoading) getGuildData()
  }, [isLoading, isIdle])

  return (
    <>
      <Button type="primary" className={styles.btn} onClick={handleModalOpen}>
        คำร้องขอ{' '}
        {hasList && `(${BigNumber(myMemRequested?.total || '0').toFormat(0)})`}
      </Button>

      <Modal
        title="คำร้องขอ"
        open={isOpen}
        onCancel={() => setOpen(false)}
        className={cx(styles.modal, {
          [styles['not-found']]: !hasList,
        })}
        footer={null}
      >
        {!hasList ? (
          <span className={styles['not-found']}>ไม่พบคำร้องขอเข้ากิลด์</span>
        ) : (
          <div className={styles.list}>
            {myMemRequested?.list?.map((req, i) => (
              <div
                className={cx(styles.card, {
                  [styles.disabled]: myGuild?.info?.isFull,
                })}
                key={req?.uid}
              >
                <div className={styles.detail}>
                  <div className={styles.profile}>
                    <ProfileAvatar
                      name="pixel"
                      width={22}
                      height={22}
                      round
                      vip={{
                        level: memberVIP?.data?.[i]?.level,
                        tier: memberVIP?.data?.[i]?.tier,
                      }}
                    />
                    <span className={styles.name}>
                      {req?.nickname ?? req?.customerCode}
                    </span>
                  </div>
                  <span>ต้องการเข้ากิลด์</span>
                </div>
                <div className={styles.action}>
                  <CheckCircleOutlined
                    role="button"
                    className={styles['accept-icon']}
                    onClick={() =>
                      !myGuild?.info?.isFull &&
                      handleReviewMemberRequested({
                        request: req,
                        type: 'accept',
                      })
                    }
                  />
                  <CloseCircleOutlined
                    role="button"
                    onClick={() =>
                      !myGuild?.info?.isFull &&
                      handleReviewMemberRequested({
                        request: req,
                        type: 'decline',
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </>
  )
}
