import { useState } from 'react'
import { Collapse } from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { HistoryInGame } from '@pig-common/services/pigShop/getFreeInGame'

export enum HISTORY_TYPE {
  INGAME = 'in-game',
  PHYSICAL = 'physical',
}

type historyGroupingProps = {
  historyData: HistoryInGame[]
  // historyData: PhysicalRedemptionHistoryDataProps[] | HistoryInGame[];
  spawner: any
  type: HISTORY_TYPE
}
const HistoryGroup = ({ historyData, spawner, type }: historyGroupingProps) => {
  const [activeKey, setActiveKey] = useState<string>('1')
  const { Panel } = Collapse
  const historyGrouping = historyData.map((data, i) => {
    const monthYearKey = `${data.month} ${data.year}`
    if (i === 0 && activeKey === '1') {
      setActiveKey(`${monthYearKey}-key`)
    }
    const monthlyGrouping = data.data
      .filter((item) => item?.reward_type === 'freecredit')
      .map((info, n) => {
        return (
          <div
            data-testid={`collapse-history__panel-content-${n}`}
            className="ps-collapse-history__panel-content"
            key={`${monthYearKey}-grouping`}
          >
            {spawner(info)}
          </div>
        )
      })
    if (monthlyGrouping.length > 0) {
      return (
        <Panel
          className="ps-collapse-history__panel-title"
          header={monthYearKey}
          key={`${monthYearKey}-key`}
        >
          {monthlyGrouping}
        </Panel>
      )
    } else {
      return null
    }
  })

  return (
    <Collapse
      className="ps-collapse-history"
      bordered={false}
      expandIcon={({ isActive }) => {
        if (isActive) {
          return (
            <UpOutlined className="ps-icon-up ps-collapse-history__arrow-icon" />
          )
        } else {
          return (
            <DownOutlined className="ps-icon-up ps-collapse-history__arrow-icon" />
          )
        }
      }}
      expandIconPosition="right"
      accordion
      defaultActiveKey={activeKey}
    >
      {historyGrouping}
    </Collapse>
  )
}

export default HistoryGroup
