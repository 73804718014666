import { useMutation } from '@tanstack/react-query'
import { commissionApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { notification } from '@pig-frontend/uikit'

const messageMapper = {
  'PIG-4015': 'ไม่พบบัญชีธนาคาร',
  'PIG-2035': 'ไม่พบค่าคอมมิสชั่นของคุณ',
  'PIG-2036': 'ค่าคอมมิสชั่นได้ถูกรับไปแล้ว',
  'PIG-2031': 'ไม่สามารถรับค่าคอมมิสชั่นได้ เนื่องจากเกินเวลารับที่กำหนด',
}

export default function useClaimCommission() {
  const auth = useRecoilValue(authStateCommon.authState)

  return useMutation({
    mutationFn: () => commissionApi.claim({ customerCode: auth?.customerCode }),
    onSuccess: (data) => {
      if (data?.serviceCode !== 'PIG-2030') {
        notification.error({
          message: messageMapper?.[data?.serviceCode] || 'มีบางอย่างผิดพลาด',
        })
      }
    },
  })
}
