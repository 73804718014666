import Image from 'next/image'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import { emptyWalletImage } from '@pig-common/constants/images'

export type EmptyMenuProps = {
  title: string
  subTitle: string
  buttonText: string
  onClickButton?: () => void
}

const EmptyMenu = ({
  title,
  subTitle,
  buttonText,
  onClickButton = () => {},
}: EmptyMenuProps) => {
  return (
    <div className="ps-empty-menu-layout">
      <div>
        <Image
          className="ps-empty-image"
          src={emptyWalletImage}
          alt="balloon"
        />
      </div>
      <strong data-testid="empty-bonus_title" className="ps-empty-title">
        {title}
      </strong>
      <span data-testid="empty-bonus_subtitle" className="ps-empty-subtitle">
        {subTitle}
      </span>
      <div>
        <Button
          id="empty-bonus__btn"
          className="ps-empty-button"
          type={BUTTON_TYPE.CTA_1}
          onClick={onClickButton}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default EmptyMenu
