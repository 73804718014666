import React, { createRef, RefObject, useEffect, useState } from 'react'
import { motion, AnimatePresence, Variants } from 'framer-motion'
import { StepperV2 } from '@pig-common/components/StepperV2'
import range from 'lodash/range'

export type StepperDetails = {
  title: string
  subtitle: string
}

export interface IStepperData {
  [key: string]: any
}

export type StepperChildrenProps = {
  ref: RefObject<StepperChildrenHandles>
  getStepData: (step: number) => IStepperData
  goTo: (step: number) => void
  next: () => void
  back: () => void
  isLoading: boolean
  data: IStepperData
  previousStep: number
}

export type StepperChildrenHandles = {
  getChildrenData: () => IStepperData
}

export type StepperChildrenType = (
  props: StepperChildrenProps,
) =>
  | React.ForwardedRef<
      StepperChildrenProps & React.RefAttributes<StepperChildrenHandles>
    >
  | any

export type StepperControllerProps = {
  title: string
  currentStep?: number
  showProgressBar?: boolean
  isLoading?: boolean
  isShowBackButton?: boolean
  stepperDetails: StepperDetails[]
  children?: StepperChildrenType[]
  onNextStep?: (
    currentStep: number,
    nextStep: number,
    currentData?: IStepperData,
    allData?: IStepperData[],
  ) => Promise<boolean | void>
  onBackStep?: (
    currentStep: number,
    previousStep: number,
    currentData?: IStepperData,
    allData?: IStepperData[],
  ) => Promise<boolean | void>
  data?: IStepperData[]
}

const rightToLeft: Variants = {
  initial: { x: 500, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: -500, opacity: 0, position: 'absolute' },
}
const leftToRight: Variants = {
  initial: { x: -500, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: 500, opacity: 0, position: 'absolute' },
}

const StepperController = ({
  title,
  isLoading = false,
  isShowBackButton = true,
  showProgressBar = true,
  stepperDetails,
  currentStep: currentNumber = 0,
  children = [],
  data = [],
  onNextStep,
  onBackStep,
}: StepperControllerProps) => {
  const length = stepperDetails.length || 0
  let calculateStep = currentNumber < 0 ? 0 : currentNumber
  calculateStep = calculateStep > length - 1 ? length - 1 : calculateStep
  const [currentStep, setCurrentStep] = useState(calculateStep)
  const [variants, setVariants] = useState<Variants>(rightToLeft)
  const [dataArr, setDataArr] = useState<IStepperData[]>(data)
  const [childrenRef, setChildrenRef] = React.useState<
    RefObject<StepperChildrenHandles>[]
  >([])
  const [previousNumberStep, setPreviousNumberStep] =
    useState<number>(currentStep)
  useEffect(() => {
    setChildrenRef((ref) =>
      Array(length)
        .fill(null)
        .map((_, i) => ref[i] || createRef()),
    )
  }, [length])

  // deep clone... aware for type function, Date
  const getStepData = (step: number) =>
    dataArr[step] && JSON.parse(JSON.stringify(dataArr[step]))

  const updateStep = (nextStep: number) => {
    const animateVariants = currentStep < nextStep ? rightToLeft : leftToRight
    setVariants(animateVariants)
    setTimeout(() => setCurrentStep(nextStep))
  }

  const cloneData = async (sourceData: any) => {
    try {
      // deep clone... aware for type function, Date
      return JSON.parse(JSON.stringify(sourceData))
    } catch (e) {
      // eslint-disable-next-line
      return undefined
    }
  }

  const next = async () => {
    const nextStep = currentStep + 1
    setPreviousNumberStep(currentStep)
    let isSuccess: boolean | void = true
    const newDataArr = await cloneData(dataArr)
    const currentData = childrenRef[currentStep]?.current?.getChildrenData()
    if (currentData != null && typeof currentData === 'object') {
      newDataArr[currentStep] = { ...currentData }
      setDataArr(newDataArr)
    }
    if (typeof onNextStep === 'function') {
      isSuccess = await onNextStep(
        currentStep,
        nextStep,
        currentData,
        newDataArr,
      )
    }
    if (nextStep < length && isSuccess) {
      updateStep(nextStep)
    }
  }

  const back = async () => {
    const previousStep = currentStep - 1
    setPreviousNumberStep(currentStep)
    let isSuccess: boolean | void = true
    const newDataArr = await cloneData(dataArr)
    if (previousStep < 0) return
    const currentData = childrenRef[currentStep]?.current?.getChildrenData()
    if (currentData != null && typeof currentData === 'object') {
      newDataArr[currentStep] = { ...currentData }
      setDataArr(newDataArr)
    }
    if (typeof onBackStep === 'function') {
      isSuccess = await onBackStep(
        currentStep,
        previousStep,
        currentData,
        newDataArr,
      )
    }
    if (previousStep >= 0 && isSuccess) {
      updateStep(previousStep)
    }
  }

  const goTo = (step: number) => {
    if (step > length && step < 0) return
    updateStep(step)
  }

  useEffect(() => {
    goTo(calculateStep)
  }, [calculateStep])

  const renderChild = () => {
    if (children.length === 0) return
    // eslint-disable-next-line consistent-return
    return range(children.length).map((n) => {
      const Child = children?.[n]
      if (n === currentStep) {
        return (
          <motion.div
            key={`stepper-child-${n}`}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
            transition={{ type: 'spring', bounce: 0 }}
            className="ps-stepper-controller__content"
          >
            <Child
              ref={childrenRef[n]}
              data={{ ...dataArr[n] }}
              next={next}
              back={back}
              goTo={goTo}
              getStepData={getStepData}
              isLoading={isLoading}
              previousStep={previousNumberStep}
            />
          </motion.div>
        )
      }
      return null
    })
  }

  const renderStepperV2 = () => (
    <div className="ps-stepper-controller__progress col-8 col-lg-6 px-0 mx-auto">
      <StepperV2 length={length} currentStep={currentStep} />
    </div>
  )

  return (
    <div className="ps-stepper-controller p-0">
      {title.length > 0 && (
        <h3
          data-testid="stepper-controller__title"
          className="ps-stepper-controller__title text-center"
        >
          {title}
        </h3>
      )}
      {currentStep > 0 && isShowBackButton && (
        <i
          className="ps-icon-left ps-stepper-controller__back-icon"
          onClick={back}
          aria-hidden
        />
      )}
      {showProgressBar && renderStepperV2()}
      <AnimatePresence>{renderChild()}</AnimatePresence>
    </div>
  )
}

export default StepperController
