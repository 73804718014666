import { VIPTiers } from '@pig-common/hooks/useVip'

const UserBadge = ({
  tier,
  level,
}: {
  tier: VIPTiers | any
  level: string | any
}) => {
  const badgeTierClassNames = () => {
    return `ps-user-badge ps-user-badge--${tier}`
  }
  const userLevel = level ? level.toString() : ''
  return (
    <div
      className={badgeTierClassNames()}
      data-testid="user-badge"
      data-level={`${tier}-${level}`}
    >
      VIP{userLevel.replace('VIP', '')}
    </div>
  )
}

export default UserBadge
