import { AxiosRequestConfig } from 'axios'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

import { useAxios } from './useAxios'

export type VerifyOtpRequest = {
  phoneNumber: string
  otpKey: string
  otpToken: string
}

export enum VERIFY_OTP_STATUS {
  /** @description ยืนยัน OTP ถูกต้อง */
  SUCCESS = 'SUCCESS',
  /** @description OTP ไม่ถูกต้องกรุณาลองใหม่อีกครั้ง หรือส่ง OTP อีกครั้ง */
  ERROR = 'ERROR',
  /** @description คุณขอรับรหัส OTP หรือทำรายการไม่ถูกต้องมากเกินไป กรุณารอทำรายการใหม่ในอีก 10 นาที */
  BLOCK = 'BLOCK',
  /** @description รหัส OTP หมดอายุแล้ว กรุณาขอรหัสใหม่ */
  EXPIRE = 'EXPIRE',
}

export type VerifyOtpResponse = BaseApiResponse<Record<string, never>>
export type VerifyOtpResult = {
  status: VERIFY_OTP_STATUS
}

export type VerifyOtpHook = {
  callVerifyOtp: (request: VerifyOtpRequest) => Promise<VerifyOtpResult>
}

const mapStatus = (apiResponse: VerifyOtpResponse) => {
  const { status, service_code } = apiResponse
  if (status === 'SUCCESS') return VERIFY_OTP_STATUS.SUCCESS
  if (service_code === 'PIG-4005') return VERIFY_OTP_STATUS.BLOCK
  if (service_code === 'PIG-4007') return VERIFY_OTP_STATUS.EXPIRE
  return VERIFY_OTP_STATUS.ERROR
}

export const useVerifyOtp = (): VerifyOtpHook => {
  const { pigspinApiInstance } = useAxios()

  const callVerifyOtp = async (request: VerifyOtpRequest) => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/otp/verify',
      method: 'POST',
      validateStatus: (status) => status >= 200 && status <= 403,
    }
    const payload = {
      phone_number: request.phoneNumber,
      otp_code: request.otpKey,
      otp_token: request.otpToken,
    }
    const response = await fetchWithJSON<VerifyOtpResponse>(
      pigspinApiInstance,
      requestConfig,
      payload,
    )
    return { status: mapStatus(response) }
  }

  return {
    callVerifyOtp,
  }
}
