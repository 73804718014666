import { ThemeProvider } from 'styled-components'
import NiceModal from '@ebay/nice-modal-react'
import DailySpin from '@pig-common/pages-common/daily-spin'

import styledTheme from '@pig-common/styles/scss/abstracts/theme.json'

function DailySpinPage() {
  return (
    <ThemeProvider theme={styledTheme}>
      <NiceModal.Provider>
        <DailySpin />
      </NiceModal.Provider>
    </ThemeProvider>
  )
}

export { DailySpinPage }
