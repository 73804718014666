import React from 'react'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import NiceModal from '@ebay/nice-modal-react'

import { LineResponse, LinkLine } from '@pig-common/server/line'
import { useLegacyProfile } from '@pig-common/hooks/useLegacyProfile'
import { AlertConfirmModal } from '@pig-common/components/Modal/AlertConfirmationModal/AlertConfirmationModal'
import { useLoading } from '@pig-common/hooks/useLoading'
import { notification } from '@pig-frontend/uikit'
import { useLogin } from '@pig-common/hooks/useLogin'
import dayjs from 'dayjs'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import { useCookies } from 'react-cookie'
import { useRecoilState } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'

export type LineLinkProps = {
  lineResponse: LineResponse
}

export default function Link(props: LineLinkProps) {
  const { lineResponse } = props
  const router = useRouter()
  const { callLinkLine, callProfile } = useLegacyProfile()
  const { loading, stopLoading } = useLoading()
  const { callLineLogin } = useLogin()
  const [, setCookies] = useCookies([
    COOKIES_KEY.CFID,
    COOKIES_KEY.CUSTOMER_CODE,
    COOKIES_KEY.UID,
  ])
  const [, setAuth] = useRecoilState(authStateCommon.authState)

  const linkLine = async () => {
    const response = await callLinkLine(lineResponse.lineIdToken)
    if (response.status === 'SUCCESS') {
      const result = await callLineLogin({
        idToken: lineResponse.lineIdToken,
      })
      await callProfile(result?.cfid)
      setAuth({
        cfid: result?.cfid,
        customerCode: result?.CustomerCode,
        gameToken: result?.GameToken,
        name: result?.CustomerName,
        isLoginPhoneSuccess: true,
        userUID: result?.UserUid || '',
      })
      const expires = dayjs().add(3, 'months').toDate()
      setCookies(COOKIES_KEY.CFID, result?.cfid, { expires, path: '/' })
      setCookies(COOKIES_KEY.UID, result?.uid || '0000000000', {
        path: '/',
      })
      setCookies(COOKIES_KEY.CUSTOMER_CODE, result?.CustomerCode || '', {
        path: '/',
      })
      router.push('/profile', '/profile', { scroll: true })
      notification.success({
        message: 'เชื่อมต่อ LINE สำเร็จ',
        duration: 3,
      })
    } else {
      await router.push('/profile', '/profile', { scroll: true })
      NiceModal.show(AlertConfirmModal, {
        hideOnClose: true,
        header: 'เชื่อมต่อ LINE ไม่สำเร็จ',
        content: response.service_message || 'มีบางอย่างผิดพลาด',
        buttonConfirmText: 'ตกลง',
      })
    }
  }

  React.useEffect(() => {
    linkLine()
    loading()
    return () => {
      stopLoading()
    }
  }, [])

  return (
    <div className="text-center" style={{ height: '50vh', display: 'flex' }} />
  )
}

export const getLineLinkServerSideProps: GetServerSideProps = async (
  context,
) => {
  const lineResponse = await LinkLine(context)
  if (!lineResponse.lineIdToken) {
    return {
      props: {},
      redirect: {
        destination: '/',
        permanent: false,
      },
    }
  }
  return {
    props: {
      lineResponse,
    },
  }
}
