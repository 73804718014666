import { ThemeProvider } from 'styled-components'
import NiceModal from '@ebay/nice-modal-react'
import Notifications from '@pig-common/pages-common/notifications'

import styledTheme from '@pig-common/styles/scss/abstracts/theme.json'

function NotificationsPage() {
  return (
    <ThemeProvider theme={styledTheme}>
      <NiceModal.Provider>
        <Notifications />
      </NiceModal.Provider>
    </ThemeProvider>
  )
}

export { NotificationsPage }
