import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import getBigWinChampionList, {
  BigWinChampionDetail,
} from '@pig-common/services/bigWin/getBigWinChampionList'
import { LoadMoreButton } from '@pig-common/components/Button'
import { Images } from '@pig-common/components/Images'
import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'
import useWindowSize from '@pig-common/utils/useWindowSize'
import { toggleLockScroll } from '@pig-common/services/lockScroll/toggleLockScroll'
import ChampionCard from '@pig-common/components/DailyQuests/champion/ChampionCard'
import { isBreakingUp } from '@pig-common/utils/utils'
import championImageMD from '@pig-common/public/images/dailyquests/chapion-header-MB.png'
import championImageDT from '@pig-common/public/images/dailyquests/chapion-header-DT.png'

const ImageViewer = dynamic(
  () => import('@pig-common/components/ImageViewer/ImageViewer'),
  { ssr: false },
)

export default function Champion() {
  const [isImageOpen, setImageOpen] = useState<boolean>(false)
  const [isFetching, setFetching] = useState<boolean>(false)
  const [getCurrentImage, setCurrentImage] = useState<string>()
  const [getChampionList, setChampionList] = useState<BigWinChampionDetail[]>(
    [],
  )
  const [getChampionQueue, setChampionQueue] = useState<BigWinChampionDetail[]>(
    [],
  )
  const [getNewChampionList, setNewChampionList] = useState<
    BigWinChampionDetail[]
  >([])
  const [getLastChampion, setLastChampion] = useState<BigWinChampionDetail>()
  const [getPage, setPage] = useState<number>(1)
  const itemPerPage = 10
  const { mq } = useWindowSize()
  const screenType = isBreakingUp(mq, 'lg') ? 'DT' : 'MB'

  const toggleImageView = (url?: string) => {
    const toggledState = !isImageOpen
    if (url) {
      setCurrentImage(url)
    }
    toggleLockScroll(toggledState)
    setImageOpen(toggledState)
  }

  const fetchBigwinChampion = async (customerCode = '') => {
    if (isFetching) return false
    const nextPage = getPage
    setFetching(true)
    const bigwinFetchData = await getBigWinChampionList({
      page: nextPage,
      limit: itemPerPage,
    })
    if (bigwinFetchData) {
      const fetchedData = bigwinFetchData
      setChampionQueue(fetchedData)
      setPage(getPage + 1)
    }
    return false
  }
  const newChampion = async () => {
    const bigwinFetchData = await getBigWinChampionList({ page: 1, limit: 1 })
    const upcomingChampion =
      bigwinFetchData && bigwinFetchData.length > 0 ? bigwinFetchData[0] : null
    if (upcomingChampion && upcomingChampion.uid !== getLastChampion?.uid) {
      setLastChampion(upcomingChampion)
      setNewChampionList([...[upcomingChampion], ...getNewChampionList])
    }
  }

  let autoLoadSwitch = true
  const onScroll = async () => {
    if (!isFetching && autoLoadSwitch) {
      const { scrollHeight, clientHeight, scrollTop } = document.documentElement
      const scrollOffset = 60
      const scrollPointer = scrollTop + clientHeight + scrollOffset
      const scrollTrigger: boolean = scrollHeight - scrollPointer < 0
      if (scrollTrigger && !isFetching) {
        autoLoadSwitch = false
        document.removeEventListener('scroll', onScroll)
        fetchBigwinChampion()
        return true
      }
    }
    return false
  }
  const mergeChampionWithGameDetail = async (
    mergedData: BigWinChampionDetail[],
  ) => {
    const mergedDataWithGameDetail = mergedData
    setChampionList(mergedDataWithGameDetail)
  }

  useEffect(() => {
    setFetching(false)
    if (getChampionQueue[0]) {
      const results = getChampionList.filter((entry) => {
        return getChampionQueue[0].uid === entry.uid
      })
      if (results.length > 0) {
        setChampionQueue([])
      } else {
        const mergedData = getChampionList.concat(getChampionQueue)
        setLastChampion(mergedData[0])
        mergeChampionWithGameDetail(mergedData)
      }
    }
  }, [getChampionQueue])
  useEffect(() => {
    fetchBigwinChampion()
  }, [])

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll)
    const min = 4000
    const max = 8000
    const rand = min + Math.random() * (max - min)
    const timerId = setInterval(() => {
      newChampion()
    }, rand)
    return () => {
      clearInterval(timerId)
      document.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <section className="container ps-dailyquest">
      <ImageViewer isImageOpen={isImageOpen} closeFunction={toggleImageView}>
        <img
          className="ps-champion-image-viewer__image"
          src={getCurrentImage}
          alt=""
        />
      </ImageViewer>
      <div className="ps-gamequests">
        <div className="ps-champion-header">
          <Images
            src={screenType == 'MB' ? championImageMD : championImageDT}
            alt="champion-header"
          />
        </div>
        <div className="ps-champion-label">แชมป์เปี้ยน</div>
        {getNewChampionList && (
          <div
            className="ps-champion-cards ps-champion-cards--new-champion"
            style={{ overflowX: 'hidden' }}
          >
            {getNewChampionList
              .slice(0)
              .reverse()
              .map((championData, index) => (
                <ChampionCard
                  championData={championData}
                  key={`new-champion-${championData?.uid}`}
                  onClick={toggleImageView}
                />
              ))}
          </div>
        )}
        {getChampionList ? (
          <div
            className="ps-champion-cards ps-champion-cards--fetched-champion"
            style={{ overflowX: 'hidden' }}
          >
            {getChampionList.map((championData, index) => (
              <ChampionCard
                championData={championData}
                key={`fetched-champion-${championData?.uid}`}
                onClick={toggleImageView}
              />
            ))}
          </div>
        ) : (
          <div
            data-testid="dailyquests-empty"
            className="ps-gamequest__empty-list"
          >
            <Images
              src="/images/dailyquests/pig-error.png"
              alt="main-quest-error"
              width={166}
              height={157}
            />
            <div>
              <b>เกมกำลังปิดปรับปรุง</b>
            </div>
            <div>{autoLineBreakerWithBr('ยังไม่มีรายการ แชมป์เปี้ยน')}</div>
          </div>
        )}
      </div>
      <LoadMoreButton
        // isLoading={isFetching}
        loadMoreText="ดูเพิ่มเติม"
        noMoreText="ไม่พบรายการเพิ่มเติม"
        onLoadMore={fetchBigwinChampion}
      />
    </section>
  )
}
