import styled from 'styled-components'
import { SpinRanking } from '@pig-common/hooks/useSpin'
import { RunningNumber } from '@pig-common/components/GameCanvas/iFrameWalletCard'
import UserBadge from '@pig-common/components/User/avatar/UserBadge'

const RankingTable = styled.div`
  display: grid;
  flex-wrap: nowrap;
  flex-direction: column;
  margin: auto;
  color: ${({ theme }) => theme['color-greyscale-400']};
  border-radius: 8px;
  overflow: hidden;
  user-select: none;
  row-gap: 4px;
  width: 100%;
`
const RankingRow = styled.div<{ isJackpot?: boolean }>`
  display: grid;
  grid-gap: 8px;
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  grid-template-columns: 1fr 1fr;
  padding: 4px 10px;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme, isJackpot }) =>
    isJackpot ? theme['butterscotch-200'] : theme['white-opacity-50']};
  border-radius: 8px;
`
const RankBar = styled.div`
  height: 32px;
  min-width: 20px;
  border-radius: 8px;
  padding: 0px 6px 0px 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme['color-greyscale-700']};
  font-size: 20px;
  font-weight: 400;
`
const RankIndex = styled.div<{ isJackpot?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme, isJackpot }) =>
    isJackpot ? theme['butterscotch-500'] : theme['color-greyscale-200']};
  width: 20px;
  justify-content: center;
`
const RankTitle = styled.div`
  color: ${({ theme }) => theme['color-greyscale-700']};
  display: grid;
  column-gap: 6px;
  grid-template-columns: 32px 1fr;
  align-items: center;
  padding-left: 16px;
`

const RankingSection = ({
  ranking,
  daily,
  monthly,
}: {
  ranking: SpinRanking[]
  daily: number
  monthly: number
}) => {
  return (
    <RankingTable>
      {ranking.map(
        ({ index, customerCode, amount, displayName, byTicket, vip }) => {
          const isJackpot = amount > 1000
          return (
            <RankingRow key={customerCode} isJackpot={isJackpot}>
              <RankTitle className="text-truncate">
                <RankIndex isJackpot={isJackpot}>{index}</RankIndex>
                {vip?.vipTier && vip?.vipLevel && (
                  <UserBadge tier={vip.vipTier} level={vip.vipLevel} />
                )}
                {`${displayName}`}
              </RankTitle>
              <RankBar>
                {byTicket && <div className="ps-icon-ticket ms-1" />}
                <RunningNumber
                  key={`daily-spin-ranking-${customerCode}`}
                  isCounting
                  start={0}
                  end={amount}
                  duration={2}
                  thousandsSeparator=","
                  decimalPlaces={2}
                />
              </RankBar>
            </RankingRow>
          )
        },
      )}
    </RankingTable>
  )
}

export default RankingSection
