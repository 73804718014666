import React, { useEffect, useState } from 'react'

export type ProgressBarProps = {
  id?: string
  label: string
  labelColor?: string
  currentProgress?: number
  maxProgress?: number
  progressColor?: string
  width?: string
  height?: string
  hasChecked?: boolean
}

const DEFAULT_PROGRESS_COLOR = 'green'

const ProgressBar = ({
  id,
  label,
  labelColor,
  currentProgress = 0,
  maxProgress,
  progressColor = DEFAULT_PROGRESS_COLOR,
  width,
  height,
  hasChecked = false,
}: ProgressBarProps): JSX.Element => {
  const [percentage, setPercentage] = useState<number>(0)

  const calculatePercentage = () => {
    if (maxProgress && currentProgress && currentProgress !== 0) {
      setPercentage((currentProgress / maxProgress) * 100)
    } else {
      setPercentage(0)
    }
  }

  useEffect(() => {
    calculatePercentage()
  }, [maxProgress, currentProgress])

  return (
    <div id={id} data-testid={id} className="ps-progress-bar-section">
      {hasChecked && (
        <div className="ps-progress-bar-section__icon-ct">
          <i
            data-testid={`${id}-check`}
            className="ps-icon-check-filled ps-progress-bar-section__checked-icon"
          />
          <div className="ps-progress-bar-section__checked-icon--filled" />
        </div>
      )}
      <div
        className={`ps-progress-bar ${
          hasChecked && 'ps-progress-bar--mainquest'
        }`}
        style={{ width, height }}
      >
        <div
          data-testid={`${id}-progress`}
          className="ps-progress-bar__progress"
          style={{ width: `${percentage}%`, backgroundColor: progressColor }}
        >
          <span
            data-testid={`${id}-label`}
            className="ps-progress-bar__label"
            style={{ color: labelColor || 'white' }}
          >
            {label}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
