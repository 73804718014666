import { AxiosRequestConfig } from 'axios'
import { useRecoilValue } from 'recoil'

import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'

import { authStateCommon } from '../recoils'
import { useAxios } from './useAxios'

export enum WITHDRAW_TYPE {
  AFFILIATE = 'AFFILIATE',
}

export type WithdrawRequest = {
  amount: number
  transfer_type?: WITHDRAW_TYPE
}

export type WithdrawResponse = BaseApiResponse<any>

export enum WITHDRAW_STATUS {
  SUCCESS = 'Success',
  ERROR = 'Error',
}
export type WithdrawResult = {
  Status: WITHDRAW_STATUS
  Message: string
}

export type WithdrawHook = {
  callWithdraw: (request: WithdrawRequest) => Promise<WithdrawResult>
}

export const useWithdraw = (): WithdrawHook => {
  const { boApiInstance } = useAxios()
  const auth = useRecoilValue(authStateCommon.authState)
  const callWithdraw = async (
    request: WithdrawRequest,
  ): Promise<WithdrawResult> => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/payment/withdraw/request-transactions',
      method: 'POST',
      data: {
        amount: request.amount,
        uid: auth.userUID,
        transfer_type: request.transfer_type,
      },
    }
    const response = await fetchWithJSON<any>(boApiInstance, requestConfig)
    if (response.service_code === 'BOE-2000') {
      return {
        Status: WITHDRAW_STATUS.SUCCESS,
        Message: '',
      }
    }
    if (response.service_code === 'BOE-4045') {
      return {
        Status: WITHDRAW_STATUS.ERROR,
        Message: 'ยอดเงินคงเหลือไม่เพียงพอ',
      }
    }
    return {
      Status: WITHDRAW_STATUS.ERROR,
      Message: 'ถอนเงินไม่สำเร็จ',
    }
  }
  return {
    callWithdraw,
  }
}
