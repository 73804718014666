import NiceModal from '@ebay/nice-modal-react'
import { ThemeProvider } from 'styled-components'

import styledTheme from '@pig-common/styles/scss/abstracts/theme.json'
import ResetPin, {
  ResetPinProps,
  getResetPinServerSideProps,
} from '@pig-common/pages-common/reset-pin'

function ResetPinPage(props: ResetPinProps) {
  return (
    <ThemeProvider theme={styledTheme}>
      <NiceModal.Provider>
        <ResetPin {...props} />
      </NiceModal.Provider>
    </ThemeProvider>
  )
}

export { ResetPinPage, getResetPinServerSideProps }
export type { ResetPinProps }
