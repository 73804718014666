import React, { useState } from 'react'
import RedemptionCard from './RedemptionCard/RedemptionCard'
import {
  FreeInGame,
  FREE_IN_GAME_CLAIM_STATUS,
  REDEEM_IN_GAME_STATUS,
} from '@pig-common/services/pigShop'
import { numberWithCommas } from '@pig-common/utils/number'

export type responseContentType = {
  status: REDEEM_IN_GAME_STATUS | ''
  message: string
  detail: string
}

export type ShopRedemptionProps = {
  id: string
  userCode: string
  freeCreditData: FreeInGame[]
  refetch: () => void
}

const ShopRedemption = ({
  id,
  userCode,
  freeCreditData = [],
  refetch,
}: ShopRedemptionProps) => {
  const [loading, setLoading] = useState(false)

  const triggerLoading = (trigger: boolean) => {
    setLoading(trigger)
  }

  return (
    <div id={id} key={id} data-testid={id} className="ps-pig-shop">
      {freeCreditData.length > 0 && (
        <>
          <div data-testid="free-credit-section__title" className="mb-1">
            <b>แลกฟรีเครดิต</b>
          </div>
          <div className="row">
            {freeCreditData.map((freeCredit: FreeInGame) => {
              const tickets = numberWithCommas(parseInt(freeCredit.tickets))
              return (
                <div key={freeCredit.reward_uid} className="col-12 col-md-6">
                  <RedemptionCard
                    id={`free-credit-section__card-${freeCredit.reward_uid}`}
                    title={freeCredit.title}
                    subTitle={freeCredit.sub_title}
                    description={freeCredit.description}
                    tickets={tickets}
                    refetch={refetch}
                    handleLoading={triggerLoading}
                    item={freeCredit}
                    buttonText={`ใช้ ${tickets} Tickets`}
                    isDisabled={
                      freeCredit.status ===
                        FREE_IN_GAME_CLAIM_STATUS.DISABLED || loading
                    }
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default ShopRedemption
