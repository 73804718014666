export enum vipTierMapper {
  bronze = 'Bronze Level',
  silver = 'Silver Level',
  gold = 'Gold Level',
  diamond = 'Diamond Level',
}

export type IVIPTierTypes = 'bronze' | 'silver' | 'gold' | 'diamond'

export interface IVIPStatusData {
  vipIcon?: string
  userVipLevel?: string
  userTier?: IVIPTierTypes
  userStar?: number
  userProgress?: number
  nextLevel?: {
    vipLevel?: string
    turnover?: number
  }
}

export interface IVIPLevelDetail {
  icon?: string
  level?: string
  tier?: IVIPTierTypes
  detail?: string[]
}

export interface IVIPMasterLevelDetail {
  vipMasterUid?: string
  level?: string
  accTurnover?: number
  star?: number
  bonusButton?: 'Received' | 'Disabled' | 'Available' | 'Hidden'
}

export interface IVIPMasterTier {
  tierIcon?: string
  tier?: IVIPTierTypes
  levels?: IVIPMasterLevelDetail[]
}

export interface IVipSummaryData {
  nextVip?: IVIPLevelDetail[]
  currentVip?: IVIPLevelDetail[]
  vipMaster?: IVIPMasterTier[]
}

export interface IRedeemResponseData {
  message?: string
  detail?: string[]
}

export interface IVIPInboxData {
  vipLevel?: string
  vipStar?: number
  vipTier?: IVIPTierTypes
}

interface IVIPReward {
  uid?: string
  type?: string
  createdAt?: string
  detail?: string
  updatedAt?: string
  vipMasterUid?: string
  value?: string
}

export interface IVIPResponseData {
  vipMaster?: {
    createdAt?: string
    uid?: string
    tierIcon?: string
    level?: number
    tier?: IVIPTierTypes
    updatedAt?: string
    icon?: string
    accTurnover?: number
  }
  vipReward?: IVIPReward[]
}
