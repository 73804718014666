import classNames from 'classnames'
import { coinIcon, refreshIcon } from '@pig-common/constants/images'
import { Image } from '@pig-frontend/uikit'

export enum WALLET_CARD_TYPE {
  DEFAULT = 'default',
  BONUS = 'bonus',
}

export type WalletCardProps = {
  id?: string
  className?: string
  type?: WALLET_CARD_TYPE
  time: string
  amount: string
  phoneNumber?: string
  loading?: boolean
  refreshUserBalance?: () => void
}

const WalletCard = ({
  id = 'wallet-card',
  className,
  type = WALLET_CARD_TYPE.DEFAULT,
  time,
  amount,
  phoneNumber,
  loading = false,
  refreshUserBalance,
}: WalletCardProps): JSX.Element => {
  const bonusClassName = classNames(
    type === WALLET_CARD_TYPE.BONUS && 'ps-wallet-card--bonus',
    className,
  )
  const bonusTextClass =
    type === WALLET_CARD_TYPE.BONUS && 'ps-wallet-card__text-bonus'

  return (
    <div className={`ps-wallet-card ${bonusClassName}`}>
      <div className="ps-wallet-card__header">
        <div
          data-testid={`${id}__title`}
          className={`ps-wallet-card__text ${bonusTextClass}`}
        >
          {type === WALLET_CARD_TYPE.BONUS ? (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              กระเป๋าโบนัส{' '}
              <Image
                src={coinIcon}
                alt="pigbet coin"
                style={{ marginTop: -5, marginLeft: 5 }}
              />
            </span>
          ) : (
            <span>กระเป๋าเงินสด ฿</span>
          )}
        </div>
        {/* {loading ? (
          <div className="ps-wallet-card__amount-spin">
            <Spin
              indicator={
                <Image
                  src={refreshIcon}
                  alt="refresh wallet"
                  width={24}
                  height={24}
                />
              }
            />
          </div>
			) : ()}
		*/}
        <h3
          data-testid={`${id}__amount`}
          className={`ps-wallet-card__amount ${bonusTextClass}`}
        >
          {!loading ? amount : '-'}
        </h3>
        <div className="ps-wallet-card__phone-number">{phoneNumber}</div>
      </div>
      <div data-testid={`${id}__footer`} className="ps-wallet-card__footer">
        <div
          className="ps-wallet-card__icon-container"
          role="button"
          aria-hidden
          tabIndex={0}
          onClick={refreshUserBalance}
        >
          <Image
            src={refreshIcon}
            alt="refresh wallet"
            width={12}
            height={12}
            className={`ps-wallet-card__icon ${
              loading && 'ps-wallet-card__active'
            }`}
          />
        </div>
        {!loading && (
          <div className={`ps-wallet-card__time ${bonusTextClass}`}>
            ข้อมูล ณ เวลา {time} น.
          </div>
        )}
      </div>
    </div>
  )
}

export default WalletCard
