import { Space } from 'antd'
import styled from 'styled-components'

import { screen } from '@pig-common/utils/responsive'
import { useResponsiveState } from '@pig-common/hooks/useResponsive'
import {
  HalfOfDownlineDeposit,
  KYCFreeCredit,
  UplineGetDownlineLoss,
} from '@pig-common/components/Icon/Coin'

type BaseBannerProps = {
  icon: JSX.Element
  content: JSX.Element
}

const BannerWrapper = styled.div`
  box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
`

const BannerConntent = styled(Space)`
  line-height: 1;
  .ant-space-item:last-child {
    margin-top: -5px;
    @media ${screen.md} {
      margin-top: 0;
    }
  }
`

const BaseBanner: React.FC<BaseBannerProps> = ({ content, icon }) => {
  const { isMD, isLG } = useResponsiveState()
  return (
    <Space size={isMD ? [20, 0] : [10, 0]}>
      {icon}
      <BannerConntent
        direction={isLG ? 'horizontal' : 'vertical'}
        size={[10, 0]}
      >
        {content}
      </BannerConntent>
    </Space>
  )
}

const Get10BahtImmediately: React.FunctionComponent<any> = () => (
  <BaseBanner
    icon={<KYCFreeCredit />}
    content={
      <>
        <strong>ได้รับโบนัส 50 บาท ทันที ! </strong>
        <span>เมื่อเพื่อนยืนยันตัวตน</span>
      </>
    }
  />
)

const Get50PercentOfDeposit: React.FunctionComponent<any> = () => (
  <BaseBanner
    icon={<HalfOfDownlineDeposit />}
    content={
      <>
        <strong>ได้รับโบนัสอีก 50 บาท !</strong>
        <span>เมื่อเพื่อนทําเทิร์นด้วยเงินสดครบ 500 บาท</span>
      </>
    }
  />
)

const Get10PercentOfLossing: React.FunctionComponent<any> = () => (
  <BaseBanner
    icon={<UplineGetDownlineLoss />}
    content={
      <>
        <strong>ได้รับเงิน 5% จากยอดเสีย</strong>
        <span>ของเพื่อน เป็นระยะเวลา 5 เดือน</span>
      </>
    }
  />
)

export type InviteBannerProps = {
  name:
    | 'get10BahtImmediately'
    | 'get50PercentOfDeposit'
    | 'get10PercentOfLossing'
}

export const InviteBanner: React.FC<InviteBannerProps> = ({ name }) => {
  return (
    <BannerWrapper className="ps-affiliate-intro__card">
      {name === 'get10BahtImmediately' && <Get10BahtImmediately />}
      {name === 'get50PercentOfDeposit' && <Get50PercentOfDeposit />}
      {name === 'get10PercentOfLossing' && <Get10PercentOfLossing />}
    </BannerWrapper>
  )
}

export default InviteBanner
