import React from 'react'

import { Otp, OTP_DATA } from '../Otp'

export type IdentityOtpProps = {
  onSuccess: (result: OTP_DATA) => void
  currentOtpData: OTP_DATA
  title: string
}

const IdentityOtp = ({
  onSuccess,
  currentOtpData,
  title,
}: IdentityOtpProps) => {
  return (
    <div className="ps-identity-otp text-center">
      <h3 className="ps-identity-otp__title mx-auto">{title}</h3>
      <div>
        <Otp
          shouldShowSubTitleText
          otpData={{ ...currentOtpData }}
          isRecovery
          onSuccess={onSuccess}
        />
      </div>
    </div>
  )
}

export default IdentityOtp
