import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { UserStar } from '@pig-common/components/User'
import { Button, notification, VIPTag } from '@pig-frontend/uikit'
import {
  IVIPMasterLevelDetail,
  IVIPMasterTier,
} from '@pig-common/types/Vip.type'
import useVIPRedeemReward from '@pig-common/hooks/useVipRedeemReward'
import useVIPSummary from '@pig-common/hooks/useVipSummary'
import styles from './index.module.scss'

interface IVIPRewardItemProps {
  reward?: IVIPMasterTier
  level?: IVIPMasterLevelDetail
}

export default function VIPRewardItem({
  reward,
  level: masterLevel,
}: IVIPRewardItemProps) {
  const {
    mutate: claimReward,
    isSuccess: isClaimSuccess,
    data: claimedRewardResponse,
    reset: resetClaimingState,
  } = useVIPRedeemReward()
  const { isRefetching: isRefetchingVIPSummary } = useVIPSummary({
    enabled: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (isClaimSuccess) {
      notification.success({
        message: (
          <div className={styles['claim-success-noti']}>
            <span>{claimedRewardResponse?.data?.message}</span>
            {claimedRewardResponse?.data?.detail?.map((reward) => (
              <p className={styles.paragraph} key={reward}>
                {reward}
              </p>
            ))}
          </div>
        ),
      })
    }
  }, [isClaimSuccess])

  useEffect(() => {
    if (isClaimSuccess && !isRefetchingVIPSummary) {
      setLoading(false)
      resetClaimingState()
    }
  }, [isRefetchingVIPSummary, isClaimSuccess])

  return (
    <div className={styles.reward}>
      <div className={styles.level}>
        <div className={styles.label}>เลเวล</div>
        <VIPTag tier={reward?.tier} level={masterLevel?.level} />
      </div>

      <div className={styles['turn-over']}>
        <div className={styles.label}>ยอดเล่นสะสม (บาท)</div>
        {BigNumber(masterLevel?.accTurnover || '0').toFormat(
          0,
          BigNumber.ROUND_DOWN,
        )}
      </div>

      <div className={styles.star}>
        <div className={styles.label}>ดาว</div>
        <UserStar progress={masterLevel?.star} />
      </div>

      <div className={styles['claim-button']}>
        <div className={styles.label}>โบนัส</div>
        {masterLevel?.bonusButton !== 'Hidden' && (
          <Button
            className={styles.receive}
            type="primary"
            shape="round"
            size="small"
            loading={loading}
            disabled={
              masterLevel?.bonusButton === 'Disabled' ||
              masterLevel?.bonusButton === 'Received' ||
              loading
            }
            {...(masterLevel?.bonusButton === 'Available' && {
              onClick: () => {
                setLoading(true)
                claimReward({
                  rewardUID: masterLevel?.vipMasterUid || '',
                })
              },
            })}
          >
            {masterLevel?.bonusButton === 'Received' ? 'รับแล้ว' : 'รับ'}
          </Button>
        )}
      </div>
    </div>
  )
}
