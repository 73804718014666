export function parse2Digit(number: number): string {
  if (number > 0 && number < 10) return `0${number}`
  if (number) return `${number}`
  if (number === 0) return '00'
  return ''
}
export function parse2Decimal(number: number | string): string {
  const intNumber = parseFloat(number.toString())
  const toFinalcialWith2Decipmal = intNumber.toLocaleString(undefined, {
    minimumFractionDigits: 2,
  })

  return toFinalcialWith2Decipmal
}
