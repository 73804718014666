export enum OS {
  MacOS = 'Mac OS',
  IOS = 'iOS',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux',
}

export const getOS = (): OS | null => {
  const { userAgent } = window.navigator
  if (/Macintosh|MacIntel|MacPPC|Mac68K/i.exec(userAgent)) return OS.MacOS
  if (/iPad|iPhone|iPod/i.exec(userAgent)) return OS.IOS
  if (/Win32|Win64|Windows|WinCE/i.exec(userAgent)) return OS.Windows
  if (userAgent.includes('Android')) return OS.Android
  if (userAgent.includes('Linux')) return OS.Linux
  return null
}
