import React from 'react'
import classNames from 'classnames'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { StepperProgressProps } from './Stepper.type'

const StepperProgressMobile = ({
  title = '',
  subtitle = '',
  currentNumber = 1,
  length = 1,
  className = '',
}: StepperProgressProps) => {
  const percentage = (currentNumber / length) * 100

  return (
    <div className={classNames('ps-stepper-circular', className)}>
      <div className="ps-stepper-circular__progress">
        <CircularProgressbar
          value={percentage}
          text={`${currentNumber} / ${length}`}
          styles={buildStyles({
            pathTransitionDuration: 0.5,
          })}
        />
      </div>
      <div className="ps-stepper-circular__step-wrapper">
        <div className="ps-stepper-circular__step-title">{title}</div>
        <div className="ps-stepper-circular__step-subtitle">{subtitle}</div>
      </div>
    </div>
  )
}

export default StepperProgressMobile
