import NiceModal from '@ebay/nice-modal-react'
import SetPhone from '@pig-common/pages-common/profile/set-phone'

function SetPhonePage() {
  return (
    <NiceModal.Provider>
      <SetPhone />
    </NiceModal.Provider>
  )
}

export { SetPhonePage }
