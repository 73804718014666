import NiceModal from '@ebay/nice-modal-react'
import SetPhoneBank from '@pig-common/pages-common/profile/set-phone-bank'

function SetPhoneBankPage() {
  return (
    <NiceModal.Provider>
      <SetPhoneBank />
    </NiceModal.Provider>
  )
}

export { SetPhoneBankPage }
