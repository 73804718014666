import { useState } from 'react'
import { useRouter } from 'next/router'
import { checkMQ } from '@pig-common/utils/utils'
import useWindowSize from '@pig-common/utils/useWindowSize'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'

export type MarqueeCardProps = {
  imgSrc: string
  id: string
  slug?: string
  ctaText?: string
}

const MarqueeCard = ({
  imgSrc,
  id,
  slug = 'gameslug',
  ctaText = 'ลองเล่นฟรี',
}: MarqueeCardProps) => {
  const { width } = useWindowSize()
  const [isShowButton, setShowButton] = useState<boolean>(false)
  const router = useRouter()

  const goToGame = () => {
    router.push(`/games/${slug}`)
  }
  const setupButton = () => {
    if (!checkMQ(width, ['xl', 'lg'])) {
      setTimeout(() => setShowButton(true), 100)
    } else {
      setShowButton(true)
    }
  }
  return (
    <div
      onMouseEnter={() => setupButton()}
      onMouseLeave={() => setShowButton(false)}
      id={id}
      className="ps-marquee-card"
    >
      <img
        alt={`maquee-card-${id}`}
        src={imgSrc}
        className="ps-marquee-card__bg-image"
      />
      {isShowButton && (
        <Button
          className="ps-marquee-card__btn"
          onClick={() => goToGame()}
          type={BUTTON_TYPE.NONE}
        >
          {ctaText}
        </Button>
      )}
    </div>
  )
}
export default MarqueeCard
