import React from 'react'
import styled from 'styled-components'

type CoinConfig = {
  times: number
  duration: number
  delay: number
  top?: number
  size?: number
}
const BonusTransfer = styled.div`
  position: absolute;
  top: 0%;
  right: calc(100% -64px -16px);
  width: 36px;
  height: 100%;
  z-index: 1;
  background-origin: 100% 50%;
  background-position: 0% center;
  background-size: cover;
`

const Coin = styled.div<{ config: CoinConfig }>`
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0px;
  animation: transfer-bonus 0.8s ease-in;
  background: url('/images/coin.svg') center no-repeat;
  transform-origin: center;
  filter: brightness(0.4);
  @keyframes transfer-bonus {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(-64px);
    }
  }
  &.big-coin {
    width: 22px;
    height: 22px;
    filter: brightness(1);
  }
  ${({ config }: { config: CoinConfig }) => {
    const coinAnimateConfig = {
      top: config.top ? `${config.top}%` : '',
      'animation-delay': `${config.delay}s`,
      'animation-duration': `${config.duration}s`,
      'animation-iteration-count': `${config.times}`,
    }
    return coinAnimateConfig
  }}
`
const globalDelay = 0
const clampDuration = { min: 0.5, max: 0.9 }
const clampDelay = { min: 0, max: 0.7 }
const coinAmount = 6
const animationDuration = 3
const coinDuration = 1
const mainCoinConfig = {
  times: animationDuration / coinDuration,
  duration: coinDuration,
  delay: globalDelay,
  top: 25,
  size: 24,
}
const mainCoinConfig2 = {
  times: animationDuration / coinDuration,
  duration: coinDuration,
  delay: globalDelay + coinDuration / 2,
  top: 25,
  size: 24,
}
const BonusTransferAnimate = React.memo(() => {
  const getCoinAnimateConfig = (): CoinConfig => {
    const duration =
      Math.random() * (clampDuration.max - clampDuration.min) +
      clampDuration.min
    const times = Math.floor(4 / duration)
    const delay =
      Math.random() * (clampDelay.max - clampDelay.min) + clampDelay.min
    const top = Math.random() * (50 - 20) + 20
    return {
      times,
      duration,
      delay,
      top,
    }
  }
  const coins = []
  for (let i = 0; i < coinAmount; i += 1) {
    coins.push(<Coin config={getCoinAnimateConfig()} key={i} />)
  }
  return (
    <BonusTransfer>
      <Coin className="big-coin" config={mainCoinConfig} key="big-coin" />
      <Coin className="big-coin" config={mainCoinConfig2} key="big-coin-2" />
    </BonusTransfer>
  )
})
BonusTransferAnimate.displayName = 'BonusTransferAnimate'
export default BonusTransferAnimate
